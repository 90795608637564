import React, { createContext, useState, useContext } from "react";

type RightMenuType = "notificação" | "perfil";

interface MenuContextData {
  isRightMenuOpen: boolean;
  isLeftMenuOpen: boolean;
  inactive: boolean;
  leftMenuWidth: number;
  rightMenuType: RightMenuType;
  setLeftMenuWidth(width: number): void;
  toggleLeftMenu(): void;
  toggleRightMenu(type: RightMenuType): void;
  initialRightMenu(): void;
}

const MenuContext = createContext<MenuContextData>({} as MenuContextData);

const MenuProvider: React.FC = ({ children }) => {
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [rightMenuType, setRightMenuType] =
    useState<RightMenuType>("notificação");
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [leftMenuWidth, setLeftMenuWidth] = useState(0);

  const toggleLeftMenu = () => {
    setIsLeftMenuOpen(!isLeftMenuOpen);
  };

  const toggleRightMenu = (type: RightMenuType) => {
    setIsRightMenuOpen(!isRightMenuOpen);
    if (!isRightMenuOpen === false) {
      setTimeout(() => {
        setRightMenuType(type);
      }, 200);
    } else {
      setRightMenuType(type);
    }
  };

  const initialRightMenu = () => {
    setIsRightMenuOpen(false);
  };

  return (
    <MenuContext.Provider
      value={{
        isRightMenuOpen,
        inactive,
        rightMenuType,
        isLeftMenuOpen,
        leftMenuWidth,
        setLeftMenuWidth,
        toggleLeftMenu,
        toggleRightMenu,
        initialRightMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu() {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error("useMenu must be used within an MenuProvider.");
  }

  return context;
}

export { MenuProvider, useMenu };
