import Provider from "./config";

export const getReport = async (details: any) => {

  const payload: any = {
    tabela: 'movdepos',
    campos: 'Recurso,Entidade,custo,quantidade',
    ...details
  }
  if(payload.agrupamento){
    const agrupador = details.agrupamento.map((item: any) => item)
    payload['agruparPor'] = agrupador.join(',').replace('Núcleo', 'Entidade');

    delete payload.agrupamento
  }

  try {
    const response = await Provider.get('relatorios/?', {
      params: payload
    })
    return response.data
  } catch (error: any) {
    throw Error ("Ocorreu um erro ao tentar buscar os relatórios.")
  }
}