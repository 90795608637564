enum AuthRoutes {
  home = "/",
  white_list = "/white-list",
  classes_explorer = "/classes-explorer",
  user = "/usuarios",
  users = "/permissoes-e-grupos",
  sales_order = "/pedidos-de-saida/inserir",
  orders_commission = "/pedidos-comissao",
  edit_sales_order = "/pedidos-de-saida/resultado/editar/:id",
  service_order = "/pedidos-de-servico/inserir",
  purchase_order = "/pedidos-de-entrada/inserir",
  purchase_service_order = "/pedidos-de-compra-de-servico/inserir",
  selling_service_order = "/pedidos-de-venda-de-servico/inserir",
  result_sales_order = "/pedidos-de-saida/resultado",
  order_report = "/relatorio-pedidos-de-saida",
  result_selling_service_order = "/pedidos-de-venda-de-servico/resultado",
  result_purchase_service_order = "/pedidos-de-compra-de-servico/resultado",
  result_purchase_order = "/pedidos-de-entrada/resultado",
  mirror_sales_order = "/pedidos-de-saida/resultado/espelho-pedido/:id",
  mirror_purchase_order = "/pedidos-de-entrada/resultado/espelho-pedido/:id",
  mirror_purchase_service_order = "/pedidos-de-compra-de-servico/resultado/espelho-pedido/:id",
  mirror_selling_service_order = "/pedidos-de-venda-de-servico/resultado/espelho-pedido/:id",
  order_down = "/baixa-do-pedido",
  light_release = "/lancamento-financeiro",
  title = "/titulos",
  accounting_plan = "/plano-de-contas",
  accounting_entry = "/lancamento-contabil",
  bank_reconciliation = "/conciliacao-bancaria",
  inventory = "/inventario",
  stock_balance = "/saldo-estoque",
  stock_balance_report = "/saldo-estoque/relatorio",
  extract_balance = "/extrato-estoque",
  stock_extract_balance_report = "/extrato-estoque/relatorio",
  employee_file = "/funcionarios/ficha/:id",
  open_orders = "/ordens-abertas",
  price_alert = "/alerta-precos",
  indications = "/indicacoes",
  financial = "/financeiro",
  support = "/suporte",
  training = "/treinamento",
  confirm_email = "/confirmar-email",
  checkout = "/checkout"
}

enum ListRoutes {
  entities = "/entidades",
  resources = "/recursos",
  products = "/produtos",
  clients = "/clientes",
  deposits = "/depositos",
  bookkeeping = "/locais-de-escrituracao",
  stablishment = "/estabelecimentos",
  providers = "/fornecedores",
  seller = "/vendedores",
  services = "/servicos",
  uf = "/unidades-federativas",
  city = "/municipios",
  price_rule = "/regras-de-precificacao",
  core_rule = "/regras-de-nucleo",
  class_rule = "/regras-de-classe",
  cfop_rule = "/regras-de-cfop",
  tax_rule = "/regras-fiscais",
  comission_rule = "/regras-de-comissao",
  payment_conditions = "/condicoes-de-pagamento",
  document_types = "/tipos-de-documentos",
  origin = "/procedencias",
  cfop = "/cfop",
  unit_measures = "/unidades-de-medidas",
  colors = "/cores",
  sizes = "/tamanhos",
  selling_order = "/pedidos-de-saida",
  buying_order = "/pedidos-de-entrada",
  purchase_service_order = "/pedidos-de-compra-de-servico",
  selling_service_order = "/pedidos-de-venda-de-servico",
  calc_base = "/bases-de-calculo",
  card_flag = "/bandeiras-de-cartao",
  banks = "/bancos",
  employees = "/funcionarios",
  tax_codes = "/codigos-de-tributacao",
  stock_move = "/movimentacao-depositos",
  cost_center = "/centro-de-custo",
  billing_portfolio = "/carteiras-de-cobranca",
  expenses = "/despesas",
  recipes = "/receitas",
  availables = "/disponiveis",
  carriers = "/transportadoras",
}

enum InsertRoutes {
  insert_entities = "/entidades/inserir",
  insert_resources = "/recursos/inserir",
  insert_products = "/produtos/inserir",
  insert_clients = "/clientes/inserir",
  insert_deposits = "/depositos/inserir",
  insert_bookkeeping = "/locais-de-escrituracao/inserir",
  insert_stablishment = "/estabelecimentos/inserir",
  insert_providers = "/fornecedores/inserir",
  insert_seller = "/vendedores/inserir",
  insert_services = "/servicos/inserir",
  insert_uf = "/unidades-federativas/inserir",
  insert_city = "/municipios/inserir",
  insert_price_rule = "/regras-de-precificacao/inserir",
  insert_core_rule = "/regras-de-nucleo/inserir",
  insert_class_rule = "/regras-de-classe/inserir",
  insert_cfop_rule = "/regras-de-cfop/inserir",
  insert_tax_rule = "/regras-fiscais/inserir",
  insert_payment_conditions = "/condicoes-de-pagamento/inserir",
  insert_document_types = "/tipos-de-documentos/inserir",
  insert_origin = "/procedencias/inserir",
  insert_cfop = "/cfop/inserir",
  insert_unit_measures = "/unidades-de-medidas/inserir",
  insert_comission_rule = "/regras-de-comissao/inserir",
  insert_colors = "/cores/inserir",
  insert_sizes = "/tamanhos/inserir",
  insert_selling_order = "/pedidos-de-saida/inserir",
  insert_buying_order = "/pedidos-de-entrada/inserir",
  insert_purchase_service_order = "/pedidos-de-compra-de-servico/inserir",
  insert_selling_service_order = "/pedidos-de-venda-de-servico/inserir",
  insert_calc_base = "/bases-de-calculo/inserir",
  insert_card_flag = "/bandeiras-de-cartao/inserir",
  insert_banks = "/bancos/inserir",
  insert_employees = "/funcionarios/inserir",
  insert_tax_codes = "/codigos-de-tributacao/inserir",
  insert_stock_move = "/movimentacao-depositos/inserir",
  insert_cost_center = "/centro-de-custo/inserir",
  insert_billing_portfolio = "/carteiras-de-cobranca/inserir",
  insert_expenses = "/despesas/inserir",
  insert_recipes = "/receitas/inserir",
  insert_availables = "/disponiveis/inserir",
  insert_carriers = "/transportadoras/inserir",
}

enum EditRoutes {
  edit_entities = "/entidades/editar/:id",
  edit_resources = "/recursos/editar/:id",
  edit_products = "/produtos/editar/:id",
  edit_clients = "/clientes/editar/:id",
  edit_deposits = "/depositos/editar/:id",
  edit_bookkeeping = "/locais-de-escrituracao/editar/:id",
  edit_stablishment = "/estabelecimentos/editar/:id",
  edit_providers = "/fornecedores/editar/:id",
  edit_seller = "/vendedores/editar/:id",
  edit_services = "/servicos/editar/:id",
  edit_uf = "/unidades-federativas/editar/:id",
  edit_city = "/municipios/editar/:id",
  edit_price_rule = "/regras-de-precificacao/editar/:id",
  edit_core_rule = "/regras-de-nucleo/editar/:id",
  edit_cfop_rule = "/regras-de-cfop/editar/:id",
  edit_class_rule = "/regras-de-classe/editar/:id",
  edit_tax_rule = "/regras-fiscais/editar/:id",
  edit_payment_conditions = "/condicoes-de-pagamento/editar/:id",
  edit_document_types = "/tipos-de-documentos/editar/:id",
  edit_origin = "/procedencias/editar/:id",
  edit_cfop = "/cfop/editar/:id",
  edit_unit_measures = "/unidades-de-medidas/editar/:id",
  edit_comission_rule = "/regras-de-comissao/editar/:id",
  edit_colors = "/cores/editar/:id",
  edit_sizes = "/tamanhos/editar/:id",
  edit_selling_order = "/pedidos-de-saida/editar/:id",
  edit_buying_order = "/pedidos-de-entrada/editar/:id",
  edit_purchase_service_order = "/pedidos-de-compra-de-servico/editar/:id",
  edit_selling_service_order = "/pedidos-de-venda-de-servico/editar/:id",
  edit_calc_base = "/bases-de-calculo/editar/:id",
  edit_card_flag = "/bandeiras-de-cartao/editar/:id",
  edit_banks = "/bancos/editar/:id",
  edit_employees = "/funcionarios/editar/:id",
  edit_tax_codes = "/codigos-de-tributacao/editar/:id",
  edit_stock_move = "/movimentacao-depositos/editar/:id",
  edit_cost_center = "/centro-de-custo/editar/:id",
  edit_billing_portfolio = "/carteiras-de-cobranca/editar/:id",
  edit_expenses = "/despesas/editar/:id",
  edit_recipes = "/receitas/editar/:id",
  edit_availables = "/disponiveis/editar/:id",
  edit_carriers = "/transportadoras/editar/:id",
  edit_password = "/redefinir-senha/",
}

export { AuthRoutes, ListRoutes, InsertRoutes, EditRoutes };
