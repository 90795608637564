import React, { useEffect, useState } from "react";
import { useFormModal } from "../../contexts/formModal";
import { orderCols } from "../../utils/form";
import { Title } from "../Home/styles";
import { Button } from "../Orders/style";
import NewVideoModal from "./NewVideoModal";
import {
  Card,
  Column,
  Container,
  ContainerModal,
  Content,
  Description,
  DivSticky,
  IndexCard,
  Row,
  TitleCard,
} from "./style";
import useFetch from "../../hooks/useFetch";
import toast from "react-hot-toast";
import YouTube from "react-youtube";
import { AiOutlineLoading } from "react-icons/ai";
import { useAuth } from "../../contexts/auth";

interface ITraining {
  title: string;
  endpoint: string;
  filters: object;
}

export interface IVideo {
  id: string;
  codigo: string;
  quantidade: number;
  nome: string;
  observacao: string;
}

const Training: React.FC<ITraining> = ({ title, endpoint, filters }) => {
  const provider = useFetch({ endpoint });
  const { user } = useAuth();
  const { openModal, closeModal } = useFormModal();
  const [videos, setVideos] = useState<Array<IVideo>>([]);
  const [loading, setLoading] = useState(false);
  const [newVideos, setNewVideos] = useState<Array<IVideo>>([]);
  const [idClass, setIdClass] = useState("62cc55d3cb1e94a14bec93c4");
  const [videoSelected, setVideoSelected] = useState<IVideo | null>(null);

  useEffect(() => {
    getVideo();
  }, []);

  useEffect(() => {
    return () => closeModal();
  }, []);

  const getVideo = async () => {
    try {
      const result = await provider.get(filters);

      if (result) {
        const sortedVideos = result.sort(orderCols);
        setVideos(sortedVideos);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const newStateVideo = (video: any) => {
    const arr = [...videos];

    let itemIndex = videos.findIndex((i: any) => i.id === video.id);
    if (itemIndex >= 0) {
      arr.splice(itemIndex, 1);
    }
    const newArr = [...arr, video];
    const sortedVideos = newArr.sort(orderCols);

    setVideos(sortedVideos);
    setVideoSelected(null);
  };

  const handleModal = (title: string) => {
    openModal({
      type: "confirm",
      title: title,
      children: () => (
        <NewVideoModal
          getVideo={getVideo}
          endpoint={endpoint}
          title={title}
          id={videoSelected ? videoSelected.id : idClass}
          video={videoSelected}
          newStateVideo={(ev: any) => newStateVideo(ev)}
        />
      ),
    });
  };

  const handleModalDelete = () => {
    openModal({
      type: "confirm",
      title: "Excluir Vídeo",
      children: renderDeleteContent,
    });
  };

  const renderDeleteContent = () => {
    return (
      <ContainerModal>
        <h3 style={{ marginTop: 2, marginBottom: 10, fontWeight: 400 }}>
          Tem certeza que deseja excluir?
        </h3>
        <p>Esta ação é irreversível.</p>
        <div>
          {loading && (
            <span>
              <AiOutlineLoading />
            </span>
          )}{" "}
          &nbsp;
          <Button className="danger" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button onClick={() => handleDelete()}>Excluir</Button>
        </div>
      </ContainerModal>
    );
  };

  const handleDelete = async () => {
    try {
      if (videoSelected) {
        setLoading(true);
        const result = await provider.remove(videoSelected.id);

        if (result.ok === true) {
          const filterItens: any = [];
          const itens = [...videos];
          {
            itens.map((it: any) => {
              if (it.id !== videoSelected.id) {
                filterItens.push(it);
              }
            });
          }
          closeModal();
          setVideos(filterItens);
          setVideoSelected(null);
          setLoading(false);
        }
      } else {
        toast.error("Selecione o item que deseja excluir.");
      }
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
  };

  return (
    <Container>
      <DivSticky>
        <Title>{title}</Title>
        {user?.Login && user.Login.nome === "Diretoria" && (
          <div style={{ marginBottom: 16 }}>
            <Button onClick={() => handleModal("Novo Vídeo")}>
              Novo Vídeo
            </Button>
            <Button
              disabled={!videoSelected}
              onClick={() => handleModal("Alterar")}
            >
              Editar
            </Button>
            <Button
              disabled={!videoSelected}
              onClick={() => handleModalDelete()}
            >
              Excluir
            </Button>
          </div>
        )}
      </DivSticky>
      <Content>
        <Row>
          <Column wide={"30%"}>
            <h2>Por onde começar?</h2>
            <p className="subtitle">&nbsp;</p>
          </Column>
          <Column wide={"70%"} padd={"4px"}>
            {videos?.length > 0 &&
              videos.map((item: any, index: any) => (
                <Card
                  key={index}
                  className={videoSelected == item ? "active" : ""}
                  onClick={() => setVideoSelected(item)}
                >
                  <Column wide={"50%"} padd={"20px"} jContent={"space-evenly"}>
                    <IndexCard>
                      <p>{item.quantidade}</p>
                    </IndexCard>
                    <div className="input_radio">
                      <TitleCard htmlFor={item.id}>{item.codigo}</TitleCard>
                    </div>
                    <Description>{item.observacao}</Description>
                  </Column>
                  <YouTube videoId={item.nome} />
                  {/* <iframe
                      src={`${item.nome}?&fs=1`}
                      title={item.codigo}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                      ></iframe> */}
                </Card>
              ))}
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

export default Training;
