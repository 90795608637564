import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 16px 0;
  width: 100%;

  & > div:nth-of-type(2)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Title = styled.h2`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 60px;
  width: 100%;

  & > div:nth-child(2) {
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    .filters {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  width: 100%;

  div {
    margin-right: 100px;
  }

  label {
    color: var(--white);
    font-size: 22px;
    margin-right: 60px;
  }

  button {
    background: transparent;
  }

  p {
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
  }

  p:first-child {
    margin-right: 20px;
  }

  .primary {
    color: var(--primary-lighter);
  }
`;

export const Content = styled.div`
  background-color: var(--form-background);
  color: var(--white);
  height: auto;
  overflow: auto;
  padding: 30px 10px 20px 10px;
  width: 100%;

  & > table > tbody > tr > td:nth-of-type(3) {
    width: 14%;
  }
`;
