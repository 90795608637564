import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, ContainerModal, Content, Row, Title, Button } from "./style";
import ListTable, { IField } from "../../components/ListTable";
import { useAuth } from "../../contexts/auth";
import useFetch from "../../hooks/useFetch";
import {
  gerarPagamentoPix,
  getConfirmPix,
  getListarOrdens,
  getPedidosComissao,
} from "../../services/pedidos";
import { IRepresentative, IResponse } from "../Orders";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import ReactPaginate from "react-paginate";
import { useFormModal } from "../../contexts/formModal";
import { AiOutlineLoading } from "react-icons/ai";
import ModalOrders from "./modalOrders";
import QRCode from "qrcode.react";
import { copyToClipboard } from "../../utils/copyToLink";
import Toast from "../../components/Toast";
import { ID_USER_ADMIN } from "../../utils/entidades";
import { getSearchEntities, sendLembretePagamento } from "../../services/entidade";
import { format, subMonths } from "date-fns";
import { IEntidade } from "../../services/auth";

interface IOrdersCommission {
  title?: string;
  endpoint: string;
  filters: object;
  multipleSelect: boolean;
}

const OrdersCommission: React.FC<IOrdersCommission> = ({
  title,
  endpoint,
  multipleSelect,
}) => {
  const { user } = useAuth();
  const RefChcriacao = useRef();
  const INTERVAL = useRef<NodeJS.Timer | undefined | null>(null);
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const [currentPage, setCurrentPage] = useState(1);
  const { openModal } = useFormModal();
  const [cols, setCols] = useState<Array<IField>>([]);
  const [colsOrders, setColsOrders] = useState<Array<IField>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [statusPayment, setStatusPayment] = useState<boolean>(false);
  const [pedidos, setPedidos] = useState<IResponse | null>(null);
  const [orders, setOrders] = useState<IResponse | null>(null);
  const [quantityPerPage, setQuantityPerPage] = useState<number>(10);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [representative, setRepresentative] = useState<IRepresentative[]>([]);
  const [searchRepresentative, setSearchRepresentative] = useState<string>("");
  const [filterOrders, setFilterOrders] = useState({
    idRepresentante: "idRepresentante",
    baixado: "baixado",
    referencia: "referencia",
  })
  const currentDate = new Date();

  const lastTwelveMonths = Array.from({ length: 12 }, (_, i) => {
    const date = subMonths(currentDate, i);
    return format(date, 'MM/yyyy');
  });



  useEffect(() => {
    getTableFields();
  }, [currentPage, quantityPerPage]);

  useEffect(() => {
    if (filterOrders.idRepresentante !== "idRepresentante" || filterOrders.baixado !== "baixado" || filterOrders.referencia !== "referencia") {
      getTableFields(filterOrders)
    } else {
      getTableFields()
    }
  }, [filterOrders])

  useEffect(() => {
    if (searchRepresentative?.length < 3) return;
    let timer = setTimeout(() => {
      searchEntities(searchRepresentative);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchRepresentative])

  const getTableFields = async (filters?: any) => {
    let _filters = { ...filters, classe: "Prov Rec Comissões" };

    try {
      setLoading(true)
      const id: any = user?.id;
      const result = await fields.get({ id, ..._filters });
      if (result) {
        setCols(result);
        let _id = user?.nome.includes("Diretoria") ? null : id;
        const resultOrders = await getPedidosComissao(
          endpoint,
          currentPage,
          _id,
          quantityPerPage,
          filters
        );

        if (resultOrders) {
          setPedidos(resultOrders);
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Erro: ", error);
    }
  };

  const getPedido = async (chcriacao: any) => {
    let _filters = { classe: "Listar pedido" };
    try {
      handleModalLoading();
      const id: any = user?.id;

      const _fields = await fields.get({ id, ..._filters });
      if (_fields) {
        setColsOrders(_fields);

        const result = await getListarOrdens(endpoint, chcriacao, id);
        if (result) {
          const { ordens, totalComissao, totalLucroLiquido } = result;
          let total = result.length;
          let totalPages = 1;
          let docs = [...ordens];

          setOrders({ total, totalPages, docs });
          handleModal(
            _fields,
            { total, totalPages, docs },
            totalComissao,
            totalLucroLiquido
          ); // Chama modal para exibir as ordens referente ao pedido de comissão
          RefChcriacao.current = chcriacao;
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const searchEntities = async (filter: string) => {
    try {
      const result = await getSearchEntities(filter)
      if (result) {
        const _result = result.map((item: IEntidade) => {
          return {
            id: item.id,
            nome: item.nome
          }
        })

        setRepresentative(_result)
      }
    } catch (error) {
      console.error("Erro: ", error)
    }
  }

  const SubmitPayment = async () => {
    try {
      setLoadingOrders(true);
      if (RefChcriacao && RefChcriacao.current) {
        let id: any = user?.id;

        //caso usuario seja admin procura o id do usuario dono do pedido de comisao.
        if (user && user.id === ID_USER_ADMIN && pedidos) {
          for (const ped of pedidos.docs) {
            if (ped.chcriacao === RefChcriacao.current) {
              id = ped.idPessoa;
            }
          }
        }

        const result = await gerarPagamentoPix(RefChcriacao.current, id);
        if (result) {
          handleModalPix(result.qrCode); // Chama modal para exibir QRCode do PIX
          setLoadingOrders(false);

          INTERVAL.current = setInterval(
            () => watchPaymentStatus(RefChcriacao.current),
            2000
          );
        }
      }
    } catch (error) {
      setLoadingOrders(false);
      console.error("Erro: ", error);
    }
  };

  const watchPaymentStatus = async (chcriacao: any) => {
    try {
      const response = await getConfirmPix(chcriacao);

      const { baixado } = response;
      if (baixado === true) {
        clearInterval(INTERVAL.current!);
        INTERVAL.current = null;
        setStatusPayment(true);
        atualizarItem(); // Chama a função para atualizar o item do state
        handleModalCheckPayment(); // Chama modal para confirmar o pagamento
      }
    } catch (error) {
      console.error(error);
    }
  };

  // função responsável por atualizar o status do item no state de pedidos
  const atualizarItem = () => {
    if (pedidos) {
      // Crie uma cópia do estado de pedidos
      const novosPedidos: IResponse = { ...pedidos };

      // Encontre o índice do item que precisa ser atualizado
      const indiceItem = novosPedidos.docs.findIndex(
        (item) => item.chcriacao === RefChcriacao.current
      );

      // Verifique se o item foi encontrado
      if (indiceItem !== -1) {
        // Crie uma cópia do item para evitar mutações diretas no estado
        const itemAtualizado = { ...novosPedidos.docs[indiceItem] };

        // Atualize o status
        itemAtualizado.baixado = "S";

        // Atualize o array de docs com o item modificado
        novosPedidos.docs[indiceItem] = itemAtualizado;

        // Atualize o estado de pedidos com os novos pedidos
        setPedidos(novosPedidos);
      }
    }
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(+value);
  };

  const handlePedido = (value: number) => {
    getPedido(value);
  };

  const handleModalLoading = () => {
    openModal({
      type: "confirm",
      wide: "340px",
      children: renderLoading,
    });
  };

  const handleModal = (
    _fields: IField[],
    resultOrders: IResponse,
    totalComissao: number,
    totalLucroLiquido: number
  ) => {
    openModal({
      type: "confirm",
      title: "Ordens",
      wide: "60%",
      children: () => (
        <ModalOrders
          loadingOrders={loadingOrders}
          colsOrders={_fields}
          orders={resultOrders}
          totalLucroLiquido={totalLucroLiquido}
          totalComissao={totalComissao}
          submit={SubmitPayment}
        />
      ),
    });
  };

  const handleModalPix = (qrCode: string) => {
    openModal({
      type: "confirm",
      title: "QR Code",
      wide: "340px",
      children: () => renderContentModal(qrCode),
    });
  };

  const handleModalCheckPayment = () => {
    openModal({
      type: "confirm",
      wide: "340px",
      children: () => renderCheckPayment(),
    });
  };

  const renderContentModal = (qrcode: string) => {
    return (
      <ContainerModal
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QRCode
          value={qrcode}
          style={{ border: "1px solid white", height: 200, width: 200 }}
        />
        <Button
          onClick={() => handleCopyPix(qrcode)}
          style={{
            color: "gray",
            fontWeight: 500,
            marginLeft: "auto",
            marginTop: 20,
            width: "fit-content",
          }}
        >
          Copiar QrCode
        </Button>
      </ContainerModal>
    );
  };

  const renderLoading = () => {
    return (
      <ContainerModal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          minWidth: 300,
        }}
      >
        <span className="loading">
          <AiOutlineLoading /> &nbsp;
        </span>
      </ContainerModal>
    );
  };

  const renderCheckPayment = () => {
    return (
      <ContainerModal>
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <p style={{ fontSize: 20, textAlign: "center" }}>Pago com sucesso!</p>
      </ContainerModal>
    );
  };

  const handleCopyPix = async (text: string) => {
    copyToClipboard(text);
    return Toast.show("O link foi copiado", "success");
  };

  const indexSelected = useCallback(async () => {
    if (pedidos?.docs.length) {
      let itens: any = [];
      let docs = [...pedidos.docs];

      selectedIndexes.map((item: any, index: any) => {
        if (item === true) {
          itens.push(docs[index].idPessoa);
        }
      });
      await sendLembretePagamento(itens);
    }
  }, [selectedIndexes]);

  const handleSearch = (key: string, value: string) => {
    const selected = representative.find(rep => rep.nome === value);
    if (key === "idRepresentante") {
      if (!value) {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: "idRepresentante"
        })
        setSearchRepresentative("")
      }
      if (selected) {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: selected.id
        })
        setSearchRepresentative(value)
      } else {
        setSearchRepresentative(value)
      }
    } else if (key === "baixado") {
      if (!value) {
        setFilterOrders({
          ...filterOrders,
          baixado: "baixado"
        })
      } else {
        setFilterOrders({
          ...filterOrders,
          baixado: value === "Pago" ? "S" : "N"
        })
      }
    } else if (key === "referencia") {
      if (!value) {
        setFilterOrders({
          ...filterOrders,
          referencia: "referencia"
        })
      } else {
        setFilterOrders({
          ...filterOrders,
          referencia: value.split("/").reverse().join("-")
        })
      }
    }
  }

  return (
    <Container>
      <Title>{title}</Title>
      {user && user.id === ID_USER_ADMIN ? <Row>
        <div className="row">
          <p className="primary">Filtros</p>
          <form autoComplete="off">
            <div>
              <input
                list="representantes"
                name="representative"
                id="representative"
                type="search"
                autoComplete="chrome-off"
                value={searchRepresentative}
                onChange={(ev) => handleSearch("idRepresentante", ev.target.value)}
                placeholder={filterOrders.idRepresentante.replace(
                  "idRepresentante",
                  "Representante"
                )}
              />
              <datalist id="representantes">
                {representative.length > 0
                  ? representative.map((item: IRepresentative) => (
                    <option value={item.nome} key={item.id}>{item.nome}</option>
                  ))
                  : null}
              </datalist>
            </div>
            <div>
              <select
                onChange={(ev) => handleSearch("baixado", ev.target.value)}
              >
                <option value="" selected>Status</option>
                <option value="Pago">Pago</option>
                <option value="Não Pago">Não Pago</option>
              </select>
            </div>
            <div>
              <select
               name="referencia" 
               id="referencia" 
               onChange={(ev) => handleSearch("referencia", ev.target.value)}
              > 
                <option value="">Referência</option>
                {lastTwelveMonths.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div>
          {loading ? (
            <span className="loading">
              <AiOutlineLoading style={{fontSize: 24}} /> &nbsp;
            </span>
          ): null}
          <Button
            type="button"
            onClick={() => indexSelected()}>
            Enviar Aviso
          </Button>
        </div>
      </Row> : null}
      <Content>
        <ListTable
          multipleSelect={multipleSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={cols}
          data={pedidos ? pedidos.docs : []}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          onClick={handlePedido}
          totalPages={pedidos ? pedidos.totalPages : 1}
          count={pedidos ? pedidos.total : 0}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={pedidos ? pedidos.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default OrdersCommission;
