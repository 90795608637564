import Provider from "./config";

export const postSupport = async (id: any, payload: any) => {
  try {
    const response = await Provider.post("support", { userId: id, ...payload });

    return response;
  } catch (err: any) {
    throw Error("Erro ao enviar dados para o suporte.");
  }
};
