import React from 'react';
import {Wrapper, Container} from './styles';

import NotificationList from '../NotificationList';
import Profile from '../Profile';

import { useMenu } from '../../contexts/menu';

export interface IContainerProps {
  isExpanded: boolean;
  type: "notificação" | "perfil";
}

const RightSidebar: React.FC = () => {
  const {isRightMenuOpen: isExpanded, toggleRightMenu: toggleMenu, rightMenuType: type} = useMenu();
  const handleToggle = () => {
    toggleMenu("notificação");
  }

  const renderComponent = () => {
    switch (type) {
      case "notificação":
        return <NotificationList />
      case "perfil":
        return <Profile />;
      default: 
        break;
    }
  }

  return (
    <React.Fragment>
    <Wrapper onClick={() => handleToggle()} isExpanded={isExpanded} type={type} />
    <Container isExpanded={isExpanded} type={type}>
        {renderComponent()}
    </Container>
    </React.Fragment>
  );
}

export default RightSidebar;