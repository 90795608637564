import React, { createElement, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formatDate, formatMoney } from '../../utils/format';
import {
  BodyOrder,
  Container,
  ContentHeader,
  DivHeader,
  DivRow,
  DivLeft,
  DivRight,
  Logo,
  DivItems,
  LogoFooter,
  Main,
  ButtonActive,
  Row,
} from './style';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { getReport } from '../../services/report';
import { BsChevronDown } from 'react-icons/bs'
import { group } from 'console';

const Report: React.FC = () => {

  const location = useLocation<any>();
  const [request, setRequest] = useState<any>(null);
  const componentRef = useRef(null);
  const [activeState, setActiveState] = useState(false);
  const [recurso, setRecurso] = useState<string>();
  const [entidade, setEntidade] = useState<string>();
  const [lote, setLote] = useState<string>();
  const [agrup, setAgrup] = useState<any>({ bundler: '', index: '', indexAgrup: '' });
  const [reports, setReports] = useState<any>([]);
  const [deposito, setDeposito] = useState<string>();
  const date = new Date();

  useEffect(() => {
    if (location.state) {
      const details = location.state.filtersToGet

      Array.isArray(details.agrupamento) && details.agrupamento.find((item: string) => setAgrup({ ...agrup, bundler: item, index: '' }))
      Array.isArray(details.agrupamento) && details.agrupamento.map((item: string) =>
        item === 'Recurso' ? setRecurso(item) : item === 'Núcleo' ? setEntidade(item) : setLote(item)
      )

      getReports(details)

    }
  }, [])

  async function getReports(details: any) {
    try {
      const result = await getReport(details)

      if (result) {
        if (Array.isArray(result)) {
          setReports(result)
          setDeposito(result[0].Entidade)
        } else {
          const resultObject = treatObject(result)
          setReports(resultObject)
        }
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  function treatObject(object: any) {
    const keys = Object.keys(object)
    let arrayObject: any = []
    for (const key of keys) {
      const base: any = {
        Agrupador: key,
        custo: null,
        quantidade: null,
        isGroup: true,
        active: false,
        children: []
      };
      const value = object[key]
      if (Array.isArray(value)) {
        base.children = value;
        value[0].Entidade && setEntidade(value[0].Entidade);
      } else {
        const objectChild: any = treatObject(value);
        base.children = [...objectChild];

      }
      arrayObject.push(base);
    }
    return arrayObject;
  }

  function childrenList(child: any, agrupador: string | null) {
    const keys = child

    let arrayChildren: any = [];
    for (const key of keys) {

      if (key.children) {
        return (
          keys.length ? keys.map((item: any, index: any) => (
            <>
              <ButtonActive onClick={() => handleActive(item.Agrupador, index)} style={{marginLeft: 10}}>{item.Agrupador === agrup.bundler ? "-" : '+'} {item.Agrupador}</ButtonActive>
              {item.active == true && childrenList(item.children, item.Agrupador ? item.Agrupador : null)}
            </>

          ))
            : <ButtonActive onClick={() => handleActive(key.Agrupador, 0)}>{key[0] == agrup.indexGrup ? "-" : '+'} {key.Agrupador}</ButtonActive>
        )
      } else {
        return (
          <>
            {
              keys ?
                <table>
                  <tr>
                    {agrupador != key.Entidade && <th>Depósito</th>}
                    {agrupador != key.Recurso && <th>Recurso</th>}
                    <th>{key.quantidade && 'Quantidade'}</th>
                    <th>{key.custo && 'Custo'}</th>
                  </tr>
                  {keys.map((item: any) => (
                    <tr>
                      {agrupador != item.Entidade && <td>{item.Entidade}</td>}
                      {agrupador != item.Recurso && <td>{item.Recurso}</td>}
                      <td className={item.quantidade - 0 ? "right red" : "right"}>{item.quantidade}</td>
                      <td className='right'>{item.custo ? formatMoney(item.custo) : ''}</td>
                    </tr>
                  ))}
                </table>
                : null
            }
          </>
        )
      }

    }
    return arrayChildren;
  }

  function handleActive(value: any | null, index: number) {

    const cloneReports = [...reports]
    cloneReports[index].active = !cloneReports[index].active

    setReports(cloneReports)
    setAgrup({ 
      ...agrup, 
      bundler: value,
      indexGrup: index === agrup.indexGrup ? '' : index
      });
  }

  function handleActiveChild(item: any, index: any) {
    setAgrup({
      ...agrup,
      bundler: item === agrup.bundler ? '' : item,
      index: index === agrup.index ? '' : index
    })
  }

  const pageStyle = `
    @media print{
      html, body{
        border-collapse: collapse;
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      table, th, td {
        border-collapse: collapse;
      }
      .page-break {
        margin-top: 20px !important;
        display: block;
        page-break-before: auto;
      }
    }
    @page{
      margin: 20mm;
      size: auto;
    }
  `

  return (
    <Main>
      <ReactToPrint
        trigger={() => {

          return <button type='button' className='buttonPrint'>IMPRIMIR</button>
        }}
        content={() => componentRef.current}
        documentTitle="new document"
        pageStyle={pageStyle}
      />

      <Container ref={componentRef}>
        <DivRow>
          <Row>
            <p>Relatório de Saldo de Estoque</p>
            <label>{formatDate(date)}</label>
          </Row>
          <div>
            <br />
            <p>Filtros:</p>
            <label>{reports.length > 0 && reports[0].Entidade ? reports[0].Entidade : entidade}</label>
          </div>
        </DivRow>
        <DivItems>
          {reports.length && reports[0].Agrupador ? reports.map((item: any, index: any) => (
            <>
              {item.isGroup ?
                <>
                  <ButtonActive onClick={() => handleActive(item.Agrupador, index)}>{item.active == true ? "-" : '+'} {item.Agrupador}</ButtonActive>
                  {item.active &&
                    childrenList(item.children, item.Agrupador)
                  }
                </>
                :
                <>
                  {item.Agrupador && <ButtonActive onClick={() => handleActive(item, index)}>{activeState === true ? "-" : '+'} {item.Agrupador}</ButtonActive>}
                  <table key={index}>
                    <tr>
                      <th>Depósito</th>
                      <th>Recurso</th>
                      <th>Quantidade</th>
                      <th>Custo</th>
                    </tr>

                    {reports ? reports.map((item: any, index: any) => (
                      <>

                        {activeState &&
                          <tr key={index}>
                            <td>{item.Agrupador}</td>
                            <td>{item.Recurso}</td>
                            <td className={item.quantidade - 0 ? "right red" : "right"}>{item.quantidade}</td>
                            <td className='right'>{item.custo ? formatMoney(item.custo) : ''}</td>
                          </tr>
                        }
                      </>
                    )) : null}
                  </table>
                </>
              }
            </>

          )) :
            <table>
              <tr>
                <th>Depósito</th>
                <th>Recurso</th>
                <th>Quantidade</th>
                <th>Custo</th>
              </tr>
              {reports && reports.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.Entidade}</td>
                  <td>{item.Recurso}</td>
                  <td className={item.quantidade < 0 ? "right red" : "right"}>{item.quantidade}</td>
                  <td className='right'>{item.custo ? formatMoney(item.custo) : ''}</td>
                </tr>
              ))}
            </table>

          }


        </DivItems>
        <LogoFooter>
          <img src={LogoDevari} alt="Logo" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default Report;
