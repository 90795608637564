import styled from 'styled-components';
import {INotificationCardContainerProps} from '.';

export const Container = styled.div<INotificationCardContainerProps>`
  background-color: var(--white);
  border-left: 8px solid var(--primary-darker);
  border-radius: 4px;
  padding: 16px;
  box-shadow: 5px 5px 4px 0px rgba(0,0,0,0.19);
  position: relative;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Subtitle = styled.p``;

export const RemoveButton = styled.button`
  background-color: transparent;
  border-radius: 14px;
  color: var(--muted);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;

  cursor: pointer;

  position: absolute;
  top: 8px;
  right: 8px;
  transition: .2s;

  & svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    opacity: .8;
  }
`;