/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Column} from '../types';
import TableCell from '../Cell';
import TableFormCell from '../TableFormCell';
import {regraClasse, regraNucleo, regraPreco} from '../../../services/pedidos';
import { TableRowElement } from './styles';

import {IoMdTrash} from 'react-icons/io';
import { useFormik } from 'formik';

export type TableRowProps = {
  fields?: any;
  fixedValue?: any;
  dataRow: any;
  loadInitialState?: boolean;
  isRecurso?: boolean;
  isFormaPagamento?: boolean;
  disabled?: boolean;
  lookupsRegras: any;
  rowActions?: any[];
  columns: Column[];
  onRowChange(row: any): void; 
};

function TableRow({dataRow, columns, isRecurso, disabled, lookupsRegras, fixedValue, fields, rowActions, onRowChange, loadInitialState, isFormaPagamento}: TableRowProps) {
  const [data, setData] = useState(dataRow);
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);
  const [loadedState, setLoadedState] = useState(false);
  const handleSubmit = (values: any) => {
    onRowChange(values)
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (loadInitialState && dataRow && !loadedState) {
      const dataWithoutNull = Object
        .fromEntries(Object.entries(dataRow).filter(([_, v]) => v != null));
      setData(dataWithoutNull);
      setLoadedState(true);
    }
  }, [loadInitialState, dataRow, loadedState]);

  useEffect(() => {
    if(dataToUpdate) {
      const newData = {...form.values, ...dataToUpdate};
      setData(newData);
    }
  }, [dataToUpdate]);

  useEffect(() => {
    if (fixedValue) {
      const newData = {...form.values, ...fixedValue};
      setData(newData);
    }
  }, [fixedValue]);

  useEffect(() => {
    if (isFormaPagamento && dataRow) {
      setDataToUpdate(dataRow);
    }
  }, [isFormaPagamento, dataRow]);

  useEffect(() => {
    if (isRecurso && (form.values.idRecurso || form.values.Recurso) && lookupsRegras) {
      let payload: any = {
        idRecurso: form.values.idRecurso ? form.values.idRecurso : form.values.Recurso ? form.values.Recurso.value : '',
      };

      if (payload.idRecurso) {
        if (lookupsRegras.Estabelecimento) {
          payload = {
            ...payload,
            idEstabelecimento: lookupsRegras.Estabelecimento.value,
          }
        }
  
        if (lookupsRegras.Pessoa) {
          payload = {
            ...payload,
            idPessoa: lookupsRegras.Pessoa.value,
          }
        }
        
        getRegraClasse(payload);
      }
    }
  }, [form.values.Recurso, form.values.idRecurso, isRecurso, lookupsRegras]);

  useEffect(() => {
    const mainForm = document.getElementById('custom-form');
    function submitRowForm() {
      form.handleSubmit();
    }
    if (mainForm) {
      mainForm.addEventListener('submit', submitRowForm, true);
      return () => {
        mainForm.removeEventListener('submit', submitRowForm, true);
      }
    }
  }, []);

  function validate() {

  }

  async function getRegraClasse(payload: any) {
    try {
      const result = await regraClasse(payload);
      if (result) {
        setDataToUpdate({
          Classe: result.SugestaoClasse,
        });
        getRegraNucleo(payload, result.SugestaoClasse.id);
      }
    } catch (err: any) {
      getRegraNucleo(payload, null);
    }
  }

  async function getRegraNucleo(payload: any, classePedido: string | null) {
    try {
      const result = await regraNucleo(payload);
      if (result) {
        setDataToUpdate((oldValue: any) => {
          return {
            ...oldValue,
            Nucleo: result.Nucleo,
          }
        });
        getRegraPreco({
          ...payload,
          idClassePedido: classePedido,
        });
      }
    } catch (err: any) {
      getRegraPreco({
        ...payload,
        idClassePedido: classePedido,
      });
    }
  }

  async function getRegraPreco(payload: any) {
    try {
      const result = await regraPreco(payload);
      if (result) {
        setDataToUpdate((oldValue: any) => {
          return {
            ...oldValue,
            unitario: result.preco,
          }
        });
      }
    } catch (err: any) {}
  }
  
  const renderRowElements = (row: any) => {
    const cells = [];

    if (rowActions) {
      for (const action of rowActions) {
        if (action.name === 'remove') {
          cells.push((
            <td key={action.name} style={{borderBottom: '1px solid var(--opacity-background)'}}>
              <button type="button" style={{backgroundColor: 'transparent', cursor: 'pointer', width: '100%'}} onClick={action.handler}>
                <IoMdTrash size={24} color="var(--primary)" />
              </button>
            </td>
          ))
        }
      }
    }

    for (const col of columns) {
      if (col.fromFixedValue) {
        cells.push((
          <TableCell 
            key={`${col.campo}`}
            data={{
              id: '',
            }}
            toObserve={{
              id: col.value,
            }}
            column={col}
          />
        ));
      } else if (col.name && col.id) {
        const value = row[col.id];
        cells.push((
          <TableCell 
            key={col.id}
            data={value}
            column={col}
          />
        ));
      } else if (col.nome && col.campo) {
        const value = row[col.campo];
        cells.push((
          <TableCell 
            key={`${col.campo}`}
            data={value}
            column={col}
          />
        ));
      }
    }

    return cells;
  }

  return (
    <TableRowElement>
      {fields && rowActions && !disabled && rowActions.map((action, index) => {
        if (action.name === 'remove') {
          return (
            <td key={`${action.name}${index}`} style={{borderBottom: '1px solid var(--opacity-background)'}}>
              <button type="button" style={{backgroundColor: 'transparent', cursor: 'pointer', width: '100%'}} onClick={action.handler}>
                <IoMdTrash size={24} color="var(--primary)" />
              </button>
            </td>
          );
        } else {
          return (<></>)
        }
      })}
      {fields && Object.keys(fields).map((key, index) => (
        <TableFormCell
          key={index}
          field={fields[key]}
          form={form}
        />
      ))}
      {!fields && renderRowElements(data)}
    </TableRowElement>
  )
}

export default TableRow;