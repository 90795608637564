import React, { useEffect, useState, FunctionComponent } from "react";
import {
  ProfilePhoto,
  ProfilePhotoButton,
  ProfilePhotoContainer,
} from "../../components/Profile/styles";
import { useAuth } from "../../contexts/auth";
import {
  Container,
  Title,
  Content,
  Card,
  Row,
  ItemSkill,
  Column,
  DivProfile,
  DivOrders,
  ButtonSvg,
  GradientOrange,
  GradientPink,
  GradientBlue,
  DivChart,
  ButtonGraph,
} from "./styles";
import ImageUser from "../../assets/images/user.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Legend,
  ZAxis,
  LineChart,
  Line,
  LabelList,
  BarChart,
  Bar,
} from "recharts";
import useFetch from "../../hooks/useFetch";
import parseDate from "date-fns/parse";
import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { addDays, parseISO, subDays } from "date-fns";
import { getBinance, getDashboard } from "../../services/home";
import { classNames } from "react-select/dist/declarations/src/utils";
import { BiBitcoin, BiDollar } from "react-icons/bi";

interface IValues {
  lucroAno: string;
  lucroDia: string;
  lucroMes: string;
  lucroTotal: string;
  opAbertas: number;
  opFechadas: number;
  percentualAno: string;
  percentualDia: string;
  valorTotalAberto: number;
  graficoDash: IGraph[];
  graficoLinha: IGraphLine[];
  graficoDiario: IGraphDiary[];
}

interface IGraph {
  aprovacao: string;
  lucroLiquido: number;
  moeda: string;
}

interface IGraphLine {
  BTC: string;
  day: string;
}

interface IGraphDiary {
  USDT: string;
  day: string;
}

interface IHome {
  title: string;
  endpoint: string;
}

const Home: React.FC<IHome> = ({ title, endpoint }) => {
  const { user, getUserData } = useAuth();
  const dashboard = useFetch({ endpoint: `${endpoint}/dashboard/` });
  const binance = useFetch({ endpoint: `${endpoint}/binanceInfo/` });
  const [currentPage, setCurrentPage] = useState(1);
  const [valuesDashboard, setValuesDashboard] = useState<IValues | null>(null);
  const [openOrders, setOpenOrders] = useState();
  const [balance, setBalance] = useState([]);
  const [notBalance, setNotBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState<any>([]);
  let currentDate = format(new Date(), "yyyy-MM-dd");
  let beforeDate = format(subDays(new Date(), 29), "yyyy-MM-dd");
  let beforeSevenDate = format(subDays(new Date(), 14), "yyyy-MM-dd");
  const [dataLineCharts, setDataLineCharts] = useState<Array<any | null>>([
    null,
  ]);
  const [dataBarCharts, setDataBarCharts] = useState<Array<any | null>>([null]);
  const [filterEvolution, setFilterEvolution] = useState({
    startTime: "",
    endTime: "",
  });
  const [filterProfit, setFilterProfit] = useState({
    startTime: "",
    endTime: "",
  });
  const [dataPieCharts, setDataPieCharts] = useState<any>([]);
  //const colors = ["#760fcb", "#de009e", "#4C3EC4"];
  const colors = ["#FF6001", "#ff8b47", "#ffd2ad"];

  useEffect(() => {
    setTimeout(() => {
      if (user && user.satp) {
        getValues();
        getCurrentBalance();
      } else {
        setNotBalance(true);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (user) {
      const id = user.id;
      if (id) {
        getUserData(id);
      }
    }
  }, []);

  useEffect(() => {
    if (valuesDashboard && balance) {
      const data: any = [];

      const total = {
        name: "Valor Total Ordens Abertas",
        value: valuesDashboard.valorTotalAberto,
      };

      balance.map((item: any) => {
        if (item.asset === "USDT") {
          let free = parseFloat(item.free).toFixed(2);
          data.push({ name: item.asset, value: parseFloat(free) });
        }
      });

      let content: any = [];
      let contentLine: any = [];
      let contentDay: any = [];
      const dataGrafico: any = valuesDashboard.graficoDash;
      const dataGraficoLine: any = valuesDashboard.graficoLinha;
      const dataGraficoDay: any = valuesDashboard.graficoDiario;

      if (dataGrafico) {
        for (let i = 0; i < dataGrafico.length; i += 2) {
          const date = dataGrafico[i + 1].split("-");
          const year: any = parseInt(date[0]);
          const month: any = parseInt(date[1]);
          const day: any = parseInt(date[2].slice(0, 2));

          content.push({
            value: dataGrafico[i],
            date: new Date(year, month - 1, day).toISOString(),
          });
        }
      }
      if (dataGraficoLine) {
        for (let item of dataGraficoLine) {
          const date = item.day.split("-");
          const year: any = parseInt(date[0]);
          const month: any = parseInt(date[1]);
          const day: any = parseInt(date[2].slice(0, 2));

          contentLine.push({
            USDT: +item.USDT,
            BTC: +item.BTC,
            Dia: new Date(year, month - 1, day).toISOString(),
          });
        }
      }
      if (dataGraficoDay) {
        for (let item of dataGraficoDay) {
          const date = item.day.split("-");
          const year: any = parseInt(date[0]);
          const month: any = parseInt(date[1]);
          const day: any = parseInt(date[2].slice(0, 2));

          contentDay.push({
            USDT: +item.USDT,
            Dia: new Date(year, month - 1, day).toISOString(),
          });
        }
      }

      content.sort((a: any[], b: any[]) => {
        const dataA = parseDate(a[0], "dd/MM", new Date());
        const dataB = parseDate(b[0], "dd/MM", new Date());
        return compareAsc(dataA, dataB);
      });
      contentLine.sort((c: any[], d: any[]) => {
        const dataC = parseDate(c[1], "dd/MM", new Date());
        const dataD = parseDate(d[1], "dd/MM", new Date());
        return compareAsc(dataC, dataD);
      });
      contentDay.sort((e: any[], f: any[]) => {
        const dataE = parseDate(e[0], "dd/MM", new Date());
        const dataF = parseDate(f[0], "dd/MM", new Date());
        return compareAsc(dataE, dataF);
      });

      setData([...content]);
      setDataPieCharts(data.length > 0 && total ? [...data, total] : []);
      setDataLineCharts([...contentLine]);
      setDataBarCharts([...contentDay]);
    }
  }, [valuesDashboard, balance]);

  useEffect(() => {
    if (filterEvolution.startTime !== "" && filterEvolution.endTime !== "") {
      getEvolutionData(filterEvolution);
    } else {
      getValues();
    }
  }, [filterEvolution]);

  useEffect(() => {
    if (filterProfit.startTime !== "" && filterProfit.endTime !== "") {
      getProfitData(filterProfit);
    } else {
      getValues();
    }
  }, [filterProfit]);

  const getEvolutionData = async (filter?: any) => {
    try {
      if (filter) {
        let strTime = (filter.startTime += "T00:00:00.000Z");
        let endTime = (filter.endTime += "T23:59:59.000Z");
        let crtTime =
          filterProfit.endTime !== ""
            ? (filterProfit.endTime += "T23:59:59.000Z")
            : (currentDate += "T23:59:59.000Z");
        let dayStrTime =
          filterProfit.startTime !== ""
            ? (filterProfit.startTime += "T00:00:00.000Z")
            : (beforeSevenDate += "T00:00:00.000Z");

        const result = await getDashboard(
          user?.id,
          strTime,
          endTime,
          dayStrTime,
          crtTime
        );
        if (result) {
          setValuesDashboard(result);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getProfitData = async (filter?: any) => {
    try {
      if (filter) {
        let crtTime = (currentDate += "T23:59:59.000Z");
        let strTime =
          filterEvolution.startTime !== ""
            ? (filterEvolution.startTime += "T00:00:00.000Z")
            : (beforeDate += "T00:00:00.000Z");
        let endTime =
          filterEvolution.startTime !== ""
            ? (filterEvolution.endTime += "T23:59:59.000Z")
            : crtTime;
        let dayStrTime = (filter.startTime += "T00:00:00.000Z");
        let dayEndTimw = (filter.endTime += "T23:59:59.000Z");

        const result = await getDashboard(
          user?.id,
          strTime,
          endTime,
          dayStrTime,
          dayEndTimw
        );
        if (result) {
          setValuesDashboard(result);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getValues = async () => {
    setLoading(true);
    try {
      let strTime = beforeDate.slice(0, 10);
      let crtTime = currentDate.slice(0, 10);
      let dayStrTime = beforeSevenDate.slice(0, 10);
      strTime = strTime += "T00:00:00.000Z";
      crtTime = crtTime += "T23:59:59.000Z";
      dayStrTime = dayStrTime += "T00:00:00.000Z";

      const result = await getDashboard(
        user?.id,
        strTime,
        crtTime,
        dayStrTime,
        crtTime
      );
      if (result) {
        setValuesDashboard(result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
    setLoading(false);
  };
  const getCurrentBalance = async () => {
    try {
      const id = user?.id;

      if (id) {
        const result = await getBinance(user?.id);

        if (result) {
          const filterBalance = result.balances.filter((item: any) => {
            if (["USDT", "BTC", "BUSD"].includes(item.asset)) {
              return {
                asset: item.asset,
                free: item.free,
              };
            }
          });
          setBalance(filterBalance);
        }
      }
    } catch (error) {
      console.error("Erro: B", error);
      setNotBalance(true);
    }
  };

  const styleValue = (value: any) => {
    if (value) {
      let item = value.toString();

      if (item.includes("-")) {
        return "label red";
      } else {
        return "label green";
      }
    } else {
      return "label";
    }
  };

  function CustomTooltip({ active, payload, label }: any) {
    if (active && label && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>
            {format(parseISO(label), "eee, d MMM, yyyy", { locale: ptBR })}
          </h4>
          <p>${payload[0].value.toFixed(2)}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipPie({ active, payload, label }: any) {
    if (active && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>{payload[0].name}</h4>
          <p>${payload[0].value.toFixed(2)}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipLine({ active, payload, label }: any) {
    if (active && label && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>
            {format(parseISO(label), "eee, d MMM, yyyy", { locale: ptBR })}
          </h4>
          <p>BTC: {payload[0].payload.BTC}</p>
          <p>USDT: {payload[0].payload.USDT}</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipBar({ active, payload, label }: any) {
    if (active && label && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>
            {format(parseISO(label), "eee, d MMM, yyyy", { locale: ptBR })}
          </h4>
          <p>USDT: {payload[0].payload.USDT}</p>
        </div>
      );
    }
    return null;
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomizedLabel: FunctionComponent<any> = (props: any) => {
    const { x, y, value } = props;

    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fill="#fff"
        fontSize={10}
        fontWeight={600}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    let date = payload.value !== "auto" ? payload.value : null;
    if (date !== null) {
      let newDate = date.substr(5, 5);
      newDate = newDate.split("-").reverse().join("-").replace("-", "/");
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="var(--white)"
            transform="rotate(-35)"
          >
            {newDate}
          </text>
        </g>
      );
    }
    return null;
  };

  const CustomizedAxisTickBar: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;

    let date = payload.value !== "auto" ? payload.value : null;
    if (date !== null) {
      let newDate = date.substr(5, 5);
      newDate = newDate.split("-").reverse().join("-").replace("-", "/");
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="var(--white)"
            transform="rotate(-35)"
          >
            {newDate}
          </text>
        </g>
      );
    }
    return null;
  };

  const handleFilter = (key: string, field: string, value: any) => {
    if (key === "filterEvolution") {
      if (value) {
        setFilterEvolution({
          ...filterEvolution,
          [field]: value,
        });
      } else {
        setFilterEvolution({
          ...filterEvolution,
          [field]: "",
        });
      }
    } else {
      if (value) {
        setFilterProfit({
          ...filterProfit,
          [field]: value,
        });
      } else {
        setFilterProfit({
          ...filterProfit,
          [field]: "",
        });
      }
    }
  };

  return (
    <Container>
      <Title>Página Inicial</Title>
      <Content>
        <Column>
          <Row className="infoProfile">
            <DivProfile>
              <ProfilePhotoContainer wide={"200px"}>
                <ProfilePhotoButton wide="130px">
                  <ProfilePhoto
                    wide={"130px"}
                    src={user?.foto || ImageUser}
                    alt="foto do usuário"
                  />
                </ProfilePhotoButton>
              </ProfilePhotoContainer>
              <Column high={"auto"}>
                <div className="nameUser">
                  <h3>{user?.nome}</h3>
                  <ButtonSvg type="button" onClick={() => setVisible(!visible)}>
                    {visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </ButtonSvg>
                </div>
                <div
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  {balance.length > 0 ? (
                    balance.map((item: any, index: any) => (
                      <div className="saldo" key={index}>
                        <label>Saldo {item.asset}</label>
                        {visible && (
                          <p>
                            {item.asset === "BTC" ? (
                              <BiBitcoin />
                            ) : (
                              <BiDollar />
                            )}
                            {item.free
                              ? `${Number(item.free).toFixed(2)}`
                              : "0,00"}
                          </p>
                        )}
                      </div>
                    ))
                  ) : notBalance ? (
                    <div className="saldo">
                      <label>Saldo</label>
                      <p>$ 0.00</p>
                    </div>
                  ) : null}
                </div>
              </Column>
            </DivProfile>
            <DivOrders>
              <GradientOrange>
                <div>
                  <div className="label">
                    {valuesDashboard?.opFechadas
                      ? valuesDashboard?.opFechadas
                      : 0}
                  </div>
                  <div className="field">Ordens Encerradas</div>
                </div>
              </GradientOrange>
              <GradientPink>
                <div>
                  <div className="label">
                    {valuesDashboard?.opAbertas
                      ? valuesDashboard?.opAbertas
                      : 0}
                  </div>
                  <div className="field">Ordens Abertas</div>
                </div>
              </GradientPink>
              <GradientBlue>
                <div>
                  <div
                    className={
                      valuesDashboard?.lucroDia
                        ? styleValue(valuesDashboard?.lucroDia)
                        : "label green"
                    }
                  >
                    {valuesDashboard?.lucroDia
                      ? `$${parseFloat(valuesDashboard?.lucroDia).toFixed(2)}`
                      : notBalance
                      ? "$0.00"
                      : null}
                  </div>
                  <div className="field">Lucro Dia</div>
                </div>
              </GradientBlue>
              <GradientOrange>
                <div>
                  <div
                    className={
                      valuesDashboard?.lucroMes
                        ? styleValue(valuesDashboard?.lucroMes)
                        : "label green"
                    }
                  >
                    {valuesDashboard?.lucroMes
                      ? `$${parseFloat(valuesDashboard.lucroMes).toFixed(2)}`
                      : notBalance
                      ? "$0.00"
                      : null}
                  </div>
                  <div className="field">Lucro Mês</div>
                </div>
              </GradientOrange>
              <GradientPink>
                <div>
                  <div
                    className={
                      valuesDashboard?.lucroTotal
                        ? styleValue(valuesDashboard?.lucroTotal)
                        : "label green"
                    }
                  >
                    {valuesDashboard?.lucroTotal
                      ? `$${parseFloat(valuesDashboard.lucroTotal).toFixed(2)}`
                      : notBalance
                      ? "$0.00"
                      : null}
                  </div>
                  <div className="field">Lucro Total</div>
                </div>
              </GradientPink>
            </DivOrders>
          </Row>
          <Row className="graphic">
            {/* {data.length > 0 && (
              <DivChart wide={"59.5%"}>
                <ResponsiveContainer width={"100%"}>
                  <AreaChart data={data}>
                    <defs>
                      <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="0%"
                          stopColor="#2451B7"
                          stopOpacity={0.4}
                        />
                        <stop
                          offset="75%"
                          stopColor="#2451B7"
                          stopOpacity={0.05}
                        />
                      </linearGradient>
                    </defs>

                    <Area dataKey="value" stroke="#2451B7" fill="url(#color)" />

                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(str) => {
                        const date = parseISO(str);
                        if (date.getDate() % 7 === 0) {
                          return format(date, "MMM, yyyy");
                        }
                        return "";
                      }}
                    />

                    <YAxis
                      dataKey="value"
                      axisLine={false}
                      tickLine={false}
                      width={100}
                      // tickCount={8}
                      tickFormatter={(number) => `$${number.toFixed(2)}`}
                    />

                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{
                        stroke: "gray",
                        strokeWidth: 1,
                        strokeDasharray: 5,
                      }}
                    />

                    <CartesianGrid opacity={0.1} vertical={false} />
                  </AreaChart>
                </ResponsiveContainer>
              </DivChart>
            )} */}
            {/* <hr /> */}
            {dataPieCharts.length > 0 && (
              <DivChart wide={"40%"} className="pieChart">
                <p>Composição da Carteira</p>
                <ResponsiveContainer width={"100%"}>
                  <PieChart>
                    <Pie
                      data={dataPieCharts}
                      dataKey="value"
                      nameKey="name"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      innerRadius={70}
                    >
                      {dataPieCharts.map((entry: any, index: any) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Pie>
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip content={CustomTooltipPie} />
                  </PieChart>
                </ResponsiveContainer>
              </DivChart>
            )}
          </Row>
          <Row className="graphic">
            <DivChart wide={"98%"} style={{ height: 370 }}>
              <p>Evolução do Patrimônio</p>
              {/* <form>
                <div>
                  <label htmlFor="startTime">
                    De: &nbsp;
                    <input
                      type="date"
                      name="startTime"
                      id="startTime"
                      onChange={({ target }) =>
                        handleFilter(
                          "filterEvolution",
                          "startTime",
                          target.value
                        )
                      }
                      min={beforeDate}
                      max={currentDate}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="to">
                    Até: &nbsp;
                    <input
                      type="date"
                      name="endTime"
                      id="endTime"
                      onChange={({ target }) =>
                        handleFilter("filterEvolution", "endTime", target.value)
                      }
                      min={beforeDate}
                      max={currentDate}
                    />
                  </label>
                </div>
              </form> */}
              {dataLineCharts[0] === null ? (
                <div className="chart">
                  <p>
                    Carregando{" "}
                    <span className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </span>
                  </p>
                </div>
              ) : dataLineCharts.length > 0 && dataLineCharts[0] !== null ? (
                <ResponsiveContainer width={"96%"}>
                  <AreaChart
                    data={dataLineCharts}
                    height={300}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <defs>
                      <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="0%"
                          stopColor="#41147e"
                          stopOpacity={0.4}
                        />
                        <stop
                          offset="75%"
                          stopColor="#41147e"
                          stopOpacity={0.05}
                        />
                      </linearGradient>
                    </defs>

                    <CartesianGrid
                      strokeDasharray="3 3"
                      opacity={0.1}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="Dia"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(str) => {
                        const date = parseISO(str);

                        if (date.getDate() % 4 === 0) {
                          return format(date, "MMM, yyyy");
                        }
                        return "";
                      }}
                    />

                    <YAxis
                      dataKey="USDT"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(number) => `$${number.toFixed(2)}`}
                    />

                    <Tooltip
                      content={<CustomTooltipLine />}
                      cursor={{
                        stroke: "gray",
                        strokeWidth: 1,
                        strokeDasharray: 5,
                      }}
                    />
                    <Area
                      dataKey="USDT"
                      type="monotone"
                      stroke="#41147e"
                      fill="url(#color)"
                      strokeWidth={2}
                      height={300}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <div className="chart">
                  <p>Dados não encontrados.</p>
                  <ButtonGraph disabled={loading} onClick={() => getValues()}>
                    Tentar novamente
                  </ButtonGraph>
                </div>
              )}
            </DivChart>
          </Row>
          <Row>
            <DivChart wide={"94%"} high={"290px"}>
              <p>Lucro Diário</p>
              {/* <form>
                <div>
                  <label htmlFor="startTime">
                    De: &nbsp;
                    <input
                      type="date"
                      name="startTime"
                      id="startTime"
                      onChange={({ target }) =>
                        handleFilter("filterProfit", "startTime", target.value)
                      }
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="endTime">
                    Até: &nbsp;
                    <input
                      type="date"
                      name="endTime"
                      id="endTime"
                      onChange={({ target }) =>
                        handleFilter("filterProfit", "endTime", target.value)
                      }
                    />
                  </label>
                </div>
              </form> */}
              {dataBarCharts[0] === null ? (
                <div className="chart">
                  <p>
                    Carregando{" "}
                    <span className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </span>
                  </p>
                </div>
              ) : dataBarCharts.length > 0 && dataBarCharts[0] !== null ? (
                <ResponsiveContainer className="dailyProfit" width={"96%"}>
                  <BarChart
                    data={dataBarCharts}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 2,
                    }}
                    height={300}
                    barSize={20}
                  >
                    <XAxis
                      dataKey="Dia"
                      stroke="var(--white)"
                      padding={{ left: 12, right: 10 }}
                      angle={-40}
                      tickLine={false}
                      tickMargin={8}
                      // tick={<CustomizedAxisTickBar />}
                      tickFormatter={(str) => {
                        const date = parseISO(str);

                        if (date.getDate() % 1 === 0) {
                          return format(date, "dd/MM");
                        }
                        return "";
                      }}
                    />
                    <YAxis
                      stroke="var(--muted)"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(number) => `$${number}`}
                    />
                    <Tooltip content={CustomTooltipBar} />
                    <Legend />
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke="var(--muted)"
                    />
                    <Bar
                      dataKey="USDT"
                      fill="var(--primary)"
                      background={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div className="chart">
                  <p>Dados não encontrados.</p>
                  <ButtonGraph onClick={() => getValues()}>
                    Tentar novamente
                  </ButtonGraph>
                </div>
              )}
            </DivChart>
          </Row>
        </Column>
      </Content>
    </Container>
  );
};

export default Home;
