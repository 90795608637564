import React, { useEffect, useRef, useState } from "react";
import { useFormik, FormikHelpers } from "formik";

import {
  Container,
  PaddingContainer,
  Content,
  Title,
  ProfilePhotoContainer,
  ProfilePhotoButton,
  ProfilePhoto,
  ProfilePhotoButtonOverlay,
  ProfileInfoContainer,
  ProfileSaveContainer,
  Form,
  Row,
  ProfileTokens,
  Column,
  Input,
  RowInput,
  Button,
  ButtonOutline,
  Select,
  ButtonSvg,
  CardImage,
} from "./styles";
import { IoMdExit } from "react-icons/io";

import { useAuth } from "../../contexts/auth";
import FormInputs from "../FormInputs";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";
import ImageUser from "../../assets/images/user.png";
import {
  getBrokerAll,
  getUser,
  postTokens,
  putTelegram,
  putUser,
} from "../../services/user";
import toast from "react-hot-toast";
import Toast from "../Toast";
import { uploadFile } from "../../services/upload";
import { getCounties, getStates } from "../../services/address";
import { ICountie, IState } from "../../services/auth";
import AvatarEditor from "react-avatar-editor";
import * as Validate from "validations-br";

interface IEntidadePayload {
  nome: string;
  foto?: string;
  email?: string;
  telefone?: string;
  celular?: string;
  cep?: string;
  cpfCnpj?: string;
  dataNascimento?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  cidade?: ICountie | ICountie[];
  idCidade?: string;
  uf?: IState | IState[];
  idUF?: string;
  complemento?: string;
}

interface ITokens {
  broker: string;
  api_key?: string;
  secret_key?: string;
}

interface ITelegram {
  code: string;
  status: boolean;
}

interface IEntidadeValidation {
  nome?: string;
  email?: string;
  cpfCnpj?: string;
  // telefone?: string;
  celular?: string;
  // cep?: string;
  // endereco?: string;
  // numeroEndereco?: string;
  // bairro?: string;
  Cidade?: Object[];
  UF?: Object[];
  // complemento?: string;
}

interface ICrop {
  unit: string; // Can be 'px' or '%'
  x: number;
  y: number;
  width: number;
  height: number;
}

interface IPicture {
  cropperOpen: boolean;
  img: any;
  zoom: number;
  croppedImage: any;
}

const Profile: React.FC = () => {
  const { user, updateUser, signOut } = useAuth();
  const imageRef = useRef<any>(null);
  const CanvasRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [masked, setMasked] = useState<string>("+9 (99) 9999-9999");
  const [isEditing, setIsEditing] = useState(false);
  const [api_key, setApi_key] = useState<boolean>(false);
  const [secret_key, setSecret_key] = useState<boolean>(false);
  const [telegram, setTelegram] = useState<boolean>(false);
  const [loadingTelegram, setLoadingTelegram] = useState<boolean>(false);
  const [coin, setCoin] = useState(false);
  const [broker, setBroker] = useState([]);
  const [statusUser, setStatusUser] = useState(false);
  const [upload, setUpload] = useState<any>({});
  const [photo, setPhoto] = useState<boolean>(false);
  const [imageCroped, setImageCroped] = useState<any>();
  var editor: any = "";
  const [picture, setPicture] = useState<IPicture>({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImage: user?.foto,
  });
  const [crop, setCrop] = useState<ICrop | any>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [initialStates, setInitialStates] = useState<Array<any>>([]);
  const [stateSelected, setStateSelected] = useState<string>();
  const [initialCounties, setInitialCounties] = useState<Array<any>>([]);
  const [initialTokens, setInitialTokens] = useState<ITokens>({
    broker: "",
    api_key: "",
    secret_key: "",
  });
  const [codeTelegram, setCodeTelegram] = useState<ITelegram>({
    code: user?.zCodigoTelegram ? user.zCodigoTelegram : "",
    status: user?.zRecebeNotificacaoTelegram === true ? true : false,
  });
  const [emailNotification, setEmailNotification] = useState(
    user?.zRecebeNotificacaoEmail === true ? true : false
  );

  useEffect(() => {
    getStatusUser();
    getBroker();
    if (isEditing) {
      getFieldsStates();
      if (user?.UF && user.UF.id) {
        getFieldsCity(user.UF.id);
      }
    }
  }, [isEditing]);

  const initialValues: IEntidadePayload = {
    nome: user?.nome || "",
    email: user?.email || "",
    celular: user?.celular || "",
    cidade: user?.Cidade ? user.Cidade.nome : initialCounties,
    uf: user?.UF ? user.UF.nome : initialStates,
    dataNascimento: user?.dataNascimento
      ? user.dataNascimento.substring(0, 10).split("-").reverse().join("-")
      : "",
    cpfCnpj: user?.cpfCnpj || "",
    cep: user?.cep || "",
    endereco: user?.endereco || "",
    numeroEndereco: user?.numeroEndereco || "",
    bairro: user?.bairro || "",
    complemento: user?.complemento || "",
  };

  const fields = {
    nome: {
      id: "nome",
      placeholder: "Digite seu nome...",
      label: "Nome",
    },
    email: {
      id: "email",
      placeholder: "Digite seu email...",
      label: "E-mail",
    },
    row0: [
      {
        id: "cpfCnpj",
        placeholder: "Digite seu CPF...",
        label: "CPF",
        mask: "999.999.999-99",
      },
      {
        id: "dataNascimento",
        placeholder: "Data Nascimento",
        label: "Data de Nascimento",
        mask: "99/99/9999",
      },
    ],
    row1: [
      // {
      //   id: "telefone",
      //   placeholder: "Digite seu telefone...",
      //   label: "Telefone",
      //   mask: "(99) 99999-9999",
      // },
      {
        id: "celular",
        placeholder: "Digite seu celular...",
        label: "Celular",
        mask: "+999 (99) 99999-9999",
      },
      {
        id: "cep",
        placeholder: "Digite seu CEP...",
        label: "CEP",
        mask: "99999-999",
      },
    ],
    row2: [
      {
        id: "endereco",
        placeholder: "Digite seu logradouro...",
        label: "Logradouro",
      },
      {
        id: "numeroEndereco",
        placeholder: "N°",
        label: "Número",
      },
    ],
    row3: [
      {
        id: "uf",
        placeholder: "Digite seu estado...",
        label: "Estado",
        type: "select",
        options: [...initialStates],
      },
      {
        id: "cidade",
        placeholder: "Digite sua cidade...",
        label: "Cidade",
        type: "select",
        options: [...initialCounties],
      },
      {
        id: "bairro",
        placeholder: "Digite seu bairro...",
        label: "Bairro",
      },
    ],
    complement: {
      id: "complemento",
      placeholder: "Digite o complemento do endereço...",
      label: "Complemento",
    },
  };

  const setEditorRef = (ed: any) => {
    editor = ed;
  };

  const handleSave = () => {
    if (editor !== "") {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      var timestamp = new Date().getTime();
      var file = dataURLtoFile(croppedImg, timestamp);

      setImageCroped(file);

      if (user) updateUser({ ...user, foto: croppedImg });
      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
      });
      setPhoto(false);
    }
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getBroker = async () => {
    try {
      const result = await getBrokerAll();

      if (result) {
        setBroker(result);
      }
    } catch (err: any) {
      Toast.show(err.message, "error");
    }
  };

  const getStatusUser = async () => {
    try {
      const result = await getUser(user?.id);

      if (result) {
        setStatusUser(result.binanceApiStatus);
        updateUser(result);
      }
    } catch (err: any) {
      Toast.show(err.message, "error");
    }
  };

  const getFieldsCity = async (state: any) => {
    try {
      const result = await getCounties(state);
      if (result) {
        let munic = result.map((item: any) => {
          return { id: item.id, name: item.nome };
        });
        setInitialCounties(munic);
      }
    } catch (err: any) {
      Toast.show(err.message, "error");
    }
  };

  const getFieldsStates = async () => {
    try {
      const result = await getStates();
      if (result) {
        let estado = result.map((item: any) => {
          return { id: item.id, name: item.nome };
        });
        setInitialStates(estado);
      }
    } catch (err: any) {
      Toast.show(err.message, "error");
    }
  };

  const handleSubmit = async (
    values: IEntidadePayload,
    helpers: FormikHelpers<IEntidadePayload>
  ) => {
    const userId = user?.id;
    let {
      bairro,
      celular,
      cep,
      cidade,
      complemento,
      cpfCnpj,
      dataNascimento,
      email,
      endereco,
      nome,
      numeroEndereco,
      uf,
    } = values;
    let payload: any = { nome, uf, cidade, celular: celular?.replaceAll("_", ""), email };

    let newDate = dataNascimento;
    if (newDate !== "") {
      let date: any = newDate?.replaceAll("/", "-");
      date = date.split("-").reverse().join("-");

      payload["dataNascimento"] =
        newDate !== "" ? (date += "T00:00:00.000Z") : "";
    }

    let city = initialCounties.find(
      (item: any) => item.name === payload?.cidade
    );

    if (city) payload["idCidade"] = city.id;

    if (stateSelected) payload["idUF"] = stateSelected;

    if (bairro !== "") payload["bairro"] = bairro;

    if (cep !== "") payload["cep"] = cep;

    if (complemento !== "") payload["complemento"] = complemento;

    if (cpfCnpj !== "") payload["cpfCnpj"] = cpfCnpj;

    if (endereco !== "") payload["endereco"] = endereco;

    if (numeroEndereco !== "") payload["numeroEndereco"] = numeroEndereco;

    if (imageCroped) {
      const value = imageCroped;

      const formData = new FormData();
      formData.append("file", value);

      try {
        const url = await uploadFile(formData);

        if (url) {
          const _payload = payload;
          _payload.foto = url;

          const result = await putUser(userId, {
            celular: _payload.celular,
            email: _payload.email?.toLowerCase(),
            idCidade: _payload.idCidade,
            idUF: _payload.idUF,
            nome: _payload.nome,
            cep: _payload.cep,
            dataNascimento: _payload.dataNascimento,
            bairro: payload.bairro,
            cpfCnpj: payload.cpfCnpj,
            complemento: payload.complemento,
            endereco: payload.endereco,
            numeroEndereco: payload.numeroEndereco,
            foto: _payload.foto,
          });
          if (result.status === 200) {
            updateUser(result.data);
            Toast.show("Dados enviados com sucesso", "success");
            setUpload({});
            setIsEditing(false);
            setImageCroped(null);
          }
        }
      } catch (err: any) {
        Toast.show(err.message, "error");
        setImageCroped(null);
      }
    } else {
      try {
        const result = await putUser(userId, {
          celular: payload.celular,
          email: payload.email?.toLowerCase(),
          idCidade: payload.idCidade,
          idUF: payload.idUF,
          nome: payload.nome,
          cep: payload.cep,
          dataNascimento: payload.dataNascimento,
          bairro: payload.bairro,
          cpfCnpj: payload.cpfCnpj,
          complemento: payload.complemento,
          endereco: payload.endereco,
          numeroEndereco: payload.numeroEndereco,
        });
        if (result.status === 200) {
          updateUser(result.data);
          Toast.show("Dados enviados com sucesso", "success");
          setIsEditing(false);
        }
      } catch (err: any) {
        Toast.show(err.message, "error");
      }
    }
  };

  const validate = (values: IEntidadePayload) => {
    const errors: IEntidadeValidation = {};

    if (!values.nome) {
      errors.nome = "* Obrigatório";
    }

    if (!values.celular) {
      errors.celular = "* Obrigatório";
    }

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(
        values.email.replaceAll(" ", "")
      )
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    if (!values.cpfCnpj) {
      errors.cpfCnpj = "* Obrigatório";
    } else if (values.cpfCnpj) {
      let isValid;
      if (values.cpfCnpj.replace(/\D/g, "").length < 12) {
        isValid = Validate.validateCPF(values.cpfCnpj);
        if (!isValid) {
          errors.cpfCnpj = "CPF inválido";
        }
      } else {
        isValid = Validate.validateCNPJ(values.cpfCnpj);
        if (!isValid) {
          errors.cpfCnpj = "CNPJ inválido";
        }
      }
    }

    return errors;
  };

  const handleValueToken = (key: string, value: string) => {
    setInitialTokens({
      ...initialTokens,
      [key]: value,
    });
  };

  const handleValueTelegram = (value: string) => {
    setCodeTelegram({ ...codeTelegram, code: value });
  };

  const userForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (userForm.values) {
      let uf = userForm.values.uf;
      let celular = userForm.values.celular;

      if (uf) {
        let filter = initialStates.find((item: any) => item.name === uf);
        if (filter) {
          getFieldsCity(filter.id);
          setStateSelected(filter.id);
        }
      }

      if(celular && celular.length < 17){
        setMasked("(99) 9999-9999");
      }else if(celular && celular?.length === 17){
        setMasked("+9 (99) 9999-9999");
      } else if(celular && celular?.length === 18){
        setMasked("+99 (99) 9999-9999");
      } else if(celular && celular?.length === 19){
        setMasked("999 (99) 9999-9999");
      }
    }
  }, [userForm.values]);

  const renderUserInfo = () => {
    return (
      <>
        <fieldset>
          <span>Nome</span>
          <p>{user?.nome}</p>
        </fieldset>
        <fieldset>
          <span>E-mail</span>
          <p>{user?.email}</p>
        </fieldset>
        {/* <fieldset>
          <span>CPF</span>
          <p>{user?.cpf}</p>
        </fieldset> */}
        <fieldset>
          <span>Celular</span>
          <p>{user?.celular}</p>
        </fieldset>
        {/* <fieldset>
          <span>Estabelecimento</span>
          <p>Nome do Estabelecimento</p>
        </fieldset>
        <fieldset>
          <span>Grupo</span>
          <p>Nome do Grupo</p>
        </fieldset> */}
        <fieldset>
          <span>Cidade</span>
          <p>
            {/* {user?.endereco} {user?.numeroEndereco && `,${user.numeroEndereco}`}{" "} */}
            {/* {user?.bairro && `.${user.bairro}, `} {user?.cidade}{" "} */}
            {user?.Cidade?.nome} {user?.UF && ` - ${user.UF.nome}`}
            {/* {user?.complemento && `. ${user.complemento}.`} */}
          </p>
        </fieldset>
      </>
    );
  };

  const renderUserForm = () => {
    return (
      <Form onSubmit={userForm.handleSubmit}>
        <FormInputs fields={fields} form={userForm} />
      </Form>
    );
  };

  const handleSubmitToken = async () => {
    if (validateToken()) {
      setLoading(true);
      try {
        const result = await postTokens(user?.id, initialTokens);

        if (result.status === 201) {
          updateUser(result.data);
          toast.success("Salvo com sucesso.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error);
        setLoading(false);
      }
    }
  };

  const handleSubmitTelegram = async () => {
    if (codeTelegram.code !== "") {
      setLoadingTelegram(true);
      try {
        const result = await putTelegram(
          user?.id,
          codeTelegram,
          emailNotification
        );
        if (result.status === 200) {
          updateUser(result.data);
          setCodeTelegram({
            ...codeTelegram,
            code: result.data.zCodigoTelegram,
            status: result.data.zRecebeNotificacaoTelegram,
          });
          toast.success("Salvo com sucesso.");
          setLoadingTelegram(false);
        }
      } catch (err: any) {
        toast.error("Erro ao salvar username do telegram");
        setLoadingTelegram(false);
      }
    } else {
      toast.error("Digite o seu username do telegram");
      setLoadingTelegram(false);
    }
  };

  const DesconectTelegram = async () => {
    if (user?.zRecebeNotificacaoTelegram === true) {
      try {
        const result = await putTelegram(
          user?.id,
          {
            code: codeTelegram.code,
            status: false,
          },
          emailNotification
        );
        if (result.status == 200) {
          updateUser(result.data);
          setCodeTelegram({
            ...codeTelegram,
            code: result.data.zCodigoTelegram,
            status: result.data.zRecebeNotificacaoTelegram,
          });
          toast.success("Salvo com sucesso.");
        }
      } catch (err: any) {
        toast.error("Erro ao desconectar username do telegram");
      }
    } else {
      toast.error("Status de notificação desativada");
    }
  };

  const validateToken = () => {
    if (initialTokens.broker === "") {
      toast.error("Selecione a corretora.");
    } else if (
      initialTokens.api_key === "" ||
      initialTokens.secret_key === ""
    ) {
      toast.error("Preencha os campos API Key e Secret Key");
    } else {
      return true;
    }
  };

  const handleImageChanged = (event: any) => {
    const files = event[0];

    if (files) {
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.addEventListener("load", () => {
        setUpload(reader.result?.toString() || "");
      });
      setPhoto(true);
    }
  };

  const clear = () => {
    const input = imageRef;
    input.current.value = "";
    setUpload(null);
    setPhoto(false);
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const handleStatusTelegram = (value: boolean) => {
    setCodeTelegram({
      ...codeTelegram,
      status: value === true ? false : true,
    });
  };

  const handleSlider = (value: any) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  return (
    <Container>
      <PaddingContainer>
        <Row jcontent={"space-between"}>
          <Title>Bem-vindo(a), {user?.nome}</Title>
          <button className="signOut" onClick={signOut}>
            <IoMdExit size={32} color="var(--primary-text-color)" />
          </button>
        </Row>
        <Content>
          <Column>
            <ProfilePhotoContainer style={{ width: "100%" }}>
              <ProfilePhotoButton wide={"250px"}>
                <ProfilePhoto
                  src={user?.foto || ImageUser}
                  alt="foto do usuário"
                />
                {isEditing && (
                  <ProfilePhotoButtonOverlay>
                    <input
                      type="file"
                      name="photo"
                      id="photo"
                      ref={imageRef}
                      accept=".jpg, .jpeg, .png"
                      onChange={({ target }) =>
                        handleImageChanged(target.files)
                      }
                    />
                    <label htmlFor="photo">Escolher a foto de perfil</label>
                  </ProfilePhotoButtonOverlay>
                )}
              </ProfilePhotoButton>
              {photo === true && (
                <CardImage>
                  <AvatarEditor
                    ref={setEditorRef}
                    image={upload}
                    // width={170}
                    // height={180}
                    border={50}
                    borderRadius={150}
                    scale={picture.zoom}
                  />
                  <input
                    type="range"
                    min={0}
                    max={10}
                    value={picture.zoom}
                    step={0.1}
                    onChange={({ target }) => handleSlider(+target.value)}
                  />
                  <Row bottom="10px" left="auto" right="auto" wide="80%">
                    <Button background="var(--warning)" onClick={() => clear()}>
                      Cancelar
                    </Button>
                    <Button
                      style={{ marginTop: 10, marginLeft: "auto" }}
                      onClick={() => handleSave()}
                    >
                      Cortar
                    </Button>
                  </Row>
                </CardImage>
              )}
              <canvas ref={CanvasRef}></canvas>
            </ProfilePhotoContainer>
            <ProfileTokens>
              <header>
                <p>Status</p>
              </header>
              <hr />

              <RowInput>
                <Column>
                  <Row bottom={"10px"}>
                    <label htmlFor="account">
                      Status da Conta:{" "}
                      <span
                        className={user?.inativo == false ? "green" : "red"}
                      >
                        {user?.inativo == false ? "Ativa" : "Inativa"}
                      </span>
                    </label>
                  </Row>
                  <Row bottom={"10px"}>
                    <label htmlFor="api">
                      Status da API:{" "}
                      <span
                        className={
                          statusUser
                            ? statusUser === true
                              ? "green"
                              : "red"
                            : user
                            ? user.zBinanceApiActivated === true
                              ? "green"
                              : "red"
                            : ""
                        }
                      >
                        {statusUser
                          ? statusUser === true
                            ? "Ativa"
                            : "Inativa"
                          : user
                          ? user.zBinanceApiActivated === true
                            ? "Ativa"
                            : "Inativa"
                          : null}
                      </span>
                    </label>
                  </Row>
                  <Row bottom={"10px"}>
                    <label htmlFor="notification">
                      Status da Notificação:{" "}
                      <span
                        className={
                          user?.zRecebeNotificacaoTelegram === true
                            ? "green"
                            : "red"
                        }
                      >
                        {user?.zRecebeNotificacaoTelegram === false
                          ? "Inativa"
                          : "Ativa"}
                      </span>
                    </label>
                  </Row>
                </Column>
              </RowInput>
            </ProfileTokens>
          </Column>
          <Column>
            {/* Inicio Section Perfil */}
            <ProfileInfoContainer>
              <header>
                <p>Informações de contato</p>
                <button onClick={() => setIsEditing(!isEditing)}>editar</button>
              </header>
              <hr />
              {isEditing ? renderUserForm() : renderUserInfo()}
              {isEditing && (
                <ProfileSaveContainer showing={isEditing}>
                  <Button
                    disabled={userForm.isSubmitting}
                    variant="filled"
                    type="submit"
                    onClick={userForm.submitForm}
                  >
                    Salvar
                  </Button>
                  <Button onClick={() => setIsEditing(false)}>Cancelar</Button>
                </ProfileSaveContainer>
              )}
            </ProfileInfoContainer>
            {/* Fim Section Perfil */}

            {/* Inicio Section Token */}
            <ProfileTokens>
              <header>
                <p>Token de acesso</p>
              </header>
              <hr />
              <RowInput>
                <Column>
                  <Row bottom={"6px"}>
                    <Column>
                      <label htmlFor="select_api">Selecione a Corretora</label>
                      <Select
                        wide={"40%"}
                        name="select_api"
                        id="select_api"
                        onChange={({ target }) =>
                          handleValueToken("broker", target.value)
                        }
                      >
                        <option hidden>Selecione...</option>
                        {broker &&
                          broker.map((item: any, index: any) => (
                            <option value={item.id} key={index}>
                              {item.nome}
                            </option>
                          ))}
                      </Select>
                    </Column>
                    {loading && (
                      <span className="loading">
                        <AiOutlineLoading /> &nbsp;
                      </span>
                    )}
                  </Row>
                  <Row bottom={"0"}>
                    <Column>
                      <label htmlFor="api_key">API Key</label>
                      <div className="token">
                        <div className="d-flex">
                          <Input
                            id="api_key"
                            name="api_key"
                            transparent={user?.satp ? true : false}
                            placeholder={
                              user?.satp
                                ? "...................................."
                                : "Digite..."
                            }
                            // label="Binance"
                            autoComplete="off"
                            type={api_key ? "text" : "password"}
                            onChange={({ target }) =>
                              handleValueToken("api_key", target.value)
                            }
                          />
                          <ButtonSvg
                            type="button"
                            onClick={() => setApi_key(!api_key)}
                            transparent={user?.satp ? true : false}
                          >
                            {api_key ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </ButtonSvg>
                        </div>
                      </div>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <label htmlFor="secret_key">Secret Key</label>
                      <div className="token">
                        <div className="d-flex">
                          <Input
                            id="secret_key"
                            name="secret_key"
                            transparent={user?.satp ? true : false}
                            placeholder={
                              user?.satp
                                ? "...................................."
                                : "Digite..."
                            }
                            autoComplete="off"
                            type={secret_key ? "text" : "password"}
                            onChange={({ target }) =>
                              handleValueToken("secret_key", target.value)
                            }
                          />
                          {/* <button
                            type="button"
                            onClick={() => setSecret_key(!secret_key)}
                            >
                            {secret_key ? (
                              <AiOutlineEye />
                              ) : (
                                <AiOutlineEyeInvisible />
                                )}
                              </button> */}
                        </div>
                      </div>
                    </Column>
                  </Row>
                  <Row bottom={"0"} jcontent={"flex-end"}>
                    <ButtonOutline right={"20px"}>Excluir</ButtonOutline>
                    <Button
                      disabled={loading}
                      onClick={() => handleSubmitToken()}
                    >
                      Atualizar
                    </Button>
                  </Row>
                </Column>
              </RowInput>
              {/* <RowInput>
                <Input 
                  id="PooCoin"
                  name="PooCoin"
                  placeholder="Digite..."
                  label="Poo Coin"
                  type={coin ? "text" : "password"}
                  onChange={(ev) => handleValueToken('coin', ev.target.value)}
                />
                <button type='button' onClick={() => setCoin(!coin)}>
                  {coin ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              </RowInput> */}
            </ProfileTokens>
            {/* Fim Section Token */}

            {/* Inicio Section Telegram */}
            <ProfileTokens>
              <header>
                <p>Notificação Telegram</p>
              </header>
              <hr />
              <RowInput>
                <Column>
                  <label htmlFor="cod_telegram">Username Telegram</label>
                  <div className="token">
                    <div className="d-flex">
                      <Input
                        wide={"70%"}
                        id="cod_telegram"
                        name="cod_telegram"
                        autoComplete="off"
                        type={"text"}
                        value={codeTelegram.code}
                        onChange={(ev) => handleValueTelegram(ev.target.value)}
                      />
                      {/* <ButtonSvg
                        type="button"
                        onClick={() => setTelegram(!telegram)}
                      >
                        {telegram ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </ButtonSvg> */}
                    </div>
                  </div>
                </Column>
              </RowInput>
              <RowInput>
                <div className="finished">
                  <div>
                    <input
                      type="checkbox"
                      name="finished"
                      id="finished"
                      checked={codeTelegram.status}
                      disabled={loadingTelegram}
                      onClick={() => handleStatusTelegram(codeTelegram.status)}
                    />
                    <label htmlFor="finished">
                      Posições finalizadas com sucesso
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="emailNotification"
                      id="emailNotification"
                      checked={emailNotification}
                      disabled={loadingTelegram}
                      onClick={() => setEmailNotification(!emailNotification)}
                    />
                    <label htmlFor="emailNotification">
                      Notificações por email
                    </label>
                  </div>
                </div>
                <div>
                  <ButtonOutline
                    wide={"auto"}
                    right={"20px"}
                    margin={"auto"}
                    onClick={() => DesconectTelegram()}
                  >
                    Desconectar Telegram
                  </ButtonOutline>
                  <Button
                    margin={"auto"}
                    onClick={() => handleSubmitTelegram()}
                  >
                    Atualizar
                  </Button>
                </div>
              </RowInput>
            </ProfileTokens>
            {/* Fim Section Telegram */}
          </Column>
        </Content>
      </PaddingContainer>
    </Container>
  );
};

export default Profile;
