import styled from "styled-components";

interface ISpace {
  wide?: string;
  padd?: string;
  jContent?: string;
  aItems?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  height: 100%;
  width: 100%;
`;

export const ContainerModal = styled.div`
  padding: 10px 20px;
  height: 100%;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .danger {
    background: var(--warning);
    border: 1px solid var(--warning);
    color: var(--white);
  }

  & > div > span > svg {
    animation: spin 1s linear infinite;
    color: var(--primary);
    font-size: 30px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Content = styled.div`
  background: var(--black);
  border: none;
  border-radius: 10px;
  margin-left: auto;
  height: auto;
  width: 85%;

  @media (max-width: 501px) {
    width: 100%;
  }
`;

export const Row = styled.div<ISpace>`
  display: flex;
  justify-content: ${(props) =>
    props.jContent ? props.jContent : "space-between"};
  align-items: ${(props) => (props.aItems ? props.aItems : "flex-start")};

  & > span > svg {
    animation: spin 1s linear infinite;
    color: var(--primary);
    font-size: 22px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

export const DivSticky = styled(Row)`
  position: sticky;
  position: -webkit-sticky;
  top: -16px;
  background: var(--background);

  @media (max-width: 501px) {
    & > div > button {
      margin-bottom: 6px;
    }
  }

  @media (max-width: 355px) {
    & > div > button {
      width: 100%;
    }
  }
`;

export const Column = styled.div<ISpace>`
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.jContent ? props.jContent : "flex-start"};
  padding: ${(props) => (props.padd ? props.padd : "20px")};
  width: ${(props) => (props.wide ? props.wide : "100%")};

  h2 {
    font-size: 2.3rem;
    margin-bottom: 10px;
  }

  .subtitle {
    color: var(--primary-text-color);
  }

  label {
    display: flex;
  }

  input {
    background: transparent;
    border: 1px solid var(--muted);
    border-radius: 6px;
    color: var(--white);
    padding: 4px;
  }

  .active {
    border: 3px solid var(--primary);
  }

  input[type="radio"] {
    display: none;
  }

  textarea {
    background: transparent;
    border: 2px solid var(--muted);
    border-radius: 10px;
    color: var(--white);
    margin-top: 10px;
    max-height: 250px;
    max-width: 100%;
    min-height: 200px;
    min-width: 80%;
    padding: 10px;
    width: 100%;
  }

  span {
    color: var(--warning);
    font-size: 1.2rem;
    margin-left: 4px;
  }

  .input_radio {
    display: flex;
    align-items: center;

    & > input {
      margin-right: 6px;
    }
  }

  @media (max-width: 599px) {
    width: 100%;
  }

  @media (max-width: 280px) {
    h2 {
      font-size: 23px;
    }
  }
`;

export const Card = styled.div`
  background: var(--form-background);
  border: 3px solid var(--form-selected);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 20px;

  iframe {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 300px;
    width: 100%;

    @media (max-width: 599px) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      align-self: center;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const TitleCard = styled.label`
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Description = styled.p`
  color: var(--muted);
  font-size: 0.9rem;
  text-align: justify;
  width: 90%;
  word-wrap: break-word;
`;

export const IndexCard = styled.div`
  background: var(--form-selected);
  border: none;
  border-radius: 10px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 50px;
  width: 50px;

  p {
    font-size: 2rem;
  }
`;

export const Form = styled.form`
  @media (max-width: 599px) {
    .buttons {
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;
