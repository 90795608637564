import styled from 'styled-components';
// width: ${({ size }) => {
//   switch (size) {
//     case 'small':
//       return '50px';
//     case 'medium':
//       return '100px';
//     case 'large':
//       return '150px';
//     default:
//       return '100px';
//   }
// }};

export const Cell = styled.td<any>`
  text-align: ${({ align }) => align};;
  min-width: 150px;
  font-size: 14px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--opacity-background);
  white-space: nowrap;
  overflow: auto;

  & input {
    border-radius: inherit;
    text-align: ${({ align }) => align};
    border: none;
    margin: auto;
    background-color: ${({disabled}) => disabled ? 'var(--opacity-background)' : 'var(--transparent)'};
  }

  & > div {
    border: none;
    margin: auto;
  }

  & > div > div {
    background-color: ${({disabled}) => disabled ? 'var(--opacity-background)' : 'var(--transparent)'};
  }
`;