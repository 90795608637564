import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.fieldset`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--input-border-blurred);
  transition: .2s;
  padding: 2px 0 2px 0;

  &:focus-within {
    border: 1px solid var(--input-border-focused);
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 300;
  color: var(--muted);
  padding: 0px 8px 0px 8px;
`;

export const Select = styled.select`
  width: 98%;
  height: 35px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
  background-color: transparent;
`;

export const Error = styled.p`
  margin-top: 4px;
  color: var(--warning);
`;