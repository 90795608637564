import Toast from "../components/Toast";
import provider from "./config";

export const postRegister = async (payload: any) => {
  const params: any = {
    nome: payload.nome,
    email: payload.email.toLowerCase().trim(),
    telefone: payload.telefone,
    cpfCnpj: payload.cpfCnpj,
    dataNascimento: payload.dataNascimento,
    senha: payload.senha,
    tipoAssinante: payload.tipoAssinante,
  };
  if (payload.idUser) {
    params["idIndicador"] = payload.idUser;
  }

  try {
    const response = await provider.post("/entidades/cadastro", params);
    return response;
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
    throw Error("Erro ao enviar os dados.");
  }
};

export const gerarPixCadastro = async (id: string) => {
  try {
    const response = await provider.post('/pedidos/checkout', {
      idUsuario: id
    })
    return response.data
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Erro ao gerar PIX")
    }
  }
}
