import styled from "styled-components";
import ButtonLayout from "../../components/Button";
import ArrowDown from "../../assets/images/down-arrow.png";
import Close from "../../assets/images/close.png";

interface ILength {
  firstItem?: string;
  secondItem?: string;
  wide?: string;
  flex?: string;
  pad?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Main = styled.div<ILength>`
  background: var(--form-background);
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  padding: ${(props) => (props.pad ? props.pad : "10px 16px 30px 16px")};
  width: 100%;

  & > div:nth-of-type(2)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Content = styled.div`
  color: var(--white);
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  @media (max-width: 599px) {
    overflow: auto;
    padding: 0;
  }
`;

export const DivRow = styled.div<ILength>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.flex ? props.flex : "flex-start")};
  padding: 10px 20px;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
  }

  & > div > span > svg {
    animation: spin 1s linear infinite;
    color: var(--primary);
    font-size: 30px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  & > div {
    margin-left: auto;
  }

  label {
    color: var(--white);
    font-size: 22px;
    margin-right: 60px;
  }

  .buttonOrder {
    background: transparent;
    padding: 0;
    white-space: nowrap;
  }

  p {
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
  }

  .filters {
    color: var(--primary-lighter);
  }

  p:first-child {
    margin-right: 20px;
  }

  .primary {
    color: var(--primary-lighter);
  }

  .button-list {
    background: var(--white);
    border: 2px solid var(--primary-lighter);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    padding: 6px;

    &:hover {
      background: var(--primary-lighter);
      border: 2px solid var(--primary-lighter);
      color: var(--white);
    }

    & > svg {
      font-size: 30px;
    }
  }

  & > form {
    display: flex;
  }

  & > form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & > input[type="search"] {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 96%;
    }

    & > input {
      border: 1px solid var(--secondary);
      border-radius: 6px;
      font-size: 12px;
      margin-top: 4px;
      padding: 6px 8px 4px 4px;
      width: 130px;
    }

    & > input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 0;
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
      cursor: pointer;
    }

    & > input[type="search"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    option {
      text-transform: capitalize;
    }
  }

  & input {
    border: 1px solid var(--muted);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--white);
    margin-right: 10px;
    padding: 4px 8px;
  }

  & input::placeholder {
    color: var(--secondary);
  }

  @media (max-width: 819px) {
    .loading {
      flex-direction: column;
    }

    & > form {
      display: flex;
      align-items: baseline;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    & > form > div {
      align-items: center;

      & > label {
        align-items: baseline !important;
      }
    }

    & > form {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
    }
  }

  @media (max-width: 419px) {
    flex-direction: column;

    & > p {
      margin-right: auto !important;
    }

    & > button {
      margin-bottom: 8px;
    }

    & > form {
      justify-content: space-between;
    }

    & > form:nth-of-type(1) > div {
      width: 48%;
      & > input[type="search"] {
        margin-right: 0;
        width: 100%;
      }

      & > input[type="search"]::-webkit-search-cancel-button {
        right: 12px;
      }
    }
  }

  @media (max-width: 375px) {
    & > div {
      margin-right: auto;
    }

    & > form:nth-of-type(1) > div {
      justify-content: center;
      width: 100%;
    }
    & > form:nth-of-type(1) > div > input {
      width: 150px;
    }
  }
`;

export const DivColumn = styled.div<ILength>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .rdl-filter-container {
    display: flex;
  }

  .rdl-filter-container > input {
    border: none;
    border-radius: 4px;
    height: 30px;
    margin-bottom: 8px;
    padding: 4px;
    width: 150px;
  }

  .rdl-list-box:first-child > .rdl-filter-container::after {
    content: ${(props) => props.firstItem};
    border: none;
    border-radius: 4px;
    background: var(--white);
    height: 23px;
    margin-bottom: 6px;
    margin-left: auto;
    padding: 4px;
    text-align: center;
    width: 50px;
  }

  .rdl-list-box:last-of-type > .rdl-filter-container::after {
    content: ${(props) => props.secondItem};
    border: none;
    border-radius: 4px;
    background: var(--white);
    height: 23px;
    margin-bottom: 6px;
    margin-left: auto;
    padding: 4px;
    text-align: center;
    width: 50px;
  }

  .react-dual-listbox {
    display: flex;
    width: 100%;
  }

  .rdl-control-label {
    display: none;
  }

  .rdl-list-box {
    width: 50%;
  }

  select {
    background: var(--white);
    outline: 3px solid var(--primary-lighter);
    outline-offset: -2px;
    box-sizing: border-box;
    border-radius: 4px;
    height: auto;
    min-height: 150px;
    padding: 2px 0;
    width: 100%;
  }

  select > option {
    border-bottom: 2px solid var(--primary-lighter);
    padding: 1px 6px;
    text-align-last: justify;
  }

  .rdl-actions {
    display: flex;
    flex-direction: column;
    margin: 50px 10px 30px 10px;
  }

  .rdl-actions-right > button,
  .rdl-actions-left > button {
    background: transparent;
  }

  .rdl-actions-right > button > .button-list,
  .rdl-actions-left > button > .button-list {
    background: var(--white);
    border: 2px solid var(--primary-lighter);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    padding: 6px;

    &:hover {
      background: var(--primary-lighter);
      border: 2px solid var(--primary-lighter);
      color: var(--white);
    }

    & > svg {
      font-size: 30px;
    }
  }
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 60px;
  padding: 10px 20px;
  width: 100%;

  & > div:nth-child(2) {
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    .filters {
      width: 100%;
    }
  }

  @media (max-width: 787px) {
    .rowButtonsOrder {
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      overflow-x: auto;
      justify-content: flex-start;
      padding-left: 0;
      width: 100%;
    }
  }
`;

export const DivLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div:nth-child(1) {
    width: 52%;
  }

  div:nth-child(2) {
    width: 48%;
  }

  div {
    margin-bottom: 8px;
  }

  div > label {
    color: var(--white);
  }
`;

export const Button = styled(ButtonLayout)<ILength>`
  margin-top: 0px;
  margin-bottom: 0px;
  width: ${(props) => (props.wide ? props.wide : "100%")};
  background-color: var(--primary);

  & > span > svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 599px) {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;

  label {
    color: var(--white);
    margin-right: 10px;
  }

  input {
    border: none;
    border-radius: 4px;
    height: 30px;
    margin-right: 4px;
    padding: 4px;
    width: 80px;
  }

  input:nth-child(1) {
    margin-right: 10px;
    width: 100px;
  }

  button {
    background: transparent;
    cursor: pointer;
    margin-top: auto;

    & > .loading > svg {
      color: var(--primary);
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }

  svg {
    color: green;
    font-size: 30px;
  }

  @media (max-width: 668px) {
    input,
    select {
      margin-top: 6px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 36%;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
`;

export const Select = styled.select`
  background: var(--white);
  border: none;
  border-radius: 4px;
  height: 30px;
  margin-right: 20px;
  padding: 2px 10px;
  width: 60px;
`;
