import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white);
  height: auto;
  padding: 10px 10px 20px 10px;
  width: 100%;
`

export const Title = styled.h2`
  font-size: 22px;
`

export const DivRow = styled.div`
  background-color: var(--primary-lighter);
  height: 100px;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  padding: 8px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DivItems = styled.div`
  margin-top: 6px;
  padding: 4px;
  width: 100%;
  overflow-x: auto;

  label{
    color: var(--primary);
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  svg{
    color: var(--primary);
  }

  h3{
    margin-bottom: 6px;
    text-decoration: underline;
  }

  table{
    width: 100%;
  }

  table, th, td {
    border: 1px solid gray !important;
  }

  th{
    background-color: gray;
    color: #fff;
  }

  th, td{
    padding: 4px 8px;
    white-space: nowrap;
  }

  td{
    font-size: 14px;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .red{
    color: var(--warning);
  }

`