import styled from "styled-components";
import ButtonLayout from "../../components/Button";
import ArrowDown from "../../assets/images/down-arrow.png";
import Check from "../../assets/images/check.png";
import Close from "../../assets/images/close.png";

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;

  & > div:nth-of-type(2)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background: var(--form-background);
  color: var(--white);
  overflow: auto;
  padding: 20px 16px 30px 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .primary {
    color: var(--primary-lighter);
  }

  & > div > span > svg {
    color: var(--primary);
    animation: spin 1s linear infinite;
    font-size: 32px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  & form {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
  }

  & form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & input[type="search"] {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 95.2%;
    }

    & .small {
      width: 70px;
    }

    & select {
      background: var(--form-background);
    }

    & input, & select {
      border: 1px solid var(--primary);
      border-radius: 6px;
      margin-top: 4px;
      margin-right: 6px;
      padding: 6px 8px 4px 4px;
      height: 30px;
      width: 120px;
    }

    & input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 4px;
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
    }

    & input[type="search"]::-webkit-search-decoration,
    & input[type="search"]::-webkit-search-results-button,
    & input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      appearance: none;
    }

    & input[type="search"]::-webkit-calendar-picker-indicator {
      opacity: 0;
      display: none;
    }

    & input::placeholder {
      color: var(--primary);
    }

    option {
      text-transform: capitalize;
    }
  }

  & input, & select {
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--primary);
    font-size: 10px;
    margin-right: 10px;
    padding: 2px 4px;
  }

  & form:nth-of-type(1) > div > label {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    margin-right: 0;
  }

  & input::placeholder {
    color: var(--primary);
  }
`;

export const Button = styled(ButtonLayout)`
  margin-bottom: 20px;
  margin-left: 10px;
  min-width: 150px;
  width: 16%;
`;


// modal styles

export const MContainer = styled.div`
  padding: 12px;  
`;
