import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import {
  Button,
  Container,
  Content,
  DivCard,
  DivZap,
  Image,
  Row,
  Title,
} from "./style";
import ImgZap from "../../assets/images/whatsapp.png";
import Toast from "../../components/Toast";
import { uploadFile } from "../../services/upload";
import toast from "react-hot-toast";
import { postSupport } from "../../services/support";
import useFetch from "../../hooks/useFetch";
import { useAuth } from "../../contexts/auth";

interface ISupport {
  title: string;
  endpoint: string;
}

interface ISuport {
  assunto: string;
  descricao: string;
  anexo: string;
}

const Support: React.FC<ISupport> = ({ title, endpoint }) => {
  const provider = useFetch({ endpoint });
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<ISuport>({
    assunto: "",
    descricao: "",
    anexo: "",
  });
  const [upload, setUpload] = useState<any>({});

  const handleSubmit = async () => {
    if (handleValidate()) {
      setLoading(true);
      try {
        const userId = user?.id;
        if (upload.name) {
          const value = upload;

          const formData = new FormData();
          formData.append("file", value);

          try {
            const url = await uploadFile(formData);
            // adjustedPayload[key] = url;

            if (url) {
              const _payload = payload;
              payload.anexo = url;

              const result = await postSupport(userId, _payload);
              if (result.status === 201) {
                Toast.show("Enviado com sucesso.", "success");
                history.push("/");
                setLoading(false);
              }
            }
          } catch (err: any) {
            Toast.show(err.message, "error");
          }
        } else {
          const result = await postSupport(userId, payload);
          if (result) {
            Toast.show("Enviado com sucesso.", "success");
            history.push("/");
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Erro: ", error);
        toast.error("Erro ao enviar dados para o suporte.");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handlePayload = (key: any, value: string) => {
    setPayload({ ...payload, [key]: value });
  };

  const handlePhoto = (field: string, options: any) => {
    let reader = new FileReader();
    let _file = options[0];

    reader.readAsDataURL(_file);
    reader.onloadend = () => {
      setUpload(_file);
    };
    // return setUpload((prevState: any) => {
    //   return { ...prevState, [field]: options };
    // });
  };

  const handleValidate = () => {
    if (payload.assunto === "") {
      toast.error("Selecione um assunto");
    } else if (payload.descricao === "") {
      toast.error("Adicione uma descrição.");
    } else {
      return true;
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard>
        <h2>Enviar mensagem para o suporte</h2>
      </DivCard>
      <DivCard>
        <Row>
          <label htmlFor="Subject">
            Assunto<span>*</span>
          </label>
          <select
            name="Subject"
            id="Subject"
            onChange={({ target }) => handlePayload("assunto", target.value)}
          >
            <option value="" hidden>
              Selecione...
            </option>
            <option value="conta">Contas</option>
            <option value="operacao">Operação</option>
            <option value="erros">Erros</option>
            <option value="duvidas">Dúvidas</option>
            <option value="reclamacao">Reclamação</option>
          </select>
        </Row>
      </DivCard>
      <Content>
        <label htmlFor="descriptin">Descrição</label>
        <span>*</span>
        <textarea
          name="decription"
          id="description"
          onChange={({ target }) => handlePayload("descricao", target.value)}
        ></textarea>
      </Content>
      <Row jContent={"space-between"}>
        <div className="upload-label">
          <p>
            {upload && upload.name ? upload.name : "Nenhum arquivo selecionado"}
          </p>
        </div>
        <div>
          {loading && (
            <span>
              <AiOutlineLoading />
            </span>
          )}
          <Button type="button" disabled={loading}>
            <label htmlFor="file">Anexo</label>
            <input
              type="file"
              id="file"
              accept=".png, .jpg, .jpeg"
              onChange={({ target }) => handlePhoto("anexo", target.files)}
            />
          </Button>
          <Button disabled={loading} onClick={() => handleSubmit()}>
            Enviar
          </Button>
        </div>
      </Row>

      <DivZap
        target="_blank"
        href="https://api.whatsapp.com/send?phone=5585996046195"
      >
        {/* <span>1</span> */}
        <Image src={ImgZap} alt="Icone do WhatsApp" />
      </DivZap>
    </Container>
  );
};

export default Support;
