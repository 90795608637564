import Toast from "../components/Toast";
import Provider from "./config";

export const getBrokerAll = async () => {
  try {
    const result = await Provider.get("entidades/?classe=fornecedores");

    return result.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar as corretoras.");
    }
  }
};

export const getUser = async (id: any) => {
  try {
    const response = await Provider.get(`entidades/${id}`);

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar usuário");
    }
  }
};

export const getUserValidate = async (id: any) => {
  try {
    const response = await Provider.get(id);

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar usuário");
    }
  }
};

export const postTokens = async (id: any, payload: any) => {
  const idUser = id;
  const satp = [
    {
      sptc: `${payload.broker}:${payload.api_key}:${payload.secret_key}`,
    },
  ];

  try {
    const response = await Provider.post("keys-manager/", { idUser, satp });

    return response;
  } catch (err: any) {
    throw Error("Erro ao enviar tokens.");
  }
};

export const statusUser = async (ids: any) => {
  try {
    const result = await Provider.post(
      "entidades/update-users-account-status/",
      { ids: ids }
    );

    return result;
  } catch (err: any) {
    throw Error("Erro ao enviar os dados.");
  }
};

export const putUser = async (id: any, payload: any) => {
  try {
    const response = await Provider.put(`entidades/${id}`, { ...payload });

    return response;
  } catch (err: any) {
    throw Error("Erro ao enviar os dados do usuário");
  }
};

export const putTelegram = async (
  id: any,
  payload: any,
  statusEmail: boolean
) => {
  try {
    const response = await Provider.put(`entidades/${id}`, {
      zCodigoTelegram: payload.code,
      zRecebeNotificacaoTelegram: payload.status,
      zRecebeNotificacaoEmail: statusEmail,
    });

    return response;
  } catch (err: any) {
    throw Error("Erro ao enviar username telegram");
  }
};

export const getSaldoIndicacoes = async (idUser: string) => {
  try {
    const response = await Provider.get("entidades/saldo-indicacao", {
      params: {
        idUser: idUser
      }
    });
    return response.data;
  } catch (error:any) {
    Toast.show("Erro ao buscar saldo de indicações", "error");
    throw Error(error.response);
  }
} 