/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Container,
  Title,
  Content,
  ListGroup,
  ListItem,
  Header,
  Row,
  HeaderCell,
  ListItemContainer,
} from './styles';
import Toast from '../../components/Toast';
import { BsCaretDownFill } from 'react-icons/bs';
import TreeMenu, { TreeMenuItem } from 'react-simple-tree-menu';
import { Menu, Item, useContextMenu } from 'react-contexify';

import ClassForm from './form/ClassForm';

import { useModal } from '../../contexts/modal';

import * as classesService from '../../services/classes';
import { useAuth } from '../../contexts/auth';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';

interface IClasseTreeNode {
  id: string;
  key: string;
  label: string;
  inativo: boolean;
  excluivel: boolean;
  editavel: boolean;
  criadoEm: string;
  atualizadoEm: string;
  nodes: Array<IClasseTreeNode>;
}

const ClassExplorer: React.FC = () => {
  const contextMenuId = 'classes-explorer-context';
  const {user} = useAuth();
  const classesHandler = useFetch({
    endpoint: '/classes/',
  });

  const { openModal, closeModal } = useModal();
  const { show } = useContextMenu({
    id: contextMenuId,
  });
  const [classPayload, setClassPayload] = useState<any>(null);
  const [insertClass, setInsertClass] = useState(false);
  const [updateClass, setUpdateClass] = useState(false);
  const [deleteClass, setDeleteClass] = useState(false);

  const [currentActions, setCurrentActions] = useState<any[]>([]);
  const [selectedToAction, setSelectedToAction] = useState<classesService.IClasse | null>(null);
  const [actionType, setActionType] = useState<string | null>(null);
  const [classesTree, setClassesTree] = useState<Array<IClasseTreeNode>>([]);

  const actions = [
    {
      id: 1,
      name: 'Inserir',
      onClick: () => setActionType('Inserir'),
    },
    {
      id: 2,
      name: 'Editar',
      onClick: () => setActionType('Editar'),
    },
    {
      id: 3,
      name: 'Excluir',
      onClick: () => setDeleteClass(true),
    }
  ];

  useEffect(() => {
    if (selectedToAction) {
      if (actionType) {
        if (actionType === 'Inserir') {
          openModal({
            type: 'submit',
            title: 'Inserir classe',
            submitLabel: 'Salvar',
            children: () => (
              <ClassForm
                token={user?.token}
                onChange={(payload: any) => setClassPayload(payload)}
                parent={selectedToAction}
              />
            ),
            onSubmit: () => setInsertClass(true),
            onCancel: () => setActionType(''),
            closeOnAction: false,
          });
        } else {
          openModal({
            type: 'submit',
            title: `Editar classe`,
            submitLabel: 'Salvar',
            children: () => (
              <ClassForm
                token={user?.token}
                onChange={(payload: any) => setClassPayload(payload)}
                initialState={selectedToAction}
              />
            ),
            onSubmit: () => setUpdateClass(true),
            onCancel: () => setActionType(''),
            closeOnAction: false,
          });
        }
      }
    }
  }, [actionType, selectedToAction]);

  useEffect(() => {
    getClasses();
    // postGraphql();
  }, []);

  useEffect(() => {
    if (insertClass) {
      handleInsertSubmit();
    }
  }, [insertClass]);

  useEffect(() => {
    if (updateClass) {
      handleUpdateSubmit();
    }
  }, [updateClass]);

  useEffect(() => {
    if (deleteClass) {
      openModal({
        type: 'confirm',
        title: 'Tem certeza que deseja excluir?',
        children: renderDeleteContent,
        onConfirm: handleDeleteSelected,
        onCancel: () => setDeleteClass(false),
      });
    }
  }, [deleteClass]);

  const getClasses = async () => {
    try {
      const response = await classesHandler.get();
      const treatedClasses = treatClasses(response);
      setClassesTree(treatedClasses);
    } catch (err: any) {
      return Toast.show(err.message);
    }
  }

  // async function postGraphql(){
  //   try {
  //     const response = await axios.post('http://192.168.1.16:3000/graphql',{
  //       query: `{ Classes { id  nome pai {  id  nome } }  }`,
  //     } 
  //     )
  //     console.log(response.data.data)
  //   } catch (error) {
  //     console.error('Erro: ', error)
  //   }
  // }

  const treatClasses = (classes: Array<classesService.IClasse>): Array<IClasseTreeNode> => {
    const treated: Array<IClasseTreeNode> = [];
    for (const classe of classes) {
      let nodes: Array<IClasseTreeNode> = [];
      if (classe.children && classe.children.length > 0) {
        nodes = treatClasses(classe.children);
      }

      treated.push({
        id: classe.id,
        key: classe.id,
        label: classe.nome,
        inativo: classe.inativo,
        excluivel: classe.excluivel,
        editavel: classe.editavel,
        criadoEm: classe.criadoEm,
        atualizadoEm: classe.atualizadoEm,
        nodes,
      });
    }

    return treated;
  }

  const handleDeleteSelected = async () => {
    if (selectedToAction) {
      await classesHandler.remove(selectedToAction.id);
      setDeleteClass(false);
      setTimeout(() => {
        getClasses();
      }, 500);
    }
  }

  const renderDeleteContent = () => {
    return (
      <p>Tem certeza que deseja excluir a classe <b>"{selectedToAction?.nome}"</b> ?</p>
    );
  }

  const handleUpdateSubmit = async () => {
    if (selectedToAction) {
      await classesHandler.put(selectedToAction.id, classPayload);
      setUpdateClass(false);
      setTimeout(() => {
        closeModal();
        getClasses();
        setActionType(null);
      }, 500);
    }
  };

  const handleInsertSubmit = async () => {
    await classesHandler.post(classPayload);
    setInsertClass(false);
    getClasses();
    closeModal();
    setActionType(null);
  };

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>, props: TreeMenuItem) => {
    event.preventDefault();

    const { id, atualizadoEm, criadoEm, inativo, editavel, excluivel, label: nome, parent: idPai } = props;
    const classe: classesService.IClasse = {
      id,
      atualizadoEm,
      criadoEm,
      inativo,
      editavel,
      excluivel,
      nome,
      idPai,
      children: [],
    };

    let actionsToAdd: any[] = [actions[0]];

    if (editavel) {
      actionsToAdd = [...actionsToAdd, actions[1]];
    }

    if (excluivel) {
      actionsToAdd = [...actionsToAdd, actions[2]];
    }

    setCurrentActions(actionsToAdd);
    setSelectedToAction(classe);
    show(event);
  }

  return (
    <Container>
      <Title>Classes Explorer</Title>
      <Content>
        <Header>
          <Row>
            <HeaderCell>Classe</HeaderCell>
          </Row>
        </Header>
        <TreeMenu
          data={classesTree}
        >
          {({ search, items }) => (
            <ListGroup>
              {items.map(props => (
                <ListItemContainer
                  key={props.id}
                  onContextMenu={(event: MouseEvent<HTMLDivElement>) => handleContextMenu(event, props)}
                >
                  <ListItem key={props.key} hasNodes={props.hasNodes} onClick={props.toggleNode} level={props.level}>
                    {props.label}
                    {props.hasNodes && (<BsCaretDownFill size={14} color="var(--muted)" />)}
                  </ListItem>
                </ListItemContainer>
              ))}
            </ListGroup>
          )}
        </TreeMenu>
      </Content>
      <Menu id={contextMenuId}>
        {currentActions.map(action => (
          <Item key={action.name} onClick={action.onClick}>{action.name}</Item>
        ))}
      </Menu>
    </Container>
  );
}

export default ClassExplorer;