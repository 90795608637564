import React, { useEffect, useState } from "react";
import { useFormik, FormikHelpers } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import {
  Container,
  Form,
  Input,
  Button,
  Link,
  FormContainer,
  Logo,
  Title,
  Subtitle,
  Row,
  ColumnInput,
} from "./styles";

import LogoImage from "../../assets/images/LOGO-ORANGE.png";
import { useAuth, ISignInPayload } from "../../contexts/auth";
import { MaskInput } from "../../components/Input/styles";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";
import { postRegister } from "../../services/register";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { getUserValidate } from "../../services/user";
import * as Validate from "validations-br";
import { useFormModal } from "../../contexts/formModal";
import { ContainerModal } from "../OrdersCommission/style";

export interface IRegisterValues {
  nome?: string;
  email?: string;
  telefone?: string;
  cpfCnpj?: string;
  dataNascimento?: string;
  senha?: string;
  confirm_password?: string;
  tipoAssinante?: number;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const query = useQuery();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>("");
  const { openModal, closeModal } = useFormModal();

  const initialValues: IRegisterValues = {
    nome: "",
    email: "",
    telefone: "",
    // cpfCnpj: "",
    // dataNascimento: "",
    senha: "",
    confirm_password: "",
    tipoAssinante: 1,
  };

  useEffect(() => {
    const idUser = query.get("id");
    if (idUser) getIdUser(idUser);
  }, []);

  const getIdUser = async (idUser: string) => {
    try {
      const response = await getUserValidate(idUser);

      if (response.result === "OK") {
        setId(idUser);
      } else {
        toast.error("Usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmit = async (
    values: IRegisterValues,
    helpers: FormikHelpers<IRegisterValues>
  ) => {
    setLoading(true);
    try {
      let { nome, email, telefone, senha, tipoAssinante } = values;
      // dataNascimento = dataNascimento?.replaceAll("/", "-");
      // dataNascimento = dataNascimento?.split("-").reverse().join("-");
      // dataNascimento += "T00:00:00.000Z";
      let idUser = id !== "" ? id : null;
      closeModal()
      
      const result = await postRegister({
        nome,
        email,
        telefone: telefone?.replaceAll("_", ""),
        senha,
        idUser,
        tipoAssinante,
      });
      if (result.status === 201) {
        toast.success("Dados enviados com sucesso.");
        toast.success("Verifique seu e-mail.");
        history.push("/login");
        setLoading(false);
      }
    } catch (err: any) {
      console.error("Erro: ", err);
      Toast.show(err.message, "error");
      setLoading(false);
    }
    // signIn(values);
    // helpers.setSubmitting(false);
  };

  const validate = (values: IRegisterValues) => {
    const errors: IRegisterValues = {};

    if (!values.nome) {
      errors.nome = "* Obrigatório";
    }

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(values.email)
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    if (!values.telefone) {
      errors.telefone = "* Obrigatório";
    }

    // if (!values.cpfCnpj) {
    //   errors.cpfCnpj = "* Obrigatório";
    // } else if (values.cpfCnpj) {
    //   let isValid;
    //   if (values.cpfCnpj.replace(/\D/g, "").length < 12) {
    //     isValid = Validate.validateCPF(values.cpfCnpj);
    //     if (!isValid) {
    //       errors.cpfCnpj = "CPF inválido";
    //     }
    //   } else {
    //     isValid = Validate.validateCNPJ(values.cpfCnpj);
    //     if (!isValid) {
    //       errors.cpfCnpj = "CNPJ inválido";
    //     }
    //   }
    // }

    if (!values.senha) {
      errors.senha = "* Obrigatório";
    } else if (values.senha.length < 8) {
      errors.senha = "A senha precisa ter no mínimo 8 dígitos";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "* Obrigatório";
    } else if (values.confirm_password !== values.senha) {
      errors.confirm_password =
        "O campo confirmar senha precisa ser igual a senha.";
    }

    return errors;
  };

  const registerForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const handleModal = () => {
    openModal({
      type: "confirm",
      title: "Declaração de Status",
      wide: "600px",
      children: () => renderContentModal(),
    });
  };

  const renderContentModal = () => {
    return (
      <ContainerModal
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 8 }}>
          Declaração de status não profissional
        </h3>
        <p>
          Vamos examinar alguns dos seus detalhes. Essa é uma prática padrão
          para que nós e nossos parceiros de dados e corretoras saibamos quem
          está usando nossos serviços. Não se preocupe – nós só precisamos fazer
          isso uma vez
        </p>
        <p>
          Esse formulário destina-se a esclarecer as condições sob as quais um
          indivíduo pode se qualificar para as taxas de assinatura para não
          profissionais. Ao se declarar como um 'Não Profissional", você
          confirma as seguintes coisas:
        </p>
        <span style={{ fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>
          Status Pessoal ou Comercial
        </span>
        <p>
          1. Você irá usar dados de mercado exclusivamente para uso pessoal, não
          para o seu negócio ou qualquer outra entidade.
        </p>
        <p>
          2. Você não está registrado ou qualificado na Securities Exchange
          Commission (SEC) ou na Commodities Futures Trading Commission (CFTC).
        </p>
        <p>
          3. Você não está registrado ou qualificado em nenhuma agência de
          valores mobiliários, bolsa de valores, associação ou órgão regulador
          de nenhum país.
        </p>
        <p>
          4. Você não desempenha funções semelhantes àquelas que exigem que um
          indivíduo se registre ou se qualifique junto à SEC, à CFTC, a qualquer
          outra agência de valores mobiliários, a qualquer bolsa de valores,
          associação ou órgão regulador, ou a qualquer mercado de commodities,
          ou de contratos futuros, associação ou órgão regulador.
        </p>
        <span style={{ fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>
          Status de Investidor
        </span>
        <p>
          5. Você não está atuando como consultor de investimentos (conforme o
          termo é definido na Seção 202 (a) (11) da Investment Advisor's Act de
          1940) ou como gerente de ativos e não está envolvido em fornecer
          consultoria de investimentos a qualquer indivíduo ou entidade.
        </p>
        <p>
          6. Você não está assinando o serviço na qualidade de diretor,
          executivo, sócio, funcionário ou agente de qualquer empresa, ou em
          nome de qualquer outro indivíduo.
        </p>
        <span style={{ fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>
          Compensação por Serviços
        </span>
        <p>
          9. Você não está recebendo espaço de escritório, equipamentos ou
          outros benefícios em troca de suas negociações ou trabalho como
          consultor financeiro para qualquer pessoa, empresa ou entidade
          comercial.
        </p>
        <p>
          10. Você não está recebendo um espaço de escritório, equipamento ou
          outros benefícios em troca do seu trabalho ou negociações como um
          consultor financeiro para quaisquer pessoas, empresas ou entidades
          comerciais.
        </p>
        <Form
          onSubmit={registerForm.handleSubmit}
          style={{
            backgroundColor: "rgba(110, 193, 228, 0.6)",
            borderRadius: 8,
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 30,
            padding: 8,
          }}
        >
          <div>
            <input
              type="radio"
              name="tipoAssinante"
              id="naoProfissional"
              onChange={handleRadioChange}
            />
            <label htmlFor="naoProfissional" style={{ marginLeft: 4 }}>
              Assinante não profissional (privado)
            </label>
          </div>
          <span>
            Eu confirmo que sou um trader não profissional, como indicado por
            essas condições.
          </span>
          <div style={{ marginTop: 8 }}>
            <input
              type="radio"
              name="tipoAssinante"
              id="profissional"
              onChange={handleRadioChange}
            />
            <label htmlFor="profissional" style={{ marginLeft: 4 }}>
              Assinante profissional (comercial)
            </label>
          </div>
          <span>
            Atualmente, Eu sou um trader profissional porque uma ou mais dessas
            condições não se aplicam para mim.
          </span>
          <Button
            disabled={registerForm.isSubmitting}
            type="submit"
            style={{ marginTop: 14 }}
          >
            Confirmar e continuar
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
        </Form>
      </ContainerModal>
    );
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tipoAssinante;
    if (event.target.id === "naoProfissional") {
     tipoAssinante = 1;
    } else if (event.target.id === "profissional") {
     tipoAssinante = 2;
    }
    registerForm.setFieldValue('tipoAssinante', tipoAssinante);
   };       

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Cadastre-se na Devari Crypto</Title>
        <Subtitle>Crie sua conta e automatize suas compras em cripto.</Subtitle>
        <Form autoComplete="off">
          <Input
            id="nome"
            name="nome"
            placeholder="Digite seu nome..."
            label="Nome"
            value={registerForm.values.nome}
            error={registerForm.errors.nome}
            onChange={registerForm.handleChange}
          />
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={registerForm.values.email}
            error={registerForm.errors.email}
            onChange={registerForm.handleChange}
          />
          <Input
            id="telefone"
            name="telefone"
            masked={true}
            mask={"+999 (99) 99999-9999"}
            placeholder="Digite seu telefone..."
            label="Telefone"
            value={registerForm.values.telefone}
            error={registerForm.errors.telefone}
            onChange={registerForm.handleChange}
          />
          {/* <Input
            id="cpfCnpj"
            name="cpfCnpj"
            masked={true}
            mask={"999.999.999-99"}
            placeholder="Digite seu CPF..."
            label="CPF"
            value={registerForm.values.cpfCnpj}
            error={registerForm.errors.cpfCnpj}
            onChange={registerForm.handleChange}
          />
          <Input
            id="dataNascimento"
            name="dataNascimento"
            masked={true}
            mask={"99/99/9999"}
            placeholder="Digite sua data de nascimento..."
            label="Data de Nascimento"
            value={registerForm.values.dataNascimento}
            error={registerForm.errors.dataNascimento}
            onChange={registerForm.handleChange}
          /> */}
          <Row>
            <ColumnInput>
              <button
                className="password"
                type="button"
                onClick={() => setPassword(!password)}
              >
                {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="senha"
                name="senha"
                placeholder="Digite sua senha..."
                label="Senha"
                type={password ? "text" : "password"}
                value={registerForm.values.senha}
                error={registerForm.errors.senha}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
            <ColumnInput>
              <button
                className="confirmPassword"
                type="button"
                onClick={() => setConfirmPassword(!confirmPassword)}
              >
                {confirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="confirm_password"
                name="confirm_password"
                placeholder="Digite sua senha novamente..."
                label="Confirmação de senha"
                type={confirmPassword ? "text" : "password"}
                value={registerForm.values.confirm_password}
                error={registerForm.errors.confirm_password}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
          </Row>
          <Button
            disabled={registerForm.isSubmitting}
            variant="filled"
            type="button"
            onClick={() => handleModal()}
          >
            Cadastrar &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
          <Link variant="outlined" to={{ pathname: "/login" }}>
            Voltar ao Login
          </Link>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Register;
