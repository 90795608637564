import styled from "styled-components";

interface ICardIndicado {
    selecionado?: boolean;
    marcado?: any;
    nivel?: number;
}

export const Table = styled.table`
    color: var(--white);
    width: 100%;
    .leftColumn {
        text-align: center;
        margin: 12px;
        max-width: 30px;
    }

    .rowColumn {
        display: flex;
    }
`;

export const Card = styled.div<ICardIndicado>`
    width: 180px;
    height: 80px;
    border-radius: 8px;
    background-color: ${({marcado, nivel}) => marcado ? nivel === 1 ? "#2c6e2e" : "var(--primary)" : '#303753'};
    border: ${({selecionado}) => selecionado ? "1px solid var(--primary)" : "none"};
    margin: 0 12px;
    padding: 12px;
    cursor: pointer;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;