export const orderCols = (a: any, b: any) => {
  
  //ordem de prioridade pelo tipo do campo
  const important_results: any = {
    upload: 1,
    texto: 2,
    select: 2,
    lookup: 2,
    checkbox: 3,
    tabela: 4,
  };
  let important_a = important_results[a.tipo as string], 
      important_b = important_results[b.tipo as string],
      ret;

  if(a.hasOwnProperty("order") && b.hasOwnProperty("order")) { 
    //verifica a ordem com base no campo order table fields.
    if(a.order < b.order) {
      ret = -1;
    } else if(b.order < a.order) {
      ret = 1;
    } else {
      ret = 0;
    }

  } else if(a.hasOwnProperty("order") && !b.hasOwnProperty("order")) {
    //considera o campo que possui field como prioridade
    ret = -1;
  } else if(!a.hasOwnProperty("order") && b.hasOwnProperty("order")) {
    ret = 1;
  } else if(a.hasOwnProperty("quantidade") && b.hasOwnProperty("quantidade")){
    //verifica a ordem com base no campo quantidade table fields.
    if(a.quantidade < b.quantidade) {
      ret = -1;
    } else if(b.quantidade < a.quantidade) {
      ret = 1;
    } else {
      ret = 0;
    }
  } else {
    // como nenhum dos fields possui o campo order, verifica prioridade com base no tipo de campo.
    if (important_a && !important_b) {
      ret = -1
    } else if (important_b && !important_a) {
      ret = 1
    } else if (important_a && important_b) {
      ret = important_a - important_b
    } else {
      ret = 0
    };
  }

  return ret;
}

export const adjustByRow = (colsField: any) => {
  let byRow: any = {};
  let currentRow = -1;
  const keys = Object.keys(colsField);
  let value = 7;

  for(let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (colsField[key].type === 'table') {
      currentRow += 1;
      byRow[`tablerow${currentRow}`] = [colsField[key]];
      continue;
    }

    if (i%value === 0 || i === 0) {
      currentRow += 1;
      byRow[`row${currentRow}`] = [colsField[key]];
      if (colsField[key].type !== 'upload') {
        value = 3;
      }
    } else {
      byRow[`row${currentRow}`].push(colsField[key]);
    }
  }

  return byRow;
}

export const treatColField = (col: any, lookupCallback: any, uploadCallback: any, tableCallback?: any) => {
  let colField = null;

  switch(col.tipo){
    case "texto":
      let field: any = {
        id: col.campo,
        placeholder: ``,
        label: col.nome,
        disabled: col.editavel ? false : true,
        fixed: col.fixo ? col.fixo : false,
        required: col.hasOwnProperty('required') ? col.required : false
      }

      if (col.mascara) {
        switch(col.mascara) {
          case 'telefone':
            field = {
              ...field,
              mask: '(99) 99999-9999',
            };
            break;
          case 'cep':
            field = {
              ...field,
              mask: '99999-999',
            };
            break;
          case 'cpf':
            field = {
              ...field,
              mask: '999.999.999-99',
            };
            break;
          case 'cnpj':
            field = {
              ...field,
              mask: '99.999.999/9999-99',
            };
            break;
          case 'data':
            field = {
              ...field,
              type: 'date',
            };
            break;
          case 'data/hora':
            field = {
              ...field,
              type: 'date',
              withHour: true,
            };
            break;
          case 'dinheiro': 
            field = {
              ...field,
              money: true,
              decimalPrecision: col.decimalPrecision,
              coin: col.coin
            }
            break;
          case 'decimal': 
            field = {
              ...field,
              money: true,
            }
            break;
          case 'inteiro': 
            field = {
              ...field,
              mask: 'integer',
            }
            break;
          default:
            break;
        }
      }

      colField = field;
      break;
    case "select":
      const options = col.opcoes.map((option: string, index: number) => {
        return {
          id: index,
          name: option,
        };
      });
      colField = {
        id: col.campo,
        label: col.nome,
        type: 'select',
        disabled: col.editavel ? false : true,
        options,
        required: col.hasOwnProperty('required') ? col.required : false
      };
      break;
    case "checkbox":
      colField = {
        id: col.campo,
        label: col.nome,
        type: 'checkbox',
        disabled: col.editavel ? false : true,
        required: col.hasOwnProperty('required') ? col.required : false
      };
      break;
    case "upload":
      colField = {
        id: col.campo,
        label: col.nome,
        type: 'upload',
        disabled: col.editavel ? false : true,
        onChange: (options: any) => uploadCallback(col.campo, options),
        required: col.hasOwnProperty('required') ? col.required : false
      };
      break;
    case "lookup":
      colField = {
        id: col.campo,
        label: col.nome,
        type: 'lookup',
        endpoint: `/${col.tabela}`,
        filters: {
          classe: col.classe,
        },
        multi: col.multi,
        data: col.data,
        field: 'nome',
        disabled: col.editavel ? false : true,
        onChange: (options: any) => lookupCallback(col.campo, options),
        required: col.hasOwnProperty('required') ? col.required : false
      };
      break;
    case "tabela":
      colField = {
        id: col.campo,
        label: col.nome,
        type: 'table',
        cols: col.cols,
        disabled: col.editavel ? false : true,
        actions: col.actions,
        onChange: (value: any) => tableCallback(col.campo, value),
      };
      break;
    default:
      break;
  }
  return colField;
}

export const treatInitialValue = (col: any) => {
  let value = null;
  switch(col.tipo){
    case "texto":
      value = '';

      if (col.mascara) {
        switch(col.mascara) {
          case 'data':
            value = null;
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }

  return value;
}

export function formatCpf(cpf: string){
  cpf = cpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCnpj(cnpj: string){
  cnpj = cnpj.replace(/[^\d]/g, "");
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function isObject(value: any) {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null ) {
    return true;
  }
  return false;
}