import React from "react";

import Logo from "../../assets/images/LOGO-ORANGE.png";
import { Container, LinhaVertical } from "./styles";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
      <Container>
      <p><b>DISCLAIMER:</b> Este serviço utiliza um robô de negociação automática baseado em algoritmo de inteligência artificial que opera no mercado SPOT de criptomoedas. As operações de compra e venda são realizadas automaticamente pelo sistema, sem intervenção humana direta na seleção das criptomoedas.</p> <br/>
      <p>Ao utilizar este serviço, você reconhece e concorda que as operações são executadas automaticamente com base nas análises e decisões do robô. Você assume total responsabilidade pelos riscos envolvidos e por eventuais prejuízos financeiros resultantes das operações realizadas. O mercado de criptomoedas é caracterizado por alta volatilidade e risco, e o desempenho passado do robô não é um indicador confiável de resultados futuros. Existe a possibilidade de que você não recupere o valor total investido.</p> <br/>
      <p>É recomendado que você exerça cautela e considere buscar orientação independente antes de decidir usar este serviço. Este serviço não constitui aconselhamento financeiro, legal ou fiscal e não substitui a consulta a um especialista qualificado. A utilização deste serviço está sujeita à legislação brasileira vigente e aos termos e condições estabelecidos pelo provedor do robô de negociação.</p>
        <div>
          <img src={Logo} alt="Logo" />
          <LinhaVertical />
          &nbsp;<p>&copy;{date} Devari Crypto</p>
          {/* <Link href="#">Suporte</Link> */}
        </div>
      </Container>
  );
};

export default Footer;
