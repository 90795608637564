import React, { useEffect, useState } from "react";
import {
  Button,
  Column,
  Container,
  Content,
  DivCard,
  DivColumn,
  DivLabel,
  DivRow,
  Main,
  Row,
  Select,
  Title,
} from "./styles";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineLoading,
} from "react-icons/ai";
import DualListBox from "react-dual-listbox";
import {
  getWhiteList,
  getWhiteListFilter,
  postWhiteList,
  getWhiteListSelected,
  getWhiteListOnlyNames,
} from "../../services/whiteList";
import { BsCheckLg } from "react-icons/bs";
import toast from "react-hot-toast";
import { IResponse } from "../Orders";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { orderCols } from "../../utils/form";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import ReactPaginate from "react-paginate";
import useFetch from "../../hooks/useFetch";

interface IFilters {
  marketCap: number;
  volume: number;
  supply: number;
}

interface IWhiteList {
  title: string;
  multipleSelect: boolean;
  endpoint: string;
  filters?: object;
}

interface ICoin {
  id: string;
  value: string;
  label: string;
}

const Whitelist: React.FC<IWhiteList> = ({
  title,
  multipleSelect,
  endpoint,
  filters,
}) => {
  const provider = useFetch({ endpoint });
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const [firstSelected, setFirstSelected] = useState([]); // recebe o valor inicial da white list que vem do back
  const [availableCurrencies, setAvailableCurrencies] =
    useState<IResponse | null>(null); // moedas disponíveis
  const [whiteList, setWhiteList] = useState<IResponse | null>(null); // moedas selecionadas
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [available, setAvailable] = useState(true); // responsável por verificar qual aba está em uso
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [loading, setLoading] = useState(false);
  const [loadingWhiteList, setLoadingWhiteList] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);
  const [coin, setCoin] = useState([]);
  const [coinWhiteList, setCoinWhiteList] = useState([]);
  const [growing, setGrowing] = useState({
    item: "",
    asc: "",
  });
  const [initialValues, setInitialValues] = useState<IFilters>({
    marketCap: 0,
    volume: 0,
    supply: 0,
  });
  const [filterWhiteList, setFilterWhiteList] = useState({
    coin: "",
    price: "",
    variation: "",
    marketCap: "",
    volume: "",
  });
  const [filterSelected, setFilterSelected] = useState({
    coin: "",
    price: "",
    variation: "",
    marketCap: "",
    volume: "",
  });

  useEffect(() => {
    getCoin();
    getCoinSelected();
  }, []);

  useEffect(() => {
    if (available == true) {
      if (
        !filterWhiteList.coin ||
        !filterWhiteList.price ||
        !filterWhiteList.variation ||
        !filterWhiteList.marketCap ||
        !filterWhiteList.volume
      ) {
        getTableFields(filterWhiteList);
      } else {
        getTableFields();
      }
    } else {
      if (
        !filterSelected.coin ||
        !filterSelected.price ||
        !filterSelected.variation ||
        !filterSelected.marketCap ||
        !filterSelected.volume
      ) {
        getCoinSelected(filterSelected);
      } else {
        getCoinSelected();
      }
    }
  }, [filterWhiteList, filterSelected, currentPage, growing, quantityPerPage]);

  function handleValues(key: string, value: any) {
    if (available == true) {
      setFilterWhiteList({ ...filterWhiteList, [key]: value });
    } else {
      setFilterSelected({ ...filterSelected, [key]: value });
    }
  }

  const validateFilter = () => {
    if (
      initialValues.volume <= 0 &&
      initialValues.marketCap <= 0 &&
      initialValues.supply <= 0
    ) {
      toast.error("Informe um valor para filtrar.");
    } else {
      return true;
    }
  };

  // Função responsável por verificar se os itens selecionados está diferente dos itens de início para enviar somente o id dos que estiverem direfentes
  // const handleId = (ids?: any) => {
  //   let itens: any = [];

  //   if (whiteList) {
  //     const first = firstSelected.map((item: any) => item.id);
  //     const list = whiteList.docs.map((item: any) => item.id);
  //     if (firstSelected.length < whiteList?.docs.length) {
  //       for (let key of list) {
  //         if (firstSelected.length > 0) {
  //           if (!first.includes(key)) {
  //             itens.push(key);
  //           }
  //         } else if (firstSelected.length === 0) {
  //           itens.push(key.id);
  //         }
  //       }
  //     } else if (firstSelected.length > whiteList.docs.length) {
  //       for (let key of first) {
  //         if (!list.includes(key)) {
  //           itens.push(key);
  //         }
  //       }
  //     } else {
  //       for (let key of list) {
  //         if (!first.includes(key)) {
  //           itens.push(key);
  //         }
  //       }
  //       for (let _key of first) {
  //         if (!list.includes(_key)) {
  //           itens.push(_key);
  //         }
  //       }
  //     }
  //   }

  //   return itens;
  // };

  const getCoin = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  const getTableFields = async (filter?: any) => {
    setLoadingWhiteList(true);
    try {
      const result = await fields.get(filters);
      if (result) {
        const ordered = result.sort(orderCols);
        setCols(ordered);
        if (filter) {
          const resultWhiteList = await getWhiteList(
            currentPage,
            growing,
            quantityPerPage,
            filter
          );

          if (resultWhiteList) {
            setAvailableCurrencies(resultWhiteList);
            setLoadingWhiteList(false);
          }
        } else {
          const resultWhiteList = await getWhiteList(
            currentPage,
            growing,
            quantityPerPage
          );

          if (resultWhiteList) {
            setAvailableCurrencies(resultWhiteList);
            setLoadingWhiteList(false);
          }
        }
      }
    } catch (error) {
      toast.error("Erro ao buscar moedas disponíveis da white list.");
      console.error("Erro: ", error);
      setLoadingWhiteList(false);
    }
  };

  const getCoinSelected = async (filter?: any) => {
    setLoadingWhiteList(true);
    try {
      if (filter) {
        const result = await getWhiteListSelected(
          currentPageClosed,
          growing,
          quantityPerPage,
          filter
        );
        if (result) {
          const _result: any = [...result.docs];
          const coins = result.docs.map((item: any) => item.observacao);
          setFirstSelected(_result);
          setWhiteList(result);
          setCoinWhiteList(coins);
          // setLoadingWhiteList(false);
        }
      } else {
        const result = await getWhiteListSelected(
          currentPageClosed,
          growing,
          quantityPerPage
        );
        if (result) {
          const _result: any = [...result.docs];
          const coins = result.docs.map((item: any) => item.observacao);
          setFirstSelected(_result);
          setWhiteList(result);
          setCoinWhiteList(coins);
        }
      }
      setLoadingWhiteList(false);
    } catch (error) {
      toast.error("Erro ao buscar moedas selecionadas da white list.");
      setLoadingWhiteList(false);
    }
  };
  // const getWhiteListAll = async () => {
  //   try {
  //     const result = await getWhiteList();
  //     const resultSelect = await getWhiteListSelected();
  //     if (result) {

  //       const formattedOptions = result.Moedas.map((moeda: any) => {

  //         return {
  //           id: moeda.id,
  //           value: moeda.observacao + " " + moeda.coinInformations.volume,

  //           label: moeda.observacao + " " + moeda.coinInformations.volume,
  //         };
  //       });

  //       setOptions(formattedOptions);
  //     }
  //     if (resultSelect) {
  //       const formattedSelect = resultSelect.map(
  //         (moeda: any) => moeda.observacao + " " + moeda.coinInformations.volume
  //       );

  //       setSelected(formattedSelect);
  //       setFirstSelected(formattedSelect);
  //     }
  //   } catch (error) {
  //     console.error("Erro: ", error);
  //   }
  // };

  // const getFilterWhiteList = async () => {
  //   if (validateFilter()) {
  //     setLoadingFilter(true);
  //     if (
  //       initialValues.volume > 0 ||
  //       initialValues.marketCap > 0 ||
  //       initialValues.supply > 0
  //     ) {
  //       let _initialValues = formattedNewValue();

  //       try {
  //         const result = await getWhiteListFilter(_initialValues);

  //         if (result) {
  //           const formattedOptions = result.Moedas.map((moeda: any) => {
  //             return {
  //               id: moeda.id,
  //               value: moeda.observacao + " " + moeda.coinInformations.volume,

  //               label: moeda.observacao + " " + moeda.coinInformations.volume,
  //             };
  //           });

  //           setOptions(formattedOptions);
  //           setLoadingFilter(false);
  //         }
  //       } catch (error) {
  //         console.error("Erro: ", error);
  //       }
  //     }
  //     setLoadingFilter(false);
  //   }
  // };

  const handleSubmit = async () => {
    const ids = available ? handleChangeWhiteList() : handleWhiteListSelected();
    // const idsSelected = handleId(ids);
    setLoading(true);

    if (ids.length > 0) {
      try {
        const result = await postWhiteList(ids);
        if (result.status === 201) {
          await getCoinSelected();
          toast.success("Salvo com sucesso!");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
    }
    setLoading(false);
  };

  const handleChangePage = (page: number) => {
    if (available) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };

  // Função responsável por retirar o item selecionado da lista de moedas disponíveis
  const handleChangeWhiteList = () => {
    const itens: any = [];
    const ids: any = [];
    const indexItem: any = [];
    if (availableCurrencies) {
      let docs = [...availableCurrencies?.docs];
      let total = availableCurrencies?.total;
      let totalPages = availableCurrencies.totalPages;
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item == true) {
          itens.push(availableCurrencies?.docs[index]);
          ids.push(availableCurrencies?.docs[index].id);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item);
        if (itemIndex >= 0) {
          docs.splice(itemIndex, 1);
        }
      });

      total = docs.length;

      setAvailableCurrencies({ docs, total, totalPages });

      setSelectedIndexes(selection);

      addWhiteList(itens);
      return ids;
    }
  };

  // Função responsável por adicionar na white list os itens que recebe por parâmetro
  const addWhiteList = (itens: any) => {
    if (whiteList) {
      let docs = [...whiteList?.docs];
      let total = whiteList?.total;
      let totalPages = whiteList?.totalPages;

      itens.map((item: any) => {
        docs.push(item);
      });

      total += itens.length;

      setWhiteList({ docs, total, totalPages });
    }
  };
  // Função responsável por retirar itens da white list
  const handleWhiteListSelected = () => {
    if (whiteList) {
      const itens: any = [];
      const ids: any = [];
      let docs = [...whiteList?.docs];
      let total = whiteList?.total;
      let totalPages = whiteList.totalPages;
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item === true) {
          itens.push(docs[index]);
          ids.push(docs[index].id);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item);
        if (itemIndex >= 0) {
          docs.splice(itemIndex, 1);
        }
      });

      total = docs.length;

      setWhiteList({ docs, total, totalPages });

      setSelectedIndexes(selection);

      addAvailableCurrencies(itens);
      return ids;
    }
  };

  // Função responsável por adicionar em moedas disponíveis os itens que recebe por parâmetro
  const addAvailableCurrencies = (itens: any) => {
    if (availableCurrencies) {
      let docs = [...availableCurrencies?.docs];
      let total = availableCurrencies?.total;
      let totalPages = availableCurrencies?.totalPages;

      itens.map((item: any) => {
        docs.push(item);
      });

      total += itens.length;

      setAvailableCurrencies({ docs, total, totalPages });
    }
  };

  const handleOrderCols = (value: string) => {
    if (value !== "") {
      if (growing.item === value) {
        setGrowing({
          ...growing,
          item: growing.asc == "false" ? "" : value,
          asc: growing.asc == "false" ? "" : "false",
        });
      } else {
        setGrowing({
          ...growing,
          item: value,
          asc: "true",
        });
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  function isTrue(item: any) {
    return item == false;
  }

  return (
    <Container>
      <Title>{title}</Title>

      <DivCard>
        <DivRow className="rowButtonsOrder">
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setAvailable(true)}
          >
            <p className={available ? "primary" : ""}>
              Moedas disponíveis
              {availableCurrencies?.total
                ? `(${availableCurrencies.total})`
                : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setAvailable(false)}
          >
            <p className={!available ? "primary" : ""}>
              Moedas Selecionadas
              {whiteList?.total ? `(${whiteList.total})` : "(0)"}
            </p>
          </button>
        </DivRow>
      </DivCard>
      <Main pad={"20px 10px 30px 10px"}>
        <DivRow>
          <p className="filters">Filtros:</p>
          {available ? (
            <form autoComplete="off">
              <div>
                <input
                  type="search"
                  list="coinAvailable"
                  name="myCoinAvailable"
                  id="myCoinAvailable"
                  autoComplete="chrome-off"
                  placeholder="Todas Moedas"
                  value={filterWhiteList.coin}
                  onChange={({ target }) => handleValues("coin", target.value)}
                />
                <datalist id="coinAvailable">
                  {coin
                    ? coin.map((item: string) => <option value={item} />)
                    : null}
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="priceAvailable"
                  name="myPriceAvailable"
                  id="myPriceAvailable"
                  autoComplete="chrome-off"
                  value={filterWhiteList.price}
                  placeholder="Preço"
                  onChange={({ target }) => handleValues("price", target.value)}
                />
                <datalist id="priceAvailable">
                  <option value="$0 - $1" />
                  <option value="$1 - $100" />
                  <option value="$101 - $1000" />
                  <option value="$1000 +" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="variationAvailable"
                  name="myVariationAvailable"
                  id="myVariationAvailable"
                  autoComplete="chrome-off"
                  placeholder="% Variação"
                  value={filterWhiteList.variation}
                  onChange={({ target }) =>
                    handleValues("variation", target.value)
                  }
                />
                <datalist id="variationAvailable">
                  <option value="+50%" />
                  <option value="+10% para +50%" />
                  <option value="0% para +10%" />
                  <option value="-10% para 0%" />
                  <option value="-50% para -10%" />
                  <option value="-50%" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="marketAvailable"
                  name="myMarketAvailable"
                  id="myMarketAvailable"
                  autoComplete="chrome-off"
                  placeholder="Market Cap"
                  value={filterWhiteList.marketCap}
                  onChange={({ target }) =>
                    handleValues("marketCap", target.value)
                  }
                />
                <datalist id="marketAvailable">
                  <option value=">$10B" />
                  <option value="$1B - $10B" />
                  <option value="$100M - $1B" />
                  <option value="$10M - $100M" />
                  <option value="$1M - $10M" />
                  <option value="<$1M" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="volumeAvailable"
                  name="myVolumeAvailable"
                  id="myVolumeAvailable"
                  autoComplete="chrome-off"
                  placeholder="Volume(24h)"
                  value={filterWhiteList.volume}
                  onChange={({ target }) =>
                    handleValues("volume", target.value)
                  }
                />
                <datalist id="volumeAvailable">
                  <option value=">$10B" />
                  <option value="$1B - $10B" />
                  <option value="$100M - $1B" />
                  <option value="$10M - $100M" />
                  <option value="$1M - $10M" />
                </datalist>
              </div>
            </form>
          ) : (
            <form autoComplete="off">
              <div>
                <input
                  type="search"
                  list="coinSelected"
                  name="myCoinSelected"
                  id="myCoinSelected"
                  autoComplete="chrome-off"
                  placeholder="Todas Moedas"
                  value={filterSelected.coin}
                  onChange={({ target }) => handleValues("coin", target.value)}
                />
                <datalist id="coinSelected">
                  {coinWhiteList
                    ? coinWhiteList.map((item: string) => (
                        <option value={item} />
                      ))
                    : null}
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="priceSelected"
                  name="myPriceSelected"
                  id="myPriceSelected"
                  autoComplete="chrome-off"
                  placeholder="Preço"
                  value={filterSelected.price}
                  onChange={({ target }) => handleValues("price", target.value)}
                />
                <datalist id="priceSelected">
                  <option value="$0 - $1" />
                  <option value="$1 - $100" />
                  <option value="$101 - $1000" />
                  <option value="$1000 +" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="variationSelected"
                  name="myVariationSelected"
                  id="myVariationSelected"
                  autoComplete="chrome-off"
                  placeholder="% Variação"
                  value={filterSelected.variation}
                  onChange={({ target }) =>
                    handleValues("variation", target.value)
                  }
                />
                <datalist id="variationSelected">
                  <option value="+50%" />
                  <option value="+10% para +50%" />
                  <option value="0% para +10%" />
                  <option value="-10% para 0%" />
                  <option value="-50% para -10%" />
                  <option value="-50%" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="marketSelected"
                  name="myMarketSelected"
                  id="myMarketSelected"
                  autoComplete="chrome-off"
                  placeholder="Market Cap"
                  value={filterSelected.marketCap}
                  onChange={({ target }) =>
                    handleValues("marketCap", target.value)
                  }
                />
                <datalist id="marketSelected">
                  <option value=">$10B" />
                  <option value="$1B - $10B" />
                  <option value="$100M - $1B" />
                  <option value="$10M - $100M" />
                  <option value="$1M - $10M" />
                  <option value="<$1M" />
                </datalist>
              </div>
              <div>
                <input
                  type="search"
                  list="volumeSelected"
                  name="myVolumeSelected"
                  id="myVolumeSelected"
                  autoComplete="chrome-off"
                  placeholder="Volume(24h)"
                  value={filterSelected.volume}
                  onChange={({ target }) =>
                    handleValues("volume", target.value)
                  }
                />
                <datalist id="volumeSelected">
                  <option value=">$10B" />
                  <option value="$1B - $10B" />
                  <option value="$100M - $1B" />
                  <option value="$10M - $100M" />
                  <option value="$1M - $10M" />
                </datalist>
              </div>
            </form>
          )}
          <div>
            <span>
              {loadingWhiteList || loading ? (
                <>
                  <AiOutlineLoading /> &nbsp;
                </>
              ) : null}
            </span>
            <Button
              wide={"auto"}
              type="button"
              disabled={loading || selectedIndexes.every(isTrue)}
              onClick={() => handleSubmit()}
            >
              {available ? "Adicionar" : "Remover"}
            </Button>
          </div>
        </DivRow>

        <Content>
          <ListTable
            multipleSelect={multipleSelect}
            changeSelectedIndexes={setSelectedIndexes}
            fields={cols}
            data={
              available && availableCurrencies
                ? availableCurrencies?.docs
                : !available && whiteList
                ? whiteList?.docs
                : []
            }
            onChangePage={handleChangePage}
            handleOrderCols={handleOrderCols}
            growing={growing}
            currentPage={available ? currentPage : currentPageClosed}
            totalPages={
              available && availableCurrencies
                ? availableCurrencies.totalPages
                : !available && whiteList
                ? whiteList.totalPages
                : 1
            }
            rowMenuOptions={actionsMenus}
            withFooter={false}
          />
        </Content>
        <Footer>
          <SelectedContainer>
            <p>Linhas por página: &nbsp;</p>
            <select
              name="quantityPerPage"
              id="quantityPerPage"
              onChange={({ target }) => handleQuantityPerPage(target.value)}
            >
              <option value="5">5</option>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </SelectedContainer>
          <PaginateContainer>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={({ selected }) => handleChangePage(selected)}
              pageRangeDisplayed={2}
              pageCount={
                available && availableCurrencies
                  ? availableCurrencies.totalPages
                  : !available && whiteList
                  ? whiteList.totalPages
                  : 1
              }
              previousLabel="< "
              renderOnZeroPageCount={undefined}
              activeClassName="active-page-item"
            />
          </PaginateContainer>
        </Footer>

        {/* <Button disabled={loading} onClick={() => handleSubmit()}>
          Salvar &nbsp;
          {loading ? (
            <span>
              <AiOutlineLoading />
            </span>
          ) : null}
        </Button> */}
      </Main>
    </Container>
  );
};

export default Whitelist;

// White list modelo anterior
{
  /* <DivColumn
          firstItem={options ? `"${options.length}"` : "0"}
          secondItem={selected ? `"${selected.length}"` : "0"}
        >
          <DivLabel>
            <div>
              <label>Moedas Disponíveis:</label>
            </div>
            <div>
              <label>Moedas Selecionadas:</label>
            </div>
          </DivLabel>

          <DualListBox
            options={options}
            selected={selected}
            canFilter
            onChange={(ev) => handleSelected(ev)}
            icons={{
              moveLeft: (
                <button className="button-list">
                  <AiOutlineArrowLeft />
                </button>
              ),
              moveRight: (
                <button className="button-list">
                  <AiOutlineArrowRight />
                </button>
              ),
            }}
          />

        </DivColumn> */
}
