import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import { FiTrash2 } from 'react-icons/fi';
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import { postOrders } from "../../services/orders";
import { getWhiteListOnlyNames } from "../../services/whiteList";
import { Form, Column, RowModal, Row, Button } from "./style";

interface IReload {
  getTableFilds: () => void;
  getTableFildsPending: () => void;
  getCurrentBalance: () => void;
}

interface ISellZone {
  id: string;
  valor: string;
}

const LabelModal: React.FC<IReload> = ({
  getTableFilds,
  getCurrentBalance,
  getTableFildsPending,
}) => {
  const { user } = useAuth();
  const { closeModal } = useFormModal();
  const [coin, setCoin] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rsiStatus, setRsiStatus] = useState(false);
  const [newOrder, setNewOrder] = useState({
    mercado: "spot",
    estrategia: "personalizada",
    valor: 0,
    lucroEsperado: 1,
    trailingDelta: 0.2,
    trailingStop: true, // por padão o orange sempre vai abrir uma ordem trailling stop
    isRsi: false,
    userId: user ? user.id : "",
    moeda: "",
    interval: "15m",
    target: "70-30",
    buyzone_1: "",
    buyzone_2: "",
    stopLoss: "",
    alavancagem: 3,
    side: "BUY"
  });
  const [sellZoneArr, setSellzoneArr] = useState<Array<ISellZone>>([]);

  useEffect(() => {
    getCoin();
  }, []);

  const getCoin = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  const handleNewOrder = (key: string, value: any, index?: number) => {
    if (key === "valor") {
      if (value <= 0) {
        toast.error("O valor não pode ser inferior a 1");
      } else {
        setNewOrder((oldValue) => {
          return { ...oldValue, [key]: value };
        });
      }
    } else if (key === "sellzone" && typeof index !== "undefined") {
      const novoArray = [...sellZoneArr];
      novoArray[index].valor = value;
      setSellzoneArr([...novoArray]);
    } else if (key === "mercado") {
      setNewOrder((oldValue) => {
        return {
          ...oldValue,
          [key]: value,
          moeda: "",
          buyzone_1: "",
          buyzone_2: "",
          stopLoss: "",
          valor: 0,
          alavancagem: 3,
          side: "BUY"
        };
      });
      setSellzoneArr([]);
    } else {
      setNewOrder((oldValue) => {
        return { ...oldValue, [key]: value };
      });
    }
  };

  const postOrder = async (ev: any) => {
    ev.preventDefault();
    if (handleValidate()) {
      if (user?.satp) {
        setLoading(true);
        const _newOrder = newOrder;
        const valor = _newOrder.valor.toString();
        const {
          estrategia,
          lucroEsperado,
          userId,
          moeda,
          trailingDelta,
          trailingStop,
          isRsi,
          interval,
          target,
          buyzone_1,
          buyzone_2,
          stopLoss
        } = _newOrder;
        const menorValorSellZone = sellZoneArr.reduce((min, item) => item.valor < min ? item.valor : min, sellZoneArr[0].valor);
        const maiorValorBuyZone = buyzone_1 > buyzone_2 ? buyzone_1 : buyzone_2;
        try {
          const payload: any = {
            userId: userId,
            lucroEsperado: lucroEsperado,
            estrategia: estrategia,
            valor: valor,
            trailingStop: trailingStop,
            isRsi: isRsi,
            stopLoss: stopLoss
          };
          if (estrategia === "personalizada") {
            payload["moeda"] = moeda;
            if (isRsi === true) {
              payload["interval"] = interval;
              payload["target"] = target;
            }
            if (trailingStop === true) {
              payload["trailingDelta"] = trailingDelta;
              payload["sellZone"] = menorValorSellZone;
              payload["buyZone"] = maiorValorBuyZone;
              payload["mercado"] = _newOrder.mercado;
              payload["side"] = _newOrder.side;
              payload["alavancagem"] = _newOrder.alavancagem;
            }

            const result = await postOrders(payload);
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              getCurrentBalance();
              getTableFilds();
              getTableFildsPending();
              setLoading(false);
            }
          } else {
            if (trailingStop === true) {
              payload["trailingDelta"] = trailingDelta;
              payload["sellZone"] = menorValorSellZone;
              payload["buyZone"] = maiorValorBuyZone;
            }
            const result = await postOrders(payload);
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              getCurrentBalance();
              getTableFilds();
              getTableFildsPending();
              setLoading(false);
            }
          }
        } catch (error: any) {
          toast.error(error.message);
          closeModal();
          // toast.error("Erro ao criar nova ordem.");
        }
      } else {
        toast.error("Cadastre o Token de acesso no perfil do usuário");
        setLoading(false);
      }
    }
  };

  const handleValidate = () => {
    if (newOrder.valor < 1) {
      toast.error("Digite um percentual acima de 1.");
    } else if (
      newOrder.estrategia !== "automatizada" &&
      newOrder.moeda === ""
    ) {
      toast.error("Selecione a moeda ou altere a opção de entrada.");
    } else if (newOrder.lucroEsperado < 1) {
      toast.error("O valor do alvo não pode ser inferior a 1.");
    } else if (newOrder.buyzone_1 == "" || newOrder.buyzone_2 == "") {
      toast.error("O valor da BuyZone deve ser maior que 0.");
    } else {
      return true;
    }
  };

  const handleStatusTralingRsi = (field: string) => {
    setNewOrder({
      ...newOrder,
      [field]:
        field === "trailingStop" ? !newOrder.trailingStop : !newOrder.isRsi,
    });
  };

  const handleStatusRsi = () => {
    setRsiStatus(!rsiStatus);
    if (rsiStatus === true) {
      setNewOrder({ ...newOrder, isRsi: false });
    }
  };

  const paridadeLabel = useCallback(() => {
    const paridade = newOrder.moeda.includes("USDT") ? "USDT" : "BTC";
    return newOrder.moeda ? (
      <span className="input-group-addon">{paridade}</span>
    ) : null
  }, [newOrder])

  return (
    <Form onSubmit={(ev) => postOrder(ev)}>
      <Column>
        <RowModal center={"center"}>
          {/* <Column> */}
          <label htmlFor="estrategia">Opções de Entrada</label>
          <select
            name="mercado"
            id="mercado"
            onChange={(ev) => handleNewOrder("mercado", ev.target.value)}
          >
            <option value="spot">SPOT</option>
            <option value="futuros">FUTUROS</option>
          </select>
          {/* </Column> */}
          {/* {newOrder.estrategia !== "automatizada" && (
            <Column>
              <label htmlFor="rsi">Indicador RSI</label>
              <Row style={{ padding: 0 }}>
                <label
                  style={{ marginRight: 20, fontSize: 16 }}
                  onClick={() => setRsi(true)}
                >
                  <input type="radio" name="rsi" id="rsi" checked={rsi} />
                  &nbsp;Sim
                </label>
                <label
                  style={{ marginRight: 20, fontSize: 16 }}
                  onClick={() => setRsi(false)}
                >
                  <input
                    type="radio"
                    name="rsi"
                    id="rsi"
                    checked={rsi == false ? true : false}
                  />
                  &nbsp;Não
                </label>
              </Row>
            </Column>
          )} */}
        </RowModal>
        <hr />
        {newOrder.estrategia === "personalizada" && (
          <RowModal>
            <Column>
              <label htmlFor="moeda">Moeda</label>
              <input
                className="datalist"
                list="moeda"
                type="search"
                name="myMoeda"
                id="myMoeda"
                autoComplete="chrome-off"
                placeholder="Selecione..."
                onChange={(ev) => handleNewOrder("moeda", ev.target.value)}
              />
              <datalist id="moeda">
                {coin &&
                  coin.map((item: any, index: any) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </datalist>
            </Column>
          </RowModal>
        )}

        {newOrder.mercado === "futuros" && (
          <RowModal>
            <Column>
              <label htmlFor="moeda">Side</label>
              <select
                name="side"
                id="side"
                onChange={(ev) => handleNewOrder("side", ev.target.value)}
              >
                <option value="BUY">COMPRA</option>
                <option value="SELL">VENDA</option>
              </select>
            </Column>
          </RowModal>
        )}

        <RowModal>
          <Column>
            <label htmlFor="valor">
              {newOrder.estrategia !== "trailingStop"
                ? "Valor Entrada"
                : "Preço Ativação"}
            </label>
            <div className="input-group">
              <input
                type="number"
                placeholder="10"
                name="valor"
                id="valor"
                value={newOrder.valor > 0 ? newOrder.valor : ""}
                onChange={(ev) => handleNewOrder("valor", +ev.target.value)}
              />
              <span>%</span>
            </div>
          </Column>
        </RowModal>
        <RowModal>
          <Column>
            <label htmlFor="stopLoss">Stop Loss</label>
            <div className="input-group">
              <input
                type="number"
                id="stopLoss"
                placeholder="0"
                value={newOrder.stopLoss}
                // min={1}
                onChange={(ev) =>
                  handleNewOrder("stopLoss", +ev.target.value)
                }
              />
              {paridadeLabel()}
            </div>
          </Column>
          {/* {newOrder.estrategia === "trailingStop" ? (
            <Column>
              <label htmlFor="trailingDelta">T/D</label>
              <div className="input-group">
                <input
                  type="number"
                  id="trailingDelta"
                  placeholder="1"
                  value={newOrder.trailingDelta}
                  onChange={(ev) =>
                    handleNewOrder("trailingDelta", ev.target.value)
                  }
                />
                <span className="input-group-addon">%</span>
              </div>
            </Column>
          ) : ( 
            
            )}
          */}
        </RowModal>
        <RowModal>
          <Column>
            <label>Buy Zone</label>
            <div style={{ display: 'flex' }}>
              <div className="input-group">
                <input
                  type="number"
                  id="buyzone"
                  placeholder="0"
                  // min={1}
                  value={newOrder.buyzone_1}
                  onChange={(ev) =>
                    handleNewOrder("buyzone_1", +ev.target.value)
                  }
                  className="small-input"
                />
                <input
                  type="number"
                  id="buyzone"
                  placeholder="0"
                  // min={1}
                  value={newOrder.buyzone_2}
                  onChange={(ev) =>
                    handleNewOrder("buyzone_2", +ev.target.value)
                  }
                  className="small-input"
                />
              </div>
              {paridadeLabel()}
            </div>
          </Column>
        </RowModal>
        <RowModal>
          <Column>
            <label>Sell Zone</label>
            {sellZoneArr.map((item, index) => (
              <div className="input-group mt-2" key={item.id}>
                <input
                  type="number"
                  id="buyzone"
                  placeholder="0"
                  value={item.valor}
                  // min={1}
                  onChange={(ev) =>
                    handleNewOrder("sellzone", ev.target.value, index)
                  }
                />
                <span
                  className="input-group-addon cursor-click"
                  onClick={() => {
                    sellZoneArr.splice(index, 1);
                    setSellzoneArr([...sellZoneArr]);
                  }}>
                  <FiTrash2 size={16} />
                </span>
              </div>
            ))}
          </Column>
        </RowModal>
        <RowModal jContent="center">
          <Button
            onClick={() => setSellzoneArr([...sellZoneArr, { id: new Date().getTime().toString(), valor: "" }])}
            type="button" wide="160px" variant="outlined">
            Adicionar Sell Zone
          </Button>
        </RowModal>
        {/* <RowModal>
          <div className="input-group input-trailing">
            <input
              type="checkbox"
              name="trailing"
              id="trailing"
              onChange={() => handleStatusTralingRsi("trailingStop")}
            />
            <label htmlFor="trailing">Trailing Stop</label>
          </div>
        </RowModal> */}
        {/* {newOrder.trailingStop == true && (
          <RowModal>
            <Column>
              <label htmlFor="trailingDelta">Distancia Trailing Stop</label>
              <div className="input-group">
                <input
                  type="number"
                  name="trailingDelta"
                  id="trailingDelta"
                  placeholder="1"
                  min={1}
                  onChange={(ev) =>
                    handleNewOrder("trailingDelta", +ev.target.value)
                  }
                />
                <span className="input-group-addon">%</span>
              </div>
            </Column>
          </RowModal>
        )} */}
        {/* {newOrder.estrategia === "personalizada" ? (
          <>
            <div className="indicadores">
              <label>
                <div className="switch-wrapper">
                  <input
                    type="checkbox"
                    name="wrapper"
                    checked={rsiStatus}
                    onClick={() => handleStatusRsi()}
                  />
                  <span className="switch-button"></span>
                </div>
                Indicadores
              </label>
            </div>
            {rsiStatus === true && (
              <RowModal>
                <div className="input-group input-trailing">
                  <input
                    type="checkbox"
                    name="isRsi"
                    id="isRsi"
                    checked={newOrder.isRsi}
                    onChange={() => handleStatusTralingRsi("isRsi")}
                  />
                  <label htmlFor="isRsi">RSI (Indice de Força Relativa)</label>
                </div>
              </RowModal>
            )}
            {newOrder.isRsi == true && rsiStatus === true && (
              <>
                <RowModal>
                  <Column>
                    <label>Pedíodo Gráfico</label>
                    <select
                      className="large"
                      name="interval"
                      id="interval"
                      onChange={(ev) =>
                        handleNewOrder("interval", ev.target.value)
                      }
                    >
                      <option value="15m" selected>
                        15 Minutos
                      </option>
                      <option value="1h">1 Hora</option>
                      <option value="2h">2 Horas</option>
                      <option value="4h">4 Horas</option>
                      <option value="1d">1 Dia</option>
                      <option value="1w">1 Semana</option>
                      <option value="1M">1 Mês</option>
                    </select>
                  </Column>
                </RowModal>
                <RowModal>
                  <Column>
                    <label htmlFor="target">Configuração</label>
                    <select
                      name="target"
                      id="target"
                      className="large"
                      onChange={(ev) =>
                        handleNewOrder("target", ev.target.value)
                      }
                    >
                      <option value="70-30" selected>
                        70 - 30
                      </option>
                      <option value="80-20">80 - 20</option>
                    </select>
                  </Column>
                </RowModal>
              </>
            )}
          </>
        ) : null} */}

        <Row className="flex-end">
          <Button
            type="button"
            wide={"auto"}
            background={"var(--warning)"}
            disabled={loading}
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            wide={"auto"}
            background={"var(--primary)"}
            disabled={loading}
          >
            Adicionar Ordem &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
        </Row>
      </Column>
    </Form>
  );
};

export default LabelModal;
