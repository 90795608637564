import React from 'react';
import {
  Container,
  Title,
  Content,
  ContentColumn,
  ContentColumnLeft,
  ContentColumnRight,
  ContentResults,
  ContentRow,
  ButtonContainer
} from './styles';
import Table from '../../components/Table';
import Button from '../../components/Button';

const Titulos: React.FC = () => {
  return (
    <Container>
      <Title>Títulos</Title>
      <Content>
        <ButtonContainer>
          <Button>Calcular</Button>
          <Button>Aprovar</Button>
          <Button>Imprimir</Button>
          <Button>Gravar</Button>
          <Button>Cancelar</Button>
          <Button>Faturar</Button>
          <Button>Fechar</Button>
        </ButtonContainer>
        <Table 
          title="Títulos"
          cols={[
            {
              id: "1",
              name: "",
              size: "small",
              align: "center",
              type: "checkbox",
              editable: true,
            },{
              id: "2",
              name: "Chave",
              size: "small",
              align: "center",
              type: "input",
              editable: false,
            },
            {
              id: "3",
              name: "P/R",
              size: "small",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "4",
              name: "Número",
              size: "medium",
              align: "center",
              type: "input",
              editable: false,
            },
            {
              id: "5",
              name: "Tipo",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "6",
              name: "Prazo",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "7",
              name: "Vencimento",
              size: "medium",
              align: "center",
              type: "input",
              editable: false,
            },
            {
              id: "8",
              name: "Correção",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "9",
              name: "Valor Principal",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "10",
              name: "Juros",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "11",
              name: "Multa",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "12",
              name: "Acrescímo",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "13",
              name: "Desconto",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "14",
              name: "Valor Corrigido",
              size: "medium",
              align: "center",
              type: "input",
              editable: true,
            },
            {
              id: "15",
              name: "Loc. Escrituração",
              size: "small",
              align: "center",
              type: "input",
              editable: true,
            },
          ]}
        />
        <ContentColumn>
          <ContentColumnLeft>
            <Table
              title="Movimentação de Disponível" 
              cols={[
                {
                  id: "1",
                  name: "",
                  size: "small",
                  align: "center",
                  type: "checkbox",
                  editable: false,
                },
                {
                  id: "2",
                  name: "Chave",
                  size: "medium",
                  align: "center",
                  type: "input",
                  editable: false,
                },
                {
                  id: "3",
                  name: "Data",
                  size: "small",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "4",
                  name: "Disponível",
                  size: "large",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "5",
                  name: "Tipo",
                  size: "small",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "6",
                  name: "Número",
                  size: "large",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "7",
                  name: "Valor",
                  size: "medium",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "8",
                  name: "Conciliação",
                  size: "large",
                  align: "center",
                  type: "input",
                  editable: true,
                },
                {
                  id: "9",
                  name: "Histórico",
                  size: "large",
                  align: "center",
                  type: "input",
                  editable: true,
                }
              ]}
            />
          </ContentColumnLeft>
          <ContentColumnRight>
            <h1>Totais dos Títulos</h1>
            <ContentResults>
              <ContentRow>
                <p>Valor Principal: </p>
                <span>0,00</span>
              </ContentRow>
              <ContentRow>
                <p>Juros: </p>
                <span>0,00</span>
              </ContentRow>
              <ContentRow>
                <p>Multa: </p>
                <span>0,00</span>
              </ContentRow>
              <ContentRow>
                <p>Valor Corrigido: </p>
                <span>0,00</span>
              </ContentRow>
            </ContentResults>
          </ContentColumnRight>
        </ContentColumn>
      </Content>
    </Container>
  );
}

export default Titulos;