import styled from 'styled-components';
import InputMask from 'react-input-mask';

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
      width: 30px;
      font-weight: bold;
      cursor: pointer;
  }
`;

export const Wrapper = styled.div<any>`
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 2px 0 2px 0;
  transition: .2s;
`;

export const Label = styled.p<ILabel>`
  color: ${props => props.error ? 'red' : 'var(--white)'};
  font-size: 12px;
  font-weight: ${props => props.bold ? '500' : '300'};
  margin-bottom: 4px;
  justify-content: center;
  text-overflow: none;
  width: auto;
  white-space: nowrap;
`;

export const Input = styled.div<any>`
  width: 100%;
  background-color: ${props => props.disabled ? 'var(--opacity-background)' : 'var(--white)'};
  border: 1px solid var(--opacity-background);
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  white-space: nowrap;
  box-shadow: none;
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 400;

  cursor: pointer;

  &:focus-within {
    border: 1px solid var(--primary);
  }
`;

export const DateMaskInput = styled(InputMask)`
  background-color: ${props => props.disabled ? 'var(--opacity-background)' : 'var(--white)'};
  border: 1px solid var(--opacity-background);
  border-radius: 4px;
  box-shadow: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 8px 6px 8px;
  width: 100%;
  white-space: nowrap;

  /* cursor: pointer; */

  &:focus-within {
    border: 1px solid var(--primary);
  }
`;