import Provider from "./config";

export const getWhiteListFilter = async (filter: any) => {
  try {
    const response = await Provider.get("exchanges/whitelist-coins", {
      params: {
        marketCap: filter.marketCap,
        volume: filter.volume,
        supply: filter.supply,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar campos para o filtro white list.");
  }
};

export const getWhiteList = async (
  currentPage?: number,
  growing?: any,
  take?: any,
  filter?: any
) => {
  const params: any = {
    page: currentPage,
    marketCap: 0,
    volume: 0,
    supply: 0,
  };

  if (growing) {
    if (growing.item === "Moeda") {
      params["moeda"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Market Cap") {
      params["marketCap"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Volume(24h)") {
      params["volume"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Circulating Supply") {
      params["currentSupply"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Variação de Preço (24h)") {
      params["priceChangePercent"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Price") {
      params["price"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "1h %") {
      params["filterPriceChange1h"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "7d %") {
      params["filterPriceChange7d"] = growing.asc === "true" ? "asc" : "desc";
    }
  }
  if (take) {
    params["take"] = take;
  }
  if (filter) {
    if (filter.coin !== "") {
      params["coin"] = filter.coin.toUpperCase();
    }
    if (filter.price !== "") {
      params["filterPrice"] = filter.price;
    }
    if (filter.marketCap !== "") {
      params["filterMarketCap"] = filter.marketCap;
    }
    if (filter.variation !== "") {
      params["variation"] = filter.variation
        .replaceAll("-", "menos")
        .replaceAll("+", "mais");
    }
    if (filter.volume !== "") {
      params["filterVolume"] = filter.volume;
    }
  }
  try {
    const response = await Provider.get("exchanges/whitelist-coins", {
      params,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar campos para white list.");
  }
};
export const getWhiteListOnlyNames = async (whiteList?: boolean) => {
  const params: any = {
    allCoinsNames: true,
  };
  if (whiteList == true) {
    params["whitelist"] = true;
  }
  try {
    const response = await Provider.get("exchanges/whitelist-coins", {
      params,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar campos para white list.");
  }
};

export const getWhiteListSelected = async (
  currentPage: number,
  growing?: any,
  take?: any,
  filter?: any
) => {
  const params: any = {
    page: currentPage,
    marketCap: 0,
    volume: 0,
    supply: 0,
  };
  if (growing) {
    if (growing.item === "Moeda") {
      params["moeda"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Market Cap") {
      params["marketCap"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Volume(24h)") {
      params["volume"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Circulating Supply") {
      params["currentSupply"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Variação de Preço (24h)") {
      params["priceChangePercent"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "Price") {
      params["price"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "1h %") {
      params["filterPriceChange1h"] = growing.asc === "true" ? "asc" : "desc";
    } else if (growing.item === "7d %") {
      params["filterPriceChange7d"] = growing.asc === "true" ? "asc" : "desc";
    }
  }
  if (take) {
    params["take"] = take;
  }
  if (filter) {
    if (filter.coin !== "") {
      params["coin"] = filter.coin.toUpperCase();
    }
    if (filter.price !== "") {
      params["filterPrice"] = filter.price;
    }
    if (filter.marketCap !== "") {
      params["filterMarketCap"] = filter.marketCap;
    }
    if (filter.variation !== "") {
      params["variation"] = filter.variation
        .replaceAll("-", "menos")
        .replaceAll("+", "mais");
    }
    if (filter.volume !== "") {
      params["filterVolume"] = filter.volume;
    }
  }
  try {
    const response = await Provider.get("exchanges/whitelist-get-coins", {
      params,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar os campos para white list.");
  }
};

export const postWhiteList = async (ids: any) => {
  try {
    const response = await Provider.post("exchanges/whitelist-set-coins", {
      ids: ids,
    });

    return response;
  } catch (err: any) {
    throw Error("Erro ao enviar dados do white list.");
  }
};
