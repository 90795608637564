import React, { useEffect, useState } from "react";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { getFields, getOpenOrders } from "../../services/orders";
import { orderCols } from "../../utils/form";
import { Container, Content, DivCard, Row, Title } from "./style";
import useFetch from "../../hooks/useFetch";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import ReactPaginate from "react-paginate";

interface IFinancial {
  title: string;
  multipleSelect: boolean;
  endpoint: string;
  filters: object;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

const Financial: React.FC<IFinancial> = ({
  title,
  multipleSelect,
  endpoint,
  filters,
}) => {
  const { user } = useAuth();
  const provider = useFetch({ endpoint });
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const [finished, setFinished] = useState(false);
  const [pending, setPending] = useState<IResponse | null>(null);
  const [realised, setRealised] = useState<IResponse | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageRealised, setCurrentPageRealised] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);

  useEffect(() => {
    getTableFilds();
  }, [currentPage, quantityPerPage]);

  useEffect(() => {
    getRealised();
  }, [currentPageRealised, quantityPerPage]);

  const getTableFilds = async () => {
    try {
      const idUser = user ? user.id : null;
      const result = await fields.get({ idUser, ...filters });
      if (result) {
        const order_cols = result.sort(orderCols);
        setCols(order_cols);

        const resultOrders = await provider.get({
          page: currentPage,
          idUser,
          ...filters,
          baixado: false,
          // take: quantityPerPage,
        });
        if (resultOrders) {
          setPending(resultOrders);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getRealised = async () => {
    try {
      const idUser = user && user.id;
      const result = await provider.get({
        page: currentPageRealised,
        idUser,
        ...filters,
        baixado: true,
      });

      if (result) {
        setRealised(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleChangePage = (page: number) => {
    if (finished === false) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageRealised) {
        setCurrentPageRealised(page + 1);
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  return (
    <Container>
      <Title>{title}</Title>

      <DivCard>
        <Row>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setFinished(false)}
          >
            <p className={!finished ? "primary" : ""}>
              Pendentes{pending?.total ? `(${pending.total})` : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setFinished(true)}
          >
            <p className={finished ? "primary" : ""}>
              Realizados{realised?.total ? `(${realised.total})` : "(0)"}
            </p>
          </button>
        </Row>
      </DivCard>

      <Content>
        <ListTable
          multipleSelect={multipleSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={cols}
          data={
            !finished && pending
              ? pending.docs
              : finished && realised
              ? realised.docs
              : []
          }
          onChangePage={handleChangePage}
          currentPage={!finished ? currentPage : currentPageRealised}
          totalPages={
            !finished && pending
              ? pending.totalPages
              : finished && realised
              ? realised.totalPages
              : 1
          }
          count={
            !finished && pending
              ? pending.total
              : finished && realised
              ? realised.total
              : 0
          }
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={
              !finished && pending
                ? pending.totalPages
                : finished && realised
                ? realised.totalPages
                : 1
            }
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default Financial;
