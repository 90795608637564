import React, { useCallback, useEffect, useState } from "react";
import { Card, Table } from "./styles";

interface IIndicado {
	id: string;
	email: string;
	nome: string;
	inativo: boolean;
	criadoEm: string;
	idIndicador: string;
	selecionado?: boolean;
	marcado?: boolean;
}

export interface INivel {
	primeiroNivel: IIndicado[];
	segundoNivel: IIndicado[];
	terceiroNivel: IIndicado[];
}

interface ITableIndicados {
	indicados?: INivel | null
}

const TableIndicados = ({
	indicados
}: ITableIndicados) => {

	const [listaIndicados, setListaIndicados] = useState<INivel | any>(indicados);

	useEffect(() => {
		setListaIndicados(indicados);
	}, [indicados])

	const handleSelect = (indicado:IIndicado, nivel: number) => {
		// encontra a relação mais proxima.
		let _lista: INivel = {...listaIndicados};	
		
		if (nivel === 1) {
			_lista.primeiroNivel = _lista.primeiroNivel.map(item => {
				if (item.id === indicado.id) {
					return {...item, selecionado: true, marcado: false}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});
			_lista.segundoNivel = _lista.segundoNivel.map(item => {
				if (item.idIndicador === indicado.id) {
					return {...item, selecionado: false, marcado: true}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});

			_lista.terceiroNivel = _lista.terceiroNivel.map(item => {
				const hasIndicador = _lista.segundoNivel.find(ind => {
					if(ind.marcado && ind.id === item.idIndicador) {
						return true
					} else {
						return false;
					}
				});

				if(hasIndicador) {
					return {...item, selecionado: false, marcado: true};
				} else {
					return {...item, selecionado: false, marcado: false};
				}
			});
			setListaIndicados(_lista);

		} else if(nivel === 2) {
			_lista.primeiroNivel = _lista.primeiroNivel.map(item => {
				if (item.id === indicado.idIndicador) {
					return {...item, selecionado: false, marcado: true}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});

			_lista.segundoNivel = _lista.segundoNivel.map(item => {
				if (item.id === indicado.id) {
					return {...item, selecionado: true, marcado: false}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});

			_lista.terceiroNivel = _lista.terceiroNivel.map(item => {
				if (item.idIndicador === indicado.id) {
					return {...item, selecionado: false, marcado: true}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});
			setListaIndicados(_lista);

		} else {
			_lista.terceiroNivel = _lista.terceiroNivel.map(item => {
				if (item.id === indicado.id) {
					return {...item, selecionado: true, marcado: false}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});

			_lista.segundoNivel = _lista.segundoNivel.map(item => {
				if (item.id === indicado.idIndicador) {
					return {...item, selecionado: false, marcado: true}
				} else {
					return {...item, selecionado: false, marcado: false}
				}
			});


			_lista.primeiroNivel = _lista.primeiroNivel.map(item => {
				const hasIndicador = _lista.segundoNivel.find(ind => {
					if(ind.marcado && ind.idIndicador === item.id) {
						return true
					} else {
						return false;
					}
				});

				if(hasIndicador) {
					return {...item, selecionado: false, marcado: true};
				} else {
					return {...item, selecionado: false, marcado: false};
				}
			});


			setListaIndicados(_lista);
		}
	}
	
	return (
		<Table cellSpacing={10}>
			<thead>
				<tr>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="leftColumn">Nível 1</td>
					<td>
						<div className="rowColumn">
							{listaIndicados && listaIndicados.primeiroNivel.map((indicado: IIndicado) => (
								<Card 
								key={indicado.id} 
								selecionado={indicado.selecionado}
								nivel={1}
								marcado={indicado.marcado}
								onClick={() => handleSelect(indicado, 1)}>
									<p>{indicado.nome}</p>
								</Card>)
							)}
						</div>
					</td>
				</tr>
				<tr>
					<td className="leftColumn">Nível 2</td>
					<td>
						<div className="rowColumn">
							{listaIndicados && listaIndicados.segundoNivel.map((indicado: IIndicado) => (
									<Card 
									key={indicado.id} 
									selecionado={indicado.selecionado} 
									marcado={indicado.marcado}
									onClick={() => handleSelect(indicado, 2)}>
										<p>{indicado.nome}</p>
									</Card>
								)
							)}
						</div>
					</td>
				</tr>
				<tr>
					<td className="leftColumn">Nível 3</td>
					<td>
						<div className="rowColumn">
							{listaIndicados && listaIndicados.terceiroNivel.map((indicado: IIndicado) => (
								<Card 
								key={indicado.id} 
								selecionado={indicado.selecionado}
								marcado={indicado.marcado}
								onClick={() => handleSelect(indicado, 3)}>
									<p>{indicado.nome}</p>
								</Card>)
							)}
						</div>
					</td>
				</tr>
			</tbody>
		</Table>
	)
}

export default TableIndicados;