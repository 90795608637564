/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../ListTable";
import useFetch from "../../hooks/useFetch";
import { getItemsAndTitulos } from "../../services/pedidos";
import {
  Wrapper,
  HeaderContent,
  Title,
  Content,
  ActionsContainer,
  Button,
  Footer,
  SearchInput,
  Row,
  DivUpdate,
  DivLeft,
  DivRight,
} from "./styles";
import { IToggleMenuOption } from "../ToggleMenuButton";
import Toast from "../Toast";
import { PaginateContainer, SelectedContainer } from "../ListTable/styles";
import { useModal } from "../../contexts/modal";
import { orderCols } from "../../utils/form";

interface IAction {
  id: string;
  name: string;
  type: "global" | "specific";
  onClick(item?: any): void;
}

interface ITemplateProps extends RouteComponentProps {
  title: string;
  actions: Array<IAction>;
  endpoint:
    | "entidades"
    | "userGroups"
    | "classes"
    | "permissoes"
    | "pedidos"
    | "titulos"
    | "tabelas"
    | "recursos";
  filters?: object;
  path?: string;
  pullOrderPath?: string;
  multipleSelect?: boolean;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

interface IBaseHandler {
  [key: string]: any;
}

const Template: React.FC<ITemplateProps> = ({
  title,
  actions,
  endpoint,
  path,
  pullOrderPath,
  multipleSelect,
  filters,
  history,
  location,
  ...props
}) => {
  const requestHandler = useFetch({ endpoint }) as any;
  const fields = useFetch({ endpoint: `${endpoint}/campos/` });
  const { openModal } = useModal();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [getResponse, setGetResponse] = useState<IResponse | null>(null);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [actionsButtons, setActionsButtons] = useState<IAction[]>();
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [baixaPedido, setBaixaPedido] = useState(false);

  const [selectedToAction, setSelectedToAction] = useState<any>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [deleteItem, setDeleteItem] = useState(false);
  const [resetPage, setResetPage] = useState(true);

  useEffect(() => {
    if (baixaPedido) handleBaixaPedido();
  }, [baixaPedido]);

  const handleInsert = () => {
    if (path) {
      history.push(`${path}/inserir`);
    }
  };

  const handleEdit = async (item: any) => {
    if (path && (item.id || item.chcriacao)) {
      let itemToSend = {
        ...item,
      };

      if (item.chcriacao) {
        try {
          const result = await getItemsAndTitulos([item.chcriacao]);
          itemToSend = {
            ...itemToSend,
            items: result.items,
            titulos: result.titulos,
          };
        } catch (err: any) {
          return Toast.show(err.message, "error");
        }
      }

      history.push({
        pathname: `${path}/editar/${item.id ? item.id : item.chcriacao}`,
        state: {
          item: itemToSend,
        },
      });
    }
  };

  const handleEspelhoPedido = (item: any) => {
    if (path) {
      history.push({
        pathname: `${path}/espelho-pedido/${item.chcriacao}`,
        state: {
          item,
        },
      });
    }
  };

  const handleFichaFuncionario = (item: any) => {
    if (path) {
      history.push({
        pathname: `${path}/ficha/${item.id}`,
        state: {
          item,
        },
      });
    }
  };

  const handleBaixaPedido = async () => {
    if (pullOrderPath && getResponse && getResponse.docs) {
      const pedidos = getResponse.docs.filter(
        (_item: any, index: number) => selectedIndexes[index] === true
      );
      setBaixaPedido(false);
      if (pedidos.length > 0) {
        const chaves = pedidos.map((pedido: any) => pedido.chcriacao);
        let items = [];
        let titulos = [];

        try {
          const result = await getItemsAndTitulos(chaves);
          items = result.items;
          titulos = result.titulos;
        } catch (err: any) {
          return Toast.show(err.message, "error");
        }

        const itemsBaixa: any = [];
        const titulosBaixa: any = [];
        for (const itemPedido of items) {
          const { chave, ...dadosItem } = itemPedido;
          itemsBaixa.push({ ...dadosItem, chpedbaixa: chave });
        }

        for (const tituloPedido of titulos) {
          if (!tituloPedido.baixado || tituloPedido.baixado !== true) {
            titulosBaixa.push(tituloPedido);
          }
        }

        if (itemsBaixa.length <= 0)
          return Toast.show("Todos os items foram baixados", "error");
        const baixa: any = {
          ...pedidos[0],
          items: itemsBaixa,
          titulos: titulosBaixa,
        };
        history.push({
          pathname: pullOrderPath,
          state: {
            baixa,
          },
        });
      } else {
        return Toast.show("Nenhum item selecionado", "error");
      }
    }
  };

  const handleRemove = (item: any) => {
    setSelectedToAction(item);
    setDeleteItem(true);
  };

  const baseActionHandlers: IBaseHandler = {
    insert: handleInsert,
    edit: handleEdit,
    espelhoPedido: handleEspelhoPedido,
    pullOrder: () => setBaixaPedido(true),
    remove: handleRemove,
    fichaFuncionario: handleFichaFuncionario,
  };

  useEffect(() => {
    setResetPage(true);
  }, [endpoint, filters]);

  useEffect(() => {
    if (resetPage) {
      handleReset();
    }
  }, [resetPage]);

  useEffect(() => {
    if (deleteItem) {
      openModal({
        type: "confirm",
        title: "Tem certeza que deseja excluir?",
        children: renderDeleteContent,
        onConfirm: handleDeleteSelected,
        onCancel: () => setDeleteItem(false),
      });
    }
  }, [deleteItem]);

  useEffect(() => {
    if (firstLoad) {
      getData();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!search && !firstLoad) return;
    let timer = setTimeout(() => {
      getData(false, search);
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    const globalActions = actions.filter((action) => action.type === "global");
    const specificActions = actions.filter(
      (action) => action.type === "specific"
    );
    const baseKeys = Object.keys(baseActionHandlers);

    if (globalActions.length > 0) {
      const global = globalActions.map((action) => {
        const option: IAction = {
          ...action,
          onClick: baseKeys.includes(action.id)
            ? () => baseActionHandlers[action.id]()
            : action.onClick
            ? () => action.onClick()
            : () => {},
        };
        return option;
      });

      setActionsButtons(global);
    }
    if (specificActions.length > 0) {
      const rowActions = specificActions.map(
        (action: IAction, index: number) => {
          const option: IToggleMenuOption = {
            id: index,
            name: action.name,
            onClick: baseKeys.includes(action.id)
              ? (item: any) => baseActionHandlers[action.id](item)
              : action.onClick
              ? () => action.onClick()
              : () => {},
          };

          return option;
        }
      );
      setActionsMenus(rowActions);
    }
  }, []);

  const handleReset = () => {
    setGetResponse(null);
    setCols([]);
    getData(true);
    setResetPage(false);
  };

  const getData = async (firstRender = false, search: string | null = null) => {
    try {
      if (firstRender) {
        setFirstLoad(true);
        const cols = await fields.get(filters);
        const order_cols = cols.sort(orderCols);
        setCols(order_cols);
      }

      let getFilters: any = { page: currentPage };

      if (filters) {
        getFilters = {
          ...getFilters,
          ...filters,
        };
      }

      if (search) {
        getFilters = {
          ...getFilters,
          nome: search,
        };
      }

      if (location && location.state) {
        const state = location.state as any;
        if (state.filtersToGet) {
          getFilters = {
            ...getFilters,
            ...state.filtersToGet,
          };
        }
      }

      const result = await requestHandler.get(getFilters);
      if (!result.error) {
        setGetResponse(result);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedToAction) {
      await requestHandler.remove(`/${selectedToAction.id}`);
      setDeleteItem(false);
      setTimeout(() => {
        getData();
      }, 1000);
    }
  };

  const renderDeleteContent = () => {
    return (
      <p>
        Tem certeza que deseja excluir{" "}
        <b>
          {selectedToAction.nome ? selectedToAction.nome : selectedToAction.id}
        </b>
        ?
      </p>
    );
  };

  return (
    <Wrapper>
      <HeaderContent>
        <Title>{title}</Title>
        <ActionsContainer>
          {actionsButtons?.map((action) => (
            <Button
              key={action.name}
              onClick={action.onClick ? () => action.onClick() : () => {}}
            >
              {action.name}
            </Button>
          ))}
        </ActionsContainer>
      </HeaderContent>
      {!title.startsWith("Resultados da busca") && (
        <Row>
          <SearchInput
            placeholder="Pesquise..."
            value={search}
            onChange={({ currentTarget }) => setSearch(currentTarget.value)}
          />
          <DivUpdate>
            <DivLeft>
              <Title>Saldo</Title>
              <b>USDT: 60,52</b>
              <b>BRL: 60,52</b>
            </DivLeft>
            <DivRight>
              <Button>Atualizar</Button>
            </DivRight>
          </DivUpdate>
        </Row>
      )}
      <Content>
        <ListTable
          multipleSelect={multipleSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={cols}
          data={getResponse ? getResponse.docs : []}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={getResponse ? getResponse.totalPages : 1}
          count={getResponse ? getResponse.total : 0}
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>
            Total: <b>{getResponse ? getResponse.total : 0}</b>
          </p>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={getResponse ? getResponse.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Wrapper>
  );
};

export default Template;
