/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button
} from './styles';
import {useHistory, useLocation} from 'react-router-dom';
import addDays from 'date-fns/addDays';
import formatDate from 'date-fns/format';
import TextInput from '../../components/TextInput';
import LookupInput from '../../components/LookupInput';
import DateInput from '../../components/DateInput';
import Table from '../../components/Table';
import Toast from '../../components/Toast';
import CardTable from '../../components/CardTable';
import {calcularPedido, criarPedido, getChavePedido} from '../../services/pedidos';
import { convertToDate } from '../../utils/date';
import { detalhesData, itemCols, tituloCols } from './fixedData';

import { useAuth } from '../../contexts/auth';

const ServiceOrder: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const baixaAutomatica = location.state && location.state.baixaAutomatica && location.state.baixaAutomatica === true;
  const baixaNaoAutomatica = location.state && location.state.baixa && location.state.baixa !== null;
  const baixaPedido = location.state && location.state.baixa ? location.state.baixa : null;
  const {user} = useAuth();
  const novoPedido = true;
  const aprovador = user ? user.nome ? user.nome : user.email ? user.email : user.id : null;
  const [payload, setPayload] = useState<any>({
    emissao: new Date().toISOString(),
    observacao: null,
    frete: null,
    seguro: null,
    acrescimo: null,
    desconto: null,
    idAcessorio1: null,
  });
  const [loadings, setLoadings] = useState({
    calcular: false,
    salvar: false,
  });
  const [comAprovador, setComAprovador] = useState(false);
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [observacaoToShow, setObservacaoToShow] = useState('');
  const [lookups, setLookups] = useState<any>({});
  const [initialLookups, setInitialLookups] = useState<any>({
    Representante: null,
    LocEscrituracao: null,
    Estabelecimento: null,
    Pessoa: null,
  });
  const [initialItems, setInitialItems] = useState<any>(null);
  const [initialTitulos, setInitialTitulos] = useState<any>(null);
  const [items, setItems] = useState<any>(null);
  const [titulos, setTitulos] = useState<any>(null);
  const [colunasTitulos, setColunasTitulos] = useState<any>(tituloCols());
  const [colunasItens, setColunasItens] = useState<any>(itemCols());
  const [totais, setTotais] = useState<any>({
    acrescimoItemTotal: 0,
    descontoItemTotal: 0,
    ipiTotal: 0,
    valorTotal: 0,
    valorCorrigidoTotal: 0,
    vrItemTotal: 0,
    items: [],
    titulos: [],
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => setObservacaoToShow(payload.observacao), 500);
    return () => clearTimeout(timeOutId);
  }, [payload.observacao]);

  useEffect(() => {
    if (baixaPedido) {
      setPayload({
        emissao: new Date(baixaPedido.emissao).toISOString(),
        observacao: baixaPedido.observacao ? baixaPedido.observacao : null,
        frete: baixaPedido.frete ? baixaPedido.frete : null,
        seguro: baixaPedido.seguro ? baixaPedido.seguro : null,
        acrescimo: baixaPedido.acrescimo ? baixaPedido.acrescimo : null,
        desconto: baixaPedido.desconto ? baixaPedido.desconto : null,
        idAcessorio1: baixaPedido.idAcessorio1 ? baixaPedido.idAcessorio1 : null,
      })
      setInitialLookups({
        Representante: baixaPedido.Representante ? { byId: baixaPedido.Representante.id } : null,
        LocEscrituracao: baixaPedido.LocEscritu ? { byId: baixaPedido.LocEscritu.id } : null,
        Estabelecimento: baixaPedido.Estabelecimento ? { byId: baixaPedido.Estabelecimento.id } : null,
        Pessoa: baixaPedido.Pessoa ? { byId: baixaPedido.Pessoa.id } : null,
      })
      const initialItemsData = [];
      const initialTitulosData = [];
      for (const itemBaixa of baixaPedido.items) {
        const itemInitialState = {
          Classe: itemBaixa.Classe ? itemBaixa.Classe : null,
          Recurso: itemBaixa.Recurso ? itemBaixa.Recurso : null,
          Nucleo: itemBaixa.Nucleo ? itemBaixa.Nucleo : null,
          Lote: itemBaixa.Lote ? itemBaixa.Lote : null,
          quantidade: itemBaixa.quantidade ? itemBaixa.quantidade : null,
          unitario: itemBaixa.unitario ? itemBaixa.unitario : null,
          acrescimoItem: itemBaixa.acrescimoItem ? itemBaixa.acrescimoItem : null,
          descontoItem: itemBaixa.descontoItem ? itemBaixa.descontoItem : null,
          ipi: itemBaixa.ipi ? itemBaixa.ipi : null,
        };
        initialItemsData.push(itemInitialState);
      }

      for (const tituloBaixa of baixaPedido.titulos) {
        const tituloInitialState = {
          Carteira: tituloBaixa.Carteira ? tituloBaixa.Carteira : null,
          TipoDocumento: tituloBaixa.TipoDocumento ? tituloBaixa.TipoDocumento : null,
          juros: tituloBaixa.juros ? tituloBaixa.juros : null,
          jurosPercm: tituloBaixa.jurosPercm ? tituloBaixa.jurosPercm : null,
          multa: tituloBaixa.multa ? tituloBaixa.multa : null,
          multaPerc: tituloBaixa.multaPerc ? tituloBaixa.multaPerc : null,
          acrescimo: tituloBaixa.acrescimo ? tituloBaixa.acrescimo : null,
          desconto: tituloBaixa.desconto ? tituloBaixa.desconto : null,
          correcao: tituloBaixa.correcao ? new Date(tituloBaixa.correcao).toISOString() : null,
        };
        initialTitulosData.push(tituloInitialState);
      }
      setInitialItems(initialItemsData);
      setInitialTitulos(initialTitulosData);
    }
  }, [baixaPedido]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
    }
  }, [novoPedido]);

  useEffect(() => {
    if (comAprovador) {
      setColunasItens((oldValue: any) => oldValue.map((item: any) => {
        return {...item, editavel: false};
      }));
      setColunasTitulos((oldValue: any) => oldValue.map((item: any) => {
        return {...item, editavel: false};
      }));
    } else {
      setColunasItens(itemCols());
      setColunasTitulos(tituloCols());
    }
  }, [comAprovador]);

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show('Ocorreu um erro ao tentar buscar a chave de criação', 'error');
    }
  }

  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return {...prevState, [field]: options}
    });
  }

  function handleChangePayload(field: string, value: any) {
    return setPayload((prevState: any) => {
      return {...prevState, [field]: value}
    });
  }

  function validatePayload(): boolean {
    if (!payload.emissao) {
      Toast.show('Insira a data de emissão');
      return false;
    }

    if (!lookups.LocEscrituracao || !lookups.LocEscrituracao.value) {
      Toast.show('Insira o local de escrituração');
      return false;
    } else if (!lookups.Estabelecimento || !lookups.Estabelecimento.value) {
      Toast.show('Insira o estabelecimento');
      return false;
    } else if (!lookups.Pessoa || !lookups.Pessoa.value) {
      Toast.show('Insira a pessoa');
      return false;
    } else if (!lookups.Representante || !lookups.Representante.value) {
      Toast.show('Insira o representante');
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.idRecurso) {
        Toast.show(`Insira o recurso do item ${ i + 1 }`);
        return false;
      } else if (!item.idClasse) {
        Toast.show(`Insira a classe do item ${ i + 1 }`);
        return false;
      } else if (!item.idNucleo) {
        Toast.show(`Insira o núcleo do item ${ i + 1 }`);
        return false;
      } else if (!item.quantidade) {
        Toast.show(`Insira a quantidade do item ${ i + 1 }`);
        return false;
      } else if (!item.unitario) {
        Toast.show(`Insira o preço do item ${ i + 1 }`);
        return false;
      }
    }

    return true;
  }

  function validarTitulos(): boolean {
    for (let i = 0; i < titulos.length; i++) {
      const titulo = titulos[i];

      if (!titulo.idTipoDocumento) {
        Toast.show(`Insira o tipo de documento do título ${ i + 1 }`);
        return false;
      } else if (!titulo.prazo) {
        Toast.show(`Insira o prazo do título ${ i + 1 }`);
        return false;
      } else if (!titulo.vencimento) {
        Toast.show(`Insira o vencimento do título ${ i + 1 }`);
        return false;
      }
    }

    return true;
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings(oldValue => {
      return {...oldValue, [field]: value};
    });
  }

  async function handleCalcular(): Promise<boolean> {
    handleChangeLoading('calcular', true);

    if (validatePayload()) {
      const payload = {
        items,
        titulos,
        condicaoPagamento: lookups.CondicaoPagamento ? lookups.CondicaoPagamento.value : null,
      };
      try {
        const result = await calcularPedido(payload);
        setTotais((oldValue: any) => {
          return {...result}
        });
        handleChangeLoading('calcular', false);
      } catch (err: any) {
        Toast.show(err.message);
        handleChangeLoading('calcular', false);
        return false;
      }
    } else {
      handleChangeLoading('calcular', false);
      return false;
    }

    handleChangeLoading('calcular', false);
    return true;
  }

  async function handleSalvar() {
    handleChangeLoading('salvar', true);
    try {
      let toSendPayload: any = {
        tipo: 'pedido de serviço',
        baixaAutomatica,
        baixaId: user?.id,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante.value,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        frete: payload.frete ? payload.frete : 0.00,
        seguro: payload.seguro ? payload.seguro : 0.00,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.00,
        desconto: payload.desconto ? payload.desconto : 0.00,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : '',
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (baixaAutomatica) {
            item = {...item, chpedbaixa: item.chave};
          }
          if (totais.items && totais.items.length > 0) {
            const {vrItem = null, valorTotal = null, ...itemPedido} = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            }
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao };
          return titulo;
        }),
      };

      if (comAprovador) {
        toSendPayload = {
          ...toSendPayload,
          idAprovador: user?.id,
          aprovacao: new Date(),
        };
      }

      await criarPedido(toSendPayload);
      handleChangeLoading('salvar', false);
      Toast.show(`O pedido de chave ${chaveCriacao} foi criado com sucesso.`, 'success');
      history.push('/pedidos-de-compra-de-servico');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    const tipo = e.nativeEvent.submitter.name;
    let calcular, titulos;
    switch(tipo) {
      case 'salvar':
        calcular = await handleCalcular();
        titulos = validarTitulos();
        if (calcular && titulos) {
          await handleSalvar();
        }
          break;
      case 'aprovar':
        if (comAprovador) {
          setComAprovador(false);
        } else {
          calcular = await handleCalcular();
          titulos = validarTitulos();
          if (calcular && titulos) {
            setComAprovador(true);
          }
        }
          break;
      case 'calcular':
        await handleCalcular();
        break;
      default:
        break;
    }
  }

  function handleChangeTitulos(result: any) {
    setTitulos(result);
  }

  function titulosFixedValues() {
    if (!titulos) return [];
    const values = titulos.map((titulo: any, index: number) => {
      let data = {
        vencimento: '',
        valorCorrigido: totais.titulos && totais.titulos.length > 0 && totais.titulos[index] ? totais.titulos[index].valorCorrigido : 0.0,
      };

      if (titulo.prazo) {
        const emissao = convertToDate(payload.emissao);
        if (emissao) {
          const vencimento = formatDate(addDays(emissao, titulo.prazo), 'dd/MM/yyyy');
          data = {...data, vencimento};
        }
      }

      return data;
    });

    return values;
  }

  function titulosFixedValue() {
    if (totais.valorTotal && titulos && titulos.length > 0) {
      const porTitulo = parseFloat(String(totais.valorTotal/titulos.length)).toFixed(2);
      return {
        valorPrincipal: porTitulo,
      };
    } else {
      return {
        valorPrincipal: 0.00
      };
    }
  }

  return (
    <Wrapper>
      <HeaderContent>
        <Title>Pedido de Compra de Serviço - Ch. de Criação N° {chaveCriacao}</Title>
        <ActionsContainer>
          <Button form="custom-form" type="submit" id="calcular" name="calcular" disabled={loadings.calcular}>
            {loadings.calcular ? "Calculando..." : "Calcular"}
          </Button>
          <Button form="custom-form" type="submit" id="aprovar" name="aprovar" disabled={loadings.calcular}>
            {comAprovador && "Desaprovar"}
            {!comAprovador ? loadings.calcular ? "Aprovando..." : "Aprovar" : ""}
          </Button>
          {/* <Button type="button" onClick={() => {}}>Imprimir</Button> */}
          <Button form="custom-form" type="submit" id="salvar" name="salvar" disabled={loadings.salvar}>
            {loadings.salvar ? "Salvando..." : "Salvar"}
          </Button>
          {/* <Button type="button" onClick={() => {}}>Faturar</Button> */}
        </ActionsContainer>
      </HeaderContent>
      <Content id="custom-form" onSubmit={handleSubmit}>
        <Container fluid style={{ width: "100%" }}>
        <Row>
          <Col md={7}>
            <Row>
              <h1>Cabeçalho</h1>
            </Row>
            <Row>
                <Col md={4}>
                  <DateInput
                    disabled={comAprovador}
                    label="Emissão" 
                    id="emissao" 
                    name="Emissão" 
                    value={payload.emissao}
                    onChange={({target: {value}}) => handleChangePayload('emissao', value)}
                    withHour={false}
                  />
                </Col>
                <Col md={4}>
                  <LookupInput
                    disabled={comAprovador}
                    initialState={initialLookups.Representante}
                    label="Vendedor"
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: "Vendedores",
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Representante", options)}
                  />
                </Col>
                <Col md={4}>
                  <TextInput 
                    label="Aprovador"
                    id="aprovador"
                    disabled
                    fixed
                    value={comAprovador ? aprovador ? aprovador : 'Pedido não aprovado' : 'Pedido não aprovado'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                 <LookupInput
                    disabled={comAprovador}
                    label="Local de Escrituração"
                    initialState={initialLookups.LocEscrituracao}
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Local de Escrituração'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("LocEscrituracao", options)}
                  />
                </Col>
                <Col md={6}>
                  <LookupInput
                    disabled={comAprovador}
                    label="Estabelecimento"
                    initialState={initialLookups.Estabelecimento}
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Estabelecimentos'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Estabelecimento", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LookupInput
                    disabled={comAprovador}
                    label="Pessoa"
                    endpoint="entidades"
                    initialState={initialLookups.Pessoa}
                    field="nome"
                    filters={{
                      classe: 'Pessoas'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Pessoa", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                <LookupInput
                    disabled={comAprovador}
                    label="Condição de Pagamento"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: 'Condições de Pagamento'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("CondicaoPagamento", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput 
                    disabled={comAprovador}
                    label="Observação"
                    id="observacao"
                    value={payload.observacao}
                    onChange={({target: {value}}) => handleChangePayload('observacao', value)}
                  />
                </Col>
              </Row>
            </Col>
          <Col md={5}>
            <CardTable 
              title="Detalhes"
              data={detalhesData(payload, lookups, observacaoToShow)}
            />
          </Col>
        </Row>
        <Row style={{marginTop: 32}}>
          <Col md={2.5}>
            <TextInput 
              label="Frete"
              id="frete"
              masked 
              money
              value={payload.frete}
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('frete', value)}
            />
          </Col>
          <Col md={2.5}>
            <TextInput 
              label="Seguro"
              id="seguro"
              masked 
              money
              value={payload.seguro}
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('seguro', value)}
            />
          </Col>
          <Col md={2.5}>
            <TextInput 
              label="Acréscimo"
              id="acrescimo"
              masked 
              money
              value={payload.acrescimo}
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('acrescimo', value)}
            />
          </Col>
          <Col md={2.5}>
           <TextInput 
              label="Desconto"
              id="desconto"
              masked 
              money
              value={payload.desconto}
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('desconto', value)}
            />
          </Col>
          <Col md={2}>
            <TextInput 
              label="Acessório"
              id="acessorio"
              value={payload.idAcessorio1}
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('idAcessorio1', value)}
            />
          </Col>
        </Row>
        <Table 
          actions={['insert', 'delete']}
          title="Itens"
          onChange={(result: any) => setItems(result)}
          fromForm
          withTotal
          isRecurso
          initialValue={initialItems}
          disabled={comAprovador}
          chaveCriacao={chaveCriacao}
          lookupsRegras={lookups}
          fixedValues={totais.items}
          fixedValue={{
            frete: payload.frete,
            seguro: payload.seguro,
            acrescimo: payload.acrescimo,
            desconto: payload.desconto,
          }}
          total={[
            {
              field: 'vrItem',
              value: `R$ ${totais.vrItemTotal}`,
            },
            {
              field: 'acrescimoItem',
              value: `R$ ${totais.acrescimoItemTotal}`,
            },
            {
              field: 'descontoItem',
              value: `R$ ${totais.descontoItemTotal}`,
            },
            {
              field: 'ipi',
              value: `R$ ${totais.ipiTotal}`,
            },
            {
              field: 'valorTotal',
              value: `R$ ${totais.valorTotal}`,
            },
          ]}
          cols={colunasItens}
        />
        <Table
          title="Títulos" 
          actions={['insert', 'delete']}
          fromForm
          withTotal
          isRecurso
          initialValue={initialTitulos}
          disabled={comAprovador}
          fixedValues={titulosFixedValues()}
          fixedValue={titulosFixedValue()}
          total={[
            {
              field: 'valorPrincipal',
              value: totais.valorTotal,
            },
            {
              field: 'valorCorrigido',
              value: totais.valorCorrigidoTotal,
            },
          ]}
          cols={colunasTitulos}
          onChange={handleChangeTitulos}
        />
        </Container>
      </Content>
    </Wrapper>
  );
}

export default ServiceOrder;