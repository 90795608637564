import Provider from "./config";

export const getFields = async (id: any) => {
  const params: any = {
    userId: id,
    classe: "alertas",
  };
  try {
    const response = await Provider.get("/evento/campos", { params });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar campos da tabela.");
  }
};

export const getAlerts = async (id: any, currentPage: any, filter?: any) => {
  const params: any = {
    userId: id,
    classe: "alertas",
    page: currentPage,
    alertActivated: false,
  };

  if (filter) {
    params["moeda"] = filter.coin;
  }

  try {
    const response = await Provider.get("evento", { params });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar alertas.");
  }
};

export const getValueAlert = async (payload: any) => {
  const params: any = {
    coin: payload.symbol,
  };
  if (payload.valor !== "") {
    params["valor"] = payload.valor;
  }
  if (payload.percValor !== "") {
    params["percValor"] = payload.percValor;
  }
  try {
    const response = await Provider.get("/evento/alert-fields-values", {
      params,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar valor do alerta.");
  }
};

export const getExecuted = async (id: any, currentPage: any, filter?: any) => {
  const params: any = {
    userId: id,
    classe: "alertas",
    page: currentPage,
    alertActivated: true,
  };

  if (filter) {
    params["moeda"] = filter.coin;
  }
  try {
    const response = await Provider.get("evento", { params });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao criar novo alerta.");
  }
};

export const editAlert = async (id: any, payload: any) => {
  const params: any = {
    classe: "alertas",
  };
  try {
    const response = await Provider.put(`evento/${id}`, payload);

    return response;
  } catch (err: any) {
    throw Error("Erro ao editar alerta.");
  }
};

export const deleteAlert = async (id: any, idUser: any) => {
  try {
    const response = await Provider.post(`evento/delete/`, {
      ids: id,
      userId: idUser,
    });

    return response;
  } catch (err: any) {
    throw Error("Erro ao excluir alerta");
  }
};
