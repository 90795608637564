import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";

import Home from "../screens/Home";
import Users from "../screens/Users";
import Template from "../components/Template";
import FormTemplate from "../components/Template/forms";
import SearchTemplate from "../components/Template/search";
import ReportTemplate from "../components/Template/report";
import ClassExplorer from "../screens/ClassExplorer";
import Report from "../screens/Report";

import SalesOrder from "../screens/SalesOrder";
import PurchaseServiceOrder from "../screens/PurchaseServiceOrder";
import SellingServiceOrder from "../screens/SellingServiceOrder";
import PurchaseOrder from "../screens/PurchaseOrder";
import Billing from "../screens/Billing";
import LightRelease from "../screens/LightRelease";
import Title from "../screens/Title";
import AccountPlan from "../screens/AccountPlan";
import AccountingEntry from "../screens/AccountingEntry";
import Inventory from "../screens/Inventory";

import { useAuth } from "../contexts/auth";

import { AuthRoutes, EditRoutes, InsertRoutes, ListRoutes } from "./routes";
import { pages } from "./pages";
import EspelhoPedido from "../components/EspelhoPedido";
import ReportExtract from "../screens/ReportExtact";
import FichaFuncionario from "../components/FichaFuncionario";
import OrderReport from "../components/OrderReport";
import Whitelist from "../screens/WhiteList";
import Order from "../screens/Orders";
import Financial from "../screens/Financial";
import User from "../screens/User";
import Support from "../screens/Support";
import Training from "../screens/Training";
import ConfirmEmail from "../screens/ForgotPassword/confirmEmail";
import ForgotPassword from "../screens/ForgotPassword";
import PriceAlert from "../screens/PriceAlert";
import Indications from "../screens/Indications";
import OrdersCommission from "../screens/OrdersCommission";
import Checkout from "../screens/Checkout";

interface IPrivateRoute {
  component: React.FC<any>;
  path: string;
  exact?: boolean;
  customProps?: any;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component,
  path,
  exact = false,
  customProps = {},
}) => {
  const { signed } = useAuth();

  const Component = component;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        signed ? (
          <Component {...props} {...customProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const renderCRUDLRoutes = () => {
  const routes = [
    <PrivateRoute
      key="home"
      exact
      path={AuthRoutes.home}
      component={Home}
      customProps={{
        title: "Página Inicial",
        endpoint: "/exchanges/account",
      }}
    />,
    <PrivateRoute
      key="white_list"
      exact
      path={AuthRoutes.white_list}
      component={Whitelist}
      customProps={{
        title: "White List",
        endpoint: "recursos",
        filters: {
          classe: "moedas",
        },
        multipleSelect: true,
      }}
    />,
    <PrivateRoute
      key="pedidos-indicacoes"
      exact
      path={AuthRoutes.orders_commission}
      component={OrdersCommission}
      customProps={{
        title: "Pedidos Comissão",
        endpoint: "pedidos",
        filters: {
          classe: "Prov Rec Comissões",
        },
        multipleSelect: true,
      }}
    />,
    <PrivateRoute
      key="user"
      exact
      path={AuthRoutes.user}
      component={User}
      customProps={{
        endpoint: "entidades",
        filters: {
          classe: "Usuários",
        },
        multipleSelect: true,
      }}
    />,
    <PrivateRoute
      key="users"
      exact
      path={AuthRoutes.users}
      component={Users}
    />,
    <PrivateRoute
      key="class"
      exact
      path={AuthRoutes.classes_explorer}
      component={ClassExplorer}
    />,
    <PrivateRoute
      key="insert_sales_order"
      exact
      path={AuthRoutes.sales_order}
      component={SalesOrder}
    />,
    <PrivateRoute
      key="edit_sales_order"
      exact
      path={AuthRoutes.edit_sales_order}
      component={SalesOrder}
    />,
    <PrivateRoute
      key="insert_purchase_service_order"
      exact
      path={AuthRoutes.purchase_service_order}
      component={PurchaseServiceOrder}
    />,
    <PrivateRoute
      key="insert_selling_service_order"
      exact
      path={AuthRoutes.selling_service_order}
      component={SellingServiceOrder}
    />,
    // <PrivateRoute key="insert_buying_order" exact path={AuthRoutes.purchase_order} component={Billing} />,
    <PrivateRoute
      exact
      key="espelho-pedido-saida"
      path={AuthRoutes.mirror_sales_order}
      component={EspelhoPedido}
    />,
    <PrivateRoute
      exact
      key="espelho-pedido-entrada"
      path={AuthRoutes.mirror_purchase_order}
      component={EspelhoPedido}
    />,
    <PrivateRoute
      exact
      key="espelho-pedido-compra-servico"
      path={AuthRoutes.mirror_purchase_service_order}
      component={EspelhoPedido}
    />,
    <PrivateRoute
      exact
      key="espelho-pedido-venda-servico"
      path={AuthRoutes.mirror_selling_service_order}
      component={EspelhoPedido}
    />,
    <PrivateRoute
      exact
      key="result_sales_order"
      path={AuthRoutes.result_sales_order}
      component={Template}
      customProps={{
        title: "Resultados da busca de pedidos de saída",
        path: AuthRoutes.result_sales_order,
        pullOrderPath: InsertRoutes.insert_selling_order,
        multipleSelect: true,
        actions: [
          {
            id: "pullOrder",
            name: "Baixar pedido",
            type: "global",
            onClick: null,
          },
          {
            id: "espelhoPedido",
            name: "Espelho de Pedido",
            type: "specific",
            onClick: null,
          },
          {
            id: "edit",
            name: "Editar",
            type: "specific",
            onClick: null,
          },
          {
            id: "remove",
            name: "Excluir",
            type: "specific",
            onClick: null,
          },
        ],
        endpoint: "pedidos",
        filters: {
          classe: "Rendas",
        },
      }}
    />,
    <PrivateRoute
      exact
      key="result_purchase_service_order"
      path={AuthRoutes.result_purchase_service_order}
      component={Template}
      customProps={{
        title: "Resultados da busca de pedidos de compra de serviço",
        path: AuthRoutes.result_purchase_service_order,
        pullOrderPath: InsertRoutes.insert_purchase_service_order,
        actions: [
          {
            id: "pullOrder",
            name: "Baixar pedido",
            type: "global",
            onClick: null,
          },
          {
            id: "edit",
            name: "Editar",
            type: "specific",
            onClick: null,
          },
          {
            id: "remove",
            name: "Excluir",
            type: "specific",
            onClick: null,
          },
        ],
        endpoint: "pedidos",
        filters: {
          classe: "Gastos",
        },
      }}
    />,
    <PrivateRoute
      exact
      key="result_selling_service_order"
      path={AuthRoutes.result_selling_service_order}
      component={Template}
      customProps={{
        title: "Resultados da busca de pedidos de venda de serviço",
        path: AuthRoutes.result_selling_service_order,
        pullOrderPath: InsertRoutes.insert_selling_service_order,
        actions: [
          {
            id: "pullOrder",
            name: "Baixar pedido",
            type: "global",
            onClick: null,
          },
          {
            id: "edit",
            name: "Editar",
            type: "specific",
            onClick: null,
          },
          {
            id: "remove",
            name: "Excluir",
            type: "specific",
            onClick: null,
          },
        ],
        endpoint: "pedidos",
        filters: {
          classe: "Rendas",
        },
      }}
    />,
    <PrivateRoute
      exact
      key="result_purchase_order"
      path={AuthRoutes.result_purchase_order}
      component={Template}
      customProps={{
        title: "Resultados da busca de pedidos de entrada",
        path: AuthRoutes.result_purchase_order,
        actions: [
          {
            id: "espelhoPedido",
            name: "Espelho de Pedido",
            type: "specific",
            onClick: null,
          },
          {
            id: "edit",
            name: "Editar",
            type: "specific",
            onClick: null,
          },
          {
            id: "remove",
            name: "Excluir",
            type: "specific",
            onClick: null,
          },
        ],
        endpoint: "pedidos",
        filters: {
          classe: "Gastos",
        },
      }}
    />,
    <PrivateRoute
      exact
      key="stock_balance"
      path={AuthRoutes.stock_balance}
      component={ReportTemplate}
      customProps={{
        title: "Saldo de Estoque",
        path: AuthRoutes.stock_balance,
        endpoint: "movdepos",
        filters: {
          report: true,
        },
      }}
    />,
    <PrivateRoute
      exact
      key="stock_balance_report"
      path={AuthRoutes.stock_balance_report}
      component={Report}
      customProps={{
        title: "Saldo de Estoque",
      }}
    />,
    <PrivateRoute
      exact
      key="extract_balance"
      path={AuthRoutes.extract_balance}
      component={ReportTemplate}
      customProps={{
        title: "Extrato de Estoque",
        path: AuthRoutes.extract_balance,
        endpoint: "movdepos",
        filters: {
          report: true,
        },
      }}
    />,
    <PrivateRoute
      exact
      key="stock_extract_balance_report"
      path={AuthRoutes.stock_extract_balance_report}
      component={ReportExtract}
      customProps={{
        title: "Extrato de Estoque",
      }}
    />,
    <PrivateRoute
      exact
      key="ficha-funcionario"
      path={AuthRoutes.employee_file}
      component={FichaFuncionario}
    />,
    <PrivateRoute
      key="order_down"
      exact
      path={AuthRoutes.purchase_order}
      component={PurchaseOrder}
    />,
    <PrivateRoute
      key="light_release"
      exact
      path={AuthRoutes.light_release}
      component={SalesOrder}
      customProps={{ baixaAutom: true }}
    />,
    <PrivateRoute
      key="title"
      exact
      path={AuthRoutes.title}
      component={Title}
    />,
    <PrivateRoute
      key="accounting_plan"
      exact
      path={AuthRoutes.accounting_plan}
      component={AccountPlan}
    />,
    <PrivateRoute
      key="accounting_entry"
      exact
      path={AuthRoutes.accounting_entry}
      component={AccountingEntry}
    />,
    <PrivateRoute
      key="inventory"
      exact
      path={AuthRoutes.inventory}
      component={Inventory}
    />,
    <PrivateRoute
      key="order_report"
      exact
      path={AuthRoutes.order_report}
      component={OrderReport}
    />,
    <PrivateRoute
      key="open_orders"
      exact
      path={AuthRoutes.open_orders}
      component={Order}
      customProps={{
        title: "Ordens",
        endpoint: "pedidos",
        filters: {
          classe: "V Crypto P Invest",
        },
        multipleSelect: true,
      }}
    />,
    <PrivateRoute
      key="price_alert"
      exact
      path={AuthRoutes.price_alert}
      component={PriceAlert}
      customProps={{
        title: "Alerta de Preços",
        endpoint: "/exchanges/account/binance",
        filters: {
          classe: "alertas",
        },
        multipleSelect: true,
      }}
    />,
    <PrivateRoute
      key="indications"
      exact
      path={AuthRoutes.indications}
      component={Indications}
      customProps={{
        title: "Painel de Indicação",
        endpoint: "/entidades",
        filters: {
          classe: "indicados",
        },
        radioSelect: true,
      }}
    />,
    <PrivateRoute
      key="financial"
      exact
      path={AuthRoutes.financial}
      component={Financial}
      customProps={{
        title: "Financeiro",
        endpoint: "titulos",
        filters: {
          classe: "Tit Rec",
        },
      }}
    />,
    <PrivateRoute
      key="support"
      exact
      path={AuthRoutes.support}
      component={Support}
      customProps={{
        title: "Suporte",
        endpoint: "/support",
      }}
    />,
    <PrivateRoute
      key="training"
      exact
      path={AuthRoutes.training}
      component={Training}
      customProps={{
        title: "Treinamento",
        endpoint: "tabela/",
        filters: {
          classe: "Vídeos de Treinamento",
        },
      }}
    />,
    <PrivateRoute
      key="confirm_email"
      exact
      path={AuthRoutes.confirm_email}
      component={ConfirmEmail}
      customProps={{
        endpoint: "",
        filters: {
          classe: "",
        },
      }}
    />,
    <PrivateRoute
      key="edit_password"
      exact
      path={EditRoutes.edit_password}
      component={ForgotPassword}
    />,
    <PrivateRoute 
      key="checkout"
      exact
      path={AuthRoutes.checkout}
      component={Checkout}
    />
  ];

  for (const page of pages) {
    const {
      key,
      labels,
      endpoint,
      filters,
      customFields,
      editCustomFields,
      startWithSearch,
    } = page;
    const insertKey: keyof typeof InsertRoutes = `insert_${key}`;
    const editKey: keyof typeof EditRoutes = `edit_${key}`;

    if (startWithSearch) {
      routes.push(
        <PrivateRoute
          exact
          key={key}
          path={ListRoutes[key]}
          component={SearchTemplate}
          customProps={{
            title: labels.list,
            actions: [
              {
                id: "insert",
                name: "Novo pedido",
                type: "global",
                onClick: InsertRoutes[insertKey],
              },
              {
                id: "insert",
                name: "Novo com baixa automática",
                type: "global",
                onClick: InsertRoutes[insertKey],
                params: {
                  baixaAutomatica: true,
                },
              },
            ],
            path: ListRoutes[key],
            endpoint,
            filters,
            customFields,
            editCustomFields,
          }}
        />
      );
    } else {
      let actions = [
        {
          id: "insert",
          name: "Inserir",
          type: "global",
          onClick: null,
        },
        {
          id: "edit",
          name: "Editar",
          type: "specific",
          onClick: null,
        },
        {
          id: "remove",
          name: "Excluir",
          type: "specific",
          onClick: null,
        },
      ];
      if (customFields.insert && customFields.insert === false) {
        actions = actions.filter((action) => action.id !== "insert");
      }

      let _customFields = customFields.actions || [];
      routes.push(
        <PrivateRoute
          exact
          key={key}
          path={ListRoutes[key]}
          component={Template}
          customProps={{
            title: labels.list,
            path: ListRoutes[key],
            actions: [
              {
                id: "insert",
                name: "Inserir",
                type: "global",
                onClick: null,
              },
              ..._customFields,
              {
                id: "edit",
                name: "Editar",
                type: "specific",
                onClick: null,
              },
              {
                id: "remove",
                name: "Excluir",
                type: "specific",
                onClick: null,
              },
            ],
            endpoint,
            filters,
            customFields,
          }}
        />
      );
      routes.push(
        <PrivateRoute
          exact
          key={insertKey}
          path={InsertRoutes[insertKey]}
          component={FormTemplate}
          customProps={{
            title: labels.insert,
            endpoint,
            filters,
            customFields,
          }}
        />
      );
      routes.push(
        <PrivateRoute
          exact
          key={editKey}
          path={EditRoutes[editKey]}
          component={FormTemplate}
          customProps={{
            title: labels.edit,
            endpoint,
            filters,
            customFields,
            editCustomFields,
          }}
        />
      );
    }
  }
  return routes;
};

const Routes: React.FC<any> = ({ initialPath, ...props }) => {
  const history = useHistory();

  useEffect(() => {
    if (initialPath) {
      history.push(initialPath);
    }
  }, [initialPath]);

  return <Switch>{renderCRUDLRoutes()}</Switch>;
};

export default Routes;
