import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import TableIndicados, { INivel } from "../../components/TableIndicados";
import Toast from "../../components/Toast";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { BASE, ID_CLASSE_USUARIOS_PREMIUM, ID_LOC_ESCRIT_DEVARI } from "../../environment";
import useFetch from "../../hooks/useFetch";
import {
  activeTrialForUser,
  getIndicationsClosed,
  getIndicationsOpen,
} from "../../services/indications";
import { copyToClipboard } from "../../utils/copyToLink";
import { orderCols } from "../../utils/form";
import { Title } from "../Home/styles";
import {
  DivCard,
  Footer,
  PaginateContainer,
  RowButtonOrder,
  SelectedContainer,
} from "../Orders/style";
import { Button, Container, Row, Content, Valores } from "./styles";
import { useFormModal } from "../../contexts/formModal";
import ModalTrialUser from "./modalTrialUser";
import { ID_USER_ADMIN } from "../../utils/entidades";
import { getSaldoIndicacoes } from "../../services/user";
import { formatMoney } from "../../utils/format";
import { solicitaSaqueSaldo } from "../../services/pedidos";
import { IRepresentative } from "../Orders";
import { getSearchEntities } from "../../services/entidade";
import { IEntidade } from "../../services/auth";
import { AiOutlineLoading } from "react-icons/ai";

interface IIndications {
  title: string;
  endpoint: string;
  filters: Object;
  radioSelect: boolean;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

interface IFilter {
  idRepresentante: string;
  inativo: string;
}

// interface IValues {
//   totalPedido: number;
//   totalReceber: number;
// }

const Indications: React.FC<IIndications> = ({
  title,
  endpoint,
  filters,
  radioSelect,
}) => {
  const { user } = useAuth();
  const [Base, setBase] = useState<string>("");
  const { openModal, closeModal } = useFormModal();
  const provider = useFetch({ endpoint });
  const providerFields = useFetch({ endpoint: `${endpoint}/campos` });
  const [indicationsActive, setIndicationsActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [indicados, setIndicados] = useState<IResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [indicationsExecuted, setIndicationsExecuted] = useState<INivel | null>(null);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [saldoIndicacoes, setSaldoIndicacoes] = useState<any>(null);
  const [representative, setRepresentative] = useState<IRepresentative[]>([]);
  const [searchRepresentative, setSearchRepresentative] = useState<string>("");
  const [filterOrders, setFilterOrders] = useState<IFilter>({
    idRepresentante: "idRepresentante",
    inativo: "inativo",
  })
  // const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  // const [indicationsPending, setIndicationsPending] =
  //   useState<IResponse | null>(null);
  // const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  // const [receivable, setReceivable] = useState<IValues>();
  // const [received, setReceived] = useState();
  // const [colsIndicationsClosed, setColsIndicationsClosed] = useState<
  //   Array<IField>
  // >([]);
  // const [growing, setGrowing] = useState({
  //   item: "",
  //   asc: "",
  // });

  // useEffect(() => {
  //   getTableFields();
  // }, [quantityPerPage, currentPage]);

  useEffect(() => {
    if (filterOrders.idRepresentante !== "idRepresentante" || filterOrders.inativo !== "inativo") {
      getTableFields(filterOrders)
    } else {
      getTableFields()
    }
  }, [filterOrders, quantityPerPage, currentPage])

  useEffect(() => {
    if (searchRepresentative?.length < 3) return;
    let timer = setTimeout(() => {
      searchEntities(searchRepresentative);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchRepresentative])

  useEffect(() => {
    getValueIndications();
    getSaldo();
  }, []);

  useEffect(() => {
    if (BASE === "prod") {
      setBase("https://crypto.devari.com.br/#/checkout");
    } else if (BASE === "homolog") {
      setBase("https://crypto.devari.com.br/#/checkout");
    } else {
      setBase("http://localhost:3000/#/checkout");
    }
  }, []);

  const getTableFields = async (filter?: IFilter) => {
    try {
      const id = user?.id;
      if (id) {
        setLoading(true);

        let _filter: any = {}
        
        if (filter) {
          if (filter?.idRepresentante !== "idRepresentante") {
            _filter["id"] = filter?.idRepresentante
          }
          if (filter?.inativo !== "inativo") {
            _filter["inativo"] = filter?.inativo === "S" ? true : false
          }
        }

        const resultFields = await providerFields.get(filters);
        if (resultFields) {
          let filterFields = resultFields.sort(orderCols);
          //remove o campo id caso nao seja admin ou usuario premio
          if(user && user.id !== ID_USER_ADMIN && user.idClasse !== ID_CLASSE_USUARIOS_PREMIUM) {
            filterFields = filterFields.filter((item:any) => item.campo !== 'id');
          }
          setCols(filterFields);

          const resultUsers = await provider.get({
            page: currentPage,
            idClasse: "61cd2730ad47e0a02eab67d8", //classe Clientes
            classe: "Clientes",
            idIndicador: id,
            take: quantityPerPage,
            // calculaGanhos: true,
            orderBy: { criadoEm: 'desc' },
            ..._filter
          });
          if (resultUsers) {
            setIndicados(resultUsers);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Erro: ", error);
    }
  };

  const getValueIndications = async () => {
    try {
      const id = user?.id;
      if (id) {
        const result = await getIndicationsClosed(currentPageClosed, id);

        if (result) {
          setIndicationsExecuted(result);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getSaldo = async () => {
    try {
      let id = user?.id;
      if (id === ID_USER_ADMIN) {
        id = ID_LOC_ESCRIT_DEVARI
      }
      if (id) {
        const result = await getSaldoIndicacoes(id);

        if (result) {
          setSaldoIndicacoes(result);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const searchEntities = async (filter: string) => {
    try {
      const result = await getSearchEntities(filter)
      if (result) {
        const _result = result.map((item: IEntidade) => {
          return {
            id: item.id,
            nome: item.nome
          }
        })

        setRepresentative(_result)
      }
    } catch (error) {
      console.error("Erro: ", error)
    }
  }

  const solicitaSaque = async () => {
    if(user?.id) {
      setLoading(true);
      try {
        await solicitaSaqueSaldo(user.id);
        Toast.show("O saque Solicitado! \n Isso pode leva até 24Hrs.", "success")
      } catch (error) {
        console.error("Error: ", error);
      }
      setLoading(false);
    }
  }

  const handleChangePage = (page: number) => {
    if (indicationsActive) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const handleOpenModal = (id:any) => {
    const item = indicados?.docs.find(item => item.id === id);
    openModal({
      type: 'confirm',
      title: `Habilitar Trial`,
      children: () => <ModalTrialUser 
      user={item}
      callback={closeModal}
      />
    });
  }

  const handlePaymentLink = () => {
    const url = `${Base}/?id=${user?.id}`;
    copyToClipboard(url);
    return Toast.show("O link foi copiado.", "success");
  };

  // const handleOrderCols = (value: string) => {
  //   if (value === "Par" || value === "Quantidade") {
  //     if (growing.item === value) {
  //       setGrowing({
  //         ...growing,
  //         item: growing.asc == "false" ? "" : value,
  //         asc: growing.asc == "false" ? "" : "false",
  //       });
  //     } else {
  //       setGrowing({
  //         ...growing,
  //         item: value,
  //         asc: "true",
  //       });
  //     }
  //   }
  // };
  
  // const handlePayment = async () => {
  //   setLoading(true);
  //   const ids = itensSelected();
  //   const idUser = user?.id;

  //   if (ids && idUser) {
  //     try {
  //       // const result = await
  //     } catch (error) {
  //       console.error("Erro: ", error);
  //     }
  //     setLoading(false);
  //   }
  // };

  // const itensSelected = () => {
  //   const itens: any = [];

  //   if (indicationsPending) {
  //     let docs = [...indicationsPending?.docs];
  //     let selection = [...selectedIndexes];

  //     selectedIndexes.map((item: any, index: any) => {
  //       if (item == true) {
  //         itens.push(docs[index].id);
  //         selection.splice(index, 1);
  //       }
  //     });

  //     itens.map((item: any) => {
  //       let itemIndex = docs.findIndex((i: any) => i === item);
  //       if (itemIndex >= 0) {
  //         docs.splice(itemIndex, 1);
  //       }
  //     });
  //   }

  //   if (itens.length > 0) {
  //     return itens;
  //   } else {
  //     Toast.show("Selecione o item que deseja excluir.");
  //   }
  // };

  // const handleFilter = (key: string, field: string) => {
  //   if (indicationsActive) {
  //     setFilterPending({ ...filterPending, [key]: field });
  //   } else {
  //     setFilterExecuted({ ...filterExecuted, [key]: field });
  //   }
  // };

  // function isTrue(item: any) {
  //   return item == false;
  // }

  const handleSearch = (key: string, value: string) => {
    const selected = representative.find(rep => rep.nome === value);
    if (key === "idRepresentante") {
      if (!value) {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: "idRepresentante"
        })
        setSearchRepresentative("")
      }
      if (selected) {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: selected.id
        })
        setSearchRepresentative(value)
      } else {
        setSearchRepresentative(value)
      }
    } else if (key === "inativo") {
      if (!value) {
        setFilterOrders({
          ...filterOrders,
          inativo: "inativo"
        })
      } else {
        setFilterOrders({
          ...filterOrders,
          inativo: value === "Inativo" ? "S" : "N"
        })
      }
    }
  }

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard>
        <Row className="link">
          <label>
            Link Indicação:{" "}
            <Button className="link" onClick={() => handlePaymentLink()}>
              Copiar Link
            </Button>
          </label>
          <Valores>
            <p>Total a Receber <span>{saldoIndicacoes ? formatMoney(saldoIndicacoes.total) : `R$ --`}</span></p>
            <p>Total Recebido <span>{saldoIndicacoes ? formatMoney(saldoIndicacoes.recebido) : `R$ --`}</span></p>
          </Valores>
        </Row>
      </DivCard>
      <DivCard>
        <TableIndicados indicados={indicationsExecuted} />
      </DivCard>

      <DivCard>
        <Row className="link" style={{justifyContent: "space-between"}}>
          <div className="filters">
            <label>Filtros:</label>
            <form autoComplete="off">
              <div>
                <input
                  list="representantes"
                  name="representative"
                  id="representative"
                  type="search"
                  autoComplete="chrome-off"
                  value={searchRepresentative}
                  onChange={(ev) => handleSearch("idRepresentante", ev.target.value)}
                  placeholder={filterOrders.idRepresentante.replace(
                    "idRepresentante",
                    "Representante"
                  )}
                />
                <datalist id="representantes">
                  {representative.length > 0
                    ? representative.map((item: IRepresentative) => (
                      <option value={item.nome} key={item.id}>{item.nome}</option>
                    ))
                    : null}
                </datalist>
              </div>
              <div>
                <select
                  onChange={(ev) => handleSearch("inativo", ev.target.value)}
                >
                  <option value="" selected>Status</option>
                  <option value="Ativo">Ativo</option>
                  <option value="Inativo">Inativo</option>
                </select>
              </div>
            </form>
          </div>
          <div>
            {loading ? (
              <span className="loading">
                <AiOutlineLoading style={{fontSize: 24}} /> &nbsp;
              </span>
            ): null}
            <Button
            className="link" 
            wide="180px"
            disabled={saldoIndicacoes && (saldoIndicacoes.total < 5 || loading)}
            onClick={() => solicitaSaque()}>
              Solicitar Saque
            </Button>
          </div>
        </Row>     
      </DivCard>
      <Content>
        <ListTable
          data={indicados ? indicados.docs : []}
          // multipleSelect={multipleSelect}
          fields={cols}
          // changeSelectedIndexes={setSelectedIndexes}
          onChangePage={handleChangePage}
          onClick={handleOpenModal}
          currentPage={currentPage}
          totalPages={indicados?.totalPages}
          count={indicados?.total}
          withFooter={false}
        />
      </Content>

      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={indicados ? indicados.totalPages : 1}
            previousLabel="< "            
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>

      {/* <DivCard>
        <RowButtonOrder jContent={"space-between"}>
          <div>
            <button
              className="buttonOrder"
              type="button"
              onClick={() => setIndicationsActive(true)}
            >
              <p className={indicationsActive ? "primary" : ""}>
                Pendentes
                {indicationsPending?.total
                  ? `(${indicationsPending.total})`
                  : "(0)"}
              </p>
            </button>
            <button
              className="buttonOrder"
              type="button"
              onClick={() => setIndicationsActive(false)}
            >
              <p className={!indicationsActive ? "primary" : ""}>
                Finalizadas
                {indicationsExecuted?.total
                  ? `(${indicationsExecuted.total})`
                  : "(0)"}
              </p>
            </button>
          </div>
          <Row>
            <div className="value-indication">
              <p>
                Total a Receber:{" "}
                <span>
                  {receivable && receivable.totalPedido
                    ? `$${receivable.totalPedido}`
                    : "$0"}
                </span>
              </p>
            </div>
            <div className="value-indication">
              <p>
                Total Recebido:{" "}
                <span>
                  {receivable && receivable.totalReceber
                    ? `$${receivable.totalReceber}`
                    : "$0"}
                </span>
              </p>
            </div>
          </Row>
        </RowButtonOrder>
      </DivCard>
      <DivCard>
        <Row>
          <p>Filtros</p>
          {indicationsActive ? (
            <form action="" autoComplete="off">
              <div>
                <input
                  type="search"
                  list="accountOpen"
                  name="myAccountOpen"
                  id="myAccountOpen"
                  autoComplete="chrome-off"
                  placeholder="Situação da Conta"
                />
                <datalist id="accountOpen">
                  <option value="Pendente Pagamento" />
                  <option value="Prazo Garantia (7 dias)" />
                  <option value="Cancelado" />
                  <option value="Efetivado" />
                </datalist>
              </div>
            </form>
          ) : (
            <form action="" autoComplete="off">
              <div>
                <input
                  type="search"
                  list="accountClosed"
                  name="myAccountClosed"
                  id="myAccountClosed"
                  autoComplete="chrome-off"
                  placeholder="Situação de Pagamento"
                  onChange={(ev) =>
                    handleFilter("statusAccount", ev.target.value)
                  }
                />
                <datalist id="accountClosed">
                  <option value="Pendente Saque" />
                  <option value="Cancelado" />
                  <option value="Efetivado" />
                </datalist>
              </div>
            </form>
          )}
          {!indicationsActive ? (
            <div>
              <Button wide={"auto"} disabled={selectedIndexes.every(isTrue)}>
                Solicitar Saque
              </Button>
            </div>
          ) : null}
        </Row>
      </DivCard>
      <Content>
        <ListTable
          radioSelect={indicationsActive ? false : radioSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={indicationsActive ? cols : colsIndicationsClosed}
          handleOrderCols={handleOrderCols}
          growing={growing}
          onChangePage={handleChangePage}
          currentPage={indicationsActive ? currentPage : currentPageClosed}
          data={
            indicationsActive && indicationsPending
              ? indicationsPending.docs
              : !indicationsActive && indicationsExecuted
              ? indicationsExecuted.docs
              : []
          }
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
        <Footer>
          <SelectedContainer>
            <p>Linhas por página: &nbsp;</p>
            <select
              name="quantityPerPage"
              id="quantityPerPage"
              onChange={({ target }) => handleQuantityPerPage(target.value)}
            >
              <option value="5">5</option>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </SelectedContainer>
          <PaginateContainer>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={({ selected }) => handleChangePage(selected)}
              pageRangeDisplayed={2}
              pageCount={
                indicationsActive && indicationsPending
                  ? indicationsPending.totalPages
                  : !indicationsActive && indicationsExecuted
                  ? indicationsExecuted.totalPages
                  : 1
              }
              previousLabel="< "
              renderOnZeroPageCount={undefined}
              activeClassName="active-page-item"
            />
          </PaginateContainer>
        </Footer>
      </Content> */}
    </Container>
  );
};

export default Indications;
