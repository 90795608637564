import styled from 'styled-components';

import ButtonLayout from '../Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -8px;
  margin-bottom: 16px;
  width: 100%;
`;

export const DivUpdate = styled.div`
  border: 2px solid var(--primary);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
  width: 70%;

  b{
    color: var(--primary-complementary-text-color);
    font-size: 24px;
    margin: 0 10px;
  }
`;

export const DivLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  white-space: nowrap;
`;

export const DivRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;

  button{
    width: 40%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const SearchInput = styled.input`
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  width: 10%;
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  font-weight: bold;

  & span {
    font-size: 14px;
    color: var(--muted);
  }
`;

export const Content = styled.div`
  background-color: var(--form-background);

  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  color: var(--white);
  overflow: auto;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;


  & > p {
    margin-bottom: 16px;
  }
`;

export const FormContent = styled.div`
  background-color: var(--form-background);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;
  padding: 16px;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const ActionsContainer = styled.div`
  background: var(--opacity-background);
  display: flex;
  gap: 16px;
  width: auto;

  & button {
    width: auto;
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 12px;
  padding: 10px;
`;

export const Form = styled.form`
  & > button {
    margin-top: 32px;
    font-size: 16px;
    padding: 0px;
  }
`;

export const Footer = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--opacity-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  
  width: 100%;

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;