import { LiHTMLAttributes } from 'react';
import styled from 'styled-components';

import SelectLayout from '../../components/Select';
import ButtonLayout from '../../components/Button';
import InputLayout from '../../components/Input';

interface IListItem extends LiHTMLAttributes<HTMLLIElement> {
  hasNodes: boolean;
  level: number;
}

export const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;

  position: relative;

  display: flex;
  flex-direction: column;

  h1 {
    width: 100%;
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: 200;
    margin-bottom: 10px;
  }
`;

export const ListGroup = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li<IListItem>`
  cursor: ${props => props.hasNodes ? 'pointer' : 'auto'};
  padding-left: ${props => props.level ? props.level * 1 + 1 : 0}em;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  font-size: 14px;
  text-align: left;
  text-transform: capitalize;

  & svg {
    margin-top: 2px;
    margin-left: 8px;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  transition: .2s;
  border-bottom: 1px solid var(--opacity-background);
  cursor: pointer;

  &:nth-child(even) {
    background-color: var(--opacity-background);
  }

  &:hover {
    opacity: .7;
  }
`;


export const Header = styled.div`
  background-color: var(--white);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--opacity-background);
`;

export const HeaderCell = styled.div`
  white-space: nowrap;
  color: var(--primary-text-color);
  letter-spacing: 1.5px;
  font-weight: 200;
  font-size: 12px;
  text-align: left;
  text-transform: capitalize;
  padding: 16px;
  text-transform: uppercase;
  transition: .2s;

  

  &:first-child {
    padding: 16px;
  }

  &:nth-child(2) {
    padding: 16px 8px;

    & button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &:nth-child(n+3):hover {
    background-color: var(--opacity-background);
    cursor: grab;
  }
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: var(--white);
`;

export const ActionContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const Select = styled(SelectLayout)`
`;

export const Button = styled(ButtonLayout)`
  width: 10%;
  border-radius: 2px;
  margin-left: 8px;
`;

export const Input = styled(InputLayout)`
  margin-bottom: 16px;
`;