import React, { useEffect, useState } from "react";
import { ContainerModal } from "./style";
import ListTable, { IField } from "../../components/ListTable";
import { AiOutlineLoading } from "react-icons/ai";
import { IResponse } from "../Orders";
import { Button } from "../Users/styles";
import { formatDate, formatMoney } from "../../utils/format";
import { CSVLink } from "react-csv";

interface IModalOrders {
  loadingOrders: boolean;
  colsOrders: IField[];
  orders: IResponse | null;
  totalLucroLiquido?: number;
  totalComissao?: number;
  submit: () => void;
}

interface ICommissionOrderItems {
  items: IItems[];
  totalLucroLiquido?: string | null;
  totalComissao?: string | null;
}

interface IItems {
  chCriacaoMae: number;
  aprovacao?: string | null;
  moeda?: string;
  lucroLiquido?: string | null;
  comissao?: string | null;
}

const column = [
  {
    key: "chCriacaoMae",
    label: "Order ID",
    _props: { scope: "col" },
  },
  {
    key: "aprovacao",
    label: "Dt. Fechamento",
    _props: { scope: "col" },
  },
  {
    key: "moeda",
    label: "Moeda",
    _props: { scope: "col" },
  },
  {
    key: "lucroLiquido",
    label: "Lucro",
    _props: { scope: "col" },
  },
  {
    key: "comissao",
    label: "Comissão",
    _props: { scope: "col" },
  }
];

const ModalOrders: React.FC<IModalOrders> = ({
  loadingOrders,
  colsOrders,
  orders,
  totalLucroLiquido,
  totalComissao,
  submit = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [commissionOrderItems, setCommissionOrderItems] =
    useState<ICommissionOrderItems | null>();

  useEffect(() => {
    if (orders && orders.docs && totalLucroLiquido && totalComissao) {
      let lucro: string | null = "";
      let comissao: string | null = "";
      const itens: IItems[] = orders.docs.map((item) => {
        return {
          chCriacaoMae: item.chCriacaoMae,
          aprovacao: item.aprovacao ? formatDate(item.aprovacao) : null,
          moeda: item.moeda,
          lucroLiquido: item.lucroLiquido
            ? formatMoney(item.lucroLiquido, "en-US", "USD")
            : null,
          comissao: item.comissao
            ? formatMoney(item.comissao, "en-US", "USD")
            : null,
        };
      });

      if (totalLucroLiquido) {
        lucro = totalLucroLiquido
          ? formatMoney(totalLucroLiquido, "en-US", "USD")
          : null;
      }

      if (totalComissao) {
        comissao = totalComissao
          ? formatMoney(totalComissao, "en-US", "USD")
          : null;
      }

      setCommissionOrderItems({
        items: itens,
        totalLucroLiquido: lucro,
        totalComissao: comissao,
      });
    }
  }, [orders, totalLucroLiquido, totalComissao]);

  const exportData = [
    ...commissionOrderItems?.items || [],
    {
      chCriacaoMae: "Totais:",
      lucroLiquido: commissionOrderItems?.totalLucroLiquido || "",
      comissao: commissionOrderItems?.totalComissao || "",
    },
  ];

  const handleSubmit = () => {
    submit();
    setLoading(true);
  };

  return (
    <ContainerModal>
      <h2>Listagem de Ordens</h2>
      <div>
        <p style={{ marginRight: 20 }}>
          Total lucro Liquido:{" "}
          <span style={{ color: "var(--green)", fontWeight: "bold" }}>
            {totalLucroLiquido
              ? formatMoney(totalLucroLiquido, "en-US", "USD")
              : "$ 0,00"}
          </span>
        </p>
        <p>
          Total comissão:{" "}
          <span style={{ color: "var(--green)", fontWeight: "bold" }}>
            {totalComissao
              ? formatMoney(totalComissao, "en-US", "USD")
              : "$ 0,00"}
          </span>
        </p>
        {loading ? (
          <span className="loading">
            <AiOutlineLoading style={{ fontSize: 30 }} /> &nbsp;
          </span>
        ) : null}
        <Button style={{ width: "fit-content" }}>
          <CSVLink
            className="btn btn-success text-white"
            data={exportData}
            headers={column}
            filename="Pedidos.csv"
            target="_blank"
            style={{
              color: "gray",
              fontWeight: 500,
              width: "fit-content",
              textDecoration: "none",
            }}
          >
            Exportar CSV
          </CSVLink>
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={loading}
          style={{
            color: "gray",
            fontWeight: 500,
            width: 100,
          }}
        >
          Gerar PIX
        </Button>
      </div>
      <ListTable
        fields={colsOrders}
        data={orders ? orders.docs : []}
        totalPages={orders ? orders.totalPages : 1}
        count={orders ? orders.total : 0}
        withFooter={false}
      />
      <div>
        <p style={{ marginRight: 20 }}>
          Total lucro Liquido:{" "}
          <span style={{ color: "var(--green)", fontWeight: "bold" }}>
            {totalLucroLiquido
              ? formatMoney(totalLucroLiquido, "en-US", "USD")
              : "$ 0,00"}
          </span>
        </p>
        <p>
          Total comissão:{" "}
          <span style={{ color: "var(--green)", fontWeight: "bold" }}>
            {totalComissao
              ? formatMoney(totalComissao, "en-US", "USD")
              : "$ 0,00"}
          </span>
        </p>
        {loading ? (
          <span className="loading">
            <AiOutlineLoading style={{ fontSize: 30 }} /> &nbsp;
          </span>
        ) : null}
        <Button style={{ width: "fit-content" }}>
          <CSVLink
            className="btn btn-success text-white"
            data={exportData}
            headers={column}
            filename="Pedidos.csv"
            target="_blank"
            style={{
              color: "gray",
              fontWeight: 500,
              width: "fit-content",
              textDecoration: "none",
            }}
          >
            Exportar CSV
          </CSVLink>
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={loading}
          style={{
            color: "gray",
            fontWeight: 500,
            width: 100,
          }}
        >
          Gerar PIX
        </Button>
      </div>
    </ContainerModal>
  );
};

export default ModalOrders;
