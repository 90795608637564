import styled from 'styled-components';
import { ILeftSidebarProps } from '../LeftSidebar'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--background);
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  max-height: calc(100% - 50px);
  transition: .2s;
  overflow: hidden;
  position: relative;

  @media (max-width: 599px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const RoutesContainer = styled.div<ILeftSidebarProps>`
  width: ${props => props.isExpanded ? '95%' : '100%'};
  height: calc(100% - 50px);
  margin-left: auto;
  padding: 16px;
  /* transition: .2s; */
  overflow: auto;

  /* ${props => props.isExpanded && 'position: absolute; left: 4%; width: 96%;'} */

  @media (max-width: 599px) {
    padding: 1em 2em 4em 2em;
    width: ${props => props.isExpanded && '100%'};
  }
`;


export const OpacityBackground = styled.div`
  background: var(--background);
  height: 100%;
  opacity: .8;
  position: absolute;
  /* left: 900px; */
  /* animation: slide 0.8s forwards; */
  /* -webkit-animation: slide 0.8s forwards; */
  width: 100%;
  z-index: 1;

  /* @keyframes slide {
    100% { left: 0 }
  }

  @-webkit-keyframes slide {
    100% { left: 0 }
  } */
`;