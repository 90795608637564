import React, { createElement, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formatDate, formatMoney } from '../../utils/format';
import {
  BodyOrder,
  Container,
  ContentHeader,
  DivHeader,
  DivRow,
  DivLeft,
  DivRight,
  Logo,
  DivItems,
  LogoFooter,
  Main,
  ButtonActive,
  Row,
  Column,
} from './style';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { getReport } from '../../services/report';
import { BsChevronDown } from 'react-icons/bs'
import { group } from 'console';

const _deposito_central = [
  {
    chave: '12345',
    data: '02/03/2022',
    hora: '09:00',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'NFe 001',
    classe_pedido: 'C p Comercializ.',
    fornecedor: 'Sodine',
    entrada_qtd: 10,
    saida_qtd: '',
    saldo_qtd: 10,
    entrada_custo: 1.10,
    saida_custo: '',
    saldo_custo: 11.00,
    custo_medio: 1.10
  },
  {
    chave: '12345',
    data: '02/03/2022',
    hora: '10:15',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'NFe 036',
    classe_pedido: 'C p Comercializ.',
    fornecedor: 'Casa Bacha',
    entrada_qtd: 9,
    saida_qtd: '',
    saldo_qtd: 19,
    entrada_custo: 0.90,
    saida_custo: '',
    saldo_custo: 19.10,
    custo_medio: 1.01
  },
  {
    chave: '12345',
    data: '15/03/2022',
    hora: '10:30',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'NFe 067',
    classe_pedido: 'C p Comercializ.',
    fornecedor: 'CDMax',
    entrada_qtd: 5,
    saida_qtd: '',
    saldo_qtd: 24,
    entrada_custo: 0.86,
    saida_custo: '',
    saldo_custo: 23.40,
    custo_medio: 0.975
  },
  {
    chave: '12345',
    data: '16/03/2022',
    hora: '12:15',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'NFe 001',
    classe_pedido: 'V Adq Ter p Cons',
    fornecedor: '',
    entrada_qtd: '',
    saida_qtd: -10,
    saldo_qtd: 14,
    entrada_custo: '',
    saida_custo: -0.975,
    saldo_custo: 13.65,
    custo_medio: 0.975
  },
  {
    chave: '12345',
    data: '17/03/2022',
    hora: '10:15',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'RQ 025',
    classe_pedido: 'Saída Requisição',
    fornecedor: '',
    entrada_qtd: '',
    saida_qtd: -1,
    saldo_qtd: 13,
    entrada_custo: '',
    saida_custo: 0.975,
    saldo_custo: 12.675,
    custo_medio: 0.975
  },
]

const _deposito_demonstracao = [
  {
    chave: '12345',
    data: '17/03/2022',
    hora: '11:15',
    classe: 'Mov. Dep.',
    recurso: 'Caneta Bic Azul ponta grossa',
    tipo: 'RQ 025',
    classe_pedido: 'Requisição',
    fornecedor: '',
    entrada_qtd: 1,
    saida_qtd: '',
    saldo_qtd: 1,
    entrada_custo: 0.975,
    saida_custo: '',
    saldo_custo: 0.975,
    custo_medio: 0.975
  },
]

const ReportExtract: React.FC = () => {

  const location = useLocation<any>();
  const [request, setRequest] = useState<any>(null);
  const componentRef = useRef(null);
  const [activeState, setActiveState] = useState(false);
  const [recurso, setRecurso] = useState<string>();
  const [entidade, setEntidade] = useState<string>();
  const [lote, setLote] = useState<string>();
  const [agrup, setAgrup] = useState<any>({ bundler: '', index: '', indexAgrup: '' });
  const [reports, setReports] = useState<any>([]);
  const [deposito, setDeposito] = useState<string>();
  const date = new Date();

  useEffect(() => {
    if (location.state) {
      const details = location.state.filtersToGet
      Array.isArray(details.agrupamento) && details.agrupamento.find((item: string) => setAgrup({ ...agrup, bundler: item, index: '' }))
      Array.isArray(details.agrupamento) && details.agrupamento.map((item: string) =>
        item === 'Recurso' ? setRecurso(item) : item === 'Núcleo' ? setEntidade(item) : setLote(item)
      )

      getReports(details)
    }
  }, [])

  async function getReports(details: any) {
    try {
      const result = await getReport(details)

      if (result) {
        if (Array.isArray(result)) {
          setReports(result)
          setDeposito(result[0].Entidade)
        } else {
          const resultObject = treatObject(result)
          setReports(resultObject)
        }
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  function treatObject(object: any) {
    const keys = Object.keys(object)
    let arrayObject: any = []
    for (const key of keys) {
      const base: any = {
        Agrupador: key,
        custo: null,
        quantidade: null,
        isGroup: true,
        active: false,
        children: []
      };
      const value = object[key]
      if (Array.isArray(value)) {
        base.children = value;
        value[0].Entidade && setEntidade(value[0].Entidade);
      } else {
        const objectChild: any = treatObject(value);
        base.children = [...objectChild];

      }
      arrayObject.push(base);
    }
    return arrayObject;
  }

  function childrenList(child: any, agrupador: string | null) {
    const keys = child

    let arrayChildren: any = [];
    for (const key of keys) {

      if (key.children) {
        return (
          keys.length ? keys.map((item: any, index: any) => (
            <>
              <ButtonActive onClick={() => handleActive(item.Agrupador, index)} style={{ marginLeft: 10 }}>{item.Agrupador === agrup.bundler ? "-" : '+'} {item.Agrupador}</ButtonActive>
              {item.active == true && childrenList(item.children, item.Agrupador ? item.Agrupador : null)}
            </>

          ))
            : <ButtonActive onClick={() => handleActive(key.Agrupador, 0)}>{key[0] == agrup.indexGrup ? "-" : '+'} {key.Agrupador}</ButtonActive>
        )
      } else {
        return (
          <>
            {
              keys ?
                <table>
                  <tr>
                    {agrupador != key.Entidade && <th>Depósito</th>}
                    {agrupador != key.Recurso && <th>Recurso</th>}
                    <th>{key.quantidade && 'Quantidade'}</th>
                    <th>{key.custo && 'Custo'}</th>
                  </tr>
                  {keys.map((item: any) => (
                    <tr>
                      {agrupador != item.Entidade && <td>{item.Entidade}</td>}
                      {agrupador != item.Recurso && <td>{item.Recurso}</td>}
                      <td className={item.quantidade - 0 ? "right red" : "right"}>{item.quantidade}</td>
                      <td className='right'>{item.custo ? formatMoney(item.custo) : ''}</td>
                    </tr>
                  ))}
                </table>
                : null
            }
          </>
        )
      }

    }
    return arrayChildren;
  }

  function handleActive(value: any | null, index: number) {

    const cloneReports = [...reports]
    cloneReports[index].active = !cloneReports[index].active

    setReports(cloneReports)
    setAgrup({
      ...agrup,
      bundler: value,
      indexGrup: index === agrup.indexGrup ? '' : index
    });
  }

  function handleActiveChild(item: any, index: any) {
    setAgrup({
      ...agrup,
      bundler: item === agrup.bundler ? '' : item,
      index: index === agrup.index ? '' : index
    })
  }

  const pageStyle = `
    @media print{
      html, body{
        border-collapse: collapse;
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      table, th, td {
        border-collapse: collapse;
      }
      .page-break {
        margin-top: 20px !important;
        display: block;
        page-break-before: auto;
      }
    }
    @page{
      margin: 20mm;
      size: auto;
    }
  `

  return (
    <Main>
      <ReactToPrint
        trigger={() => {

          return <button type='button' className='buttonPrint'>IMPRIMIR</button>
        }}
        content={() => componentRef.current}
        documentTitle="new document"
        pageStyle={pageStyle}
      />

      <Container ref={componentRef}>
        <DivRow>
          <Row>
            <p>Relatório de Extrato de Estoque</p>
            <label>{formatDate(date)}</label>
          </Row>
          <div>
            <br />
            <p>Filtros:</p>
            <label>{reports.length > 0 && reports[0].Entidade ? reports[0].Entidade : entidade}</label>
          </div>
        </DivRow>
        <DivItems>
          {reports.length && reports[0].Agrupador ? reports.map((item: any, index: any) => (
            <>
              {item.isGroup ?
                <>
                  <ButtonActive onClick={() => handleActive(item.Agrupador, index)}>{item.active == true ? "-" : '+'} {item.Agrupador}</ButtonActive>
                  {item.active &&
                    childrenList(item.children, item.Agrupador)
                  }
                </>
                :
                <>
                  {item.Agrupador && <ButtonActive onClick={() => handleActive(item, index)}>{activeState === true ? "-" : '+'} {item.Agrupador}</ButtonActive>}
                  <table key={index}>
                    <tr>
                      <th>Chave</th>
                      <th>Data</th>
                      <th>Hora</th>
                      <th>Classe</th>
                      <th>Recurso</th>
                      <th>Tipo/Nr.</th>
                      <th>Classe Pedido</th>
                      <th>Fornecedor</th>
                      <th>Entrada</th>
                      <th>Saída</th>
                      <th>Saldo</th>
                      <th>Entrada</th>
                      <th>Saída</th>
                      <th>Saldo</th>
                      <th>Custo Médio</th>
                    </tr>

                    {reports ? reports.map((item: any, index: any) => (
                      <>

                        {activeState &&
                          <tr key={index}>
                            <td>{item.Agrupador}</td>
                            <td>{item.Recurso}</td>
                            <td className={item.quantidade - 0 ? "right red" : "right"}>{item.quantidade}</td>
                            <td className='right'>{item.custo ? formatMoney(item.custo) : ''}</td>
                          </tr>
                        }
                      </>
                    )) : null}
                  </table>
                </>
              }
            </>

          )) :
          <>
            <p>Depósito Central</p>
            <table>
              <tr>
                <th>Chave</th>
                <th>Data</th>
                <th>Hora</th>
                <th>Classe</th>
                <th>Recurso</th>
                <th>Tipo/Nr.</th>
                <th>Classe Pedido</th>
                <th>Fornecedor</th>
                <th>Entrada</th>
                <th>Saída</th>
                <th>Saldo</th>
                <th>Entrada</th>
                <th>Saída</th>
                <th>Saldo</th>
                <th>Custo Médio</th>
              </tr>
              {_deposito_central.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.chave}</td>
                  <td>{item.data}</td>
                  <td>{item.hora}</td>
                  <td>{item.classe}</td>
                  <td>{item.recurso}</td>
                  <td>{item.tipo}</td>
                  <td>{item.classe_pedido}</td>
                  <td>{item.fornecedor}</td>
                  <td className='right'>{formatMoney(item.entrada_qtd)}</td>
                  <td className="right red">{item.saida_qtd}</td>
                  <td className='right'>{formatMoney(item.saldo_qtd)}</td>
                  <td className="right">{formatMoney(item.entrada_custo)}</td>
                  <td className="right red">{formatMoney(item.saida_custo)}</td>
                  <td className='right'>{formatMoney(item.saldo_custo)}</td>
                  <td className='right'>{formatMoney(item.custo_medio)}</td>
                </tr>
              ))}
            </table>
          </>

          }


        </DivItems>
        <DivItems>
          <p>Depósito Demonstração</p>
          <table>
              <tr>
                <th>Chave</th>
                <th>Data</th>
                <th>Hora</th>
                <th>Classe</th>
                <th>Recurso</th>
                <th>Tipo/Nr.</th>
                <th>Classe Pedido</th>
                <th>Fornecedor</th>
                <th>Entrada</th>
                <th>Saída</th>
                <th>Saldo</th>
                <th>Entrada</th>
                <th>Saída</th>
                <th>Saldo</th>
                <th>Custo Médio</th>
              </tr>
              {_deposito_demonstracao.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.chave}</td>
                  <td>{item.data}</td>
                  <td>{item.hora}</td>
                  <td>{item.classe}</td>
                  <td>{item.recurso}</td>
                  <td>{item.tipo}</td>
                  <td>{item.classe_pedido}</td>
                  <td>{item.fornecedor}</td>
                  <td className='right'>{formatMoney(item.entrada_qtd)}</td>
                  <td className="right red">{item.saida_qtd}</td>
                  <td className='right'>{formatMoney(item.saldo_qtd)}</td>
                  <td className="right">{formatMoney(item.entrada_custo)}</td>
                  <td className="right red">{formatMoney(item.saida_custo)}</td>
                  <td className='right'>{formatMoney(item.saldo_custo)}</td>
                  <td className='right'>{formatMoney(item.custo_medio)}</td>
                </tr>
              ))}
            </table>
        </DivItems>
        {/* <Row>
          <Column>
            <p>Saldo por Recurso</p>
            <hr />
            <div>
              <label>Caneta Bic</label>
              <label>14 unid.</label>
            </div>
            <div>
              <label>Depósito Central</label>
              <label>13 unid.</label>
            </div>
            <div>
              <label>Depósito Demonstração</label>
              <label>1 unid.</label>
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <p>Saldo por Depósito</p>
            <hr />
            <div>
              <label>Depósito Central</label>
              <label>13 unid.</label>
            </div>
            <div>
              <label>Depósito Demonstração</label>
              <label>1 unid.</label>
            </div>
          </Column>
        </Row> */}
        <LogoFooter>
          <img src={LogoDevari} alt="Logo" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default ReportExtract;
