import React, {useState, useEffect} from 'react';
import LookupInput from '../LookupInput';
import TextInput from '../TextInput';
import { Container } from './styles';

interface Props {
  token?: string;
  handleChange?(payload: any): void;
}

const PaymentModalForm: React.FC<Props> = ({token, handleChange}) => {
  const [payload, setPayload] = useState({
    parcelas: 1,
    diasEntreParc: 0,
    diasPrimeiraParc: 0,
    diasFixoParc: 0,
    juros: 0.0,
    acrescimo: 0.0,
  });
  const [lookups, setLookups] = useState<any>({});

  useEffect(() => {
    if (handleChange) {
      const lookupsData: any = {};
      for (const key of Object.keys(lookups)) {
        lookupsData[key] = {
          id: lookups[key].value,
          nome: lookups[key].item.nome,
          codigo: lookups[key].item.codigo,
        };
      }
      handleChange({
        ...payload,
        ...lookupsData,
      });
    }
  }, [payload, lookups]);

  const handleChangePayload = (field: string, value: any) => setPayload({...payload, [field]: value});
  const handleChangeLookup = (field: string, value: any) => setLookups({...lookups, [field]: value});

  return (
    <Container>
      <TextInput 
        label="Parcelas"
        id="parcelas"
        masked 
        mask="integer"
        value={payload.parcelas}
        onChange={({target: {value}}) => handleChangePayload('parcelas', value)}
      />
      <LookupInput
        label="Tipo de documento"
        endpoint="tabela"
        field="nome"
        filters={{
          classe: "Tipos de Documentos",
        }}
        multi={false}
        token={token}
        onChange={(options) => handleChangeLookup("TipoDocumento", options)}
      />
      <LookupInput
        label="Carteira"
        endpoint="entidades"
        field="nome"
        filters={{
          classe: "Carteiras de Cobrança",
        }}
        multi={false}
        token={token}
        onChange={(options) => handleChangeLookup("Carteira", options)}
      />
      <LookupInput
        label="Bandeira de Cartão"
        endpoint="tabela"
        field="nome"
        filters={{
          classe: "Bandeiras de Cartão",
        }}
        multi={false}
        token={token}
        onChange={(options) => handleChangeLookup("Bandeira", options)}
      />
      <TextInput 
        label="Dias entre parcelas"
        id="diasEntreParc"
        masked 
        mask="integer"
        value={payload.diasEntreParc}
        onChange={({target: {value}}) => handleChangePayload('diasEntreParc', value)}
      />
      <TextInput 
        label="Dias primeira parcela"
        id="diasPrimeiraParc"
        masked 
        mask="integer"
        value={payload.diasPrimeiraParc}
        onChange={({target: {value}}) => handleChangePayload('diasPrimeiraParc', value)}
      />
      <TextInput 
        label="Dia fixo do mês para parcela"
        id="diasFixoParc"
        masked 
        mask="integer"
        value={payload.diasFixoParc}
        onChange={({target: {value}}) => handleChangePayload('diasFixoParc', value)}
      />
      <TextInput 
        label="Percentual de juros"
        id="juros"
        masked 
        money
        value={payload.juros}
        onChange={({target: {value}}) => handleChangePayload('juros', value)}
      />
      <TextInput 
        label="Percentual de acréscimo"
        id="acrescimo"
        masked 
        money
        value={payload.acrescimo}
        onChange={({target: {value}}) => handleChangePayload('acrescimo', value)}
      />
    </Container>
  );
}

export default PaymentModalForm;