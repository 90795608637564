import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import {
  getBalance,
  getClosedOrders,
  getOpenOrders,
  getPendingOrders,
  postPauseOrder,
  postSellOrder,
  encerrarOrdens,
} from "../../services/orders";
import { getWhiteListOnlyNames } from "../../services/whiteList";
import { orderCols } from "../../utils/form";
import LabelModal from "./labelModal";
import {
  Button,
  Container,
  DivCard,
  Row,
  Title,
  Content,
  Footer,
  SelectedContainer,
  PaginateContainer,
  RowButtonOrder,
} from "./style";
import useFetch from "../../hooks/useFetch";
import Toast from "../../components/Toast";
import { isAfter } from "date-fns";
import { parseISO } from "date-fns/esm";
import { BiBitcoin, BiDollar } from "react-icons/bi";
import CancelOrdersModal from "./cancelOrdersModal";
import { ADMIN_ID } from "../../environment";
import { getSearchEntities } from "../../services/entidade";
import { IEntidade } from "../../services/auth";
import { ID_USER_ADMIN } from "../../utils/entidades";
import AppFooter from '../../components/Footer';

interface IOrder {
  title?: string;
  endpoint: string;
  filters: object;
  multipleSelect: boolean;
}

export interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

export interface IRepresentative {
  id: string;
  nome: string;
}

const Order: React.FC<IOrder> = ({
  title,
  endpoint,
  filters,
  multipleSelect,
}) => {
  const { user } = useAuth();
  const { openModal, closeModal } = useFormModal();
  const [balance, setBalance] = useState([]);
  const provider = useFetch({ endpoint: `${endpoint}/tipos-de-entrada` });
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const [orders, setOrders] = useState<IResponse | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<IResponse | null>(null);
  const [ordersClosed, setOrdersClosed] = useState<IResponse | null>(null);
  const [ordersPending, setOrdersPending] = useState<IResponse | null>(null);
  const [filteredOrdersClosed, setFilteredOrdersClosed] =
    useState<IResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingSellOrder, setLoadingSellOrder] = useState(false);
  const [loadingPauseOrder, setLoadingPauseOrder] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateFields, setUpdateFields] = useState(false);
  const [representative, setRepresentative] = useState<IRepresentative[]>([]);
  const [searchRepresentativeOpen, setSearchRepresentativeOpen] = useState<string>("");
  const [searchRepresentativeClosed, setSearchRepresentativeClosed] = useState<string>("");
  const [openOrders, setOpenOrders] = useState<string>("open");
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [colsOrdersClosed, setColsOrdersClosed] = useState<Array<IField>>([]);
  const [colsOrdersPending, setColsOrdersPending] = useState<Array<IField>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [currentPagePending, setCurrentPagePending] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [coin, setCoin] = useState<any>([]);
  const [coinClosed, setCoinClosed] = useState<any>([]);
  const [coinPending, setCoinPending] = useState<any>([]);
  const [broker, setBroker] = useState<any>([]);
  const [brokerClosed, setBrokerClosed] = useState<any>([]);
  const [brokerPending, setBrokerPending] = useState<any>([]);
  const [entry, setEntry] = useState<any>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>('100');
  const [growing, setGrowing] = useState({
    item: "",
    asc: "",
  });
  const [filterOrders, setFilterOrders] = useState({
    corretora: "Binance",
    moeda: "todosPares",
    idRepresentante: "idRepresentante",
    observacao: "tipoEntrada",
    emissaoInicial: "today",
    emissaoFinal: "today",
    alvo: 0,
  });
  const [filterOrdersClosed, setFilterOrdersClosed] = useState({
    corretora: "Binance",
    moeda: "todosPares",
    idRepresentante: "idRepresentante",
    observacao: "tipoEntrada",
    emissaoInicial: "today",
    emissaoFinal: "today",
    alvo: 0,
  });
  const [filterOrdersPending, setFilterOrdersPending] = useState({
    corretora: "Binance",
    moeda: "todosPares",
    idRepresentante: "idRepresentante",
    observacao: "tipoEntrada",
    emissaoInicial: "today",
    emissaoFinal: "today",
    alvo: 0,
  });

  useEffect(() => {
    getCurrentBalance();
    getTableFilds();
    getTableFildsClosed();
    getTableFildsPending();
    getTypeEntry();
    getCoins();
    // getCoinsCloseds();
    // getCoinsPendings();
  }, []);

  useEffect(() => {
    if (updateFields) {
      initialValues("all");
    }
    if (openOrders) {
      initialValues(openOrders);
    }
  }, [updateFields, openOrders]);

  useEffect(() => {
    if (openOrders === "open") {
      if (
        filterOrders.corretora !== "todasCorretoras" ||
        filterOrders.moeda !== "todosPares" ||
        filterOrders.idRepresentante !== "idRepresentante" ||
        filterOrders.observacao !== "tipoEntrada" ||
        filterOrders.alvo > 0 ||
        (filterOrders.emissaoInicial !== "today" &&
          filterOrders.emissaoFinal !== "today")
      ) {
        filterOrder(filterOrders);
      } else {
        filterOrder();
      }
    } else if (openOrders === "closed") {
      if (
        filterOrdersClosed.corretora !== "todasCorretoras" ||
        filterOrdersClosed.moeda !== "todosPares" ||
        filterOrdersClosed.idRepresentante !== "idRepresentante" ||
        filterOrdersClosed.observacao !== "tipoEntrada" ||
        filterOrdersClosed.alvo > 0 ||
        (filterOrdersClosed.emissaoInicial !== "today" &&
          filterOrdersClosed.emissaoFinal !== "today")
      ) {
        filterOrder(filterOrdersClosed);
      } else {
        filterOrder();
      }
    } else {
      if (
        filterOrdersPending.corretora !== "todasCorretoras" ||
        filterOrdersPending.moeda !== "todosPares" ||
        filterOrdersPending.idRepresentante !== "idRepresentante" ||
        filterOrdersPending.observacao !== "tipoEntrada" ||
        filterOrdersPending.alvo > 0 ||
        (filterOrdersPending.emissaoInicial !== "today" &&
          filterOrdersPending.emissaoFinal !== "today")
      ) {
        filterOrder(filterOrdersPending);
      } else {
        filterOrder();
      }
    }
  }, [
    currentPage,
    currentPageClosed,
    currentPagePending,
    growing,
    quantityPerPage,
    filterOrders,
    filterOrdersClosed,
    filterOrdersPending,
  ]);

  useEffect(() => {
    return () => closeModal();
  }, []);

  useEffect(() => {
    if(openOrders === "open") {
      if (searchRepresentativeOpen?.length < 3) return;
      let timer = setTimeout(() => {
        searchEntities(searchRepresentativeOpen);
      }, 500);
      return () => clearTimeout(timer);
    } else if (openOrders === "closed") {
      if (searchRepresentativeClosed?.length < 3) return;
      let timer = setTimeout(() => {
        searchEntities(searchRepresentativeClosed);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [searchRepresentativeOpen, searchRepresentativeClosed])

  const getCoins = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
        setCoinClosed(filterCoin);
        setCoinPending(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  // const getCoins = async () => {
  //   try {
  //     let idUser = user?.id;
  //     if (idUser) {
  //       const result = await getCoin(idUser);
  //       if (result) {
  //         setCoin(result);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Erro: ", error);
  //   }
  // };

  // const getCoinsCloseds = async () => {
  //   try {
  //     let idUser = user?.id;
  //     if (idUser) {
  //       const result = await getCoinClosed(idUser);
  //       if (result) {
  //         setCoinClosed(result);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Erro: ", error);
  //   }
  // };

  // const getCoinsPendings = async () => {
  //   try {
  //     let idUser = user?.id;
  //     if (idUser) {
  //       const result = await getCoinPending(idUser);
  //       if (result) {
  //         setCoinPending(result);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Erro: ", error);
  //   }
  // };

  const searchEntities = async (filter: string) => {
    try {
      const result = await getSearchEntities(filter)
      if (result) {
        const _result = result.map((item: IEntidade) => {
          return {
            id: item.id,
            nome: item.nome
          }
        })

        setRepresentative(_result)
      }
    } catch (error) {
      console.error("Erro: ", error)
    }
  }

  const getTypeEntry = async () => {
    try {
      const result = await provider.get();
      if (result) {
        const _result = result.map(
          (item: any) => item.charAt(0).toUpperCase() + item.slice(1)
        );

        setEntry(_result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleFilter = (key: string, value: string) => {
    if (openOrders === "open") {
      if (key === "corretora") {
        setFilterOrders({
          ...filterOrders,
          [key]:
            value === "Todas as Corretoras" || value === ""
              ? "todasCorretoras"
              : value,
        });
      } else if (key === "moeda") {
        setFilterOrders({
          ...filterOrders,
          [key]:
            value === "Todas as Moedas" || value === "" ? "todosPares" : value,
        });
      } else if (key === "idRepresentante") {
        setFilterOrders({
          ...filterOrders,
          [key]:
            value === "Representante" || value === "" ? "idRepresentante" : value,
        })
      } else if (key === "observacao") {
        setFilterOrders({
          ...filterOrders,
          [key]:
            value === "Tipo de Entrada" || value === "" ? "tipoEntrada" : value,
        });
      } else if (key === "alvo") {
        setFilterOrders({ ...filterOrders, [key]: +value });
      } else {
        if (value) {
          setFilterOrders({
            ...filterOrders,
            [key]:
              key === "emissaoInicial"
                ? (value += "T00:00:00.000Z")
                : (value += "T23:59:59.000Z"),
          });
        } else {
          setFilterOrders({
            ...filterOrders,
            [key]: "today",
          });
        }
      }
    } else if (openOrders === "closed") {
      if (key === "corretora") {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          [key]:
            value === "Todas as Corretoras" || value === ""
              ? "todasCorretoras"
              : value,
        });
      } else if (key === "moeda") {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          [key]:
            value === "Todas as Moedas" || value === "" ? "todosPares" : value,
        });
      } else if (key === "idRepresentante") {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          [key]:
            value === "Representante" || value === "" ? "idRepresentante" : value,
        })
      } else if (key === "observacao") {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          [key]:
            value === "Tipo de entrada" || value === "" ? "tipoEntrada" : value,
        });
      } else if (key === "alvo") {
        setFilterOrdersClosed({ ...filterOrdersClosed, [key]: +value });
      } else {
        if (value) {
          setFilterOrdersClosed({
            ...filterOrdersClosed,
            [key]:
              key === "emissaoInicial"
                ? (value += "T00:00:00.000Z")
                : (value += "T23:59:59.000Z"),
          });
        } else {
          setFilterOrdersClosed({
            ...filterOrdersClosed,
            [key]: "today",
          });
        }
      }
    } else {
      if (key === "corretora") {
        setFilterOrdersPending({
          ...filterOrdersPending,
          [key]:
            value === "Todas as Corretoras" || value === ""
              ? "todasCorretoras"
              : value,
        });
      } else if (key === "moeda") {
        setFilterOrdersPending({
          ...filterOrdersPending,
          [key]:
            value === "Todas as Moedas" || value === "" ? "todosPares" : value,
        });
      } else if (key === "idRepresentante") {
        setFilterOrdersPending({
          ...filterOrdersPending,
          [key]:
            value === "Representante" || value === "" ? "idRepresentante" : value,
        })
      } else if (key === "observacao") {
        setFilterOrdersPending({
          ...filterOrdersPending,
          [key]:
            value === "Tipo de entrada" || value === "" ? "tipoEntrada" : value,
        });
      } else {
        if (value) {
          setFilterOrdersPending({
            ...filterOrdersPending,
            [key]:
              key === "emissaoInicial"
                ? (value += "T00:00:00.000Z")
                : (value += "T23:59:59.000Z"),
          });
        } else {
          setFilterOrdersPending({
            ...filterOrdersPending,
            [key]: "today",
          });
        }
      }
    }
  };

  const getCurrentBalance = async () => {
    setLoading(true);
    try {
      const result = await getBalance(user?.id);
      if (result) {
        const filterBalance = result.balances.filter((item: any) => {
          if (["USDT", "BTC", "BUSD"].includes(item.asset)) {
            return {
              asset: item.asset,
              free: item.free,
            };
          }
        });
        setBalance(filterBalance);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Erro ao consultar saldo");
      console.error("Erro: ", error);
      setLoading(false);
    }
  };

  const initialValues = (value: string) => {
    if (value === "closed") {
      initialValuesOrder();
      initialValuesOrderPending();
    } else if (value === "open") {
      initialValuesOrderClosed();
      initialValuesOrderPending();
    } else if (value === "pending") {
      initialValuesOrder();
      initialValuesOrderClosed();
    } else {
      initialValuesOrder();
      initialValuesOrderClosed();
      initialValuesOrderPending();
    }
  };

  const initialValuesOrder = () => {
    setFilterOrders({
      ...filterOrders,
      corretora: "Binance",
      moeda: "todosPares",
      idRepresentante: "idRepresentante",
      observacao: "tipoEntrada",
      emissaoInicial: "today",
      emissaoFinal: "today",
      alvo: 0,
    });
  };

  const initialValuesOrderClosed = () => {
    setFilterOrdersClosed({
      ...filterOrdersClosed,
      corretora: "Binance",
      moeda: "todosPares",
      idRepresentante: "idRepresentante",
      observacao: "tipoEntrada",
      emissaoInicial: "today",
      emissaoFinal: "today",
      alvo: 0,
    });
  };

  const initialValuesOrderPending = () => {
    setFilterOrdersPending({
      ...filterOrdersPending,
      corretora: "Binance",
      moeda: "todosPares",
      idRepresentante: "idRepresentante",
      observacao: "tipoEntrada",
      emissaoInicial: "today",
      emissaoFinal: "today",
      alvo: 0,
    });
  };

  // Função responsável por dar get em todas as colunas da tabelas e as ordens abertas
  const getTableFilds = async (filter?: any) => {
    setUpdate(true);
    try {
      // setLoadingSellOrder(true);
      const id = user?.id;
      const result = await fields.get({ id, ...filters });
      if (result) {
        let fieldsOrder = [
          {
            campo: "precoAtual",
            coin: "USD",
            decimalPrecision: 6,
            editavel: false,
            nome: "Preço Atual",
            mascara: "dinheiro",
            order: 26,
            required: true,
            tipo: "texto",
          },
          {
            campo: "profitLossPercent",
            editavel: false,
            nome: "Lucro(%)",
            order: 28,
            required: true,
            tipo: "texto",
          },
          {
            campo: "profitLoss",
            coin: "USD",
            editavel: false,
            nome: "Lucro(U$)",
            order: 30,
            required: true,
            tipo: "texto",
            mascara: "dinheiro",
          },
        ];
        let fieldsOrderClosed = [
          {
            campo: "lucroPercent",
            editavel: false,
            nome: "Lucro(%)",
            order: 36,
            required: true,
            tipo: "texto",
          },
        ];

        const openOrders = result.filter(
          (item: any) =>
            item.campo !== "aprovacao" &&
            item.campo !== "comissao" &&
            item.campo !== "lucroLiquido" &&
            item.campo !== "diasPendente"
        );
        const closedOrders = result.filter(
          (item: any) =>
            item.campo !== "unitario" && item.campo !== "diasPendente" && item.campo !== "entradaObject.sellZone"
        );

        const pendingOrders = result.filter(
          (item: any) =>
            item.campo !== "aprovacao" &&
            item.campo !== "lucroLiquido" &&
            item.campo !== "cancelamento" &&
            item.campo !== "observacao"
        );

        const fieldsOpenOrders = [...fieldsOrder, ...filterCols(openOrders)];
        const allFieldsOrdersClosed = [
          ...filterCols(closedOrders),
          ...fieldsOrderClosed,
        ];
        const fildsPending = filterCols(pendingOrders);

        const openOrders_cols = fieldsOpenOrders.sort(orderCols);
        const closedOrders_cols = allFieldsOrdersClosed.sort(orderCols);
        const pendingOrder_cols = fildsPending.sort(orderCols);

        setCols(openOrders_cols);
        setColsOrdersClosed(closedOrders_cols);
        setColsOrdersPending(pendingOrder_cols);

        if (filter) {
          if (filter === "update") {
            setUpdateFields(true);
            getCurrentBalance();
          } else {
            const resultOrders = await getOpenOrders(
              currentPage,
              user?.id,
              growing,
              quantityPerPage,
              filter
            );
            if (resultOrders) {
              const newCoin: any = [];
              const newBroker: any = [];
              const { docs, totalPages, total } = resultOrders;

              const filterCoin = docs.map(
                (item: any) => item.moeda
              );
              const _filterCoin = new Set(filterCoin);

              const filterBroker = docs.map(
                (item: any) => item.Pessoa.nome
              );
              const _filterBroker = new Set(filterBroker);

              _filterCoin.forEach((item) => {
                newCoin.push(item);
              });
              _filterBroker.forEach((item) => {
                newBroker.push(item);
              });

              // setCoin(newCoin);
              // setBroker(newBroker);

              const newResultOrders = docs.map((item: any) =>
                formatCoinBtc(item)
              );


              setOrders({totalPages, total, docs: newResultOrders});
              setUpdate(false);
              setUpdateFields(false);
              // setLoadingSellOrder(false);
              // initialValues("all");
            }
          }
        } else {
          const resultOrders = await getOpenOrders(
            currentPage,
            user?.id,
            growing,
            quantityPerPage
          );
          if (resultOrders) {
            const newCoin: any = [];
            const newBroker: any = [];
            const { docs, totalPages, total } = resultOrders;

            const filterCoin = docs.map((item: any) => item.moeda);
            const _filterCoin = new Set(filterCoin);

            const filterBroker = docs.map(
              (item: any) => item.Pessoa.nome
            );
            const _filterBroker = new Set(filterBroker);

            _filterCoin.forEach((item: any) => {
              newCoin.push(item);
            });
            _filterBroker.forEach((item: any) => {
              newBroker.push(item);
            });

            const newResultOrders = docs.map((item: any) =>
              formatCoinBtc(item)
            );

            // const _representatives = newResultOrders.map((item: any) => item.Representante)

            // setCoin(newCoin);
            setBroker(newBroker);
            setOrders({totalPages, total, docs: newResultOrders});
            setUpdate(false);
            setUpdateFields(false);
            // setRepresentative(_representatives);
            // setLoadingSellOrder(false);
            // initialValues("all");
          }
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
    setUpdate(false);
  };

  const getTableFildsClosed = async (filter?: any) => {
    // Função responsável por dar get nas ordens fechadas
    try {
      setUpdate(true);
      if (filter) {
        const resultOrders = await getClosedOrders(
          currentPageClosed,
          user?.id,
          growing,
          quantityPerPage,
          filter
        );

        if (resultOrders) {
          const newCoin: any = [];
          // const newBroker: any = [];
          const { docs, totalPages, total } = resultOrders;

          const filterCoin = docs.map((item: any) => item.moeda);
          const _filterCoin = new Set(filterCoin);

          _filterCoin.forEach((item) => {
            newCoin.push(item);
          });

          const newResultOrders = docs.map((item: any) =>
            formatCoinBtc(item)
          );

          setOrdersClosed({totalPages, total, docs: newResultOrders});
          setUpdate(false);

          // const filterBroker = resultOrders.docs.map(
          //   (item: any) => item.Pessoa.nome
          // );
          // const _filterBroker = new Set(filterBroker);

          // _filterBroker.forEach((item) => {
          //   newBroker.push(item);
          // });

          // setCoinClosed(newCoin);
          // setBrokerClosed(newBroker);
        }
      } else {
        const resultOrders = await getClosedOrders(
          currentPageClosed,
          user?.id,
          growing,
          quantityPerPage
        );

        if (resultOrders) {
          const newCoin: any = [];
          const newBroker: any = [];
          const { docs, totalPages, total } = resultOrders;

          const filterCoin = docs.map((item: any) => item.moeda);
          const _filterCoin = new Set(filterCoin);

          _filterCoin.forEach((item) => {
            newCoin.push(item);
          });

          const newResultOrders = docs.map((item: any) =>
            formatCoinBtc(item)
          );

          setOrdersClosed({totalPages, total, docs: newResultOrders});
          setUpdate(false);
          // const filterBroker = resultOrders.docs.map(
          //   (item: any) => item.Pessoa.nome
          // );
          // const _filterBroker = new Set(filterBroker);

          // _filterBroker.forEach((item) => {
          //   newBroker.push(item);
          // });

          // setCoinClosed(newCoin);
          // setBrokerClosed(newBroker);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const filterCols = (cols: any) => {
    const newCols = cols.filter((col: any) => {
      if (user && user.id !== "6404afb1c65c4c98337b6433") {
        if (
          col.campo !== "Representante" &&
          col.campo !== "sellZone" &&
          col.campo !== "stopLoss"
        ) {
          return col;
        }
      } else {
        return col;
      }
    });

    return newCols;
  };

  // Função responsável por dar get nas ordens pendentes
  // Passando o id de classe como padrão para as chamadas pedentes.
  const getTableFildsPending = async (filter?: any) => {
    let _filters = { ...filter, classe: "C Crypto P Invest", baixa: "false" };
    try {
      setUpdate(true);
      if (filter) {
        const resultOrders = await getPendingOrders(
          currentPageClosed,
          user?.id,
          _filters
        );
        if (resultOrders) {
          const newCoin: any = [];
          const newBroker: any = [];
          const { docs, totalPages, total } = resultOrders;

          const filterCoin = docs.map((item: any) => item.moeda);
          const _filterCoin = new Set(filterCoin);

          const filterBroker = docs.map(
            (item: any) => item.Pessoa.nome
          );
          const _filterBroker = new Set(filterBroker);

          _filterCoin.forEach((item) => {
            newCoin.push(item);
          });
          _filterBroker.forEach((item) => {
            newBroker.push(item);
          });

          const newResultOrders = docs.map((item: any) =>
            formatCoinBtc(item)
          );

          setBrokerPending(newBroker);
          // setCoinPending(newCoin);
          setOrdersPending({totalPages, total, docs: newResultOrders});
          setUpdate(false);
        }
      } else {
        const result = await getPendingOrders(
          currentPagePending,
          user?.id,
          _filters
        );
        if (result) {
          const newCoin: any = [];
          const newBroker: any = [];
          const { docs, totalPages, total } = result;

          const filterCoin = docs.map((item: any) => item.moeda);
          const _filterCoin = new Set(filterCoin);

          const filterBroker = docs.map((item: any) => item.Pessoa.nome);
          const _filterBroker = new Set(filterBroker);

          _filterCoin.forEach((item) => {
            newCoin.push(item);
          });
          _filterBroker.forEach((item) => {
            newBroker.push(item);
          });

          const newResultOrders = docs.map((item: any) =>
            formatCoinBtc(item)
          );

          setBrokerPending(newBroker);
          // setCoinPending(newCoin);
          setOrdersPending({totalPages, total, docs: newResultOrders});
          setUpdate(false);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const filterOrder = (filter?: any) => {
    // Função responsável por realizar um filtro de acordo com a opção que o usuário escolher
    if (filter) {
      if (
        isAfter(parseISO(filter.emissaoInicial), parseISO(filter.emissaoFinal))
      ) {
        Toast.show(
          "Favor informar uma data válida, a data final não pode ser inferior a data inicial.",
          "error"
        );
      } else {
        if (openOrders === "open") {
          getTableFilds(filter);
        } else if (openOrders === "closed") {
          getTableFildsClosed(filter);
        } else {
          getTableFildsPending(filter);
        }
      }
    } else {
      getTableFilds();
      getTableFildsClosed();
      getTableFildsPending();
    }
  };

  const handleChangePage = (page: number) => {
    if (openOrders === "open") {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else if (openOrders === "closed") {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    } else {
      if (page + 1 !== currentPagePending) {
        setCurrentPagePending(page + 1);
      }
    }
  };

  const handleModal = () => {
    openModal({
      type: "confirm",
      // title: "Nova Ordem",
      wide: "340px",
      children: () => (
        <LabelModal
          getTableFilds={getTableFilds}
          getCurrentBalance={getCurrentBalance}
          getTableFildsPending={getTableFildsPending}
        />
      ),
    });
  };

  const handleModalCancela = () => {
    openModal({
      type: "confirm",
      wide: "340px",
      children: () => (
        <CancelOrdersModal openOrders={openOrders}/>
      )
    })
  }

  const dataTable = () => {
    if (openOrders && filteredOrders) {
      return filteredOrders.docs;
    } else if (openOrders && !filteredOrders && orders) {
      return orders.docs;
    } else if (!openOrders && filteredOrdersClosed) {
      return filteredOrdersClosed.docs;
    } else if (!openOrders && !filteredOrdersClosed && ordersClosed) {
      return ordersClosed.docs;
    } else {
      return [];
    }
  };

  const encerrar = async () => {
    try {
      setLoadingSellOrder(true);
      await encerrarOrdens(user?.id);
    } catch (error: any) {
      console.error("Erro: ", error);
      Toast.show(error.message, "error");
    }
    setLoadingSellOrder(false);
  };

  const sellOrder = async () => {
    setLoadingSellOrder(true);
    const ids = itensSelected();
    if (ids) {
      try {
        const result = await postSellOrder(ids, user?.id, openOrders);
        if (result.status === 201) {
          toast.success("Cancelamento de ordens EM PROGRESSO.", { duration: 2000 });
          await getTableFilds();
          await getTableFildsClosed();
          setLoadingSellOrder(false);
        }
      } catch (error: any) {
        console.error("Erro: ", error);
        Toast.show(error.message, "error");
        setLoadingSellOrder(false);
      }
    }
    setLoadingSellOrder(false);
  };

  const pauseOrder = async () => {
    setLoadingPauseOrder(true);
    const ids = itensSelected();
    if (ids) {
      try {
        const response = await postPauseOrder(ids, user?.id);

        if (response.status === 201) {
          toast.success("Ordem pausada.");
          await getTableFilds();
          await getTableFildsClosed();
          setLoadingPauseOrder(false);
        }
      } catch (error: any) {
        console.error("Erro: ", error);
        Toast.show(error.message, "error");
        setLoadingPauseOrder(false);
      }
    }
  };

  const itensSelected = () => {
    const itens: any = [];
    let docs: any;

    if (openOrders === "open" && orders) {
      docs = [...orders?.docs];
    }
    if (openOrders === "pending" && ordersPending) {
      docs = [...ordersPending?.docs];
    }

    if (docs) {
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item == true) {
          itens.push(docs[index].chcriacao);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item);
        if (itemIndex >= 0) {
          docs.splice(itemIndex, 1);
        }
      });

      // setSelectedIndexes(selection)
    }
    if (itens.length > 0) {
      return itens;
    } else {
      toast.error("Selecione a ordem que deseja vender");
    }
  };

  const handleOrderCols = (value: string) => {
    if (value === "Par" || value === "Quantidade") {
      if (growing.item === value) {
        setGrowing({
          ...growing,
          item: growing.asc == "false" ? "" : value,
          asc: growing.asc == "false" ? "" : "false",
        });
      } else {
        setGrowing({
          ...growing,
          item: value,
          asc: "true",
        });
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const formatedDate = (date: string) => {
    const data = date.slice(0, 10);
    return data;
  };

  const formatCoinBtc = (coin: any) => {
    let newCoin: any = coin;

    const coinBTC = newCoin.moeda.slice(newCoin.moeda.length - 3);

    if (coinBTC === "BTC") {
      return (newCoin = {
        ...newCoin,
        ["mascara"]: "BTC",
      });
    } else {
      return newCoin;
    }
  };

  const handleSearch = (type: string, value: string) => {
    const selected = representative.find(rep => rep.nome === value);
    if(!value) {
      if (type === "open") {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: "idRepresentante"
        })
        setSearchRepresentativeOpen("")
      } else {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          idRepresentante: "idRepresentante"
        })
        setSearchRepresentativeClosed("")
      }
    }
    if(selected) {
      if(type === "open") {
        setFilterOrders({
          ...filterOrders,
          idRepresentante: selected.id
        })
        setSearchRepresentativeOpen(value)
      } else {
        setFilterOrdersClosed({
          ...filterOrdersClosed,
          idRepresentante: selected.id
        })
        setSearchRepresentativeClosed(value)
      }
    } else {
      if(type === "open") {
        setSearchRepresentativeOpen(value)
      } else {
        setSearchRepresentativeClosed(value)
      }
    }
  }

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard className="balance">
        <Row wide={"auto"} aItems={"baseline"}>
          <label htmlFor="">Saldo</label>
          <div className="rowBalanceValue">
            {balance.length > 0
              ? balance.map((item: any, index: any) => (
                  <div key={index} className="balanceValue">
                    <p>
                      {item.asset === "BTC" ? <BiBitcoin /> : <BiDollar />}
                      {item.free ? `${Number(item.free).toFixed(2)}` : "0,00"}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </Row>
        <Row wide={"auto"} padding={"0 20px"}>
          {loading ? (
            <span>
              <AiOutlineLoading /> &nbsp;
            </span>
          ) : null}
          {/* <Button
            wide={"auto"}
            disabled={loading}
            onClick={() => getCurrentBalance()}
          >
            Atualizar
          </Button> */}
        </Row>
      </DivCard>
      <DivCard>
        <RowButtonOrder className="buttonsOrder">
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setOpenOrders("open")}
          >
            <p className={openOrders === "open" ? "primary" : ""}>
              Abertas{orders?.total ? `(${orders.total})` : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setOpenOrders("closed")}
          >
            <p className={openOrders === "closed" ? "primary" : ""}>
              Fechadas{ordersClosed?.total ? `(${ordersClosed.total})` : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setOpenOrders("pending")}
          >
            <p className={openOrders === "pending" ? "primary" : ""}>
              Pendentes
              {ordersPending?.total ? `(${ordersPending.total})` : "(0)"}
            </p>
          </button>
        </RowButtonOrder>
      </DivCard>

      <DivCard className="filters">
        <Row wide={"auto"} padding={"10px 20px 0 20px"} className="filters">
          <p className="primary">Filtros</p>
          {openOrders === "open" ? (
            <form autoComplete="off">
              {/* <div>
                <input
                  list="broker"
                  name="myBroker"
                  id="myBroker"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrders.corretora !== "todasCorretoras"
                      ? filterOrders.corretora
                      : ""
                  }
                  onChange={(ev) => handleFilter("corretora", ev.target.value)}
                  placeholder={filterOrders.corretora.replace(
                    "todasCorretoras",
                    "Todas Corretoras"
                  )}
                />
                <datalist id="broker">
                  {broker ?
                    broker.map((item: string) => (
                      <option value={item}>{item}</option>
                    )) : null}
                </datalist>
              </div> */}
              <div>
                <input
                  list="coinOpen"
                  name="myCoinOpen"
                  id="myCoinOpen"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrders.moeda !== "todosPares"
                      ? filterOrders.moeda
                      : ""
                  }
                  onChange={(ev) => handleFilter("moeda", ev.target.value)}
                  placeholder={filterOrders.moeda.replace(
                    "todosPares",
                    "Todas Moedas"
                  )}
                />
                <datalist id="coinOpen">
                  {coin
                    ? coin.map((item: string, index: number) => (
                        <option value={item} key={index}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div>
              {user && user.id === ID_USER_ADMIN ? (
                <div>
                  <input
                    list="representantes"
                    name="representative"
                    id="representative"
                    type="search"
                    autoComplete="chrome-off"
                    value={searchRepresentativeOpen}
                    onChange={(ev) => handleSearch("open", ev.target.value)}
                    placeholder={filterOrders.idRepresentante.replace(
                      "idRepresentante",
                      "Representante"
                    )}
                  />
                  <datalist id="representantes">
                    {representative.length > 0
                      ? representative.map((item: IRepresentative) => (
                          <option value={item.nome} key={item.id}>{item.nome}</option>
                        ))
                      : null}
                  </datalist>
                </div>
              ) : null}
              {/* <div>
                <input
                  list="entryOpen"
                  name="myEntryOpen"
                  id="myEntryOpen"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrders.observacao !== "tipoEntrada"
                      ? filterOrders.observacao
                      : ""
                  }
                  onChange={(ev) => handleFilter("observacao", ev.target.value)}
                  placeholder={filterOrders.observacao.replace(
                    "tipoEntrada",
                    "Tipo Entrada"
                  )}
                />
                <datalist id="entryOpen">
                  {entry.length > 0
                    ? entry.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div> */}
              {/* <div>
                <input
                  className="small"
                  type="number"
                  name="alvo"
                  id="alvo"
                  value={filterOrders.alvo > 0 ? filterOrders.alvo : ""}
                  onChange={(ev) => handleFilter("alvo", ev.target.value)}
                  placeholder="% alvo"
                />
              </div> */}
              <div>
                <label htmlFor="emissaoInicial">
                  De: &nbsp;
                  <input
                    type="date"
                    name="emissaoInicial"
                    id="emissaoInicial"
                    value={
                      filterOrders.emissaoInicial !== "today"
                        ? formatedDate(filterOrders.emissaoInicial)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoInicial", ev.target.value)
                    }
                  />
                </label>
              </div>
              <div>
                <label htmlFor="emissaoFinal">
                  Até: &nbsp;
                  <input
                    type="date"
                    name="emissaoFinal"
                    id="emissaoFinal"
                    value={
                      filterOrders.emissaoFinal !== "today"
                        ? formatedDate(filterOrders.emissaoFinal)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoFinal", ev.target.value)
                    }
                  />
                </label>
              </div>
            </form>
          ) : openOrders === "closed" ? (
            <form autoComplete="off" style={{ display: "flex" }}>
              {/* <div>
                <input
                  list="brokerClosed"
                  name="myBrockerClosed"
                  id="myBrockerClosed"
                  type="search"
                  autoComplete="off"
                  value={
                    filterOrdersClosed.corretora !== "todasCorretoras"
                      ? filterOrdersClosed.corretora
                      : ""
                  }
                  onChange={(ev) => handleFilter("corretora", ev.target.value)}
                  placeholder={filterOrdersClosed.corretora.replace(
                    "todasCorretoras",
                    "Todas Corretoras"
                  )}
                />
                <datalist id="brokerClosed">
                  {brokerClosed ?
                    brokerClosed.map((item: string) => (
                      <option value={item}>{item}</option>
                    )) : null}
                </datalist>
              </div> */}
              <div>
                <input
                  list="coinClosed"
                  name="myCoinClosed"
                  id="myCoinClosed"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrdersClosed.moeda !== "todosPares"
                      ? filterOrdersClosed.moeda
                      : ""
                  }
                  onChange={(ev) => handleFilter("moeda", ev.target.value)}
                  placeholder={filterOrdersClosed.moeda.replace(
                    "todosPares",
                    "Todas Moedas"
                  )}
                />
                <datalist id="coinClosed">
                  {coinClosed
                    ? coinClosed.map((item: string, index: number) => (
                        <option value={item} key={index}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div>
              {user && user.id === ID_USER_ADMIN ? (
                <div>
                  <input
                    list="representantes"
                    name="representative"
                    id="representative"
                    type="search"
                    autoComplete="chrome-off"
                    value={searchRepresentativeClosed}
                    onChange={(ev) => handleSearch("closed", ev.target.value)}
                    placeholder={filterOrders.idRepresentante.replace(
                      "idRepresentante",
                      "Representante"
                    )}
                  />
                  <datalist id="representantes">
                    {representative.length > 0
                      ? representative.map((item: IRepresentative) => (
                          <option value={item.nome} key={item.id}>{item.nome}</option>
                        ))
                      : null}
                  </datalist>
                </div>
              ) : null}
              {/* <div>
                <input
                  list="entryClosed"
                  name="myEntryClosed"
                  id="myEntryClosed"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrdersClosed.observacao !== "tipoEntrada"
                      ? filterOrdersClosed.observacao
                      : ""
                  }
                  onChange={(ev) => handleFilter("observacao", ev.target.value)}
                  placeholder={filterOrdersClosed.observacao.replace(
                    "tipoEntrada",
                    "Tipo Entrada"
                  )}
                />
                <datalist id="entryClosed">
                  {entry.length > 0
                    ? entry.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div> */}
              {/* <div>
                <input
                  className="small"
                  type="number"
                  name="alvo"
                  id="alvo"
                  value={
                    filterOrdersClosed.alvo > 0 ? filterOrdersClosed.alvo : ""
                  }
                  onChange={(ev) => handleFilter("alvo", ev.target.value)}
                  placeholder="% alvo"
                />
              </div> */}
              <div>
                <label htmlFor="fromClosed">
                  De: &nbsp;
                  <input
                    type="date"
                    name="fromClosed"
                    id="fromClosed"
                    value={
                      filterOrdersClosed.emissaoInicial !== "today"
                        ? formatedDate(filterOrdersClosed.emissaoInicial)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoInicial", ev.target.value)
                    }
                  />
                </label>
              </div>
              <div>
                <label htmlFor="toClosed">
                  Até: &nbsp;
                  <input
                    type="date"
                    name="toClosed"
                    id="toClosed"
                    value={
                      filterOrdersClosed.emissaoFinal !== "today"
                        ? formatedDate(filterOrdersClosed.emissaoFinal)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoFinal", ev.target.value)
                    }
                  />
                </label>
              </div>
            </form>
          ) : (
            <form autoComplete="off" style={{ display: "flex" }}>
              {/* <div>
                <input
                  list="brokerPending"
                  name="myBrockerPending"
                  id="myBrockerPending"
                  type="search"
                  autoComplete="off"
                  value={
                    filterOrdersPending.corretora !== "todasCorretoras"
                      ? filterOrdersPending.corretora
                      : ""
                  }
                  onChange={(ev) => handleFilter("corretora", ev.target.value)}
                  placeholder={filterOrdersPending.corretora.replace(
                    "todasCorretoras",
                    "Todas Corretoras"
                  )}
                />
                <datalist id="brokerPending">
                  {brokerPending ?
                    brokerPending.map((item: string) => (
                      <option value={item}>{item}</option>
                    )) : null}
                </datalist>
              </div> */}
              <div>
                <input
                  list="coinPending"
                  name="myCoinPending"
                  id="myCoinPending"
                  type="search"
                  autoComplete="crhome-off"
                  value={
                    filterOrdersPending.moeda !== "todosPares"
                      ? filterOrdersPending.moeda
                      : ""
                  }
                  onChange={(ev) => handleFilter("moeda", ev.target.value)}
                  placeholder={filterOrdersPending.moeda.replace(
                    "todosPares",
                    "Todas Moedas"
                  )}
                />
                <datalist id="coinPending">
                  {coinPending
                    ? coinPending.map((item: string, index: number) => (
                        <option value={item} key={index}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div>
              {/* <div>
                <input
                  list="entryPending"
                  name="myEntryPending"
                  id="myEntryPending"
                  type="search"
                  autoComplete="chrome-off"
                  value={
                    filterOrdersPending.observacao !== "tipoEntrada"
                      ? filterOrdersPending.observacao
                      : ""
                  }
                  onChange={(ev) => handleFilter("observacao", ev.target.value)}
                  placeholder={filterOrdersPending.observacao.replace(
                    "tipoEntrada",
                    "Tipo Entrada"
                  )}
                />
                <datalist id="entryPending">
                  {entry.length > 0
                    ? entry.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))
                    : null}
                </datalist>
              </div> */}
              {/* <div>
                <input
                  className="small"
                  type="number"
                  name="alvo"
                  id="alvo"
                  value={
                    filterOrdersPending.alvo > 0 ? filterOrdersPending.alvo : ""
                  }
                  onChange={(ev) => handleFilter("alvo", ev.target.value)}
                  placeholder="% alvo"
                />
              </div> */}
              <div>
                <label htmlFor="fromClosed">
                  De: &nbsp;
                  <input
                    type="date"
                    name="fromClosed"
                    id="fromClosed"
                    value={
                      filterOrdersPending.emissaoInicial !== "today"
                        ? formatedDate(filterOrdersPending.emissaoInicial)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoInicial", ev.target.value)
                    }
                  />
                </label>
              </div>
              <div>
                <label htmlFor="toClosed">
                  Até: &nbsp;
                  <input
                    type="date"
                    name="toClosed"
                    id="toClosed"
                    value={
                      filterOrdersPending.emissaoFinal !== "today"
                        ? formatedDate(filterOrdersPending.emissaoFinal)
                        : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(ev) =>
                      handleFilter("emissaoFinal", ev.target.value)
                    }
                  />
                </label>
              </div>
            </form>
          )}
        </Row>
        {openOrders === "open" ? (
          <Row wide={"auto"} padding={"0 20px 10px 20px"} className="loading">
            {loadingSellOrder ? (
              <span>
                <AiOutlineLoading /> &nbsp;
              </span>
            ) : update ? (
              <span>
                <AiOutlineLoading /> &nbsp;
              </span>
            ) : null}
            <div className="buttonsAction">
              <Button
                className="small"
                disabled={update}
                onClick={() => getTableFilds("update")}
              >
                Atualizar
              </Button>
              {user?.id === ADMIN_ID ? (
                <Button className="small" onClick={() => handleModal()}>
                  Nova Ordem
                </Button>
              ) : null}
              {/* {user?.id === ADMIN_ID || true ? (
                <Button className="small" onClick={encerrar}>
                  Encerrar Ordens
                </Button>
              ) : null} */}
              {user?.id === ADMIN_ID ? (
                <Button
                  className="small"
                  disabled={loadingSellOrder}
                  onClick={() => sellOrder()}
                >
                  Vender
                </Button>
              ) : null }
              {/* <Button
                className="small"
                disabled={loadingPauseOrder}
                onClick={() => pauseOrder()}
              >
                Pausar
              </Button> */}
            </div>
          </Row>
        ) : (openOrders === "pending" && (user?.id === ADMIN_ID || true)) ? (
          <Row wide={"auto"} padding={"0 20px 10px 20px"} className="loading">
            <div className="buttonsAction">
              {user?.id === ADMIN_ID ? (
                <Button
                  className="small"
                  disabled={loadingSellOrder}
                  onClick={() => sellOrder()}
                >
                  Vender
                </Button>
              ) : null }
            </div>
          </Row>
        ) : (
          <Row wide={"auto"} className="loading">
            {loadingSellOrder ? (
              <span>
                <AiOutlineLoading /> &nbsp;
              </span>
            ) : update ? (
              <span>
                <AiOutlineLoading /> &nbsp;
              </span>
            ) : (
              <>&nbsp;</>
            )}
          </Row>
        )}
      </DivCard>

      <Content>
        <ListTable
          multipleSelect={openOrders === "open" || openOrders === "pending" ? multipleSelect : false}
          changeSelectedIndexes={setSelectedIndexes}
          fields={
            openOrders === "open"
              ? cols
              : openOrders === "closed"
              ? colsOrdersClosed
              : colsOrdersPending
          }
          data={
            openOrders === "open" && orders
              ? orders.docs
              : openOrders === "closed" && ordersClosed
              ? ordersClosed.docs
              : openOrders === "pending" && ordersPending
              ? ordersPending.docs
              : []
          }
          handleOrderCols={handleOrderCols}
          growing={growing}
          onChangePage={handleChangePage}
          currentPage={openOrders ? currentPage : currentPageClosed}
          totalPages={
            openOrders === "open" && orders
              ? orders.totalPages
              : openOrders === "closed" && ordersClosed
              ? ordersClosed.totalPages
              : openOrders === "pending" && ordersPending
              ? ordersPending.totalPages
              : 1
          }
          count={
            openOrders === "open" && orders
              ? orders.total
              : openOrders === "closed" && ordersClosed
              ? ordersClosed.total
              : openOrders === "pending" && ordersPending
              ? ordersPending.total
              : 0
          }
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10">
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100" selected>100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={
              openOrders === "open" && orders
                ? orders.totalPages
                : openOrders === "closed" && ordersClosed
                ? ordersClosed.totalPages
                : openOrders === "pending" && ordersPending
                ? ordersPending.totalPages
                : 1
            }
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default Order;
