/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import parseDate from 'date-fns/parse';
import formatDate from 'date-fns/format';
import isWeekend from 'date-fns/isWeekend';
import nextMonday from 'date-fns/nextMonday';
import ptBR from 'date-fns/locale/pt-BR';
import { Column } from './types';
import { TableContainer, TableBody } from './styles';
import TableHead from './Head';
import TableToolBar from './Toolbar';
import TableRow from './Row';
import axios from 'axios';
import { BASE_URL } from '../../environment/stores';
import { useAuth } from '../../contexts/auth';
import { getChavePedido } from '../../services/pedidos';

import {treatColField} from '../../utils/form';

import {isValidDate} from '../../utils/date';
import {formatCnpj, formatCpf} from '../../utils/form';

function validVencimento() {
  const today = new Date();
  if (isWeekend(today)) {
    const monday = nextMonday(today);
    return formatDate(monday, 'dd/MM/yyyy');
  } else {
    return formatDate(today, 'dd/MM/yyyy');
  }
}

export interface TableProps {
  title?: string;
  cols?: Column[];
  actions?: string[];
  searchCol?: string;
  loadState?: any;
  item?: any;
  fromForm?: boolean;
  isRecurso?: boolean;
  isTitulo?: boolean;
  chaveCriacao?: number;
  lookupsRegras?: any;
  onChange?: any;
  initialValue?: any[];
  fixedValues?: any[];
  fixedValue?: any;
  withTotal?: boolean;
  disabled?: boolean;
  total?: any[];
}

function Table({
  title,
  cols,
  item,
  actions,
  // searchCol,
  initialValue,
  fixedValue,
  fixedValues,
  loadState,
  fromForm,
  isRecurso,
  isTitulo,
  lookupsRegras,
  onChange,
  withTotal,
  disabled,
  total
}: TableProps) {
  const {user} = useAuth();
  const [data, setData] = useState<any[]>([]);
  const [loadedInitialState, setLoadedInitialState] = useState(false);
  const [fields, setFields] = useState<any>(null);

  useEffect(() => {
    if (loadState && item && cols) {
      getData({
        endpoint: loadState.endpoint,
        filters: {
          classe: loadState.filter.classe,
          [loadState.filter.field]: item.id,
        },
      });
    }
  }, [loadState, item, cols]);

  useEffect(() => {
    if (fromForm) {
      onChange(data);
    }
  }, [data]);

  useEffect(() => {
    if (initialValue && !loadedInitialState && !isRecurso) {
      setData(initialValue);
      setLoadedInitialState(true);
    } 
  }, [initialValue, loadedInitialState]);

  useEffect(() => {
    if (initialValue && isRecurso) {
      loadInitialValueKeys(initialValue);
    } 
  }, [initialValue, isRecurso, isTitulo]);

  async function loadInitialValueKeys(values: any[]) {
    if (values.length > 0 && (values[0].chave || isTitulo)) {
      setData(values);
    }
    for (let i = 0; i < values.length; i++) {
      if (i === 0) {
        setData([]);
      }
      await getKeyAndInsertItem(i !== 0);
    }
  }

  async function getKeyAndInsertItem(add: boolean = false, fromInsert = false) {
    try {
      const result = await getChavePedido(user?.id);
      if (add) {
        let dt: any = {chave: result.chave};
        if (fromInsert) {
          if (data.length === 0 && isTitulo) {
            const vencimento = validVencimento();
            dt = {...dt, prazo: 0, vencimento};
          }
          setData(oldData => { return [...oldData, dt] });
        } else {
          setData(oldData => {
            const newIndex = oldData.length;
            if (initialValue && initialValue.length > 0 && initialValue[newIndex]) {
              dt = {...dt, ...initialValue[newIndex]};
            }
            return [...oldData, dt];
          });
        }
      } else {
        let dt: any = {chave: result.chave};
        if (isTitulo) {
          dt = {...dt, prazo: 0, vencimento: validVencimento()}
        }
        if (initialValue && initialValue.length > 0) {
          dt = {...dt, ...initialValue[0]};
        }
        setData(oldData => {
          return [dt]
        });
      }
    } catch (err: any) {
      getKeyAndInsertItem(add);
      console.error(err);
    }
  }

  async function getData({endpoint, filters}: any) {
    if (!cols) return;
    try {
      const response = await axios.get(`${BASE_URL}/${endpoint}/`, {
        params: filters,
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      setData(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (cols) {
      adjustStartCols();
    }
  }, [cols]);

  function adjustStartCols() {
    if (!cols) return;
    const colsField: any = {};
    
    for (const col of cols) {
      if (col.campo && col.nome) {
        const field = treatColField(col, () => {}, () => {});

        if (field) {
          colsField[col.campo] = field;
        }
      }
    }

    if (Object.keys(colsField).length > 0)
      setFields(colsField);
  }

  const formatPayload = (values: any) => {
    let payload: any = {};
    for (const formFieldKey of Object.keys(values)) {
      const value = values[formFieldKey];
      if (value) {
        if (typeof value === 'object' && value.hasOwnProperty('value')) {
          const idKey = `id${formFieldKey}`;
          payload = {
            ...payload,
            [idKey]: value.value,
          }
        } else {
          const isDate = isValidDate(value);
          if (isDate) {
            payload = {
              ...payload,
              [formFieldKey]: parseDate(value, isDate === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR }),
            }
          } else {
            if (formFieldKey === 'cpfCnpj') {
              if (value === null || value === "") {
                payload[formFieldKey] = null;
              } else {
                let adjustedValue = value.replace(/\D/g, "");
                if (adjustedValue.length < 12) {
                  adjustedValue = formatCpf(adjustedValue);
                } else {
                  adjustedValue = formatCnpj(adjustedValue);
                }
                payload[formFieldKey] = adjustedValue;
              }
            } else {
              payload[formFieldKey] = value === "" ? null : value;
            }
          }
        }
      }
    }

    return payload;
  }

  const onInsert = () => {
    if (isRecurso) {
      getKeyAndInsertItem(true, true);
    } else {
      setData([...data, {}]);
    }
  }

  const onDelete = (index: number) => {
    const newData = data.filter((rowData, idx) => idx !== index);
    setData(newData);
  }

  const handleRowChange = (index: number, values: any) => {
    const payload = formatPayload(values);
    setData(oldValue => oldValue.map((rowData, idx) => idx === index ? payload : rowData));
  }

  const updateFormaPagamento = (_newData: any) => {
    setData([...data, ..._newData]);
  }

  return (
    <>
      <TableToolBar title={title} actions={disabled ? [] : actions} onInsert={onInsert} updateFormaPagamento={title === 'Formas de Pagamento' ? updateFormaPagamento : null} />
      <TableContainer>
        <TableHead 
          columns={actions?.includes('delete') && cols && !disabled ? [{
            name: 'Apagar',
          }, ...cols] : cols ? cols : []}
        />
        <TableBody>
          {
            data.map((dataRows, index) => (
              <TableRow 
                key={index}
                disabled={disabled}
                dataRow={dataRows}
                isRecurso={isRecurso}
                isFormaPagamento={title === 'Formas de Pagamento'}
                lookupsRegras={initialValue ? null : lookupsRegras}
                loadInitialState={loadedInitialState}
                fixedValue={fixedValues && fixedValues.length > 0 ? {
                  ...fixedValue,
                  ...fixedValues[index]
                } : fixedValue}
                fields={fields}
                columns={cols ? cols : []}
                onRowChange={(row: any) => handleRowChange(index, row)}
                rowActions={actions?.includes('delete') && cols ? [
                  {
                    'name': 'remove',
                    handler: () => onDelete(index),
                  }
                ] : []}
              />
            ))
          }
          {withTotal && cols && total && (
            <tr>
              {actions?.includes('delete') && !disabled && (
                <td></td>
              )}
              {cols.map(col => {
                const isOnTotal = total.filter(totalCol => col.campo === totalCol.field)[0];

                if (isOnTotal) {
                  return (
                    <td><span>Total</span><br />{isOnTotal.value}</td>
                  )
                } else {
                  return (
                    <td></td>
                  )
                }
              })}
            </tr>
          )}
        </TableBody>
      </TableContainer>
    </>
  );
}

export default Table;