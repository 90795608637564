import styled from 'styled-components';

import ButtonLayout from '../../components/Button';
import InputLayout from '../../components/Input';
import LookupLayout from '../../components/Lookup';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  color: var(--white);
`;

export const Content = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;

  position: relative;

  &  div {
    margin: 0;
  }

  & h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  
  & h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const ActionsContainer = styled.div`
  width: auto;
  display: flex;
  gap: 16px;

  & > button {
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 12px;
  padding: 10px;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  & > div {
    width: 100%;
  }

  & > div:last-child {
    max-height: 75vh;
  }
`;

export const PermissionsTable = styled.table`
  border-collapse: collapse;
  font-weight: 200;
  position: relative;
  width: 100%;
  min-width: 60vw;

  & thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--white);

    & th {
      white-space: nowrap;
      color: var(--primary-text-color);
      letter-spacing: 1.5px;
      font-weight: 200;
      font-size: 12px;
      text-align: left;
      text-transform: capitalize;
      padding: 4px;
      border-bottom: 1px solid var(--opacity-background);
      transition: .2s;

      &:first-child {

        & button {
          background-color: transparent;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      &:nth-child(2) {
        padding: 4px 8px;
      }
    }
  }

  & tbody {
    overflow: auto;

    & tr:nth-child(odd) {
      background-color: var(--opacity-background);
    }

    & tr:nth-child(even) td{
      border: 1px solid var(--opacity-background);
    }

    & tr:nth-child(odd) td{
      border: 1px solid var(--white);
    }

    & td {
      font-size: 14px;
      text-align: left;
      text-transform: capitalize;
      vertical-align: center;
      border-bottom: 1px solid var(--opacity-background);
      padding: 4px;
      white-space: nowrap;

      &:first-child {
        padding: 8px;
      }

      &:nth-child(2) {
        padding: 4px 8px;
      }
    }
  }

  & tr {
    position: relative;
  }
`;

export const Input = styled(InputLayout)`
  margin-bottom: 16px;
`;

export const Lookup = styled(LookupLayout)`
`;