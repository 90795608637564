import Toast from "../components/Toast";
import Provider from "./config";

export const getSearchEntities = async (search: string) => {
  try {
    const response = await Provider.get("entidades", {
      params: {
        nome: search,
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar as corretoras.");
    }
  }
};

export const getEntities = async (filters: any) => {
	const params: any = {
		classe: "Usuários",
    page: 1
	}
	if (filters) {
		if(filters.idRepresentante !== "idRepresentante") {
			params["id"] = filters.idRepresentante
		}
		if(filters.idClasseUsuario !== "idClasseUsuario") {
			params["idClasse"] = filters.idClasseUsuario
		}
		if (filters.idIndicador !== "idIndicador") {
			params["idIndicador"] = filters.idIndicador
		}
	}
  try {
	const response = await Provider.get("entidades", { params })
	
	return response.data
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar as entidades.");
    }
  }
};

export const sendLembretePagamento = async (idUsuarios: string[]) => {
  try {
    const response = await Provider.post("entidades/lembrete-pagamento", {
      idUsuarios,
    });
    Toast.show("Aviso Enviado", "success");
    return response.data;
  } catch (error) {
    Toast.show("Erro ao enviar os dados.", "error");
  }
};
