import Provider from "./config";

export const getBinance = async (id: any) => {
  try {
    const result = await Provider.get("exchanges/account/binanceInfo/", {
      params: { idUser: id },
    });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao consultar API Binance.");
  }
};

export const getDashboard = async (
  id: any,
  strTime: any,
  endTime: any,
  dayStrTime: any,
  crtTime: any
) => {
  try {
    const result = await Provider.get("/exchanges/account/dashboard/", {
      params: {
        idUser: id,
        startTime: strTime,
        endTime: endTime,
        dailyStartTime: dayStrTime,
        dailyEndTime: crtTime,
      },
    });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar dados dos gráficos.");
  }
};
