export const formatMoney = (value: number | string, coin?: string, currency?: string) => 
  Number(value).toLocaleString(coin ? coin : 'pt-br', { style: 'currency', currency: currency ? currency : 'BRL' });

export const formatDate = (date: string | Date): string => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });
  }
  return date.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
  });
};

export const formatPhoneNumber = (phone: string) => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/(\d{0,2})(\d{1})(\d{0,4})(\d{0,4})/);
  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
  }
  return null;
};

export const formatCPF = (cpf: string) => {
  const cleaned = `${cpf}`.replace(/\D/g, '');
  const match = cleaned.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
  }
  return null;
};


export const formatCoin = (value: number) => {
  
  if(value.toString().length == 10){
    const amount = (value/1000000000).toString().slice(0, 4) 
    return amount + ' B' //value.toString().slice(0, 1) + 'B'
  } else if(value.toString().length == 9){
    const amount = (value/100000000).toString().slice(0,4)
    return amount + ' M' //value.toString().slice(0, 3) + 'M'
  } else if(value.toString().length == 8){
    const amount = (value/10000000).toString().slice(0,4)
    return amount + ' M' //value.toString().slice(0, 2) + 'M'
  } else if(value.toString().length == 7){
    const amount = (value/1000000).toString().slice(0,4)
    return amount + ' M'// value.toString().slice(0, 1) + 'M'
  }
}