import styled from 'styled-components';

import InputLayout from '../../components/Input';
import ButtonLayout from '../../components/Button';
import LinkButtonLayout from '../../components/LinkButton';
import InputMask from 'react-input-mask';

import Background from '../../assets/images/background.jpg';
import { AiOutlineEyeInvisible } from 'react-icons/ai';

interface IWide{
  wide?: string;
}

export const Container = styled.div`
  background: var(--background);
  width: 100%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    overflow: auto;
  }
`;

export const FormContainer = styled.div`
  background-color: var(--form-background);
  width: 50%;
  min-height: 300px;
  padding: 16px;
  /* box-shadow: 5px 7px 3px -2px rgba(80,80,80,0.41); */
  box-shadow: 0 0 10px 2px var(--secondary);
  border-radius: 2px;

  & form fieldset:nth-last-child(3n){
    margin-bottom: 32px;
  }

  @media(max-width: 1048px) {
    & {
      width: 80%;
    }
  }

  @media(max-width: 420px) {
    & {
      width: 90%;
    }
  }
`;

export const Form = styled.form``;

export const Input = styled(InputLayout)<IWide>`
  margin-bottom: 16px;
  width: ${props => props.wide ? props.wide : ''};

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const Button = styled(ButtonLayout)`
  margin-top: 10px;
  margin-bottom: 16px;
  color: black;

  & > span > svg{
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Link = styled(LinkButtonLayout)`
  color: var(--white);
  margin-bottom: 16px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 70px;
  object-fit: contain;
  margin-bottom: 32px;
`;

export const Title = styled.p`
  font-size: 26px;
  font-weight: bold;
  /* color: var(--primary-text-color); */
  color: var(--white);
`;

export const Subtitle = styled.p`
  font-size: 22px;
  font-weight: 300;
  color: #dadada;
  margin-bottom: 32px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button{
    background: transparent;
    margin-left: auto;
    margin-right: 6px;
    margin-bottom: -60px;
    z-index: 1;
  }

  svg{
    font-size: 30px;
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

export const ColumnInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 49%;

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const MaskInput = styled(InputMask)`
  height: 35px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
`;