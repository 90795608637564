import styled from 'styled-components';

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

export const Page = styled.div`
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  border-radius: 10px;
  border: 1px solid var(--muted);
  height: auto;
  width: auto;
  /* background-color: var(--white); */

  input[type="file"] {
    display: none;
  }
`;

export const ImageHolder = styled.div`
  margin: auto;
  box-sizing: border-box;
  width: auto;
  border: 1px solid var(--opacity-background);
  border-radius: 10px;
`;

export const Image = styled.img`
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
  width: 146px;
`;

export const Label = styled.div<ILabel>`
  width: 100%;
  display: flex;
  justify-content: center;
  
  label {
    margin: auto;
    margin-top: 8px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${props => props.error ? 'var(--warning)' : 'var(--white)'};
    font-weight: ${props => props.bold ? '500': '300'};

    & svg {
      margin-right: 8px;
    }
  }
`;