import Toast from '../components/Toast';
import { ID_CLASSE_REGRA_PRECIFICACAO } from '../environment';
import provider from './config';

export const calcularPedido = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/calcular/', payload);
  
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, "error");
      } else {
        throw Error("Ocorreu um erro ao tentar calcular o pedido");
      }
    }
}

export const getTitulosCondicao = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/gerar-titulos-condicao/', payload);
  
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        Toast.show(err.response.data.message, "error");
      } else {
        throw Error("Ocorreu um erro ao tentar gerar os títulos da condição de pagamento");
      }
    }
}

export const criarPedido = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/pedidos/', payload);

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar criar o pedido");
    }
  }
}

export const updatePedido = async (id: number, payload: any): Promise<any> => {
  try {
    const response = await provider.put(`/pedidos/${id}`, payload);

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar atualizar o pedido");
    }
  }
}

export const regraClasse = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-classe', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar a regra de classe");
    }
  }
}

export const regraNucleo = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-nucleo', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar a regra de núcleo");
    }
  }
}

export const regraPreco = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/precificacao', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar a regra de preço");
    }
  }
}

export const getChavePedido = async (idUsuario: any): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/chave-pedido', {
      params: {
        idUsuario,
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar a chave de criação");
    }
  }
}

export const checkChavesParaBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixas', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar as baixas dos items");
    }
  }
}

export const checkIfPedidoHasBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixa-pedido-aprovado', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao verificar se o pedido tem baixa");
    }
  }
}

export const getItemsAndTitulos = async (chaves: number[], all = false): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/items-titulos', {
      params: {
        chaves: chaves.join(','),
        all,
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Ocorreu um erro ao tentar buscar os items e títulos");
    }
  }
}

export const getReport = async () => {
  try {
    const response = await provider.get('/pedidos/', {
      params: {
        page: 1,
        classe: 'Rendas',
        baixa: true
      }
    })

    return response.data
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Erro ao buscar os pedidos de saída.")
    }
  }
}

export const getPedidosComissao = async (endpoint: string, page: number, idUser?: string, take?: any, filter?: any) => {
  const params: any = {
    page: page,
    idUser: idUser,
    take: take
  };

  if (filter) {
    if (filter.idRepresentante !== "idRepresentante") {
      params["idUser"] = filter.idRepresentante
    }
    if (filter.baixado !== "baixado") {
      params["baixado"] = filter.baixado
    }
    if (filter.referencia !== "referencia") {
      params["referencia"] = filter.referencia
    }
  }

  try {
    const response = await provider.get(`${endpoint}/pedidos-comissao`, { params })

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Erro ao buscar os pedidos de comissão.")
    }
  }
} 

export const getListarOrdens = async (endpoint: string, chcriacao: number, idUser?: string) => {
  try {
    const response = await provider.get(`${endpoint}/listar-ordens`, {
      params: {
        chcriacao: chcriacao,
        idUser: idUser
      },
    })

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Erro ao buscar ordens.")
    }
  }
}

export const gerarPagamentoPix = async (chcriacao: number, idUser: string) => {
  try {
    const response = await provider.post('titulos/gera-pagamento-comissao/', { chcriacao, idUser })

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
      throw Error("Erro ao gerar PIX")
    }
  }
}

export const getConfirmPix = async (idChCriacao: number) => {
	try {
		const response = await provider.get("titulos/verifica-baixa/", {
			params: {
				chcriacao: idChCriacao
			}
		})

		return response.data
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao confirmar pagamento no PIX");
		}
	}
}

export const getPriceSetup = async () => {
  try {
    const response = await provider.get("tabela/regras-preco", {
      params: {
        idClasse: ID_CLASSE_REGRA_PRECIFICACAO
      }
    });
    return response.data;
  } catch (error:any) {
    Toast.show("Erro ao buscar preco do setup", "error");
    throw Error(error.response);
  }
}

export const solicitaSaqueSaldo = async (idUser: string) => {
  try {
    const response = await provider.post("pedidos/saque", {
      idUser: idUser
    });
    return response.data;
  } catch (error:any) {
    Toast.show("O correu um erro ao solicita o saque", "error");
    throw Error(error.response);
  }
}