/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestHeaders } from 'axios';
import React, { useState, useEffect } from 'react';
import { ActionMeta } from 'react-select';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Container, Label, AsyncSelect } from './styles';

import FormTemplate from '../../components/Template/forms';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/auth';
import { useFormModal } from '../../contexts/formModal';

export interface ISelectItem {
  label: string;
  value: string;
}

// const treeify = (nodes: any[], field: string) => { 
//   const indexed_nodes: any = {};
//   const tree_roots: any = [];
//   for (let i = 0; i < nodes.length; i += 1) {
//     indexed_nodes[nodes[i].id] = nodes[i];
//   }
//   for (let i = 0; i < nodes.length; i += 1) {
//     const parent_id = nodes[i][field];
//     if (parent_id === 0 || parent_id === null) {
//       tree_roots.push(nodes[i]);
//     } else {
//       const indexed = indexed_nodes[parent_id];
//       if (!indexed) {
//         tree_roots.push(nodes[i]);
//       } else {
//         if (indexed.hasOwnProperty('children')) {
//           indexed.children.push(nodes[i]);
//         } else {
//           indexed.children = [nodes[i]];
//         }
//       }
//     }
//   }
//   return tree_roots;
// };
interface Props {
  key?: string;
  label: string;
  endpoint: string;
  field: string;
  uf?: string;
  filters?: any;
  multi?: boolean;
  token?: string;
  onChange?(options: ISelectItem[]): void;
  disabled?: boolean;
  initialState?: any;
  data?: any;
  require?: boolean;
  error?: boolean;
}

const LookupInput: React.FC<Props> = ({
  key, label, endpoint, uf, field, filters,
  token, disabled, onChange, data: requestData,
  initialState, multi = true, require, error
}) => {
  const lookupHandler = useFetch({ endpoint });
  const { openModal, closeModal } = useFormModal();
  const { user } = useAuth();
  const [value, setValue] = useState<ISelectItem[]>([]);
  const [baseOptions, setBaseOptions] = useState<ISelectItem[]>([]);

  useEffect(() => {
    if (initialState) {
      if (initialState.byName) {
        loadOptions(initialState.byName, true);
      } else if (initialState.byId) {
        loadOptions('', true);
      } else {
        setValue(initialState);
      }
    }
  }, [initialState]);

  useEffect(() => {
    if (uf) {
      loadBaseOptions();
    }
  }, [uf])

  useEffect(() => {
    loadBaseOptions();
  }, [])

  const loadBaseOptions = async () => {
    const options = await loadOptions('', true);
    setBaseOptions(options);
  }

  const loadOptions = async (
    inputValue: string,
    loadInitialState: boolean = false,
  ) => {
    if (!loadInitialState) return;
    const headers: AxiosRequestHeaders = {};
    const userToken = token ? token : user?.token;

    if (userToken) {
      headers.Authorization = `Bearer ${userToken}`;
    }

    let params: any = {
      // page: 1,
    };

    if (inputValue) {
      params = { ...params, [field]: inputValue, };
    }

    if (uf) {
      params = { ...params, uf };
    }

    if (filters) {
      params = { ...params, ...filters, };
    }

    if (endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/') {
      params = {
        ...params,
        all: true,
      }
    }

    if (requestData) {
      const formatted = requestData.map((item: any) => {
        return {
          value: item,
          label: item,
          item,
        };
      });

      return formatted;
    }

    let data = await lookupHandler.get(params, headers);

    if (data.docs) {
      data = data.docs;
    }

    const formatted = [];

    if (data.length <= 0 && initialState) {
      handleChange([]);
    }

    for (const item of data) {
      let found = false;
      const codigo = `${item.codigo ? item.codigo : ''}${item.cpfCnpj ? ` - ${item.cpfCnpj}` : ''}`;
      if (initialState) {
        if (initialState.byName) {
          let itemNome = item.nome ? item.nome.toLowerCase() : '';
          let initialStateByName = initialState.byName ? initialState.byName.toLowerCase() : '';
          let itemCodigo = item.codigo ? item.codigo.toLowerCase() : '';
          if (itemNome === initialStateByName || itemCodigo === initialStateByName) {
            handleChange({
              value: item.id,
              label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
              item,
            });
            found = true;
          } else {
            handleChange([])
          }
        } else if (initialState.byId && item.id === initialState.byId) {
          handleChange({
            value: item.id,
            label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
            item,
          });
          found = true;
        }
      }

      formatted.push({
        value: item.id,
        label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
        item,
      });

      if (found) {
        break;
      }
    }

    return formatted;
  };

  const handleChange = (newValue: unknown, actionMeta?: ActionMeta<unknown>) => {
    if (onChange && newValue) {
      const options = newValue as ISelectItem[];
      onChange(options);
      setValue(options);
    }
  }

  const handleSubmitModal = (item: any) => {
    const newValue = {
      value: item.id,
      label: item.nome,
      item,
    };
    setBaseOptions(prev => [...prev, newValue]);
    handleChange(newValue);
    closeModal();
  }

  const handleOpenModal = () => {
    openModal({
      type: 'confirm',
      title: `Adicionar ${label}`,
      children: () => <FormTemplate
        filters={{ ...filters }}
        customFields={{}}
        title={label}
        endpoint={`${endpoint}`}
        onSubmitCallback={handleSubmitModal} />,
    })
  }

  return (
    <Container>
      <Label error={error ? true : false} bold={require}>{label}
        {require ? <span>*</span> : null}
        {endpoint !== "/classes" ? <IoAddCircleOutline style={{ cursor: 'pointer' }} type="button" size={16} onClick={handleOpenModal} color="var(--primary-text-color)" /> : null}
      </Label>
      <AsyncSelect
        isDisabled={disabled}
        isMulti={multi}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        maxMenuHeight={150}
        value={value}
        loadOptions={loadOptions}
        defaultOptions={baseOptions}
        onChange={handleChange}
        noOptionsMessage={() => "Nenhum item"}
        placeholder=""
        loadingMessage={() => "Carregando..."}
      />
      
    </Container>
  );
}

export default LookupInput;