import styled from 'styled-components';
import AsyncSelectLayout from 'react-select/async';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: .2s;
`;

export const AsyncSelect = styled(AsyncSelectLayout)`
  & > div {
    box-shadow: none;
  }

  & > div:nth-child(3) {
    width: 100%;
    padding: 0px 8px 0px 8px;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    box-shadow: none;
    font-size: 12px;
    font-weight: 300;
    color: var(--warning);

    & > div {
      padding: 0;
    }

    & > div > div > div {

      background-color: red;
    }

    &:focus {
      border: none;
    }
  }
`;