import React, { useState } from 'react';
import { Button, MContainer } from './styles';
import { activeTrialForUser } from '../../services/indications';
import Toast from '../../components/Toast';
import { format } from 'date-fns';

interface IModalTrialUser {
  user: any;
  callback: () => void;
}

const ModalTrialUser: React.FC<IModalTrialUser> = ({
  user, callback
}) => {
  const [loading, setLoading] = useState(false);

  const submitTrialUser = async () => {
    setLoading(true);
    try {
      await activeTrialForUser(user.id);
      Toast.show("Trial Ativado", "success");
      callback();
    } catch (error:any) {
      Toast.show(error.message, "error");
    }
    setLoading(false);
  }

  if (user.dtFimTrial) {
    return(<MContainer>
      <p>O usuário {user.nome} já foi ativado com a versão Trial!</p>
      <p><strong>Dt. Fim Trial</strong> {format(new Date((user.dtFimTrial) as string), "dd/MM/yyyy HH:mm")}</p>
    </MContainer>
    )
  }
  return (<MContainer>
    <p>Deseja Habilitar o Versão Trial para <strong>{user.nome}</strong> ?</p>
    <div className='row'>
      <Button disabled={loading} onClick={submitTrialUser}>Habilitar</Button>
    </div>
  </MContainer>
  );
}

export default ModalTrialUser;