/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import useFetch from '../../../hooks/useFetch';
import {Input, Select} from '../styles';

interface Props {
  token?: string;
  onChange(payload: any): void;
  initialState?: any;
  parent?: any;
}

const ClassForm: React.FC<Props> = ({token, onChange, initialState, parent}) => {
  const classesHandler = useFetch({
    endpoint: '/classes/'
  });

  const [payload, setPayload] = useState({
    nome: '',
    idPai: '',
  });
  const [classes, setClasses] = useState<any[]>([]);

  useEffect(() => {
    getClasses();
  }, []);

  useEffect(() => {
    if (initialState) {
      if (initialState.idPai.includes('/')) {
        const split = initialState.idPai.split('/');
        setPayload({
          nome: initialState.nome,
          idPai: split[split.length - 1],
        });
      } else {
        setPayload({
          nome: initialState.nome,
          idPai: initialState.idPai,
        });
      }
    }
  }, [initialState]);

  useEffect(() => {
    if (parent) {
      if (parent.idPai.includes('/')) {
        const split = parent.id.split('/');
        setPayload({
          ...payload,
          idPai: split[split.length - 1],
        });
      } else {
        setPayload({
          ...payload,
          idPai: parent.id,
        });
      }
    }
  }, [parent]);

  useEffect(() => {
    onChange(payload);
  }, [payload]);

  async function getClasses() {
    const response = await classesHandler.get({}, {
        Authorization: `Bearer ${token}`,
    });
    
    if (!initialState && !parent)
      handleChangeValue('idPai', response[0].id);

    const treatedClasses = treatClasses(response);

    setClasses(treatedClasses);
  }

  const treatClasses = (classesList: any, level: number = 0) => {
    let result: any[] = [];
    for (const classe of classesList) {
        if (initialState) {
          if (initialState.id === classe.id) {
            continue;
          }
        }

        result.push({
          id: classe.id,
          name: `${"-".repeat(level)} ${classe.nome}`,
        });

        if (classe.children) {
            const children = treatClasses(classe.children, level + 1);
            result = [...result, ...children];
        }
    }

    return result;
  }

  const handleChangeValue = (field: string, value: string) => {
    setPayload({...payload, [field]: value});
  }

  return (
    <>
        <Input
            id="nome"
            label="Nome"
            value={payload.nome}
            onChange={({currentTarget: {value}}) => handleChangeValue('nome', value)}
        />
        <Select
            id="pai"
            label="Classe mãe"
            options={classes}
            value={payload.idPai}
            onChange={({currentTarget: {value}}) => handleChangeValue('idPai', value)}
        />
    </>
  );
}

export default ClassForm;