import React, { useCallback, useEffect, useRef, useState } from "react";
import { 
  Container, Content, HeaderCheckout, Input, UserForm,
  Row, ColumnInput
} from "./styles";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineLoading } from "react-icons/ai";
import { FormikHelpers, useFormik } from "formik";
import { IRegisterValues } from "../Register";
import { useHistory, useLocation } from "react-router-dom";
import { getUserValidate } from "../../services/user";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { gerarPixCadastro, postRegister } from "../../services/register";
import { Button, Link } from "../Register/styles";
import { useFormModal } from "../../contexts/formModal";
import { ContainerModal } from "../OrdersCommission/style";
import QRCode from "qrcode.react";
import { copyToClipboard } from "../../utils/copyToLink";
import { getConfirmPix, getPriceSetup } from "../../services/pedidos";
import { formatMoney } from "../../utils/format";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Checkout: React.FC<any> = () => {
  const query = useQuery();
  const history = useHistory();
  const RefChcriacao = useRef();
  const INTERVAL = useRef<NodeJS.Timer | undefined | null>(null);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>("");
  const [price, setPrice] = useState<any>(null);
  const { openModal, closeModal } = useFormModal();
  const initialValues: IRegisterValues = {
    nome: "",
    email: "",
    telefone: "",
    senha: "",
    confirm_password: "",
  };

  useEffect(() => {
    const idUser = query.get("id");
    if (idUser) getIdUser(idUser);
    getPrice();
  }, []);

  const getPrice = async () => {
    try {
      const response = await getPriceSetup();
      if(Array.isArray(response) && response.length > 0) {
        setPrice(response[0]);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getIdUser = async (idUser: string) => {
    try {
      const response = await getUserValidate(idUser);

      if (response.result === "OK") {
        setId(idUser);
      } else {
        toast.error("Usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmit = async (
    values: IRegisterValues,
    helpers: FormikHelpers<IRegisterValues>
  ) => {
    setLoading(true);
    try {
      let { nome, email, telefone, senha } = values;
      // dataNascimento = dataNascimento?.replaceAll("/", "-");
      // dataNascimento = dataNascimento?.split("-").reverse().join("-");
      // dataNascimento += "T00:00:00.000Z";
      let idUser = id !== "" ? id : null;

      const result = await postRegister({
        nome,
        email,
        telefone: telefone?.replaceAll("_", ""),
        senha,
        idUser,
      });
      if (result.status === 201) {
        const { id } = result.data
        handleCheckout(id)
      }
    } catch (err: any) {
      console.error("Erro: ", err);
      Toast.show(err.message, "error");
      setLoading(false);
    }
    
  };

  const validate = (values: IRegisterValues) => {
    const errors: IRegisterValues = {};

    if (!values.nome) {
      errors.nome = "* Obrigatório";
    }

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(values.email)
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    if (!values.telefone) {
      errors.telefone = "* Obrigatório";
    }

    if (!values.senha) {
      errors.senha = "* Obrigatório";
    } else if (values.senha.length < 8) {
      errors.senha = "A senha precisa ter no mínimo 8 dígitos";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "* Obrigatório";
    } else if (values.confirm_password !== values.senha) {
      errors.confirm_password =
        "O campo confirmar senha precisa ser igual a senha.";
    }

    return errors;
  };

  const handleCheckout = async (id: string) => {
    try {
      const result = await gerarPixCadastro(id);
      if (result) {
        handleModalPix(result.qrCode);
        RefChcriacao.current = result.chcriacao;
        INTERVAL.current = setInterval(
          () => watchPaymentStatus(RefChcriacao.current),
          2000
        );
        
        setLoading(false);
      }

    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const watchPaymentStatus = async (chcriacao: any) => {
    try {
      const response = await getConfirmPix(chcriacao);

      const { baixado } = response;
      if (baixado === true) {
        clearInterval(INTERVAL.current!);
        INTERVAL.current = null;
        closeModal();
        toast.success("Pagamento realizado com sucesso.");
        toast.success("Verifique seu e-mail.");
        history.push("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const registerForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const handleModalPix = (qrCode: string) => {
    openModal({
      type: "confirm",
      title: "QR Code",
      wide: "340px",
      children: () => renderContentModal(qrCode),
    });
  };

  const renderContentModal = (qrcode: string) => {
    return (
      <ContainerModal
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QRCode
          value={qrcode}
          style={{ border: "1px solid white", height: 200, width: 200 }}
        />
        <Button
          onClick={() => handleCopyPix(qrcode)}
          style={{
            color: "gray",
            fontWeight: 500,
            marginLeft: "auto",
            marginTop: 20,
            width: "fit-content",
          }}
        >
          Copiar QrCode
        </Button>
      </ContainerModal>
    );
  };

  const handleModalLoading = () => {
    openModal({
      type: "confirm",
      wide: "340px",
      children: renderLoading,
    });
  };

  const renderLoading = () => {
    return (
      <ContainerModal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          minWidth: 300,
        }}
      >
        <span className="loading">
          <AiOutlineLoading /> &nbsp;
        </span>
      </ContainerModal>
    );
  };

  const handleCopyPix = async (text: string) => {
    copyToClipboard(text);
    return Toast.show("O link foi copiado", "success");
  };

  return (
    <Container>
      <Content>
        <HeaderCheckout>
          <h4>Setup Robo Devari Crypto</h4>
          <p>
            {price ? formatMoney(price.preco) : `R$ --`}
          </p>
        </HeaderCheckout>
        <hr />
        <UserForm onSubmit={registerForm.handleSubmit} autoComplete="off">
          <Input
            id="nome"
            name="nome"
            placeholder="Digite seu nome..."
            label="Nome"
            value={registerForm.values.nome}
            error={registerForm.errors.nome}
            onChange={registerForm.handleChange}
          />
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={registerForm.values.email}
            error={registerForm.errors.email}
            onChange={registerForm.handleChange}
          />
          <Input
            id="telefone"
            name="telefone"
            masked={true}
            mask={"+999 (99) 99999-9999"}
            placeholder="Digite seu telefone..."
            label="Telefone"
            value={registerForm.values.telefone}
            error={registerForm.errors.telefone}
            onChange={registerForm.handleChange}
          />
          <Row>
            <ColumnInput>
              <button
                className="password"
                type="button"
                onClick={() => setPassword(!password)}
              >
                {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="senha"
                name="senha"
                placeholder="Digite sua senha..."
                label="Senha"
                type={password ? "text" : "password"}
                value={registerForm.values.senha}
                error={registerForm.errors.senha}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
            <ColumnInput>
              <button
                className="confirmPassword"
                type="button"
                onClick={() => setConfirmPassword(!confirmPassword)}
              >
                {confirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirme sua senha"
                label="Confirmação de senha"
                type={confirmPassword ? "text" : "password"}
                value={registerForm.values.confirm_password}
                error={registerForm.errors.confirm_password}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
          </Row>
          <Button
            disabled={loading}
            variant="filled"
            type="submit"
          >
            Cadastrar &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
          <Link variant="outlined" to={{ pathname: "/login" }}>
            Voltar ao Login
          </Link>
        </UserForm>
      </Content>
    </Container>
  );
}

export default Checkout;