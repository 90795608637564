import React, { useEffect, useState } from "react";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import useFetch from "../../hooks/useFetch";
import { editAlert, getAlerts, getValueAlert } from "../../services/alert";
import { getWhiteListOnlyNames } from "../../services/whiteList";
import { Button, Column, FormModal, Row } from "./styles";

interface IPriceAlertModal {
  title: string;
  endpoint: string;
  item?: object;
  getTableFieldsAlerts(): void;
}

interface IValurAlert {
  valor: string;
  percValor: string;
}

var timer: any;

const PriceAlertModal: React.FC<IPriceAlertModal> = ({
  title,
  endpoint,
  item,
  getTableFieldsAlerts,
}) => {
  const { closeModal } = useFormModal();
  const { user } = useAuth();
  const postProvider = useFetch({ endpoint: `${endpoint}/create-alert` });
  // const [idItem, setIdItem] = useState();
  // const [initialValues, setInitialValues] = useState({
  //   alertType: "",
  //   target: "",
  //   condition: "",
  //   symbol: "",
  // });
  const [coin, setCoin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    alertType: "Preço ultrapassar",
    valor: "",
    condition: "Somente uma vez",
    symbol: "",
    percValor: "",
  });

  useEffect(() => {
    getCoin();
  }, []);

  // useEffect(() => {
  //   if (item) {
  //     let itens = [];
  //     itens.push(item);
  //     var arr = itens.map(function (obj: any) {
  //       return Object.keys(obj).map(function (key) {
  //         return obj[key];
  //       });
  //     });
  //     let allItems = arr[0];
  //     setIdItem(allItems[1]);
  //     setInitialValues(allItems[0]);
  //   }
  // }, []);

  const getCoin = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  const getAlerts = async () => {
    try {
      const result = await getValueAlert(payload);
      if (result) {
        const obj = Object.entries(result);
        let key: any = obj[0][0];
        let value: any = obj[0][1];

        setPayload({
          ...payload,
          [key]: value.toString(),
        });
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleValue = (field: string, value: any) => {
    setPayload({ ...payload, [field]: value });
  };

  function debounce(func?: any, timeout = 1000) {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }

  function doSomething() {
    getAlerts();
  }

  const debounced = debounce(doSomething);

  const handleSubmit = async () => {
    if (validation()) {
      setLoading(true);
      try {
        let id = user?.id;
        const _payload: any = payload;
        _payload["userId"] = id;
        if (id) {
          const result = await postProvider.post(_payload);
          if (result) {
            // Toast.show("Alerta criado com sucesso.", "success");
            closeModal();
            getTableFieldsAlerts();
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
      setLoading(false);
    }
  };

  const validation = () => {
    if (payload.alertType === "") {
      Toast.show("O campo tipo de alerta precisa ser preenchido.");
    } else if (
      ["Preço ultrapassar", "Preço cair para"].includes(payload.alertType) &&
      payload.valor === ""
    ) {
      Toast.show("O campo valor alvo precisa ser preenchido.");
    } else if (payload.condition === "") {
      Toast.show("O campo frequencia precisa ser preenchido.");
    } else if (payload.symbol === "") {
      Toast.show("O campo moeda precisa ser preenchido.");
    } else if (
      ["Variação acima de", "Variação menor que"].includes(payload.alertType) &&
      payload.percValor === ""
    ) {
      Toast.show("O campo alvo precisa ser preenchido.");
    } else {
      return true;
    }
  };

  return (
    <FormModal>
      <Row padd="10px" jContent="space-between">
        <Column>
          <label htmlFor="alertType">Tipo de alerta</label>
          <select
            name="alertType"
            id="alertType"
            onChange={({ target }) => handleValue("alertType", target.value)}
          >
            <option value="Preço ultrapassar" selected>
              Preço ultrapassar
            </option>
            <option value="Preço cair para">Preço cair para</option>
            <option value="Variação acima de">Variação acima de</option>
            <option value="Variação menor que">Variação menor que</option>
          </select>
        </Column>
        <Column>
          <label htmlFor="mySymbol">Moeda</label>
          <input
            className="datalist"
            list="symbol"
            name="mySymbol"
            id="mySymbol"
            type="search"
            autoComplete="chrome-off"
            placeholder="Selecione..."
            onChange={(ev) => handleValue("symbol", ev.target.value)}
          />
          <datalist id="symbol">
            {coin
              ? coin.map((item: string) => <option value={item}>{item}</option>)
              : null}
          </datalist>
        </Column>
        <Column>
          <label
            htmlFor="percValor"
            className={payload.symbol === "" ? "disabled" : ""}
          >
            Alvo
          </label>
          <div className="input-group">
            <input
              type="number"
              name="percValor"
              id="percValor"
              min={0}
              value={payload.percValor ? payload.percValor : ""}
              disabled={payload.symbol === ""}
              placeholder="% 0"
              onKeyUp={() => debounced()}
              onChange={({ target }) => handleValue("percValor", target.value)}
            />
            <span className="input-group-addon">%</span>
          </div>
        </Column>
        <Column>
          <label
            htmlFor="valor"
            className={payload.symbol === "" ? "disabled" : ""}
          >
            Valor Alvo
          </label>
          <input
            type="number"
            name="valor"
            id="valor"
            min={0}
            value={payload.valor ? payload.valor : ""}
            disabled={payload.symbol === ""}
            placeholder="0"
            onKeyUp={() => debounced()}
            onChange={({ target }) => handleValue("valor", target.value)}
          />
        </Column>
        {/* <Column>
          <label htmlFor="condition">Frequência</label>
          <select
            name="condition"
            id="condition"
            onChange={({ target }) => handleValue("condition", target.value)}
          >
            <option value="" disabled selected>
              Selecione...
            </option>
            <option value="Somente uma vez">Somente uma vez</option>
            <option value="Uma vez por dia">Uma vez por dia</option>
            <option value="Sempre">Sempre</option>
          </select>
        </Column> */}
      </Row>
      <Row padd="10px" className="rowModalButtons">
        <Button
          type="button"
          wide={"auto"}
          background={"var(--warning)"}
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button
          wide={"150px"}
          mLeft={"10px"}
          disabled={loading}
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={() => handleSubmit()}
        >
          {title}
        </Button>
      </Row>
    </FormModal>
  );
};

export default PriceAlertModal;
