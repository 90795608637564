import styled from "styled-components";
import InputLayout from '../../components/Input';

interface IStyle {
  wide?: string;
}

export const Container = styled.div`
  background: var(--background);
  color: var(--white);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  box-shadow: 0 0 10px 2px var(--secondary);
  border-radius: 2px;
  min-width: 50%;

  hr {
    border: .5px solid var(--secondary);
    margin-bottom: 6px;
  }
`;

export const HeaderCheckout = styled.div`
  font-weight: 600;
  font-size: 26px;
  margin: 6px;
  padding: 6px;
  
  p {
    margin-top: 9px;
    color: var(--secondary);
  }
`;

export const UserForm = styled.form`
  padding: 4px 8px;
`;

export const Input = styled(InputLayout)<IStyle>`
  margin-bottom: 16px;
  width: ${props => props.wide ? props.wide : 'auto'};
`;

export const ColumnInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 49%;

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 2px 6px;
  width: 100%;

  button{
    background: transparent;
    margin-left: auto;
    margin-right: 6px;
    margin-bottom: -60px;
    z-index: 1;
  }

  svg{
    font-size: 30px;
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;