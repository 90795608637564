import styled from "styled-components";
import { ICellProps } from ".";

import SelectLayout from "../Select";
import ButtonLayout from "../Button";

interface IWidth {
  wide?: string;
  background?: string;
}

export const Container = styled.table`
  border-collapse: collapse;
  font-weight: 200;
  position: relative;
  width: 100%;
`;

export const Header = styled.thead`
  background-color: var(--white);
  position: sticky;
  top: -20px;
  z-index: 2;
`;

export const Body = styled.tbody`
  overflow: auto;

  & tr:nth-child(odd) {
    background-color: var(--opacity-background);
  }

  & tr:nth-child(even) td {
    border: 1px solid var(--form-selected);
  }

  & tr:nth-child(odd) td {
    border: 1px solid var(--form-selected);
  }

  & tr > td:nth-child(4),
  tr > td:nth-child(5),
  tr > td:nth-child(6) {
    text-align: right;
  }

  input {
    background-color: inherit;
  }

  select {
    background-color: inherit;
  }
`;

export const Row = styled.tr`
  position: relative;

  .green {
    color: var(--green);
    font-weight: 500;
    text-align: right;
  }

  .danger {
    color: var(--warning);
    font-weight: 500;
    text-align: right;
  }

  .white {
    color: var(--white);
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }
`;

export const HeaderCell = styled.th<ICellProps>`
  background: var(--form-background);
  border-bottom: 1px solid var(--opacity-background);
  color: var(--primary);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.5px;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.2s;
  white-space: nowrap;

  label {
    margin-left: 6px;
  }

  &:first-child {
    padding: 8px;

    & button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &:nth-child(2) {
    padding: 4px 8px;
  }

  &:nth-child(n + 3):hover {
    background-color: var(--opacity-background);
    cursor: grab;
  }
`;

export const Cell = styled.td<ICellProps>`
  border-bottom: 1px solid var(--opacity-background);
  font-size: 14px;
  padding: 8px;
  text-align: left;
  text-transform: capitalize;
  vertical-align: center;
  white-space: nowrap;

  &:first-child {
    padding: 8px;
  }

  &:nth-child(2) {
    padding: 4px 8px;
  }

  & > div > .number {
    text-align: right;
  }
`;

export const Footer = styled.tfoot`
  background-color: var(--form-background);
  bottom: 0;
  position: sticky;
  z-index: 2;
`;

export const FooterContent = styled.div`
  border-top: 1px solid var(--opacity-background);
  border-bottom: 1px solid var(--opacity-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  height: 50px;

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

export const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;

  & > p {
    color: var(--primary-lighter);
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;

    & > b {
      /* font-weight: 500; */
      margin-left: 4px;
    }
  }
`;

export const PaginateContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 600px;

  @media (max-width: 599px) {
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
  }

  & .active-page-item {
    background-color: var(--primary);
    color: var(--white);
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    li {
      padding: 4px 8px;
      margin: 0 2px;
      cursor: pointer;
      color: var(--primary-text-color);
      background-color: var(--white);
      transition: 0.2s;
      border-radius: 2px;
      border: 1px solid var(--input-border-blurred);

      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }

      &:active {
        background-color: var(--primaryDarker);
      }
    }
  }
`;

export const ActionContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const Select = styled(SelectLayout)`
  width: 20%;
`;

export const Button = styled(ButtonLayout)<IWidth>`
  width: ${(props) => (props.wide ? props.wide : "10%")};
  border-radius: 10px;
  margin-left: 8px;

  & > a {
    color: var(--white);
    text-decoration: none;
    padding: 8px;
  }
`;
