import { ID_CLASSE_USUARIOS_TRIAL } from "../environment";
import Provider from "./config";

export const getIndicationsOpen = async (page: any, id: any, filter: any) => {
  try {
    const response = await Provider.get("/entidades/indicacoes", {
      params: {
        page: page,
        idIndicador: id,
        inativo: true,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar indicações abertas.");
  }
};

export const getIndicationsClosed = async (page: any, id: any) => {
  try {
    const response = await Provider.get("/entidades/indicacoes", {
      params: {
        page: page,
        idIndicador: id,
        inativo: false,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar indicações fechadas.");
  }
};

export const activeTrialForUser = async (id: string) => {
  try {
    const dtTrial = new Date();
    dtTrial.setDate(dtTrial.getDate() + 15);
    const response = await Provider.put(`/entidades/${id}`, {
      idClasse: ID_CLASSE_USUARIOS_TRIAL,
      dtFimTrial: dtTrial.toISOString()
    });
    return response.data;
  } catch (error) {
    throw Error("Ocorreu um erro ao torna o usuário Trial");
  }
}
