/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestHeaders } from 'axios';
import React, {useState, useEffect} from 'react';
import { ActionMeta } from 'react-select';
import {Container, AsyncSelect} from './styles';

import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/auth';

export interface ISelectItem {
  label: string;
  value: string;
}

interface Props {
    endpoint: string;
    field: string;
    filters?: any;
    multi?: boolean;
    token?: string;
    onChange?(options: ISelectItem[]): void;
    disabled?: boolean;
    initialState?: any;
    menuPlacement?: "auto" | "top" | "bottom";
}

const LookupInput: React.FC<Props> = ({endpoint, field, filters, token, disabled, onChange, initialState, multi = true}) => {
  const lookupHandler = useFetch({endpoint});
  const {user} = useAuth();
  const [value, setValue] = useState<ISelectItem[]>([]);

  useEffect(() => {
    if (initialState) {
      if (initialState.nome && initialState.id) {
        const toReturn = {
          value: initialState.id,
          label: initialState.nome,
          item: initialState,
        }
        handleChange(toReturn);
        loadOptions('', true, true, toReturn);
      } else if (initialState.byName) {
        loadOptions(initialState.byName, true);
      } else if(typeof initialState === 'string') {
        loadOptions(initialState, true);
      } else if (initialState.byId) {
        loadOptions('', true);
      }
    }
  }, [initialState]);

  const loadOptions = async (
    inputValue: string,
    loadInitialState: boolean = false,
    onlyReturn: boolean = false,
    toReturn: any = null,
  ) => {
    if (!loadInitialState) return;
    if (onlyReturn) {
      return [toReturn];
    }

    const headers: AxiosRequestHeaders = {};
    const userToken = token ? token : user?.token;

    if (userToken) {
        headers.Authorization = `Bearer ${userToken}`;
    }
    
    let params: any = {};

    if (inputValue) {
      params = {...params, [field]: inputValue, }
    }

    if (filters) {
        params = {...params, ...filters};
    }

    if (endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/') {
      params = {
        ...params,
        all: true,
      }
    }
    
    const data = await lookupHandler.get(params, headers);
    const formatted = data.map((item: any) => {
      const codigo = `${item.codigo ? item.codigo : ''}${item.cpfCnpj ? ` - ${item.cpfCnpj}` : ''}`;
      if (initialState) {
        if (initialState.byName && item.nome === initialState.byName) {
          handleChange({
            value: item.id,
            label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
            item,
          });
        } else if (initialState.byId && item.id === initialState.byId) {
          handleChange({
            value: item.id,
            label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
            item,
          });
        }
      } 

      return {
          value: item.id,
          label: endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/' ? item.nome : codigo,
          item,
      };
    });
    return formatted;
  };

  const handleChange = (newValue: unknown, actionMeta?: ActionMeta<unknown>) => {
    if (onChange && newValue) {
      const options = newValue as ISelectItem[];
      onChange(options);
      setValue(options);
    }
  }

  return (
    <Container>
        <AsyncSelect
            isDisabled={disabled}
            isMulti={multi}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            maxMenuHeight={150}
            value={value}
            loadOptions={loadOptions}
            onChange={handleChange}
            defaultOptions
            noOptionsMessage={() => "Nenhum item"}
            placeholder=""
            loadingMessage={() => "Carregando..."}
        />
    </Container>
  );
}

export default LookupInput;