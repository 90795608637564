import React, { useEffect, useState } from "react";
import { getReport } from "../../services/pedidos";
import { formatDate, formatMoney } from "../../utils/format";
import { Container, DivItems, DivRow, Row, Title } from "./style";

interface IReport {
  acrescimo?: string;
  aprovacao?: string;
  baixaAutom?: boolean;
  chcriacao?: number;
  desconto?: string;
  emissao?: string;
  frete?: string;
  ipi?: string;
  observacao?: string;
  seguro?: string;
  totalPedido?: string;
  valor?: string;
  Aprovador?: IAprovador;
  Estabelecimento?: IEstab;
  LocEscritu?: ILocalEsc;
  Pessoa?: IPessoa;
  Representante?: IRepresent;
}

interface IAprovador {
  nome?: string;
}

interface IEstab {
  nome?: string;
}

interface ILocalEsc {
  nome?: string;
}

interface IPessoa {
  nome?: string;
  email?: string;
  celular?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  Cidade?: ICidade;
  UF?: IUF;
  cpfCnpj?: string;
  Classe?: IClass;
}

interface ICidade {
  nome?: string;
  codigo?: string;
}

interface IUF {
  nome?: string;
}

interface IRepresent {
  codigo?: string;
}

interface IClass {
  nome?: string;
}

const OrderReport: React.FC = () => {

  const date = new Date()
  const [report, setReport] = useState<Array<IReport>>([])

  useEffect(() => {
    getOrderReport()
  }, [])

  async function getOrderReport(){
    try {
      const result = await getReport()
      if(result){
        setReport(result.docs)
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  return(
    <Container>
      <DivRow>
        <Row>
          <Title>Relatório pedidos de saída</Title>
          <label>{formatDate(date)}</label>
        </Row>
      </DivRow>
      <DivItems>
        <table>
          <tr>
            <th>Chave</th>
            <th>Documento</th>
            <th>Movimentação</th>
            <th>Hora</th>
            <th>Aprovação</th>
            <th>Aprovador</th>
            <th>Estabelecimento</th>
            <th>Local Escrituração</th>
            <th>Pessoa</th>
            <th>Classe</th>
            <th>UF</th>
            <th>Representante</th>
            <th>IPI</th>
            <th>Seguro</th>
            <th>Frete</th>
            <th>Acréscimo</th>
            <th>Desconto</th>
            <th>Unitário</th>
            <th>Valor Total</th>
          </tr>
          {report.length ? report.map((item: any) => (
            <tr>
              <td>{item?.chcriacao}</td>
              <td>NFSe 2675</td>
              <td>{item.emissao ? formatDate(item.emissao) : ''}</td>
              <td>{item?.emissao.slice('11', '19')}</td>
              <td>{item.aprovacao ? formatDate(item.aprovacao) : ''}</td>
              <td>{item?.Aprovador?.nome}</td>
              <td>{item?.Estabelecimento?.nome}</td>
              <td>{item?.LocEscritu?.nome}</td>
              <td>{item?.Pessoa?.nome}</td>
              <td>{item?.Pessoa?.Classe?.nome}</td>
              <td>{item?.Pessoa?.UF?.codigo}</td>
              <td>{item?.Representante?.codigo}</td>
              <td className="right">{item.ipi ? formatMoney(item.ipi) : ''}</td>
              <td className="right">{item.seguro ? formatMoney(item.seguro) : ''}</td>
              <td className="right">{item.frete ? formatMoney(item.frete) : ''}</td>
              <td className="right">{item.acrescimo ? formatMoney(item.acrescimo) : ''}</td>
              <td className="right">{item.desconto ? formatMoney(item.desconto) : ''}</td>
              <td className="right">{item?.valor ? formatMoney(item.valor) : ''}</td>
              <td className="right">{item?.totalPedido ? formatMoney(item.totalPedido) : ''}</td>
            </tr>
          )) : null}
        </table>
      </DivItems>
    </Container>
  )
}

export default OrderReport;