import { Column } from "../../components/Table/types";

export const detalhesData = (payload: any, lookups: any, observacaoToShow: string) => {
    return [
        {
          type: 'title',
          value: payload.emissao ? 'Emissão' : '',
        },
        {
          value: payload.emissao ? payload.emissao : '',
        },
        {
          type: 'title',
          value: lookups.LocEscrituracao ? 'Local de escrituração' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.nome 
          ? `Nome: ${lookups.LocEscrituracao.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.telefone 
          ? `Fone: ${lookups.LocEscrituracao.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.endereco 
          ? `Endereço: ${lookups.LocEscrituracao.item.endereco}` : 'Endereço: ---' : ''
        },
        {
          type: 'title',
          value: lookups.Estabelecimento ? 'Estabelecimento' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.nome 
          ? `Nome: ${lookups.Estabelecimento.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.telefone 
          ? `Fone: ${lookups.Estabelecimento.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.email 
          ? `E-mail: ${lookups.Estabelecimento.item.email}` : 'E-mail: ---' : ''
        },
        {
          type: 'title',
          value: lookups.Pessoa ? 'Pessoa' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.nome 
          ? `Nome: ${lookups.Pessoa.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.telefone 
          ? `Fone: ${lookups.Pessoa.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.email 
          ? `E-mail: ${lookups.Pessoa.item.email}` : 'E-mail: ---' : ''
        },
        {
          type: 'title',
          value: lookups.CondicaoPagamento ? 'Pagamento' : '',
        },
        {
          value: lookups.CondicaoPagamento 
          ? lookups.CondicaoPagamento.item && lookups.CondicaoPagamento.item.nome 
          ? lookups.CondicaoPagamento.item.nome : '---' : ''
        },
        {
          type: 'title',
          value: payload.observacaoToShow ? 'Observação' : '',
        },
        {
          value: observacaoToShow || '',
        },
      ];
}

export const itemCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'Recurso',
          nome: 'Produto',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
        },
        {
          campo: 'Classe',
          nome: 'Classe',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'classes',
          classe: 'Vendas',
          fixo: true,
        },
        {
          campo: 'Nucleo',
          nome: 'Núcleo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Núcleos',
          fixo: true,
        },
        {
          campo: 'Lote',
          nome: 'Lote',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Lotes',
          fixo: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
        },
        {
          campo: 'unitario',
          nome: 'Pr. Unitário',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'vrItem',
          nome: 'Vr. Item',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'acrescimoItem',
          nome: 'Acr. Item',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'descontoItem',
          nome: 'Desc. Item',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'frete',
          nome: 'Frete',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'seguro',
          nome: 'Seguro',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'ipi',
          nome: 'IPI',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
        },
        {
          campo: 'valorTotal',
          nome: 'Valor Total',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}

export const tituloCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'TipoDocumento',
          nome: 'Tipo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Tipos de Documentos',
        },
        {
          campo: 'Carteira',
          nome: 'Carteira',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'Carteiras de Cobrança',
        },
        {
          campo: 'prazo',
          nome: 'Prazo',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
          fixo: true,
        },
        {
          campo: 'vencimento',
          nome: 'Vencimento',
          editavel: true,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'correcao',
          nome: 'Correção',
          editavel: true,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'valorPrincipal',
          nome: 'Vr. Principal',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juros',
          nome: 'Juros',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'juroPercm',
          nome: 'Juros (%)',
          editavel: true,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'multa',
          nome: 'Multa',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'multaPerc',
          nome: 'Multa (%)',
          editavel: true,
          tipo: 'texto',
          mascara: 'decimal',
          fixo: true,
        },
        {
          campo: 'acrescimo',
          nome: 'Acréscimo',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'desconto',
          nome: 'Desconto',
          editavel: true,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'valorCorrigido',
          nome: 'Vr. Corrigido',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}