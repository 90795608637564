enum Bases {
  LOCAL = "local",
  HOMOLOG = "homolog",
  PROD = "prod",
}

let BASE: Bases = Bases.HOMOLOG;

const currentDomain = window.location.href;

if (currentDomain.includes("devaribox.co")) {
  BASE = Bases.HOMOLOG;
} else if (currentDomain.includes("devari.com.br")) {
  BASE = Bases.PROD;
} else {
  BASE = Bases.PROD;
}

let CURRENT_STORE = "orange";

if (currentDomain.includes("gmdimensional")) {
  CURRENT_STORE = "gmdimensional";
} else if (currentDomain.includes("orange")) {
  CURRENT_STORE = "orange";
}

// OrangeDex para teste
// const ADMIN_ID = '61c944816522a55a6eb92bf5';
// Roberio diretoria para produção
const ADMIN_ID = '6404afb1c65c4c98337b6433';
const ID_LOC_ESCRIT_DEVARI = '629bde5e4a1f7c71bb8e6e46';

const ID_CLASSE_CLIENTES = '61cd2730ad47e0a02eab67d8';
const ID_CLASSE_USUARIOS_TRIAL = '659d578b8f4fd68fe5bfbc71';
const ID_CLASSE_USUARIOS_PREMIUM = '6595a33be2c1ba092da36375';
const ID_CLASSE_REGRA_PRECIFICACAO = '61e06d3f4145199672473f3c';

export { 
  BASE, CURRENT_STORE, ADMIN_ID, ID_CLASSE_CLIENTES,
  ID_CLASSE_USUARIOS_TRIAL, ID_CLASSE_USUARIOS_PREMIUM,
  ID_CLASSE_REGRA_PRECIFICACAO, ID_LOC_ESCRIT_DEVARI
};
