import styled from "styled-components";
import ButtonLayout from '../../components/Button';
import ArrowDown from "../../assets/images/down-arrow.png";
import Close from "../../assets/images/close.png";

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;

  & > div:nth-of-type(2)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }

  & .loading > svg {
    background: transparent;
    color: var(--primary);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background: var(--form-background);
  color: var(--white);
  overflow: auto;
  padding: 20px 16px 30px 16px;
`;

export const ContainerModal = styled.div`
  padding: 10px 20px;
  height: 100%;
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  /* .danger {
    background: var(--warning);
    border: 1px solid var(--warning);
    color: var(--white);
  } */

  & > span > svg, & > div > span > svg {
    animation: spin 1s linear infinite;
    color: var(--primary);
    font-size: 60px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            /* background: #FFFFFF; */
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            /* border: 4px solid rgba(76, 175, 80, .5); */
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            /* background-color: #FFFFFF; */
        }
    }
  }

  @keyframes rotate-circle {
      0% {
          transform: rotate(-45deg);
      }
      5% {
          transform: rotate(-45deg);
      }
      12% {
          transform: rotate(-405deg);
      }
      100% {
          transform: rotate(-405deg);
      }
  }

  @keyframes icon-line-tip {
      0% {
          width: 0;
          left: 1px;
          top: 19px;
      }
      54% {
          width: 0;
          left: 1px;
          top: 19px;
      }
      70% {
          width: 50px;
          left: -8px;
          top: 37px;
      }
      84% {
          width: 17px;
          left: 21px;
          top: 48px;
      }
      100% {
          width: 25px;
          left: 14px;
          top: 45px;
      }
  }

  @keyframes icon-line-long {
      0% {
          width: 0;
          right: 46px;
          top: 54px;
      }
      65% {
          width: 0;
          right: 46px;
          top: 54px;
      }
      84% {
          width: 55px;
          right: 0px;
          top: 35px;
      }
      100% {
          width: 47px;
          right: 8px;
          top: 38px;
      }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
  }

  p:first-child {
    margin-right: 10px;
  }

  & form {
    display: flex;
    flex-wrap: wrap;
  }

  .row {
    display: flex;
  }

  & form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & > input[type="search"] {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 95.2%;
    }

    & > .small {
      width: 70px;
    }

    & select {
      background: var(--form-background);
    }

    & input, & select {
      border: 1px solid var(--primary);
      border-radius: 6px;
      margin-top: 4px;
      padding: 6px 8px 4px 4px;
      height: 30px;
      width: 120px;
    }

    & > input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 4px;
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
    }

    & > input[type="search"]::-webkit-search-decoration,
    & > input[type="search"]::-webkit-search-results-button,
    & > input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      appearance: none;
    }

    & > input[type="search"]::-webkit-calendar-picker-indicator {
      opacity: 0;
      display: none;
    }

    option {
      text-transform: capitalize;
    }
  }

  & input, & select {
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: var(--form-background);
    color: var(--primary);
    font-size: 10px;
    margin-right: 10px;
    padding: 2px 4px;
  }

  & > form:nth-of-type(1) > div > label {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    margin-right: 0;
  }

  & input::placeholder {
    color: var(--primary);
  }
`;

export const Button = styled(ButtonLayout)`
  margin-bottom: 20px;
  margin-left: 10px;
  min-width: 150px;
  width: 16%;
`;