import styled from 'styled-components';
import AsyncSelectLayout from 'react-select/async';

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 4px;
  transition: .2s;
  padding: 2px 0 2px 0;
`;

export const Label = styled.p<ILabel>`
  font-size: 12px;
  font-weight: ${props => props.bold ? '500' : '300'};
  margin-bottom: 4px;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
  color: ${props => props.error ? 'var(--warning)' : 'var(--white)'};

  span {
    color: var(--warning);
  }
`;

export const AsyncSelect = styled(AsyncSelectLayout)`
  min-width: 180px;
  & > div {
    box-shadow: none;
  }

  & > div:nth-child(3) {
    background: var(--white);
    border: 1px solid var(--opacity-background);
    border-radius: 4px;
    box-shadow: none;
    color: var(--warning);
    font-size: 14px;
    font-weight: 300;
    height: 30px;
    padding: 0px 8px 0px 8px;
    white-space: nowrap;
    width: 100%;

    & > div {
      padding: 0;
    }

    &:focus {
      background-color: var(--warning);
      border: 1px solid var(--primary);
    }
    
  }

`;