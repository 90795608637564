import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formatDate, formatMoney } from '../../utils/format';
import {
  BodyOrder,
  Container,
  ContentHeader,
  DivHeader,
  DivRow,
  DivLeft,
  DivRight,
  Logo,
  DivItems,
  LogoFooter,
  Main
} from './style';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { getItemsAndTitulos } from '../../services/pedidos';
import Toast from '../Toast';

interface IRequest {
  Estabelecimento?: IEstabelecimento;
  LocEscritu?: ILocEscritu;
  Pessoa?: IPessoa;
  Representante?: IRepresentante;
  titulos: ITitulos[];
  acrescimo?: string;
  baixaAutom?: boolean;
  chcriacao?: number;
  desconto?: string;
  emissao: string | Date;
  frete?: string;
  idAcessorio1?: string;
  ipi?: number;
  items?: IItems[];
  observacao?: string;
  seguro?: string;
  totalPedido: number;
  valor: number;
}

interface IItem {
  item: IRequest;
}

interface IEstabelecimento {
  Classe?: IClasse;
  id: string;
  codigo: string;
  nome: string;
  foto: string;
}

interface ILocEscritu {
  Classe?: IClasse;
  id: string;
  nome: string;
}

interface IPessoa {
  Classe?: IClasse;
  id: string;
  nome: string;
  endereco?: string;
  numeroEndereco?: string;
  complemento?: string;
  bairro?: string;
  Cidade?: ICidade;
  UF?: IUf;
  cep?: string;
  celular?: string;
  telefone?: string;
  cpfCnpj?: string;
  email?: string;
}

interface ICidade {
  nome?: string;
}

interface IUf{
  nome?: string;
}

interface IRepresentante {
  Classe?: IClasse;
  id: string;
  nome: string;
  codigo: string;
}

interface IItems {
  Classe: IClasse;
  Estabelecimento?: IEstabelecimento;
  ItemsRequest?: any;
  LocEscritu?: ILocEscritu;
  Pessoa?: IPessoa;
  Representante?: IRepresentante;
  Recurso: IRecurso;
  acrescimo?: string;
  atualizadoEm?: string;
  criadoEm?: string;
  chave?: number; 
  chitem: number;
  desconto?: string;
  emissao?: string;
  excluido?: boolean;
  frete?: string;
  id: string;
  idClasse?: string;
  idEstabelecimento?: string;
  idLocEscritu?: string;
  idCore?: string;
  idPessoa?: string;
  idResource?: string;
  idRepresentante?: string;
  ipi?: number;
  inativo?: boolean;
  quantidade?: number;
  seguro?: string;
  total?: string;
  totalPedido?: string;
  unitario?: string;
  valor?: string;
}

interface IClasse {
  id: string;
  nome: string;
}

interface IRecurso {
  codigo: string;
  id: string;
  nome: string;
  ean: string;
  Classe: IClasse;
  UnidadeMedida: IUnidadeMedida;
}

interface IUnidadeMedida {
  id: string;
  nome: string;
}

interface ITitulos {
  Carteira?: ICarteira;
  id: string;
  idTipoDocumento?: string;
  acrescimo?: string;
  atualizadoEm?: string;
  chave?: number;
  chpedido?: number;
  carteira?: string;
  desconto?: string;
  juroPercm?: string;
  multa: string;
  prazo?: number;
  valorCorrigido?: string;
  valorPrincipal?: string;
  vencimento?: string;
}

interface ICarteira{
  Classe: IClasse;
  id: string;
  codigo?: string;
  nome?: string;
}

const EspelhoPedido: React.FC = () => {

  const location = useLocation<IItem>();
  const [request, setRequest] = useState<IRequest>();
  const [items, setItems] = useState<any>([]);
  const [titulos, setTitulos] = useState<any>([]);
  const componentRef = useRef(null);

  useEffect(() => {
    if (location.state) {
      const detail = location.state.item;

      if (detail.chcriacao) {
        getPedidoInfo(detail.chcriacao);
      }

      setRequest(detail)
    }
  }, [location])

  async function getPedidoInfo(chave: number) {
    try {
      const result = await getItemsAndTitulos([chave], true);
      setItems(result.items);
      setTitulos(result.titulos);
    } catch (err: any) {
      return Toast.show(err.message, 'error');
    }
  }

  const pageStyle = `
    @media print{
      html, body{
        border-collapse: collapse;
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      table, th, td {
        border-collapse: collapse;
      }
      .page-break {
        margin-top: 20px !important;
        display: block;
        page-break-before: auto;
      }
    }
    @page{
      margin: 20mm;
      size: auto;
    }
  `

  return (
    <Main>
      <ReactToPrint
        trigger={() => {

          return <button type='button' className='buttonPrint'>IMPRIMIR</button>
        }}
        content={() => componentRef.current}
        documentTitle="new document"
        pageStyle={pageStyle}
      />

      <Container ref={componentRef}>
        <p style={{ textAlign: 'center' }}>Espelho de Pedido</p>
        <DivHeader>
          <Logo>
            <img src={request?.Estabelecimento?.foto} alt="Logo" />
          </Logo>
          <ContentHeader>
            <p>Ch. Pedido: <strong>{request?.chcriacao}</strong></p>
            <p>Data do pedido: <strong>{request?.emissao ? formatDate(request.emissao) : ''}</strong></p>
            <p>Usuário: <strong>{request?.Representante?.nome || request?.Representante?.codigo || request?.Representante?.id}</strong></p>
          </ContentHeader>
        </DivHeader>
        <BodyOrder>
          <DivRow>
            <DivLeft>
              <h3>Informações Gerais</h3>
              <p>Data de emissão: <strong>{request?.emissao ? formatDate(request.emissao) : ''}</strong></p>
              <p>Usuário aprovado: </p>
              <p>Estabelecimento: <strong>{request?.Estabelecimento?.codigo} - {request?.Estabelecimento?.nome}</strong></p>
              <p>Local de Escrituração: <strong>{request?.LocEscritu?.nome}</strong></p>
              <p>Obervação: {request?.observacao ? request.observacao : ''}</p>
            </DivLeft>
            <DivRight>
              <h3>Dados Pessoais</h3>
              <p>Nome: <strong>{request?.Pessoa?.nome}</strong></p>
              <p>Endereço: <strong>{request?.Pessoa?.endereco ? `${request.Pessoa.endereco}, ${request.Pessoa.numeroEndereco}` : ''}</strong></p>
              {request?.Pessoa?.complemento ? <p>{request.Pessoa.complemento}</p> : ''}
              <p>Bairro: <strong>{request?.Pessoa?.bairro}</strong></p>
              <p>Cidade: <strong>{request?.Pessoa?.Cidade?.nome ? request?.Pessoa?.Cidade.nome : ''} {request?.Pessoa?.UF?.nome ? ` - ${request.Pessoa.UF.nome}` : ''}</strong></p>
              <p>CEP: <strong>{request?.Pessoa?.cep}</strong></p>
              <p>Fone: <strong>{request?.Pessoa?.telefone}</strong> / Celular <strong>{request?.Pessoa?.celular}</strong></p>
              <p>Email: <strong>{request?.Pessoa?.email}</strong></p>
              <p>CPF/CNPJ: <strong>{request?.Pessoa?.cpfCnpj}</strong></p>
            </DivRight>
          </DivRow>
          {/* <DivRow>
          <DivLeft>
          <h3>Transportador</h3>
          </DivLeft>
          <DivRight>
          <h3>Entrega</h3>
          <p>Nome: <strong>{request?.Pessoa?.nome}</strong></p>
          <p></p>
          </DivRight>
        </DivRow> */}
          <DivItems>
            <h3>Itens</h3>
            <table>
              <tr>
                <th>Situação</th>
                <th>Chave</th>
                <th>Classe</th>
                <th>Recurso</th>
                <th>UN</th>
                <th>Lote</th>
                <th>Qtd</th>
                <th>Valor unit.</th>
                <th>Desconto</th>
                <th>Acréscimo</th>
                <th>Valor IPI</th>
                <th>Valot Total</th>
              </tr>
              {items.map((item: any, index: number) => (
                <tr key={index}>
                  <td></td>
                  <td className='left'>{item.chave}</td>
                  <td className='left'>{item.Classe.nome}</td>
                  <td className='left'>{item.Recurso.codigo} - {item.Recurso.nome} - {item.Recurso.ean}</td>
                  <td>UN</td>
                  <td></td>
                  <td className='right'>{item.quantidade}</td>
                  <td className='right'>{item.unitario ? formatMoney(item.unitario) : ''}</td>
                  <td className='right'>{item.desconto ? formatMoney(parseFloat(item.desconto ? item.desconto : 0) + parseFloat(item.descontoItem ? item.descontoItem : 0)) : ''}</td>
                  <td className='right'>{item.acrescimo ? formatMoney(parseFloat(item.acrescimo ? item.acrescimo : 0) + parseFloat(item.acrescimoItem ? item.acrescimoItem : 0)) : ''}</td>
                  <td className='right'>{item.ipi ? formatMoney(item.ipi) : formatMoney('0')}</td>
                  <td className='right'>{item.total ? formatMoney(item.total) : ''}</td>
                </tr>
              ))}
            </table>
          </DivItems>
          <DivRow>
            <DivItems>
              <h3>Títulos</h3>
              <table>
                <tr>
                  <th>Chave</th>
                  <th>Número</th>
                  <th>Prazo</th>
                  <th>Vencimento</th>
                  <th>Correção</th>
                  <th>Carteira</th>
                  <th>Valor Principal</th>
                  <th>Desconto</th>
                  <th>Juros</th>
                  <th>Multa</th>
                  <th>Acréscimo</th>
                  <th>Valor Corrigido</th>
                </tr>
                {titulos.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.chave}</td>
                    <td>{item.chpedido}/{index + 1}</td>
                    <td className='right'>{item.prazo}</td>
                    <td className='right'>{item.vencimento ? formatDate(item.vencimento) : ''}</td>
                    <td className='right'>{item.atualizadoEm ? formatDate(item.atualizadoEm) : ''}</td>
                    <td className='left'>{item.Carteira?.nome ? item.Carteira.nome : ''}</td>
                    <td className='right'>{item.valorPrincipal ? formatMoney(item.valorPrincipal) : ''}</td>
                    <td className='right'>{item.desconto ? formatMoney(item.desconto) : ''}</td>
                    <td className='right'>{item.juroPercm ? formatMoney(item.juroPercm) : formatMoney('0')}</td>
                    <td className='right'>{item.multa ? formatMoney(item.multa) : formatMoney('0')}</td>
                    <td className='right'>{item.acrescimo ? formatMoney(item.acrescimo) : ''}</td>
                    <td className='right'>{item.valorCorrigido ? formatMoney(item.valorCorrigido) : ''}</td>
                  </tr>
                ))}
              </table>
            </DivItems>
          </DivRow>
          <DivRow>
            <DivLeft>
              <h3>Visto</h3>
              <div>
                <p>________ de ______________ de _____________</p>
                <p>___________________________________________</p>
              </div>
            </DivLeft>
            <DivRight>
              <h3>Totais</h3>
              <div>
                <p>Total de Mercadorias:</p>
                <p>{request?.valor ? formatMoney(request.valor) : ''}</p>
              </div>
              <div>
                <p>IPI:</p>
                <p><strong>{request?.ipi ? formatMoney(request.ipi) : ''}</strong></p>
              </div>
              <div>
                <p><strong>Total NF:</strong></p>
                <p><strong>{request?.valor ? formatMoney(request.totalPedido) : ''}</strong></p>
              </div>
            </DivRight>
          </DivRow>
        </BodyOrder>
        <LogoFooter>
          <img src={LogoDevari} alt="Logo" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default EspelhoPedido;
