import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import { useFormModal } from "../../contexts/formModal";
import { Button } from "../Orders/style";
import { Column, Form, Row } from "./style";
import useFetch from "../../hooks/useFetch";
import { IVideo } from ".";
import { postVideos, putVideos } from "../../services/training";

interface INewVideo {
  getVideo(): void;
  newStateVideo?(ev: any): void;
  endpoint: string;
  title: string;
  id: string;
  video?: IVideo | null;
}

const NewVideoModal: React.FC<INewVideo> = ({
  getVideo,
  newStateVideo,
  endpoint,
  title,
  id,
  video,
}) => {
  const provider = useFetch({ endpoint });
  const { closeModal } = useFormModal();
  const [loading, setLoading] = useState(false);
  const [payloadVideo, setPayloadVideo] = useState({
    codigo: "",
    observacao: "",
    quantidade: 1,
    nome: "",
    idClasse: id,
    id: "",
  });

  useEffect(() => {
    if (title === "Novo Vídeo") {
      return;
    } else if (video && title === "Alterar") {
      setPayloadVideo({
        ...payloadVideo,
        codigo: video?.codigo,
        observacao: video?.observacao,
        quantidade: video?.quantidade,
        nome: video?.nome,
        id: video?.id,
      });
    } else {
      closeModal();
      toast.error("Selecione o item que deseja editar");
    }
  }, []);

  const handlePayloadVideo = (key: any, value: any) => {
    if (key === "quantidade") {
      if (value !== 0 && value < 1) {
        toast.error("O valor não pode ser inferior a 1");
      } else {
        setPayloadVideo({ ...payloadVideo, [key]: value });
      }
    } else {
      setPayloadVideo({ ...payloadVideo, [key]: value });
    }
  };
  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    if (handleValidate()) {
      setLoading(true);
      try {
        if (title === "Novo Vídeo") {
          const { codigo, observacao, quantidade, nome, idClasse } =
            payloadVideo;
          const result = await postVideos({
            codigo,
            observacao,
            quantidade,
            nome,
            idClasse,
          });
          if (newStateVideo) {
            newStateVideo(result.data);
            closeModal();
            setLoading(false);
            toast.success("Salvo com sucesso.");
          }
        } else {
          const { codigo, observacao, quantidade, nome } = payloadVideo;
          const resultPut = await putVideos(id, {
            codigo,
            observacao,
            quantidade,
            nome,
          });

          if (resultPut && newStateVideo) {
            newStateVideo(resultPut);
            closeModal();
            setLoading(false);
            toast.success("Alterado com sucesso.");
          }
        }
      } catch (error) {
        console.error("Erro: ", error);
        closeModal();
      }
      setLoading(false);
    }
  };

  const handleValidate = () => {
    if (payloadVideo.codigo === "") {
      toast.error("Adicione um título");
    } else if (payloadVideo.quantidade < 1) {
      toast.error("Digite a ordem de exibição do vídeo");
    } else if (payloadVideo.nome === "") {
      toast.error("Adicione a url do vídeo");
    } else if (payloadVideo.observacao === "") {
      toast.error("Adicione uma descrição");
    } else {
      return true;
    }
  };

  return (
    <Form onSubmit={(ev) => handleSubmit(ev)} autoComplete="off">
      <Row>
        <Column wide={"80%"} padd={"10px 20px"}>
          <label htmlFor="title">
            Título <span>*</span>
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={payloadVideo.codigo ? payloadVideo.codigo : ""}
            onChange={({ target }) =>
              handlePayloadVideo("codigo", target.value)
            }
          />
        </Column>
        <Column wide={"20%"} padd={"10px 20px"}>
          <label htmlFor="order">
            Ordem <span>*</span>
          </label>
          <input
            type="number"
            name="order"
            id="order"
            min={1}
            value={payloadVideo.quantidade ? payloadVideo.quantidade : ""}
            onChange={({ target }) =>
              handlePayloadVideo("quantidade", +target.value)
            }
          />
        </Column>
      </Row>
      <Row>
        <Column padd={"10px 20px"}>
          <label htmlFor="link">
            ID Vídeo <span>*</span>
          </label>
          <input
            type="text"
            name="link"
            id="link"
            value={payloadVideo.nome ? payloadVideo.nome : ""}
            onChange={({ target }) => handlePayloadVideo("nome", target.value)}
          />
        </Column>
      </Row>
      <Row>
        <Column padd={"10px 20px"}>
          <label htmlFor="description">
            Descrição <span>*</span>
          </label>
          <textarea
            name="description"
            id="description"
            cols={30}
            rows={10}
            value={payloadVideo.observacao ? payloadVideo.observacao : ""}
            onChange={({ target }) =>
              handlePayloadVideo("observacao", target.value)
            }
          ></textarea>
        </Column>
      </Row>
      <Row jContent={"flex-end"} aItems={"center"} className="buttons">
        {loading && (
          <span>
            <AiOutlineLoading />
          </span>
        )}{" "}
        &nbsp;
        <Button
          type="button"
          wide={"auto"}
          background={"var(--warning)"}
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button type="submit" wide={"auto"} disabled={loading}>
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default NewVideoModal;
