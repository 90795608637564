import React, { useCallback, useState } from "react";
import { Button, MContainer, Row } from "./style";
import LookupInput from "../../components/LookupInput";
import TextInput from "../../components/TextInput";
import { putUser } from "../../services/user";

import Toast from "../../components/Toast";

interface IModalEditUser {
  user: any,
  callback: () => void;
}

const ModalEditUser : React.FC<IModalEditUser> = ({
  user,
  callback
}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    Classe: {label: user.Classe.nome, value: user.Classe.id}
  });

  const onChange = useCallback((field, value) => {
    setPayload({...payload, [field]: value})
  }, [payload]);

  const handleSubmit = useCallback(async () => {
    const { Classe, ...rest } = payload;
    setLoading(true);
    try {
      const _payload = { ...rest, idClasse: Classe.value };
      await putUser(user.id, _payload);
      Toast.show("Atualizado", "success");
      callback();
    } catch (error:any) {
      Toast.show(error.message, "error");
    }
    setLoading(false);
  }, [payload])

  return (
    <MContainer>
      <LookupInput 
      endpoint="/classes"
      label="Classe"      
      initialState={[payload.Classe]}
      multi={false}
      onChange={(data:any) => onChange("Classe", {label: data.label, value: data.value})}
      field="nome"
      filters={{id: '61cd2731ad47e0a02eab67dd'}}
      />
      <TextInput
        label="Status Conta"
        id="inativo"
        value={user.inativo}
        type="checkbox"
        onChange={({ target }) => onChange("inativo", target.value)}
      />
      <TextInput
        label="Bloqueado"
        id="excluido"
        value={user.excluido}
        type="checkbox"
        onChange={({ target }) => onChange("excluido", target.value)}
      />

      <Row>
        <Button onClick={handleSubmit} disabled={loading}>
          Atualizar
        </Button>
      </Row>
    </MContainer>
  )
}

export default ModalEditUser;