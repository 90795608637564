import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import { orderCols } from "../../utils/form";
import { IRepresentative, IResponse } from "../Orders";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import { Button, Container, Content, Row, Title } from "./style";
import useFetch from "../../hooks/useFetch";
import { statusUser } from "../../services/user";
import Toast from "../../components/Toast";
import { AiOutlineLoading } from "react-icons/ai";
import { useFormModal } from "../../contexts/formModal";
import ModalEditUser from "./modalEditUser";
import { IClasse, getClasses } from "../../services/classes";
import { getEntities, getSearchEntities } from "../../services/entidade";
import { IEntidade } from "../../services/auth";

interface IUser {
  endpoint: string;
  filters?: object;
  multipleSelect: boolean;
}

interface IFilterUser {
  idRepresentante: string;
  idClasseUsuario: string;
  idIndicador: string;
}

const User: React.FC<IUser> = ({ endpoint, filters, multipleSelect }) => {
  const provider = useFetch({ endpoint });
  const fields = useFetch({ endpoint: `${endpoint}/campos` });
  const { openModal, closeModal } = useFormModal();
  const [currentPage, setCurrentPage] = useState(1);
  const idPai: string = '61cd2731ad47e0a02eab67dd';
  const [classes, setClasses] = useState<IClasse[]>([]);
  const [loading, setLoading] = useState(true);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [users, setUsers] = useState<IResponse | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);
  const [representative, setRepresentative] = useState<IRepresentative[]>([]);
  const [usersPremium, setUsersPremium] = useState<any[]>([]);
  const [searchRepresentative, setSearchRepresentative] = useState<string>("");
  const [filterUsers, setFilterUsers] = useState<IFilterUser>({
    idRepresentante: "idRepresentante",
    idClasseUsuario: "idClasseUsuario",
    idIndicador: "idIndicador"
  })

  useEffect(() => {
    getTableFields();
  }, [currentPage, quantityPerPage]);

  useEffect(() => {
    getClassUser();
  }, [])

  useEffect(() => {
    if(filterUsers.idRepresentante !== "idRepresentante" ||
      filterUsers.idClasseUsuario !== "idClasseUsuario" ||
      filterUsers.idIndicador !== "idIndicador") {
        getFilterUsers();
      } else {
        getTableFields()
      }
  }, [filterUsers])

  useEffect(() => {
    if (searchRepresentative?.length < 3) return;
      let timer = setTimeout(() => {
        searchEntities(searchRepresentative);
      }, 500);
      return () => clearTimeout(timer);
  }, [searchRepresentative])

  const getTableFields = async () => {
    try {
      setLoading(true);
      const result = await fields.get(filters);

      if (result) {
        const user = result.sort(orderCols);

        setCols(user);

        const resultUsers = await provider.get({
          page: currentPage,
          idClasse: "61cd2731ad47e0a02eab67dd", //classe Clientes
          classe: "Usuários",
          take: quantityPerPage,
          // calculaGanhos: true,
          orderBy: { criadoEm: "desc" },
        });
        if (resultUsers) {
          setUsers(resultUsers);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getClassUser = async () => {
    try {
      const result = await getClasses({id: idPai});
      if (result) {
        const classePremium = result.find((classe: IClasse) => classe.nome === "Usuários Premium")
        const _classes = result.filter((classe: IClasse) => classe.nome !== "Usuários Diretoria") 

        setClasses(_classes)
        
        // Buscar usuários premium
        if (classePremium) {
          const resultUsers = await provider.get({
            idClasse: classePremium.id, //classe Clientes
            classe: "Usuários",
            // take: quantityPerPage,
            // calculaGanhos: true,
            orderBy: { criadoEm: "desc" },
          });
          if (resultUsers) {
            setUsersPremium(resultUsers)
          }
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }  
  }

  const searchEntities = async (filter: string) => {
    try {
      const result = await getSearchEntities(filter)
      if (result) {
        const _result = result.map((item: IEntidade) => {
          return {
            id: item.id,
            nome: item.nome
          }
        })

        setRepresentative(_result)
      }
    } catch (error) {
      console.error("Erro: ", error)
    }
  }

  const getFilterUsers = async () => {
    try {
      setLoading(true)
      const result = await getEntities(filterUsers)
      if(result) {
        setUsers(result)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Erro: ", error);
    }
  }

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const handleActive = async (itens: any) => {
    try {
      setLoading(true);
      const result = await statusUser(itens);
      if (result.status === 201) {
        getTableFields();
        Toast.show("Alterado com sucesso.", "success");
      }
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const indexSelected = () => {
    if (users) {
      let itens: any = [];
      let docs = [...users?.docs];

      selectedIndexes.map((item: any, index: any) => {
        if (item === true) {
          let ativo = docs[index].excluido;
          docs[index].excluido = !ativo;

          itens.push(docs[index].id);
        }
      });

      if (itens.length > 0) {
        handleActive(itens);
      }
    }
  };

  function isTrue(item: any) {
    return item === false;
  }

  const handleOpenForm = (id: any) => {
    const item = users?.docs.find((item) => item.id === id);
    openModal({
      type: "confirm",
      title: `Editar ${item.nome}`,
      children: () => (
        <ModalEditUser
          user={item}
          callback={() => {
            closeModal();
            getTableFields();
          }}
        />
      ),
    });
  };

  const handleSearch = (type: string, value: string) => {
    const selected = representative.find(rep => rep.nome === value);
    if(!value) {
      if (type === "idRepresentante") {
        setFilterUsers({
          ...filterUsers,
          idRepresentante: "idRepresentante"
        })
        setSearchRepresentative("")
      } else if (type === "idClasseUsuario") {
        setFilterUsers({
          ...filterUsers,
          idClasseUsuario: "idClasseUsuario"
        })
      } else if (type === "idIndicador") {
        setFilterUsers({
          ...filterUsers,
          idIndicador: "idIndicador"
        })
      }
    } else {
      if(["idClasseUsuario", "idIndicador"].includes(type)) {
        setFilterUsers({
          ...filterUsers,
          [type]: value
        })
      }
    }
    if(selected) {
      if(type === "idRepresentante") {
        setFilterUsers({
          ...filterUsers,
          idRepresentante: selected.id
        })
        setSearchRepresentative(value)
      }
    } else {
      if(type === "idRepresentante") {
        setSearchRepresentative(value)
      }
    }
  }

  return (
    <Container>
      <Title>Usuários</Title>
      <Row style={{ padding: "10px 20px 0 20px" }} className="filters">
        <div style={{ display: 'flex' }}>
          <p className="primary">Filtros</p>
          <form autoComplete="off">
            <div>
              <select name="classeUsuario" id="classeUsuario" onChange={(ev) => handleSearch("idClasseUsuario", ev.target.value)}>
                <option value="idClasseUsuario" selected>Classe Usuário</option>
                {classes.map((classe: IClasse) => (
                  <option key={classe.id} value={classe.id}>{classe.nome}</option>
                ))}
              </select>
            </div>
            <div>
              <input
                list="representantes"
                name="representative"
                id="representative"
                type="search"
                autoComplete="chrome-off"
                value={searchRepresentative}
                onChange={(ev) => handleSearch("idRepresentante", ev.target.value)}
                placeholder={filterUsers.idRepresentante.replace(
                  "idRepresentante",
                  "Nome"
                )}
              />
              <datalist id="representantes">
                {representative.length > 0
                  ? representative.map((item: IRepresentative) => (
                      <option value={item.nome} key={item.id}>
                        {item.nome}
                      </option>
                    ))
                  : null}
              </datalist>
            </div>
            <div>
              <select name="indicador" id="indicador" onChange={(ev) => handleSearch("idIndicador", ev.target.value)}>
                <option value="idIndicador" selected>Indicador</option>
                {usersPremium.map((user: any) => (
                  <option value={user.id} key={user.id}>{user.nome}</option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div>
          {loading && (
            <span>
              <AiOutlineLoading />
            </span>
          )}
          <Button
            type="button"
            onClick={() => indexSelected()}
            disabled={selectedIndexes.every(isTrue) || loading == true}
          >
            Alterar Status
          </Button>
        </div>
      </Row>
      <Content>
        <ListTable
          data={users ? users.docs : []}
          multipleSelect={multipleSelect}
          fields={cols}
          changeSelectedIndexes={setSelectedIndexes}
          onChangePage={handleChangePage}
          onClick={handleOpenForm}
          currentPage={currentPage}
          totalPages={users ? users.totalPages : 1}
          count={users ? users.total : 0}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Linhas por página: &nbsp;</p>
          <select
            name="quantityPerPage"
            id="quantityPerPage"
            onChange={({ target }) => handleQuantityPerPage(target.value)}
          >
            <option value="5">5</option>
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={users ? users.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default User;
