import provider from './config';

export const getAddressCep = async (cep: string): Promise<any> =>{
  try {
    const response = await provider.get("/entidades/correios/", {
      params: {
        cep
      }  
    });

    return response.data;
  } catch (error: any) {
    throw Error("Ocorreu um erro ao tentar calcular o pedido");
  }
}

export const getCounties = async (state?: any, currentPage?: any) => {
  try {
    const params: any = {
      classe: "munic"
    }
    if(currentPage){
      params['page'] = currentPage
    }
    if(state){
      params['uf'] = state
    }
    const response = await provider.get("/tabela", { params });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os municípios");
  }
}

export const getStates = async (currentPage?: any) => {
  try {
    const params: any = {
      classe: "Unidades da Federação"
    }
    if(currentPage){
      params['page'] = currentPage
    }
    const response = await provider.get("/tabela", { params });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os estados");
  }
}