import { BASE, CURRENT_STORE } from ".";

interface IStoreAPI {
  local?: string;
  homolog?: string;
  prod?: string;
}

interface IStoresAPIs {
  [key: string]: IStoreAPI;
}

export interface IStoreColors {
  primary?: string;
  primaryDarker?: string;
  primaryLighter?: string;
  secondary?: string;
  cardColor?: string;
  formBackground?: string;
  formSelected?: string;
  opacityBackground?: string;
  whiteOpacityBackground?: string;
  primaryTextColor?: string;
  primaryComplementaryTextColor?: string;
  inputBorderFocused?: string;
  inputBorderBlurred?: string;
  background?: string;
  white?: string;
  black?: string;
  green?: string;
  muted?: string;
  disabled?: string;
  warning?: string;
  alert?: string;
  menuBackground?: string;
}

interface IStoresColors {
  [key: string]: IStoreColors;
}

const StoresAPIs: IStoresAPIs = {
  local: {
    local: "http://localhost:3001/api/v1",
    homolog: "https://api-erp.devaribox.co/api/v1",
    prod: "https://api-erp.devaribox.co/api/v1",
  },
  gmdimensional: {
    local: "https://api-gmdimensional.devari.com.br/api/v1",
    homolog: "https://api-gmdimensional.devari.com.br/api/v1",
    prod: "https://api-gmdimensional.devari.com.br/api/v1",
  },
  orange: {
    local: "http://192.168.1.3:3001/api/v1",
    homolog: "https://crypto-api.devari.com.br/api/v1",
    prod: "https://crypto-api.devari.com.br/api/v1",
  },
};

const StoresColors: IStoresColors = {
  local: {
    primary: "#2C7D95",
    primaryDarker: "#2B4C84",
    primaryLighter: "#2DACC6",
    secondary: "#FF8A00",
    cardColor: "#eed8aa",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#555",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    menuBackground: "#3C4B64",
  },
  gmdimensional: {
    primary: "#2C7D95",
    primaryDarker: "#2B4C84",
    primaryLighter: "#2DACC6",
    secondary: "#FF8A00",
    cardColor: "#eed8aa",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#555",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    menuBackground: "#3C4B64",
  },
  orange: {
    primary: "#00fbca", //"#173F54",
    primaryDarker: "#1a5351",
    primaryLighter: "#23f0c6",
    secondary: "#6EC1E4",
    cardColor: "#041945",
    formBackground: "#181f24",
    formSelected: "#40404E",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#94949b",
    primaryComplementaryTextColor: "#9F8CB7",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    black: "#000000",
    green: "#1AAA42",
    muted: "#888", //#9F8CB7
    disabled: "#ccc",
    background: "#12191f",
    warning: "#E73E80",
    menuBackground: "#27273F",
  }
};

export const BASE_URL = StoresAPIs[CURRENT_STORE][BASE];
export const COLORS = StoresColors[CURRENT_STORE];
