import Toast from "../components/Toast";
import Provider from "./config";

export const getBalance = async (id: any) => {
  try {
    const result = await Provider.get("exchanges/account/binanceInfo", {
      params: { idUser: id },
    });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar o saldo.");
  }
};

export const getOpenOrders = async (
  currentPage: number,
  id: any,
  growing?: any,
  take?: any,
  filter?: any
) => {
  const params: any = {
    idUser: id,
    classe: "C Crypto P Invest",
    page: currentPage,
    baixa: false,
  };
  if (filter) {
    if (filter.corretora !== "todasCorretoras") {
      params["corretora"] = filter.corretora;
    }
    if (filter.moeda !== "todosPares") {
      params["moeda"] = filter.moeda.toUpperCase();
    }
    if (filter.observacao !== "tipoEntrada") {
      params["observacao"] = filter.observacao.toLowerCase();
    }
    if (filter.emissaoInicial !== "today" && filter.emissaoFinal !== "today") {
      params["emissaoInicial"] = filter.emissaoInicial;
      params["emissaoFinal"] = filter.emissaoFinal;
    }
    if (filter.idRepresentante !== "idRepresentante") {
      params["idRepresentante"] = filter.idRepresentante;
    }
    if (filter.alvo > 0) {
      params["alvo"] = filter.alvo;
    }
  }
  if (growing) {
    if (growing.item !== "") {
      if (growing.item === "Par") {
        params["moeda"] = growing.asc === "true" ? "asc" : "desc";
      } else {
        params["quantidade"] = growing.asc === "true" ? "asc" : "desc";
      }
    }
  }
  if (take) {
    params["take"] = take;
  }
  try {
    const result = await Provider.get("pedidos/ordens", { params });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar ordens abertas.");
  }
};

export const getClosedOrders = async (
  currentPage: number,
  id: any,
  growing?: any,
  take?: any,
  filter?: any
) => {
  const params: any = {
    idUser: id,
    classe: "C Crypto P Invest",
    page: currentPage,
    baixa: true,
  };
  if (filter) {
    if (filter.corretora !== "todasCorretoras") {
      params["corretora"] = filter.corretora;
    }
    if (filter.moeda !== "todosPares") {
      params["moeda"] = filter.moeda.toUpperCase();
    }
    if (filter.observacao !== "tipoEntrada") {
      params["observacao"] = filter.observacao.toLowerCase();
    }
    if (filter.emissaoInicial !== "today" && filter.emissaoFinal !== "today") {
      params["emissaoInicial"] = filter.emissaoInicial;
      params["emissaoFinal"] = filter.emissaoFinal;
    }
    if (filter.idRepresentante !== "idRepresentante") {
      params["idRepresentante"] = filter.idRepresentante;
    }
    if (filter.alvo > 0) {
      params["alvo"] = filter.alvo;
    }
  }
  if (growing) {
    if (growing.item !== "") {
      if (growing.item === "Par") {
        params["moeda"] = growing.asc === "true" ? "asc" : "desc";
      } else {
        params["quantidade"] = growing.asc === "true" ? "asc" : "desc";
      }
    }
  }
  if (take) {
    params["take"] = take;
  }

  try {
    const result = await Provider.get("pedidos/ordens", { params });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar ordens fechadas.");
  }
};

export const getPendingOrders = async (
  currentPage: number,
  id: any,
  filter?: any
) => {
  const params: any = {
    idUser: id,
    classe: "C Crypto P Invest",
    page: currentPage,
    baixa: "null",
  };
  
  if (filter) {
    if (filter.corretora !== "todasCorretoras") {
      params["corretora"] = filter.corretora;
    }
    if (filter.moeda && filter.moeda !== "todosPares") {
      params["moeda"] = filter.moeda.toUpperCase();
    }
    if (filter.observacao && filter.observacao !== "tipoEntrada") {
      params["observacao"] = filter.observacao.toLowerCase();
    }
    if (filter.emissaoInicial !== "today" && filter.emissaoFinal !== "today") {
      params["emissaoInicial"] = filter.emissaoInicial;
      params["emissaoFinal"] = filter.emissaoFinal;
    }
    if (filter.idRepresentante !== "idRepresentante") {
      params["idRepresentante"] = filter.idRepresentante;
    }
    if (filter.alvo > 0) {
      params["alvo"] = filter.alvo;
    }
    if (filter.classe) {
      params["classe"] = filter.classe;
    }
    if (filter.baixa === "false") {
      params["baixa"] = filter.baixa;
    }
  }
  
  try {
    const result = await Provider.get("pedidos", { params });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar ordens pendentes.");
  }
};

export const getFilterOrders = async (
  currentPage: number,
  filter: any,
  id: any
) => {
  try {
    const result = await Provider.get("pedidos", {
      params: {
        idUser: id,
        classe: "V Crypto P Invest",
        page: currentPage,
        baixa: false,
        filter: filter,
      },
    });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar ordens abertas.");
  }
};

export const getFields = async (id: any) => {
  try {
    const result = await Provider.get("pedidos/campos", {
      params: {
        idUser: id,
        classe: "V Crypto P Invest",
      },
    });

    return result.data;
  } catch (err: any) {
    throw Error("Erro ao buscar os campos da tabela.");
  }
};

export const postOrders = async (payload: any) => {
  try {
    const response = await Provider.post(
      `exchanges/account/binance/open-order/`,
      payload
    );

    return response;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      Toast.show("Erro ao enviar os dados.", "error");
    }
    throw Error("Erro ao enviar os dados.");
  }
};

export const postSellOrder = async (chcriacao: any, id: any, tipo:string) => {
  try {
    const response = await Provider.post(`exchanges/ordem-vender`, {
      ids: chcriacao,
      userId: id,
      tipo: tipo
    });

    return response;
  } catch (err: any) {
    throw Error("Erro ao vender ordem.");
  }
};

export const postPauseOrder = async (chcriacao: any, id: any) => {
  try {
    const response = await Provider.post(`exchanges/ordem-pausar`, {
      ids: chcriacao,
      userId: id,
    });

    return response;
  } catch (err: any) {
    throw Error("Erro ao pausar ordem.");
  }
};

export const encerrarOrdens = async (userId: any) => {
  try {
    const response = await Provider.post(`exchanges/account/binance/cancel-all-orders`, {userId});
    return response;
  } catch (error: any) {
    throw Error("Erro ao cancelar ordens");
  }
}

export const cancelaOrdensLimite = async (payload: any) => {
  try {
    const response = await Provider.post('exchanges/account/binance/cancel-limit-order', payload);
    return response;
  } catch( error: any) {
    throw Error("Error ao cancelar ordens limite");
  }
}

export const getCoin = async (id: any) => {
  try {
    const response = await Provider.get("pedidos/coinsfilter", {
      params: { userId: id, closed: false },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar moedas.");
  }
};

export const getCoinClosed = async (id: any) => {
  try {
    const response = await Provider.get("pedidos/coinsfilter", {
      params: { userId: id, closed: true },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar moedas.");
  }
};

export const getCoinPending = async (id: any) => {
  try {
    const response = await Provider.get("pedidos/coinsfilter", {
      params: { userId: id },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Erro ao buscar moedas.");
  }
};
