import React from 'react';
import {Column} from '../types';
import {
  TableHeadGroup,
  TableHeadElement,
  TableHeadCell
} from './styles';

export type TableRowProps = {
  columns?: Column[];
};

function TableHead({columns}: TableRowProps) {
  const renderRowElements = () =>
    columns && columns.map(column => 
      {
        return (
          <TableHeadCell key={column.name ? column.name : column.nome} isLookup={column.tipo && column.tipo === 'lookup'}>
            {column.name ? column.name : column.nome}
          </TableHeadCell>
        )
      }
    );

  return (
    <TableHeadGroup>
      <TableHeadElement>
        {renderRowElements()}
      </TableHeadElement>
    </TableHeadGroup>
  )
}

export default TableHead;